<div class="container-fluid app-retailers">

    <h1 class="title-head">
        {{ 'retailers.head_title' | translate }}
        <div class="line-full"></div>
    </h1>

    <p *ngIf="!loggedIn">
        <ngb-alert [dismissible]="true" *ngIf="!closed" (close)="closed=true">
            <strong>{{ 'general.warning' | translate }} </strong>  <span [innerHtml]="trans('retailers.login_warn')"></span>
        </ngb-alert>
    </p>

    <div class="">
        <app-alert [global]="false"></app-alert>
    </div>

    <!-- filter zone -->
    <mat-card class="filter-wrapper">
        <mat-card-content class="filter-content">
            <div class="left-filter">

                <div class="field">
                    <div class="sort-icon">
                        <mat-icon>category</mat-icon>
                    </div>
                    <mat-form-field class="select-category">
                        <mat-select [placeholder]="trans('retailers.category')" [value]="selectedCategory"
                        (selectionChange)="changeCatOption($event)" [disabled]="categoryLoading || loading">
                            <mat-option [value]="0">{{ 'category.any' | translate }}</mat-option>
                            <mat-option *ngFor="let option of categories; let i = index" [value]="option.id" >
                                <span [innerHtml]="option.name"></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="field">
                    <div class="sort-icon">
                        <mat-icon>search</mat-icon>
                    </div>
                    <mat-form-field class="search-string">
                        <input matInput [formControl]="searchField" (keyup.enter)="searchEnter($event)"
                        [placeholder]="trans('retailers.filter')" class="" [readonly]="loading">
                    </mat-form-field>
                </div>

                <div class="field">
                    <div class="sort-icon">
                        <mat-icon>sort</mat-icon>
                    </div>
                    <mat-form-field class="sort-icon-select">
                        <mat-select [placeholder]="trans('retailers.sort_after')" [value]="sort.id"
                        (selectionChange)="changeOption($event)" [disabled]="loading">
                            <mat-option *ngFor="let option of sortsOptions; let i = index" [value]="option.id" >
                                {{ option.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="sort-dir-icon" (click)="changeSortDir()">
                        <mat-icon [ngClass]="{'clicked': sort.direction === 'desc'}">arrow_upward</mat-icon>
                        <!-- <mat-icon>arrow_downward</mat-icon> -->
                    </div>
                </div>
            </div>
            <span class="nav-spacer"></span>
            <mat-paginator #matPaginator1 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [disabled]="loading"></mat-paginator>
        </mat-card-content>
    </mat-card>

    <div class="loading-shade-relative" *ngIf="loading || error || retailers.length === 0">
        <app-loading *ngIf="loading"></app-loading>
        <div class="error" *ngIf="error">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
        <div class="error" *ngIf="retailers.length === 0 && !loading && !error">
            <h3> {{ 'retailers.empty_results' | translate }} </h3>
        </div>
    </div>

    <!-- retailers grid-->
    <div class="band" *ngIf="!error && !loading && retailers.length > 0">
        <div *ngFor="let retailer of retailers; let i = index" class="card">
            <mat-card class="mat-card-w">
                <mat-card-header>
                    <!-- <span class="featured" [title]="trans('retailers.recommanded_retailer')" [matTooltip]="trans('retailers.recommanded_retailer')"></span> -->
                    <mat-card-title>
                        <a [routerLink]="helperService.genRouteRetailer(retailer.retailer_id, retailer.slug)"> {{ retailer.title }} </a>
                    </mat-card-title>
                    <mat-card-subtitle>{{ helperService.displayCashback(retailer.cashback) }} </mat-card-subtitle>

                </mat-card-header>
                <mat-card-content>
                    <div class="wrap-logo" [routerLink]="helperService.genRouteRetailer(retailer.retailer_id, retailer.slug)">
                        <img class="img" [src]="retailer.image" [alt]="retailer.title" />
                    </div>
                    <!-- <div class="content" [innerHTML]="cutText(retailer.description)"></div> -->
                </mat-card-content>
                <mat-card-actions>
                    <div class="action-btn">
                        <button mat-button class="details-btn"
                            [routerLink]="helperService.genRouteRetailer(retailer.retailer_id, retailer.slug)">
                            {{ 'general.details' | translate }}
                        </button>
                        <div class="go-shop-wrap">
                            <button mat-button class="copy-btn"
                                *ngIf="loggedIn"
                                (click)="generateRefLink(retailer.retailer_id)"
                                [title]="'retailers.send_link_friend' | translate">
                                    <mat-icon *ngIf="!referralLink[retailer.retailer_id]">content_copy</mat-icon>
                                    <mat-icon *ngIf="referralLink[retailer.retailer_id]">done</mat-icon>
                            </button>

                            <button mat-raised-button class="go-shop-btn" color="primary"
                                (click)="helperService.goShopping(retailer.retailer_id, null)"> {{ 'retailers.go_shopping' | translate }}
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

    <mat-card class="filter-wrapper" [hidden]="loading || error || retailers.length === 0">
        <mat-card-content>
            <mat-paginator #matPaginator2 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [disabled]="loading"></mat-paginator>
        </mat-card-content>
    </mat-card>

</div>
