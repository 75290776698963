import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MaintenanceComponent } from 'src/app/maintenance/maintenance.component';
import { BothGuard } from './shared/guards/both.guard';
import { ReferralComponent } from './user/referral/referral.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [ BothGuard ],
        component: HomeComponent
    },
    {
        path: 'maintenance',
        canActivate: [ BothGuard ],
        component: MaintenanceComponent
    },
    {
        path: 'referral/:id',
        canActivate: [ BothGuard ],
        component: ReferralComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        // Required for AoT
        // LocalizeRouterModule.forRoot(routes, {
        //     parser: {
        //         provide: LocalizeParser,
        //         useFactory: (translate, location, settings, http) =>
        //             new LocalizeRouterHttpLoader(translate, location, settings, http),
        //         deps: [ TranslateService, Location, LocalizeRouterSettings, HttpClient ]
        //     }
        // }),
        RouterModule.forRoot(routes)
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {

}
