import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { ResponseApi } from '../models/general/response-api';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { FirstLoadService } from './first-load.service';
import { ErrorApi } from '../models/general/error-api';
import { LocalStorageService } from './local-storage.service';
import { TranslationsVersions } from '../models/translation/translations-versions';
import { environment } from '../../../environments/environment';
import { InProgress } from '../models/general/in-progress';
import { ProgressDone } from '../models/general/progress-done';

@Injectable({
    providedIn: 'root',
})
export class TranslationProviderService implements TranslateLoader {
    private versions: TranslationsVersions;
    private inProgress: InProgress;
    private progressDone : ProgressDone;

    constructor(private translationService: TranslationService,
                private firstLoadService: FirstLoadService,
                private localStorageService: LocalStorageService) {

        this.versions = new TranslationsVersions();
        this.inProgress = new InProgress();
        this.progressDone = new ProgressDone();
        this.firstLoadService.addLoader('translation');
    }
    public getTranslation(lang: string): Observable<Object | undefined> {

        if(this.inProgress[lang]) {
            return this.progressDone[lang].asObservable();
        }

        this.inProgress[lang] = true;
        this.progressDone[lang] = new Subject();

        return this.getTranslationWorker(lang).pipe(tap((trans) => {
            this.inProgress[lang] = false;
            this.progressDone[lang].next(trans);
        }));
    }

    private getTranslationWorker(lang: string): Observable<Object | undefined> {
        environment.debug && console.log('get trans: ' + lang);

        if(this.versions[ lang ]) {
            const trans = this.localStorageService.getTranslationsStorage(lang);
            if(trans) {
                return of(trans);
            }
            return this.getFromServer(lang);
        }

        return this.translationService.getVersion()
            .pipe(
                mergeMap((result: ResponseApi<string>) => {
                    if(result && result.statusCode === 200) {
                        const version = result.data;
                        if(version && version === this.localStorageService.getTranslationVersionStorage(lang)) {
                            const trans = this.localStorageService.getTranslationsStorage(lang);
                            if(trans) {
                                this.versions[lang] = true;
                                this.firstLoadService.event.next('translation');
                                return of(trans);
                            }
                        }
                        return this.getFromServer(lang, version);
                    }
                    return this.getFromServer(lang);
                }),
                catchError(() => {
                    return this.getFromServer(lang);
                }),
            ) as Observable<Object | undefined>;

    }

    private getFromServer(lang: string, version?: string): Observable<Object> {
        return this.translationService.getIndex(lang)
            .pipe(
                map((result: ResponseApi<Object | ErrorApi[]>) => {
                    let data = undefined;
                    if(result && result.statusCode === 200) {
                        if(version) {
                            this.localStorageService.setTranslationVersionStorage(version, lang);
                            if(result.data) {
                                this.localStorageService.setTranslationsStorage(lang, result.data);
                            }
                            this.versions[lang] = true;
                        }
                        if(result.data) {
                            data = result.data;
                        }
                    }
                    this.firstLoadService.event.next('translation');
                    return data;
                }),
                catchError((error: string) => {
                    this.firstLoadService.event.next('translation');
                    return of(undefined);
                }),
            ) as Observable<Object | undefined>;
    }
}