import { Injectable } from '@angular/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AnalyticsClientIds } from '../models/analytics/analytics-client-ids';
import { CustomClick } from '../models/analytics/custom-click';
import { FacebookPixelService } from './facebook-pixel.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { SettingsProviderService } from './settings-provider.service';
import { ClickData } from '../models/analytics/click-data';
import { GlobalEventsService } from './global-events.service';
import { Logged } from './../models/general/logged';
import { AnalyticsEvent } from '../models/analytics/analytics-event';
import { Helper } from 'src/app/shared/helpers/helper';
import { AppPath } from './app-path.service';
import { NavigationEnd, Event, Router } from '@angular/router';
import { EventServiceService } from './event-service.service';
import { AnalyticsGoShopping } from '../models/analytics/analytics-go-shopping';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
    private loggedSubscription: Subscription;
    private analyticsSubscription: Subscription;
    private urlSubscription: Subscription;

    private isGoogleTracking = false;
    private isFacebookTracking = false;

    private isGoogleKey = false;
    private isFacebookKey = false;

    private firstTime = true;

    constructor(private settingsProviderService: SettingsProviderService,
                private googleAnalyticsService: GoogleAnalyticsService,
                private facebookPixelService: FacebookPixelService,
                private globalEventsService: GlobalEventsService,
                private eventService: EventServiceService,
                private helper: Helper,
                private appPath: AppPath,
                private router: Router) {
    }

    private subscribe() {
        this.unsubscribe();

        this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
            if(result.isInitialized && result.loggedIn) {
                this.setUser(result.userId);
                this.auth();
            }
            environment.debug && console.log('first page view');

            const path = this.router.url;

            if(this.helper.checkStaticMetaPages(path)) {
                const title = this.appPath.getTitle(path);
                this.pageView(path, title);
            }
        });

        this.urlSubscription = this.router.events.subscribe((event: Event) => {
            if(event instanceof NavigationEnd) {
                if(event.url) {
                    // console.log('NavigationEnd', event);
                    const path = event.url;

                    if(this.helper.checkStaticMetaPages(path)) {
                        const title = this.appPath.getTitle(path);
                        this.pageView(path, title);
                    }
                }
            }
        });

        this.analyticsSubscription = this.globalEventsService.analytics.subscribe((event: AnalyticsEvent) => {

            if(event.userId) {
                this.setUser(event.userId)
            }

            switch(event.type) {
                case 'auth':
                    this.auth();
                    break;
                case 'login':
                    this.login();
                    break;
                case 'register':
                    this.register();
                    break;
                case 'pageView':
                    this.pageView(event.url, event.title)
                    break;
                case 'goShopping':
                    this.goShopping(event.clickData)
                    break;
                case 'clickEvent':
                    this.clickEvent(event.customClick)
                    break;
            }
        });
    }

    public pageView(url?: string, title?: string): void {
        if(this.isGoogleTracking) {
            this.googleAnalyticsService.pageView(url, title);
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.pageView();
        }
    }

    public clickEvent(event: CustomClick): void {

        if(!event.target) {
            return;
        }

        // console.log('clickout',
        //     event.target.offsetParent?.nodeName,
        //     event.target.nodeName
        // );

        const isLink = event.target.offsetParent?.nodeName === 'A' || event.target.nodeName == 'A';

        const isButton = event.target.offsetParent?.nodeName === 'BUTTON' || event.target.nodeName == 'BUTTON';

        if(!isLink && !isButton) {
            return;
        }

        const text = event.target.innerText;
        let type: string;

        switch(true) {
            case isLink:
                type = 'link';
                break;
            case isButton:
            default:
                type = 'button';
                break;
        }

        if(this.isGoogleTracking) {
            this.googleAnalyticsService.clickEvent(type, text);
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.clickEvent(type, text);
        }
    }

    public setUser(userId: number): void {
        environment.debug && console.log('analytics_set_user', userId);

        if(this.isGoogleTracking) {
            this.googleAnalyticsService.setUser(userId);
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.setUser(userId);
        }
    }

    public auth(): void {

        if(!this.firstTime) {
            return;
        }

        if(this.isGoogleTracking) {
            this.googleAnalyticsService.auth();
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.auth();
        }

        this.firstTime = false;
    }

    public login(): void {
        if(this.isGoogleTracking) {
            this.googleAnalyticsService.login();
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.login();
        }
    }

    public register(): void {
        if(this.isGoogleTracking) {
            this.googleAnalyticsService.register();
        }

        if(this.isFacebookTracking) {
            this.facebookPixelService.register();
        }
    }

    public goShopping(clickData: ClickData): void {

        let google = false;
        let facebook = false;

        const title = (clickData.couponId ? 'voucher' : 'magazin') + ' ' + clickData.retailerTitle;
        const id = clickData.clickId ?? clickData.retailerId;

        if(this.isGoogleTracking) {
            google = this.googleAnalyticsService.goShopping(id, title);
        }

        if(this.isFacebookTracking) {
            facebook = this.facebookPixelService.goShopping(id, title);
        }

        if((this.isGoogleKey && !google) || (this.isFacebookKey && !facebook)) {
            const postData: AnalyticsGoShopping = {
                clickId: clickData.clickId,
                title: title,
                analyticsStatus: {
                    google: google,
                    facebook: facebook
                },
                clientIds: this.getClientIds()

            };
            this.eventService.goShopping(postData).subscribe(() => { }, () => { });
        }
    }

    public getClientIds(): AnalyticsClientIds {

        const clientIds: AnalyticsClientIds = {
            google: this.isGoogleKey ? this.googleAnalyticsService.getClientId(): null,
            facebook: this.isFacebookKey ? this.facebookPixelService.getClientId() : null,
        };

        return clientIds;
    }

    private unsubscribe() {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        if(this.analyticsSubscription) {
            this.analyticsSubscription.unsubscribe();
        }
    }

    public isBlocked() {

    }

    public init(): void {

        const gaTrackingId = this.settingsProviderService.getSettingString('google_analytics');
        const gaAwConversion = null;
        const fbTrackingId = this.settingsProviderService.getSettingString('facebook_analytics');

        this.isGoogleKey = !!gaTrackingId;
        this.isFacebookKey = !!fbTrackingId;

        const googleLoad = this.googleAnalyticsService.init(gaTrackingId, gaAwConversion).pipe(
            catchError((error: any) => {
                console.log(error);
                return of(false);
            })
        );

        const facebookLoad = this.facebookPixelService.init(fbTrackingId).pipe(
            catchError((error: string) => {
                console.log(error);
                return of(false);
            })
        );

        forkJoin([ googleLoad, facebookLoad ]).subscribe(
            ([ googleRes, facebookRes ]) => {

                this.isGoogleTracking = googleRes;
                this.isFacebookTracking = facebookRes;

                if(googleRes || facebookRes) {
                    this.subscribe();
                }
            }
        );
    }
}