<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-xs-12 form-content">
            <div class="custom-card card mat-elevation-z2">
                <div class="card-header">
                    <h2>{{ 'user.activate.head_title' | translate }}</h2>
                </div>
                <div class="card-body">
                    <app-alert [global]="false"></app-alert>
                    <ng-template [ngIf]="success">
                        <div class="success-txt">
                            <h3>{{ 'user.activate.success' | translate }}</h3>
                            <h4>{{ 'user.activate.success_autologin' | translate }}</h4>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="error">
                        <div class="error-alert-div error">
                            <div class="h4" *ngFor="let error of errors">{{ error }}</div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
