import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './services/local-storage.service';
import { GoShoppingDialogComponent } from './components/go-shopping-dialog/go-shopping-dialog.component';
import { AlertComponent } from './components/directives/alert/alert.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YesNoDialogComponent } from './components/directives/yes-no-dialog/yes-no-dialog.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyCustomMaterialModule } from './modules/material.module';
import { MomentModule } from 'ngx-moment';
// import 'moment/locale/ro';
import { TranslationService } from './services/translation.service';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AlertService } from './services/alert.service';
import { Helper } from './helpers/helper';
import { LoadingComponent } from './components/loading/loading.component';
import { FirstLoadService } from './services/first-load.service';
import { MessageComponent } from './components/messages/message/message.component';
import { MessageFormComponent } from './components/messages/message-form/message-form.component';
import { ReplyFormComponent } from './components/messages/reply-form/reply-form.component';
import { TranslationProviderFactory } from './providers/translation-provider-factory';
import { SettingsService } from './services/settings.service';
import { AuthenticationService } from './services/authentication.service';
import { MobileHelperService } from './services/mobile-helper.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NewLinePipe } from './pipes/new-line.pipe';
import { Subscription } from 'rxjs';
import { HelperService } from './services/helper.service';
import { MomentDateFormatPipe } from './pipes/moment-pipes/moment-date-format.pipe';
import { MomentRemainingTimePipe } from './pipes/moment-pipes/moment-remaining-time.pipe';
import { MessageService } from './services/message.service';
import { MessageCheckService } from './services/message-check.service';
import { CustomMultiSnackbarComponent } from './components/directives/custom-multi-snackbar/custom-multi-snackbar.component';
import { CustomSnackbarComponent } from './components/directives/custom-snackbar/custom-snackbar.component';
import { MessagesComponent } from './components/messages/messages/messages.component';
import { SearchUserFormComponent } from './components/user/search-user-form/search-user-form.component';
import { ExtensionHelperService } from './services/extension-helper.service';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { AppInitializerProviderFactory } from './providers/app-initializer-provider-factory';
import { AppInitializerProvider } from './providers/app-initializer-provider';
import { SettingsProviderService } from './services/settings-provider.service';
import { TranslationProviderService } from './services/translation-provider.service';
import { BothGuard } from './guards/both.guard';
import { PermissionGuard } from './guards/permission.guard';
import { UsernamePipe } from './pipes/username.pipe';
import { StarRatingModule } from './modules/star-rating/star-rating.module';
import { RoutingProviderService } from './services/routing-provider.service';
import { MailchimpComponent } from './components/mailchimp/mailchimp.component';
import { SubscribeDialogComponent } from './components/mailchimp/subscribe-dialog/subscribe-dialog.component';
import { MatSelectAutocompleteComponent } from './components/mat-select-autocomplete/mat-select-autocomplete.component';
import { environment } from '../../environments/environment';
import { GlobalEventsService } from './services/global-events.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ReplacePipe } from './pipes/replace.pipe';
import { providers } from './config/providers';
import { Moment } from 'moment';
import { UserIdPipe } from './pipes/user-id.pipe';
import { FacebookChatComponent } from './components/facebook-chat/facebook-chat.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { AttachmentsComponent } from './components/messages/message/attachments/attachments.component';
import { SocialSignInComponent } from './components/social-sign-in/social-sign-in.component';
import { GoogleSigninComponent } from './components/social-sign-in/google-signin/google-signin.component';
import { LoginSourcePipe } from './pipes/login-source.pipe';

const pipes = [
    SafeHtmlPipe,
    NewLinePipe,
    UsernamePipe,
    UserIdPipe,
    ReplacePipe,
    MomentDateFormatPipe,
    MomentRemainingTimePipe,
    LoginSourcePipe
];

const entryComponents = [
    YesNoDialogComponent,
    CustomSnackbarComponent,
    CustomMultiSnackbarComponent,
    SearchUserFormComponent
];

const components = [
    ...entryComponents,
    AlertComponent,
    LoadingComponent,
    MessageComponent,
    MessageFormComponent,
    ReplyFormComponent,
    GoShoppingDialogComponent,
    MessagesComponent,
    MultiselectComponent,
    MailchimpComponent,
    SubscribeDialogComponent,
    MatSelectAutocompleteComponent,
    FacebookChatComponent,
    FileUploadComponent,
    AttachmentsComponent,
    GoogleSigninComponent,
    SocialSignInComponent,
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MDBBootstrapModule.forRoot(),
        MyCustomMaterialModule,
        MomentModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationProviderFactory,
                deps: [
                    TranslationProviderService
                ]
            }
        }),
        StarRatingModule.forRoot(),
    ],
    declarations: [
        ...pipes,
        ...components,
    ],
    exports: [
        ...pipes,
        ...components,
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MDBBootstrapModule,
        MyCustomMaterialModule,
        MomentModule,
        TranslateModule,
        StarRatingModule,
    ],
    providers: [
        ...providers,
        BothGuard,
        PermissionGuard
    ],
    entryComponents: entryComponents
})
export class SharedModule {
    private eventSubscription: Subscription;
    private langChangeSubscription: Subscription;

    constructor(@Optional() @SkipSelf() parentModule: SharedModule,
                private localStorageService: LocalStorageService,
                private firstLoadService: FirstLoadService,
                private globalEventsService: GlobalEventsService,
                private translate: TranslateService,
                private readonly dateAdapter: NgxMatDateAdapter<Moment>) {

        environment.debug && console.log('init SharedModule');

        if(parentModule) {
            this.langInit();
            return;
        }

        this.eventSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if(type === 'app-init') {
                this.langInit();
                this.eventSubscription.unsubscribe();
            }
        });
    }

    private langInit() {
        const lang = this.localStorageService.getLangStorage();
        this.translate.setDefaultLang(lang);
        this.langLoad(lang);

        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }

        this.langChangeSubscription = this.globalEventsService.langChange.subscribe((lang: string) => {
            environment.debug && console.log('lang change shared module');
            this.langLoad(lang);
        });
    }

    private langLoad(lang: string) {
        this.dateAdapter.setLocale(lang);

        this.translate.use(lang).subscribe((trans: Object | undefined) => {
            this.globalEventsService.translationsLoaded.next(!!trans);
        });
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                GlobalEventsService,
                LocalStorageService,
                SettingsService,
                SettingsProviderService,
                RoutingProviderService,
                FirstLoadService,
                TranslationProviderService,
                TranslationService,
                TranslateService,
                Helper,
                MobileHelperService,
                ExtensionHelperService,
                AuthenticationService,
                AlertService,
                HelperService,
                MessageService,
                MessageCheckService,
                // {
                //     provide: APP_BASE_HREF,
                //     useValue: window['baseHref']
                // },
                {
                    provide: APP_INITIALIZER,
                    useFactory: AppInitializerProviderFactory,
                    deps: [ AppInitializerProvider, SettingsProviderService, RoutingProviderService, FirstLoadService, SettingsService ],
                    multi: true
                }
            ]
        };
    }
}
