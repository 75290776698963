import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { CUSTOM_DATE_FORMATS } from './custom-date-formats';

export const providers = [
    {
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
        useValue: {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: 'custom-snackbar'
        }
    },
    {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            panelClass: 'custom-dialog',
            closeOnNavigation: true,
            hasBackdrop: true,
            disableClose: true
        }
    },
    {
        provide: MAT_CHIPS_DEFAULT_OPTIONS,
        useValue: {
            separatorKeyCodes: [ ENTER, COMMA ]
        }
    },
    // {
    //     provide: DateAdapter,
    //     useClass: MomentDateAdapter,
    //     deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    // },
    {
        provide: MAT_DATE_FORMATS,
        useValue: CUSTOM_DATE_FORMATS
    },
    {
        provide: NGX_MAT_DATE_FORMATS,
        useValue: CUSTOM_DATE_FORMATS
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },
];