import { Component, Injector, OnInit } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base.component';

declare let window: Window & { FB: any, fbAsyncInit: Function | undefined };

@Component({
    selector: 'app-facebook-chat',
    templateUrl: './facebook-chat.component.html',
    styleUrls: [ './facebook-chat.component.scss' ]
})
export class FacebookChatComponent extends BaseComponent implements OnInit {

    public loading: boolean = false;
    private appId: string;
    private pageId: string;
    private facebookVersion: string;
    public loaded = false;
    public enabled = false;
    private timeoutFlag: number;
    private dialogStatus: boolean = false;

    constructor(injector: Injector) {
        super(injector);

        if(!window || !document || !this.helperService.checkOnline()) {
            return;
        }

        this.enabled = this.settingsProviderService.getSettingBool('facebook_chat')

        if(!this.enabled) {
            return;
        }

        this.pageId = this.settingsProviderService.getSettingString('facebook_page_id');
        this.appId = this.settingsProviderService.getSettingString('facebook_business_appid');
        this.facebookVersion = this.settingsProviderService.getSettingString('facebook_graph_version');

        if(!this.pageId || !this.appId) {
            this.enabled = false;
        }
    }

    ngOnInit(): void {
    }

    public async onClick() {
        if(this.loaded || !this.enabled) {
            return;
        }

        this.loading = true;

        this.loadProject().subscribe(loaded => {
            environment.debug && console.log('load', loaded);

            if(!loaded) {
                this.loading = false;
                return;
            }

            this.timeoutFlag = setInterval(this.dialogOpenHandler, 2000);
        });
    }

    public dialogOpenHandler: TimerHandler = () => {
        if(!this.dialogStatus) {
            window.FB.CustomerChat.showDialog();
            return;
        }

        this.loading = false;
        this.loaded = true;
        clearInterval(this.timeoutFlag);
    }

    private loadProject(): Observable<boolean> {
        return new Observable((observer: Subscriber<boolean>) => {
            if(document.getElementById('fb-customer-chat') != null) {
                observer.next(true);
                observer.complete();
                return;
            }

            //div
            if(document.getElementById('fb-root') == null) {
                const fbRoot = document.createElement('div');
                fbRoot.id = 'fb-root';

                document.body.append(fbRoot);
            }

            //div
            const chatbox = document.createElement('div');
            chatbox.setAttribute('page_id', this.pageId);
            chatbox.setAttribute('attribution', 'biz_inbox');
            chatbox.id = 'fb-customer-chat';
            chatbox.classList.add('fb-customerchat');
            chatbox.hidden = true;

            document.body.append(chatbox);

            window.fbAsyncInit = () => {
                environment.debug && console.log('fb chat asyncInit!');

                window.FB.init({
                    appId: this.appId,
                    xfbml: true,
                    version: this.facebookVersion
                });

                window.FB.Event.subscribe('customerchat.load', () => {
                    environment.debug && console.log('customerchat.load');
                    clearTimeout(this.timeoutFlag);
                    window.FB.CustomerChat.show(true);
                    observer.next(true);
                    observer.complete();
                });

                window.FB.Event.subscribe('customerchat.dialogShow', () => {
                    environment.debug && console.log('show dialog');
                    this.dialogStatus = true;
                });

                window.FB.Event.subscribe('customerchat.dialogHide', () => {
                    environment.debug && console.log('hide dialog');
                    this.dialogStatus = false;
                });

                window.FB.CustomerChat.hide();
                this.dialogStatus = false;
            };

            // script
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'facebook-jssdk';
            script.async = true;
            script.src = 'https://connect.facebook.net/ro_RO/sdk/xfbml.customerchat.js';

            const first = document.getElementsByTagName('script')[ 0 ];
            first.parentNode.insertBefore(script, first);

            const handler: TimerHandler = () => {
                observer.next(false);
                observer.complete();
            }

            this.timeoutFlag = setTimeout(handler, 30000);
        });
    }
}
