import { RetailerService } from 'src/app/shared/services/retailer.service';
import { GoShoppingResponse } from './../../models/go-shopping/go-shopping-response';
import { ContentService } from './../../services/content.service';
import { GoToStore } from '../../models/go-shopping/go-to-store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClicksService } from '../../services/clicks.service';
import { ResponseApi } from '../../models/general/response-api';
import { ClickLink } from '../../models/click/click-link';
import { ErrorApi } from '../../models/general/error-api';
import { forkJoin, Observable } from 'rxjs';
import { Content } from '../../models/content/content';
import { RetailerQuickInfo } from '../../models/retailer/retailer-quick-info';
import { Helper } from '../../helpers/helper';
import { ExtensionHelperService } from './../../services/extension-helper.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { SettingsProviderService } from '../../services/settings-provider.service';
import { AnalyticsService } from '../../services/analytics.service';
import { GoToStoreRequest } from '../../models/go-shopping/go-to-store-request';
import { ClickData } from '../../models/analytics/click-data';
import { ClickSource } from '../../models/go-shopping/click-source';

@Component({
    selector: 'app-go-shopping-dialog',
    templateUrl: './go-shopping-dialog.component.html',
    styleUrls: [ './go-shopping-dialog.component.scss' ]
})
export class GoShoppingDialogComponent implements OnInit {

    content: Content = null;
    loading = true;
    error = false;

    isAdBlock = false;

    private click: GoToStore;

    private helperService: HelperService;

    errorMessages: string[];

    retailer: RetailerQuickInfo;

    loadingShopping: boolean = false;

    defaultApproval: number;

    constructor(@Inject(MAT_DIALOG_DATA) private data: { click: GoToStore, helperService: HelperService },
                private dialogRef: MatDialogRef<GoShoppingDialogComponent>,
                private alertService: AlertService,
                private clicksService: ClicksService,
                private retailerService: RetailerService,
                private contentService: ContentService,
                private helper: Helper,
                private settingsProviderService: SettingsProviderService,
                private extensionHelperService: ExtensionHelperService,
                private analyticsService: AnalyticsService) {

        this.click = data.click;
        this.helperService = data.helperService;
        this.defaultApproval = this.settingsProviderService.getSettingNumber('default_approval_time');
    }

    ngOnInit() {

        this.dialogRef.backdropClick().subscribe((event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            this.close();
        });

        this.alertService.reset(false);

        const adBlockReq: Promise<boolean> = this.helper.checkAdsBlocked();
        const retailerReq: Observable<ResponseApi<RetailerQuickInfo| ErrorApi[]>> =
            this.retailerService.getQuickInfo(this.click.retailerId);
        const contentReq: Observable<ResponseApi<Content| ErrorApi[]>> = this.contentService.getByName('go-shopping');

        forkJoin([ contentReq, adBlockReq, retailerReq ]).subscribe(
            ([ resContent, resAdBlock, resRetailer ]) => {

                if(resContent && resContent.statusCode === 200 && resContent.data) {
                    this.content = <Content>resContent.data;
                }

                if(resRetailer && resRetailer.statusCode === 200 && resRetailer.data) {
                    this.retailer = <RetailerQuickInfo>resRetailer.data;
                }

                this.isAdBlock = resAdBlock;

                this.loading = false;
            },
            (error: string) => {
                console.log(error);
                this.error = true;
                this.loading = false;
            }
        );

    }

    public goShopping() {

        this.errorMessages = undefined;
        this.loadingShopping = true;

        if(!this.extensionHelperService.isExtension()) {
            this.getLink();
            return;
        }

        this.extensionHelperService.goShopping(this.click, this.retailer, this.analyticsService.getClientIds())
            .then(() => {
                const response: GoShoppingResponse<string> = {
                    type: 'extension',
                    data: ''
                };

                this.dialogRef.close(response);

            })
            .catch(() => {
                this.getLink();
            });
    }

    private getLink() {

        const clickReq: GoToStoreRequest = {
            ...this.click,
            clientIds: this.analyticsService.getClientIds(),
            source: ClickSource.SITE
        }

        this.clicksService.goToStore(clickReq).subscribe(
            (result: ResponseApi<ClickLink | ErrorApi[]>) => {
                if(result && result.statusCode === 200 && result.data) {
                    const data = <ClickLink>result.data;

                    const response: GoShoppingResponse<ClickLink> = {
                        type: 'click',
                        data: data
                    };

                    this.sendAnalytics(data.clickId);

                    this.dialogRef.close(response);
                    return;
                }

                const errors = <ErrorApi[]>result.data;
                this.errorMessages = this.helper.formatError(errors);
                this.loadingShopping = false;

            }, (error: string) => {
                console.log(error);

                const response: GoShoppingResponse<string> = {
                    type: 'login_error',
                    data: error
                }

                this.dialogRef.close(response);
            });
    }

    close() {
        const response: GoShoppingResponse<string> = {
            type: 'close',
            data: ''
        }

        this.dialogRef.close(response);
    }

    setCongrats(): string {
        if(this.retailer) {
            let text = this.helper.trans('goshopping.congrats');
            text = text.replace('{cashback}', this.helperService.displayCashback(this.retailer.cashback));
            text = text.replace('{retailer}', this.retailer.title);
            return text;
        }
        return '';
    }

    setDesc(): string {
        if(this.content) {
            let text = this.content.description;

            const approvalTime = (this.retailer && this.retailer.approval_time > 0) ?
                this.retailer.approval_time : this.defaultApproval;

            text = text.replace('{approval_time}', approvalTime.toString());
            return text;
        }
        return '';
    }

    private sendAnalytics(clickId: number | null) {
        const clickData: ClickData = {
            clickId: clickId,
            couponId: this.click.couponId,
            retailerId: this.click.retailerId,
            retailerTitle: this.retailer.title,
        };

        this.analyticsService.goShopping(clickData);
    }
}
