import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertModel } from 'src/app/shared/models/general/alert-model';
import { GlobalEventsService } from '../../../services/global-events.service';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription | undefined;
    public message: AlertModel;
    private timeout: number = null;
    public dismissible: boolean = true;

    @Input() global: boolean;

    constructor(private alertService: AlertService, private globalEventsService: GlobalEventsService) {
        if (typeof this.global === 'undefined') {
            this.global = false;
        }
    }

    ngOnInit() {

        this.subscription = this.alertService.getMessage().subscribe((message: AlertModel) => {

            if (typeof message === 'undefined' || message.global !== this.global) {
                return;
            }

            if (message.type === 'reset') {
                this.message = undefined;
                this.triggerChange();
                return;
            }

            this.message = message;

            clearTimeout(this.timeout);

            if (message.timeout) {
                const handler: TimerHandler = () => {
                    this.message = undefined;
                    this.triggerChange();
                }
                this.timeout = setTimeout(handler, 10000);
            }

            this.triggerChange();
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    public close() {
        this.message = undefined;
        this.triggerChange();
        this.triggerClose();
    }

    private triggerChange() {
        if (!this.global) {
            return;
        }

        setTimeout(() => {
            this.globalEventsService.customSubject.next('alert-global');
        }, 250);
    }

    private triggerClose() {
        if (!this.global) {
            return;
        }

        setTimeout(() => {
            this.globalEventsService.customSubject.next('alert-global-closed');
        }, 250);
    }
}
