import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { RecoverForm } from '../models/user/public/recover-form';
import { ChangePasswordForm } from '../models/user/private/change-password-form';
import { CheckRecoverForm } from '../models/user/public/check-recover-form';
import { RecoverChangePassword } from '../models/user/public/recover-change-password';
import { LoginForm } from '../models/user/public/login-form';
import { UserProfileData } from '../models/user/private/user-profile-data';
import { Helper } from '../helpers/helper';
import { RegisterForm } from '../models/user/public/register-form';
import { UserActivateForm } from '../models/user/public/user-activate-form';
import { SearchFilters } from '../models/filters/search-filters';
import { ResponseApiList } from '../models/general/response-api-list';
import { ActivationEmail } from '../models/user/public/activation-email';
import { UserActivate } from '../models/user/public/user-activate';
import { ErrorApi } from '../models/general/error-api';
import { UserResponse } from '../models/user/private/user-response';
import { ActivationEmailAll } from 'src/app/shared/models/user/private/activation-email-add';
import { QuickInfo } from '../models/user/private/quick-info';
import { UserRegister } from '../models/user/public/register-response';
import { UserList } from '../models/user/private/user-list';
import { OldAdminResponse } from 'src/app/shared/models/user/old-admin/old-admin-response';
import { Communication } from '../models/user/private/communication';
import { GetCommunication } from '../models/user/private/get-communication';
import { MediaHostingResponse } from '../models/user/media-hosting/media-hosting-response';
import { LinkResponse } from '../../admin/shared/models/email-portal/link-response';
import { PaymentDetail } from '../models/user/private/payment-detail';
import { LoginSource } from '../models/user/public/login-source';

@Injectable()
export class UserService {
    constructor(private http: HttpClient, private helper: Helper) {
    }

    login(login: LoginForm) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/login`, login);
    }

    loginExternal(source: LoginSource) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/login-external`, { source });
    }

    check(source: LoginSource) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v2/user/check`, { source });
    }

    logout(): Observable<ResponseApi<boolean | ErrorApi[]>> {
        return this.http.post<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/user/logout`, {});
    }

    // getAll(): Observable<ResponseApi> {
    //     return this.http.get<ResponseApi>(`${environment.apiUrl}/v1/user`);
    // }

    list(postData: SearchFilters) {
        return this.http.post<ResponseApiList<UserList | ErrorApi[]>>(`${environment.apiUrl}/v1/user/list`, postData);
    }

    // getById(id: number) {
    //     return this.http.get(`${environment.apiUrl}/v1/user/` + id);
    // }

    register(user: RegisterForm) {
        this.helper.appendCurrentLocalUrl(user);

        return this.http.post<ResponseApi<UserRegister | ErrorApi[]>>(`${environment.apiUrl}/v1/user/register`, user);
    }

    // update(user: User) {
    //     return this.http.put(`${environment.apiUrl}/v1/user/` + user.id, user);
    // }

    // delete(id: number) {
    //     return this.http.delete(`${environment.apiUrl}/v1/user/` + id);
    // }

    recover(email: string) {

        const recover: RecoverForm = {
            email: email
        };

        this.helper.appendCurrentLocalUrl(recover);

        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/user/recover`, recover);
    }

    changePassword(changePassword: ChangePasswordForm) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/change-password`, changePassword);
    }

    checkRecover(data: CheckRecoverForm) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/user/recover/check`, data);
    }

    recoverChangePassword(recoverChangePassword: RecoverChangePassword) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/user/recover/change-password`, recoverChangePassword);
    }

    oldAdminLogin() {
        return this.http.post<ResponseApi<OldAdminResponse>>(`${environment.apiUrl}/v1/user/admin/old-admin/login`, {});
    }

    oldAdminLogout() {
        return this.http.post<ResponseApi<boolean>>(`${environment.apiUrl}/v1/user/admin/old-admin/logout`, {});
    }

    oldAdminPhpLogout(session: string) {
        return this.http.post<ResponseApi<boolean>>(`${environment.oldAdminLogoutUrl}`, { session });
    }

    mediaHostingPhpLogout(url: string, apiKey: string) {

        const form = new FormData();
        form.append('api_key', apiKey);

        return this.http.post<any>(`${url}/auto-logout`, form, {
            responseType: 'json'
        });
    }

    getQuickInfo() {
        return this.http.get<ResponseApi<QuickInfo>>(`${environment.apiUrl}/v1/user/quick-info`);
    }

    getCommunication() {
        return this.http.get<ResponseApi<GetCommunication>>(`${environment.apiUrl}/v1/user/communication`);
    }

    updateCommunication(data: Communication) {
        return this.http.post<ResponseApi<Communication | ErrorApi[]>>(`${environment.apiUrl}/v1/user/communication`, data);
    }

    getProfile() {
        return this.http.get<ResponseApi<UserProfileData | ErrorApi[]>>(`${environment.apiUrl}/v1/user/profile`);
    }

    updateProfile(data: UserProfileData) {
        return this.http.post<ResponseApi<UserProfileData | ErrorApi[]>>(`${environment.apiUrl}/v1/user/profile`, data);
    }

    getAllPaymentDetail() {
        return this.http.get<ResponseApiList<PaymentDetail | ErrorApi>>(`${environment.apiUrl}/v1/user/payment-detail/all`);
    }

    getListPaymentDetail() {
        return this.http.get<ResponseApiList<PaymentDetail | ErrorApi>>(`${environment.apiUrl}/v1/user/payment-detail/list`);
    }

    updatePaymentDetail(data: PaymentDetail) {
        return this.http.put<ResponseApi<PaymentDetail | ErrorApi[]>>(`${environment.apiUrl}/v1/user/payment-detail/` + data.id, data);
    }

    createPaymentDetail(data: PaymentDetail) {
        return this.http.post<ResponseApi<PaymentDetail | ErrorApi[]>>(`${environment.apiUrl}/v1/user/payment-detail`, data);
    }

    deletePaymentDetail(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/user/payment-detail/` + id);
    }

    activate(data: UserActivateForm) {

        this.helper.appendCurrentLocalUrl(data);

        return this.http.post<ResponseApi<UserActivate | ErrorApi[]>>(`${environment.apiUrl}/v1/user/activate`, data);
    }

    activationEmail(email: string) {

        const data: ActivationEmail = {
            email: email
        };

        this.helper.appendCurrentLocalUrl(data);

        return this.http.post<ResponseApi<string>>(`${environment.apiUrl}/v1/user/activation/email`, data);
    }

    activationEmailAll() {
        return this.http.post<ResponseApi<ActivationEmailAll[]>>(`${environment.apiUrl}/v1/user/activation/email-all`, {});
    }

    mediaHostingLogin() {
        return this.http.post<ResponseApi<MediaHostingResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/admin/media-hosting/login`, {});
    }

    emailLogin(id: number) {
        return this.http.post<ResponseApi<LinkResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/admin/email/login`, { id });
    }

    emailOptions() {
        return this.http.get<ResponseApiList<string>>(`${environment.apiUrl}/v1/user/admin/email/options`, {});
    }

}
