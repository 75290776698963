import { Component, OnInit, Inject } from '@angular/core';
import { SimpleSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CustomSnackbarData } from 'src/app/shared/models/snackbar/custom-snackbar-data';

@Component({
    selector: 'app-custom-multi-snackbar',
    templateUrl: './custom-multi-snackbar.component.html',
    styleUrls: [ './custom-multi-snackbar.component.scss' ]
})
export class CustomMultiSnackbarComponent implements OnInit {

    public messages: Array<CustomSnackbarData> = [];

    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: Array<CustomSnackbarData>,
                private snackBarRef: MatSnackBarRef<SimpleSnackBar>) {

        this.messages = data && data.length ? data : [];
    }

    ngOnInit() {}

    close(index: number) {
        if(this.messages && this.messages.length > 1) {
            this.messages.splice(index, 1);
        } else {
            this.snackBarRef.dismiss();
        }
    }

    go(index: number) {
        const message = this.messages[index];
        if(typeof message.callback === 'function') {
            message.callback();
        }
        this.close(index);
    }

    public addMessage(message: CustomSnackbarData) {
        if(!this.messages) {
            this.messages = [];
        }

        let messages = this.messages.filter(m => message.type !== m.type);
        messages.unshift(message);

        this.messages = messages;
    }

}
