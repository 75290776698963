import { Component, OnInit, HostListener, OnDestroy, Injector } from '@angular/core';
import { Content } from '../shared/models/content/content';
import { forkJoin, Subject, Subscription, Observable } from 'rxjs';
import { ContentService } from '../shared/services/content.service';
import { RetailerService } from '../shared/services/retailer.service';
import { BannerService } from '../shared/services/banner.service';
import { debounceTime } from 'rxjs/operators';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { RetailerFeatured } from '../shared/models/retailer/retailer-featured';
import { ErrorApi } from '../shared/models/general/error-api';
import { ResponseApi } from '../shared/models/general/response-api';
import { BannerShort } from '../shared/models/banner/banner-short';
import { BaseComponent } from '../shared/components/base.component';
import { GlobalEventsService } from './../shared/services/global-events.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [ './home.component.scss' ]
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

    total = 0;
    banners: BannerShort[] = [];
    content: Content;
    retailers: RetailerFeatured[] = [];
    retailersBackup: RetailerFeatured[] = [];

    loadingContent = false;
    isBanner = false;

    cSet = {
        animation: 'slide',
        interval: 5000,
        noPause: false,
        keyboard: true,
        isControls: true
    };

    private widthChangeEvent = new Subject<number>();
    private widthChangeEventSubscription: Subscription;
    private langChangeSubscription: Subscription;

    constructor(injector: Injector,
                private contentService: ContentService,
                private retailerService: RetailerService,
                private bannerService: BannerService,
                private globalEventsService: GlobalEventsService) {

        super(injector);
    }

    ngOnInit() {

        const featureStoreReq: Observable<ResponseApiList<RetailerFeatured>> = this.retailerService.getFeatured();
        const contentReq: Observable<ResponseApi<Content| ErrorApi[]>> = this.contentService.getByName('home');
        const bannerReq: Observable<ResponseApiList<BannerShort>> = this.bannerService.getActive();

        forkJoin([ featureStoreReq, contentReq, bannerReq ])
            .pipe()
            .subscribe(
                ([ resfeatureStore, resContent, resBanner ]) => {
                    if(resfeatureStore && resfeatureStore.statusCode === 200 && resfeatureStore.data) {
                        this.retailers = resfeatureStore.data;
                        this.retailersBackup = [...this.retailers];
                        this.total = resfeatureStore.total;
                        this.widthChangeEvent.next(window.innerWidth);
                    } else {
                        this.total = 0;
                        this.retailers = [];
                    }

                    if(resContent && resContent.statusCode === 200 && resContent.data) {
                        this.content = <Content>resContent.data;
                    } else {
                        this.content = undefined;
                    }

                    if(resBanner && resBanner.statusCode === 200 && resBanner.data) {
                        this.banners = resBanner.data;
                        if(this.banners.length === 1)
                        {
                            this.cSet.interval = 0;
                        }
                        this.isBanner = true;
                    } else {
                        this.isBanner = false;
                    }

                    this.loading = false;
                },
                (error: string) => {
                    console.log(error);
                    this.error = true;
                    this.loading = false;
                }
            );

        this.widthChangeEventSubscription = this.widthChangeEvent
            .pipe(debounceTime(250))
            .subscribe((width: number) => {
                const total = this.retailersBackup.length;

                let finalNr = total;

                if(width >= 1400) {
                    finalNr = this.helper.closestInteger(total, 5);
                } else if(width >= 992) {
                    finalNr = this.helper.closestInteger(total, 4);
                } else if(width >= 768) {
                    finalNr = this.helper.closestInteger(total, 3);
                } else if(width > 576) {
                    finalNr = this.helper.closestInteger(total, 2);
                }

                if(finalNr !== this.retailers.length) {
                    this.retailers = this.retailersBackup.slice(0, finalNr);
                    this.total = finalNr;
                }
            });

        this.langChangeSubscription = this.globalEventsService.langChange.subscribe(() => {
            this.loadingContent = true;

            contentReq.subscribe((response: ResponseApi<Content | ErrorApi[]>) => {
                if(response && response.statusCode === 200) {
                    this.content = <Content>response.data;
                } else {
                    this.content = undefined;
                }
                this.loadingContent = false;
            },
            (error: string) => {
                this.loadingContent = false;
                this.content = undefined;
                console.log(error);
            });
        });
    }

    ngOnDestroy() {
        if(this.widthChangeEventSubscription) {
            this.widthChangeEventSubscription.unsubscribe();
        }
        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.widthChangeEvent.next(event.target.innerWidth);
    }
}
