import { HelperService } from 'src/app/shared/services/helper.service';
import { Injector, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../helpers/helper';
import { MobileHelperService } from '../services/mobile-helper.service';
import { SettingsProviderService } from './../services/settings-provider.service';
import { AppPath } from '../services/app-path.service';

@Injectable()
export class BaseComponent implements OnInit, OnDestroy {

    // service injections
    public helperService: HelperService;
    public helper: Helper;
    protected router: Router;
    protected route: ActivatedRoute;
    protected translateService: TranslateService;
    public appPath: AppPath;
    protected settingsProviderService: SettingsProviderService;
    protected mobileHelperService: MobileHelperService;

    // base vars
    public loading = true;
    public error = false;

    constructor(private injector: Injector) {

        this.helperService = this.injector.get(HelperService);
        this.helper = this.injector.get(Helper);
        this.router = this.injector.get(Router);
        this.route = this.injector.get(ActivatedRoute);
        this.translateService = this.injector.get(TranslateService);
        this.settingsProviderService = this.injector.get(SettingsProviderService);
        this.appPath = this.injector.get(AppPath);
        this.mobileHelperService = this.injector.get(MobileHelperService);
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    public trans(key: string): string {
        return this.translateService.instant(key);
    }

    public isMobile(): boolean {
        return this.mobileHelperService.isMobile();
    }
}