import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WithdrawRequestForm } from '../models/withdraw/withdraw-request-form';
import { WithdrawData } from '../models/withdraw/withdraw-data';
import { ErrorApi } from '../models/general/error-api';
import { SortFilters } from '../models/filters/sort-filters';
import { ResponseApiList } from '../models/general/response-api-list';
import { WithdrawProcessForm } from '../models/withdraw/withdraw-process-form';

@Injectable()
export class WithdrawService {

    constructor(private http: HttpClient) {
    }

    get() {
        return this.http.post<ResponseApi<WithdrawData>>(`${environment.apiUrl}/v1/withdraw/data`, {});
    }

    request(data: WithdrawRequestForm) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/withdraw/request`, data);
    }

    process(data: WithdrawProcessForm) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/withdraw/admin/process`, data);
    }

    getList(data: SortFilters) {
        return this.http.post<ResponseApiList<any | ErrorApi[]>>(`${environment.apiUrl}/v1/withdraw/list`, data);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/withdraw/` + id);
    }

    checkNr() {
        return this.http.get<ResponseApi<number>>(`${environment.apiUrl}/v1/withdraw/admin/check`);
    }
}
