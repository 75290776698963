import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Helper } from '../helpers/helper';
import { AppPath } from '../services/app-path.service';

@Injectable({
    providedIn: 'root'
})
export class BothGuard implements CanActivate {

    constructor(private router: Router,
                private appPath: AppPath,
                private authenticationService: AuthenticationService,
                private helper: Helper) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const toRoute = state.url;
        const isAuth = this.authenticationService.isAuthenticated();

        if (typeof (isAuth) === 'boolean') {
            return this.check(isAuth, toRoute);
        }
        return isAuth.then((auth: boolean) => this.check(auth, toRoute));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    check(auth: boolean, toRoute: string): boolean {
        this.helper.scrollTop();

        if(auth && (
            toRoute === '/login' || toRoute === '/logare' ||
            toRoute === '/register' || toRoute === '/inregistrare'
        )) {
            this.router.navigate(this.appPath.getRoutePathArr(this.appPath.getDefaultHome(), '/'));
            return false;
        }
        return true;
    }
}
