<h3 class="text-center" mat-dialog-title>{{ 'subscribe.head_title' | translate }}</h3>
<mat-dialog-content class="subscribe">
    <iframe id="mailchimp-iframe" name="mailchimp-iframe" class="iframe" #mailchimpIframe></iframe>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="close">{{ 'general.close' | translate }}</button>
</mat-dialog-actions>

<form method="post" enctype="multipart/form-data"
    [action]="mailchimpFormUrl" target="mailchimp-iframe" class="mailchimp-form" #mailchimpForm>

    <input type="text" [value]="subscriberData.email" name="EMAIL">
    <input type="text" [value]="mailchimpConfig.u" name="u">
    <input type="text" [value]="mailchimpConfig.id" name="id">

    <ng-template [ngIf]="subscriberData.subscribe">
        <input *ngIf="subscriberData.fname" type="text" [value]="subscriberData.fname" name="FNAME">
        <input *ngIf="subscriberData.lname" type="text" [value]="subscriberData.lname" name="LNAME">
        <input *ngIf="subscriberData.userId" type="text" [value]="subscriberData.userId" name="USERID">

        <input *ngFor="let option of mailchimpConfig.groupOptions"
            type="checkbox"
            [value]="option.value"
            [name]="'group[' + mailchimpConfig.groupId + '][' + option.value + ']'"
            [checked]="checkMemeber(option.type)">

        <input *ngFor="let id of mailchimpConfig.gdpr" type="checkbox" [name]="'gdpr[' + id + ']'" value="Y" checked>
        <input type="text" [name]="'b_' + mailchimpConfig.u" value="">
    </ng-template>

    <input type="submit" [value]="subscriberData.subscribe ? 'Subscribe' : 'Unsubscribe'" name="subscribe">
</form>
