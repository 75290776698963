import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Injector } from '@angular/core';
import { Retailer } from '../shared/models/retailer/retailer';
import { MatPaginator } from '@angular/material/paginator';
import { SortOptions } from '../shared/models/sort/sort-options';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { RetailerService } from '../shared/services/retailer.service';
import { SortModel } from '../shared/models/sort/sort-model';
import { merge, Subject, Subscription, of } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime, distinctUntilChanged, first} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CategoryService } from '../shared/services/category.service';
import { RetailerFilters } from '../shared/models/filters/retailer-filters';
import { MatSelectChange } from '@angular/material/select';
import { CategoryShort } from '../shared/models/category/category-short';
import { ErrorApi } from '../shared/models/general/error-api';
import { BaseComponent } from '../shared/components/base.component';
import { GlobalEventsService } from '../shared/services/global-events.service';
import { Logged } from '../shared/models/general/logged';

@Component( {
    selector: 'app-retailers',
    templateUrl: './retailers.component.html',
    styleUrls: [ './retailers.component.scss' ]
} )
export class RetailersComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    fatalError = false;
    loggedIn: boolean;

    total = 0;
    retailers: Retailer[] = [];

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;

    pageIndex = 0;
    sortObs = new Subject<boolean>();
    paginationObs = new Subject<boolean>();
    sort: SortModel = {
        id: 0,
        field: 'visits', // selected option
        direction: 'desc' // sort direction
    };
    sortsOptions: SortOptions[] = [
        { id: 0, name: 'retailers.sort.popularity', field: 'visits', sort: 'desc' },
        { id: 1, name: 'retailers.sort.name', field: 'title', sort: 'asc' },
        { id: 2, name: 'retailers.sort.added', field: 'added', sort: 'desc' },
        { id: 3, name: 'retailers.sort.cashback', field: 'cashback', sort: 'desc' }
    ];

    @ViewChild('matPaginator1', { static: true }) paginator1: MatPaginator;
    @ViewChild('matPaginator2', { static: true }) paginator2: MatPaginator;

    searchObs = new Subject<boolean>();
    searchField: FormControl;
    enterFired = false;
    searchString = '';
    searchBy = 'title';

    categoryLoading = true;
    categories: CategoryShort[] = [];
    selectedCategory = 0;

    closed: boolean = false;

    public referralLink: Record<number, string> = {};

    private subscriptionPag1: Subscription;
    private subscriptionPag2: Subscription;
    private subscriptionObs: Subscription;
    private loggedSubscription: Subscription;
    private subscriptionRetailers: Subscription;

    constructor(injector: Injector,
                private retailerService: RetailerService,
                private categoryService: CategoryService,
                private globalEventsService: GlobalEventsService) {

        super(injector);

        this.pageSize = this.settingsProviderService.getSettingNumber('retailers_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options');
        this.pageSizeOptions = this.defaultPageSizeOptions;

        this.route.queryParams.subscribe((params) => {

            // console.log('queryparams:', params);

            if(params.pageSize && Number.isInteger(+params.pageSize) && +params.pageSize > 0) {
                this.pageSize = +params.pageSize;
            }

            if(params.searchString && params.searchString.length > 0) {
                this.searchString = params.searchString;
            }

            if(params.cat && Number.isInteger(+params.cat) && +params.cat > 0) {
                this.selectedCategory = +params.cat;
            }

            if(params.page && Number.isInteger(+params.page) && +params.page > 0) {
                this.pageIndex = +params.page;
            }

            if(params.sort && Number.isInteger(+params.sort)) {
                const index = +params.sort;
                const option = this.sortsOptions[index];
                if(option) {
                    this.sort.field = option.field;
                    this.sort.id = index;
                    this.sort.direction = option.sort;

                    if(params.direction) {
                        switch(params.direction) {
                            case 'asc':
                            case 'desc':
                                this.sort.direction = params.direction;
                                break;
                        }
                    }
                }
            }
        });
    }

    ngOnInit() {
        this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
            this.loggedIn = result.loggedIn;
        });

        this.searchField = new FormControl();
        this.searchField.setValue(this.searchString);

        this.categoryService.getList().subscribe(
            (response: ResponseApiList<CategoryShort>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.categories = <CategoryShort[]>response.data;
                } else {
                    // error
                }
                this.categoryLoading = false;
            }, (errors: string) => {
                console.log(errors);
                this.categoryLoading = false;
            });
    }

    ngAfterViewInit() {

        this.paginator1.pageSize = this.pageSize;
        this.paginator2.pageSize = this.pageSize;
        this.paginator1.pageIndex = this.pageIndex;
        this.paginator2.pageIndex = this.pageIndex;

        this.subscriptionPag1 = this.paginator1.page.subscribe(() => {
            this.paginator2.pageIndex = this.paginator1.pageIndex;
            this.paginator2.pageSize = this.paginator1.pageSize;
            this.helperService.updateUrl({
                page:  this.paginator1.pageIndex,
                pageSize:  this.paginator1.pageSize
            });
            this.paginationObs.next(true);
        });

        this.subscriptionPag2 = this.paginator2.page.subscribe(() => {
            this.paginator1.pageIndex = this.paginator2.pageIndex;
            this.paginator1.pageSize = this.paginator2.pageSize;
            this.helperService.updateUrl({
                page: this.paginator2.pageIndex,
                pageSize: this.paginator2.pageSize
            });
            this.paginationObs.next(true);
        });

        this.searchField.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe(filterValue => {
            if(!this.loading && !this.enterFired && this.searchString !== filterValue) {
                this.searchString = filterValue;
                this.helperService.updateUrl({ searchString: this.searchString });
                if(this.paginator1) {
                    this.paginator1.firstPage();
                }
                this.searchObs.next(true);
            }
        });

        this.getRetailers();
    }

    getRetailers() {
        const retailersReq = merge(this.sortObs, this.paginationObs, this.searchObs)
            .pipe(
                startWith({}),
                switchMap(() => {
                    if(this.fatalError) {
                        this.fatalError = false;
                        return of(this.helper.getDefaultErrorResponse<Retailer | ErrorApi>());
                    } else {
                        this.loading = true;

                        const postData: RetailerFilters = {
                            sortField: this.sort.field,
                            sortDirection: this.sort.direction,
                            pageIndex: this.paginator1.pageIndex,
                            pageSize: this.paginator1.pageSize,
                            string: this.searchString,
                            searchBy: this.searchBy,
                            category: this.selectedCategory
                        };
                        return this.retailerService.getList(postData);
                    }

                    // if(typeof this.paginator.pageSize !== 'undefined') {
                    //     this.pageSize = this.paginator.pageSize;
                    // }
                }),
                map((response) => {
                    if(response && response.statusCode === 200) {

                        this.total = response.total;

                        // if(response.totalPage !== this.paginator1.pageSize) {
                        //     this.paginator1.pageSize = response.totalPage;
                        //     this.helperService.updateUrl({ pageSize: this.paginator1.pageSize });
                        // }

                        this.pageSizeOptions = this.helper.generatePageSizeOptions(
                            this.total,
                            this.defaultPageSizeOptions,
                            this.paginator1.pageSize
                        );

                        this.error = false;
                        this.loading = false;
                        return <Retailer[]>response.data;
                    } else {
                        this.error = true;
                        this.total = 0;
                        this.loading = false;
                        return [];
                    }
                }),
                catchError(() => {
                    this.loading = false;
                    this.error = true;
                    this.fatalError = true;
                    this.total = 0;
                    return of([]);
                })
            );

        const callback = (data: Retailer[]) => {

            this.retailers = data;
            this.enterFired = false;

            this.helperService.handleGoShoppingReturn(this.route.snapshot.queryParams);

            if(this.fatalError) {
                this.subscriptionRetailers = retailersReq.subscribe(callback);
            }
        };

        this.subscriptionRetailers = retailersReq.subscribe(callback);
    }

    ngOnDestroy() {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        if(this.subscriptionPag1) {
            this.subscriptionPag1.unsubscribe();
        }
        if(this.subscriptionPag2) {
            this.subscriptionPag2.unsubscribe();
        }
        if(this.subscriptionObs) {
            this.subscriptionObs.unsubscribe();
        }
        if(this.subscriptionRetailers) {
            this.subscriptionRetailers.unsubscribe();
        }
    }

    changeSortDir() {
        if(!this.loading) {
            switch(this.sort.direction) {
                case 'asc':
                    this.sort.direction = 'desc';
                    break;
                case 'desc':
                    this.sort.direction = 'asc';
                    break;
                default:
                    this.sort.direction = 'desc';
                    break;
            }
            this.paginator1.pageIndex = 0;
            this.paginator2.pageIndex = 0;
            this.helperService.updateUrl({
                direction: this.sort.direction,
                page: 0
            });
            this.sortObs.next(true);
        }
    }

    changeOption(event: any) {
        const index = event.value;
        const option = this.sortsOptions[index];
        if(option) {
            this.sort.field = option.field;
            this.sort.id = index;
            this.sort.direction = option.sort;
            this.paginator1.pageIndex = 0;
            this.paginator2.pageIndex = 0;
            this.helperService.updateUrl({
                sort: this.sort.id,
                direction: this.sort.direction,
                page: 0
            });
            this.sortObs.next(true);
        }
    }

    changeCatOption(event: MatSelectChange) {
        this.selectedCategory = event.value;
        this.helperService.updateUrl({ cat: this.selectedCategory });
        if(this.paginator1) {
            this.paginator1.firstPage();
        }
        this.searchObs.next(true);
    }

    public searchEnter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        if(!this.loading) {
            this.enterFired = true;
            this.searchString = filterValue;
            if(this.paginator1) {
                this.paginator1.firstPage();
                this.helperService.updateUrl({ page: 0 });
            }
            this.searchObs.next(true);
        }
    }

    public generateRefLink(retailerId: number) {
        this.helperService.generateRefLink('retailer', retailerId, this.referralLink);
    }
}
