<div class="content-body offers-list" fxLayout="column wrap">
    <h1 class="title-head">
        {{ 'offers.head_title' | translate }}
        <div class="line-full"></div>
    </h1>

    <div class="loading-shade-relative" *ngIf="loading || error || offers.length === 0">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3>{{ 'offers.error' | translate }}</h3>
        </div>
        <div *ngIf="!error && !loading && offers.length === 0">
            <h3>{{ 'offers.empty' | translate }} </h3>
        </div>
    </div>

    <div class="band" *ngIf="total > 0">
        <div *ngFor="let offer of offers; let i = index" class="card">
            <mat-card class="mat-card-w">
                <mat-card-header>
                    <mat-card-title class="title">
                        <div class="date-added"> {{ offer.added | dateFormat: 'DD.MM.YYYY' }} </div>
                        <div (click)="goShopping(offer.retailer_id)"> {{ offer.title }}  </div>
                    </mat-card-title>
                </mat-card-header>

                <img mat-card-image class="img" [src]="offer.img_url" [alt]="offer.title"
                    (click)="goShopping(offer.retailer_id)" />

                <mat-card-content class="wrap-logo">
                    <p [innerHtml]="offer.description"></p>
                </mat-card-content>

                <mat-card-actions class="actions">
                    <a class="btn-1" mat-button *ngIf="isMarketing()" [title]="'offers.go_to_offer_admin' | translate"
                        [href]="goToAdminOfferUrl(offer.id)" (click)="goToAdminOffer($event, offer.id)">
                        <mat-icon class="mat-18">pageview</mat-icon>
                    </a>
                    <button mat-raised-button color="primary" class="btn-2"
                        (click)="goShopping(offer.retailer_id)" [disabled]="!offer.retailer_id">
                            {{ 'offers.see_offer' | translate }}
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

    <mat-card class="filter-wrapper" [hidden]="total === 0">
        <mat-card-content>
            <mat-paginator #matPaginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
