import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: `${window?.location?.hostname ?? ''}`// it is recommended to set your domain, for cookies to work properly
    },
    autoOpen: false,
    position: 'bottom',
    theme: 'classic',
    // theme: 'edgeless',
    palette: {
        popup: {
            background: '#efefef',
            text: '#000000',
            link: 'blue',
            border: '1px solid #ccc'
        },
        button: {
            background: '#43a047',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    // type: 'opt-in',
    type: 'info',
    layouts: {
        'my-custom-layout': '{{messagelink}}'
    },
    elements: {
        messagelink: `
            <span id="cookieconsent" class="cc-message">
                {{message}} {{message2}}
                <!-- <a aria-label="learn more about cookies" tabindex="0" class="cc-link"
                    href="{{cookiePolicyHref}}" target="_blank">
                    {{cookiePolicyLink}}
                </a>, -->
                <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link"
                    href="{{privacyPolicyHref}}" target="_blank">
                    {{privacyPolicyLink}}
                </a>
                {{and}}
                <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link"
                    href="{{tosHref}}" target="_blank">
                    {{tosLink}}
                </a>
            </span>`,
    },
    content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        message2: 'By using our site, you acknowledge that you have read and understand our',
        allow: 'Allow cookies',
        deny: 'Refuse cookies',
        dismiss: 'Got it',
        cookiePolicyHref: '',
        cookiePolicyLink: '',
        privacyPolicyHref: '',
        privacyPolicyLink: '',
        tosHref: '',
        tosLink: '',
        and: 'and',
    }
};
