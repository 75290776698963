import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsGoShopping } from '../models/analytics/analytics-go-shopping';
import { ErrorApi } from '../models/general/error-api';
import { ResponseApi } from '../models/general/response-api';

@Injectable({
    providedIn: 'root'
})
export class EventServiceService {

    constructor(private http: HttpClient) {
    }

    goShopping(data: AnalyticsGoShopping) {
        return this.http.post<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/event/go-shopping`, data);
    }
}
