<div class="container-fluid app-message">
    <ng-container *ngIf="!loading && !error && !!message; then messageTemplate; else errorTemplate"></ng-container>

    <ng-template #errorTemplate>
        <div class="loading-shade">
            <app-loading *ngIf="loading"></app-loading>
            <div class="error" *ngIf="error">
                <h3 *ngIf="errorText1">{{ 'messages.no_message_found' | translate }}</h3>
                <h3 *ngIf="errorText2">{{ 'general.failed_load' | translate }}</h3>
                <h3 *ngIf="errorText3">{{ 'messages.invalid_page' | translate }}</h3>
            </div>
        </div>
    </ng-template>

    <ng-template #messageTemplate>
        <h1 class="title-head">
            <div>
                {{ (admin ? 'messages.admin.head_title_1' : 'messages.head_title_1') | translate }}
                <div class="new-btn-w">
                    <button mat-mini-fab color="primary" [disabled]="loading" (click)="back()">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                </div>

                <div class="close-btn-w" *ngIf="admin">
                    <button mat-fab color="primary" [disabled]="loading || checkClosedStatus()" (click)="closeMessage()"
                            [matTooltip]="trans('messages.close_msg_title')" matTooltipPosition="left">
                        {{'messages.close_msg' | translate }}
                    </button>
                </div>
            </div>
            <div class="line-full"></div>
        </h1>
        <div class="message band">
            <div class="card">
                <mat-card class="mat-card-w mat-elevation-z8">
                    <mat-card-header>
                        <mat-card-title class="head-ans">
                            <div class="h4 title">
                                <mat-icon>message</mat-icon>
                                {{message.subject}}
                                <mat-icon *ngIf="checkMessage()" [matTooltip]="readTooltip()"
                                    matTooltipPosition="right">
                                    {{ checkMessageViewed() ? 'drafts' : 'markunread' }}
                                </mat-icon>
                            </div>
                            <div class="date" fxHide.xs>
                                {{ message.created | dateFormat: 'DD.MM.YYYY HH:mm'}}
                            </div>
                        </mat-card-title>
                        <mat-card-subtitle fxHide fxShow.xs>
                            {{message.created | dateFormat: 'DD.MM.YYYY HH:mm'}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content-wrap">

                        <div class="user" *ngIf="message.user">
                            {{ (checkSenderOrReceiver(message) ? 'messages.to' : 'messages.from') | translate }}:
                            <ng-template [ngIf]="admin" [ngIfElse]="user">
                                <a [href]="goToUserUrl(message.user)" (click)="goToUser($event, message.user)">
                                    {{ message.user.fname ? message.user.fname + ' ' + message.user.lname : message.user.username }}
                                </a>
                                {{ message.user ? ' - ' + message.user.email: ''}}
                            </ng-template>
                            <ng-template #user>
                                {{ message.user.fname ? message.user.fname + ' ' + message.user.lname : message.user.username }}
                            </ng-template>
                        </div>

                        <div class="content" [innerHtml]="message.message"></div>

                        <app-attachments [attachments]="message.attachments"></app-attachments>
                    </mat-card-content>
                </mat-card>
            </div>
            <div *ngFor="let answer of message.answers; let i = index" class="card">
                <mat-card class="mat-card-w mat-elevation-z8" [ngClass]="{'new-message': checkNewMsg(answer)}">
                    <mat-card-header>
                        <mat-card-title class="head-ans">
                            <div class="h4 title">
                                <mat-icon>{{ checkReply(answer) ? 'reply' : 'message' }}</mat-icon>
                                {{answer.user.fname}} {{answer.user.lname}}
                                <mat-icon *ngIf="checkViewed(answer)"
                                    [matTooltip]="readTooltipAnswer(answer.viewed)" matTooltipPosition="right">
                                    {{answer.viewed == 1 ? 'drafts' : 'markunread'}}
                                </mat-icon>
                                <mat-icon *ngIf="checkNewMsg(answer)" color="primary"
                                    [matTooltip]="trans('messages.new_message')" matTooltipPosition="right">
                                    fiber_new
                                </mat-icon>
                            </div>
                            <div class="date" fxHide.xs>
                                {{answer.answer_date | dateFormat: 'DD.MM.YYYY HH:mm'}}
                            </div>
                        </mat-card-title>
                        <mat-card-subtitle fxHide fxShow.xs>
                            {{answer.answer_date | dateFormat: 'DD.MM.YYYY HH:mm'}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content-wrap">

                        <div class="content" [innerHtml]="answer.answer"></div>

                        <app-attachments [attachments]="answer.attachments"></app-attachments>

                    </mat-card-content>
                </mat-card>
            </div>

        </div>

        <app-reply-form *ngIf="admin || !checkClosedStatus()" [id]="id" [userId]="message.user_id" [admin]="admin"
            (answerAction)="onAnswerEvent($event)">
        </app-reply-form>
    </ng-template>
</div>