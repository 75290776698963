export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY HH:mm',
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};