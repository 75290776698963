import { Component, OnInit, ViewChild, OnDestroy, Injector, Input, EventEmitter } from '@angular/core';
import { merge, of, Subject, Subscription, Observable } from 'rxjs';
import { CouponFilters } from '../shared/models/filters/coupon-filters';
import { BaseComponent } from '../shared/components/base.component';
import { CouponList } from '../shared/models/coupon/coupon-list';
import { MatPaginator } from '@angular/material/paginator';
import { CouponService } from '../shared/services/coupon.service';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { ErrorApi } from '../shared/models/general/error-api';
import { RetailerService } from './../shared/services/retailer.service';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { CouponFilterOptions } from '../shared/models/coupon/coupon-filter-options';
import { RetailerWithCoupon } from '../shared/models/retailer/retailer-with-coupon';
import { CouponSelectionFilters } from '../shared/models/coupon/coupon-selection-filters';
import { OptionItem } from './../shared/models/select-autocomplete/option-item';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
    selector: 'app-coupons',
    templateUrl: './coupons.component.html',
    styleUrls: [ './coupons.component.scss' ]
})
export class CouponsComponent extends BaseComponent implements OnInit, OnDestroy {

    private fatalError = false;
    public retailerLoading: boolean = true;
    public pageSizeOptions: number[];
    private defaultPageSizeOptions: number[];
    private searchEvent = new EventEmitter<void>();
    private subscriptionPag1: Subscription;
    private subscriptionPag2: Subscription;
    private subscriptionCoupons: Subscription;

    @ViewChild('matPaginator1', { static: true }) paginator1: MatPaginator;
    @ViewChild('matPaginator2', { static: true }) paginator2: MatPaginator;
    public total = 0;
    public coupons: CouponList[] = [];
    public retailers: OptionItem[] = [];

    public referralLink: Record<number, string> = {};

    public filters: CouponSelectionFilters = {
        retailerId: null,
        pageSize: null,
        pageIndex: null,
        option: null
    };

    public filterOptions: CouponFilterOptions[] = [
        { id: 0, name: 'coupons.popular', field: 'visits', sort: 'desc', exclusive: false, interval: null },
        { id: 1, name: 'coupons.latest', field: 'added', sort: 'desc', exclusive: false, interval: null  },
        { id: 2, name: 'coupons.expire', field: 'end_date', sort: 'asc', exclusive: false, interval: 3  },
    ];

    constructor(injector: Injector,
                private couponService: CouponService,
                private retailerService: RetailerService,
                private authenticationService: AuthenticationService) {

        super(injector);

        this.loading = false;

        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_4');
        this.pageSizeOptions = this.defaultPageSizeOptions;

        this.filters = {
            retailerId: 0,
            pageSize: this.settingsProviderService.getSettingNumber('coupons_per_page'),
            pageIndex: 0,
            option: 0
        };

        this.route.queryParams.subscribe((params) => {

            if(params.pageSize && Number.isInteger(+params.pageSize) && +params.pageSize > 0) {
                this.filters.pageSize = +params.pageSize;
            }

            if(params.retailerId && Number.isInteger(+params.retailerId) && +params.retailerId > 0) {
                this.filters.retailerId = +params.retailerId;
            }

            if(params.page && Number.isInteger(+params.page) && +params.page > 0) {
                this.filters.pageIndex = +params.page;
            }

            if(params.option && Number.isInteger(+params.option)) {
                const index = +params.option;
                const option = this.filterOptions[index];
                if(option) {
                    this.filters.option = index;
                }
            }
        });
    }

    ngOnInit() {
        this.retailerService.getAllWithCoupon().subscribe(
            (response) => {
                if(response && response.statusCode === 200 && response.data) {

                    const data = <RetailerWithCoupon[]>response.data;

                    const retailers = data.map(r => {
                        return {
                            value: r.retailer_id,
                            display: r.title + '(' + r.coupon_nr + ')',
                            disabled: false
                        };
                    });

                    retailers.unshift({ value: 0, display: this.trans('coupons.any'), disabled: false });

                    this.retailers = retailers;
                } else {
                    // error
                }
                this.retailerLoading = false;
            }, (errors: string) => {
                console.log(errors);
                this.retailerLoading = false;
            });

    // ngAfterViewInit() {
        this.paginator1.pageSize = this.filters.pageSize;
        this.paginator2.pageSize = this.filters.pageSize;
        this.paginator1.pageIndex = this.filters.pageIndex;
        this.paginator2.pageIndex = this.filters.pageIndex;


        this.subscriptionPag1 = this.paginator1.page.subscribe(() => {
            this.paginator2.pageIndex = this.paginator1.pageIndex;
            this.paginator2.pageSize = this.paginator1.pageSize;
            this.helperService.updateUrl({
                page:  this.paginator1.pageIndex,
                pageSize:  this.paginator1.pageSize
            });
            this.searchEvent.next();
        });

        this.subscriptionPag2 = this.paginator2.page.subscribe(() => {
            this.paginator1.pageIndex = this.paginator2.pageIndex;
            this.paginator1.pageSize = this.paginator2.pageSize;
            this.helperService.updateUrl({
                page: this.paginator2.pageIndex,
                pageSize: this.paginator2.pageSize
            });
            this.searchEvent.next();
        });

        this.getCoupons();
    }

    ngOnDestroy() {
        this.subscriptionPag1?.unsubscribe();
        this.subscriptionPag2?.unsubscribe();
        this.subscriptionCoupons?.unsubscribe();
    }

    getCoupons() {
        this.loading = true;

        const couponsReq = merge(this.searchEvent)
            .pipe(
                startWith({}),
                switchMap(() => {
                    if(this.fatalError) {
                        this.fatalError = false;
                        return of(this.helper.getDefaultErrorResponse<CouponList | ErrorApi>());
                    } else {
                        this.loading = true;

                        const selectedOption = this.filterOptions[this.filters.option];

                        const postData: CouponFilters = {
                            exclusive: selectedOption.exclusive,
                            sortField: selectedOption.field,
                            sortDirection: selectedOption.sort,
                            interval: selectedOption.interval,
                            pageIndex: this.paginator1.pageIndex,
                            pageSize: this.paginator1.pageSize,
                            retailerId: this.filters.retailerId,
                        };
                        return this.couponService.getList(postData);
                    }

                    // if(typeof this.paginator.pageSize !== 'undefined') {
                    //     this.pageSize = this.paginator.pageSize;
                    // }
                }),
                map((response) => {
                    if(response && response.statusCode === 200) {

                        this.total = response.total;

                        // if(response.totalPage !== this.paginator1.pageSize) {
                        //     this.paginator1.pageSize = response.totalPage;
                        //     this.helperService.updateUrl({ pageSize: this.paginator1.pageSize });
                        // }

                        this.pageSizeOptions = this.helper.generatePageSizeOptions(
                            this.total,
                            this.defaultPageSizeOptions,
                            this.paginator1.pageSize
                        );

                        this.error = false;
                        this.loading = false;
                        return <CouponList[]>response.data;
                    } else {
                        this.error = true;
                        this.total = 0;
                        this.loading = false;
                        return [];
                    }
                }),
                catchError(() => {
                    this.loading = false;
                    this.error = true;
                    this.fatalError = true;
                    this.total = 0;
                    return of([]);
                })
            );

        const callback = (data: CouponList[]) => {

            this.coupons = data;

            this.helperService.handleGoShoppingReturn(this.route.snapshot.queryParams);

            if(this.fatalError) {
                this.subscriptionCoupons = couponsReq.subscribe(callback);
            }
        };

        this.subscriptionCoupons = couponsReq.subscribe(callback);
    }

    public changeRetailerOption(value: number | string) {
        this.filters.retailerId = +value;
        this.helperService.updateUrl({ retailerId: this.filters.retailerId });
        this.paginator1?.firstPage();
        this.searchEvent.next();
    }

    public changeOption(event: MatSelectChange) {
        const index = event.value;
        const option = this.filterOptions[index];
        if(option) {
            this.filters.option = index;
            this.paginator1.pageIndex = 0;
            this.paginator2.pageIndex = 0;
            this.helperService.updateUrl({
                option: index,
                page: 0
            });
            this.searchEvent.next();
        }
    }

    public goToAdminCoupon(event: Event, couponId: number): void {
        event.preventDefault();
        this.helperService.goToAdminCoupon(couponId);
    }

    public goToAdminCouponUrl(couponId: number): string {
        return couponId > 0 ? this.helperService.goToAdminCouponUrl(couponId): '#';
    }

    public isAdmin(): boolean {
        return this.authenticationService.checkPermission('admin');
    }

    public generateRefLink(couponId: number) {
        this.helperService.generateRefLink('coupon', couponId, this.referralLink);
    }

    public goShoppingAndCopyCode(coupon: CouponList) {
        this.helper.copyCode(coupon.code);

        this.helperService.goShopping(coupon.retailer_id, coupon.coupon_id);
    }
}
