import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, startWith, switchMap, map, catchError } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ResponseApiList } from 'src/app/shared/models/general/response-api-list';
import { Subject, merge, Subscription, of } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SearchFilters } from 'src/app/shared/models/filters/search-filters';
import { UserService } from 'src/app/shared/services/user.service';
import { UserList } from 'src/app/shared/models/user/private/user-list';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
    selector: 'app-search-user-form',
    templateUrl: './search-user-form.component.html',
    styleUrls: [ './search-user-form.component.scss' ]
})
export class SearchUserFormComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    firstTime = true;
    firstSearch = true;

    displayedColumns: string[] = [ 'id', 'name', 'username', 'email' ];

    searchField: FormControl;
    enterFired = false;
    searchString: string;
    currentIndex = -1;
    selectedUser: UserList = null;

    users: UserList[] = [];
    total = 0;

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;

    @ViewChild('matPaginator', { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    searchObs = new Subject<boolean>();
    sortObs = new Subject<boolean>();

    private subscription: Subscription;
    private subscriptionSearch: Subscription;
    private subscriptionSort: Subscription;

    constructor(injector: Injector,
                private dialogRef: MatDialogRef<SearchUserFormComponent>,
                private userService: UserService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        super(injector);
        this.loading = false;

        this.pageSize = this.settingsProviderService.getSettingNumber('results_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_table');
        this.pageSizeOptions = this.defaultPageSizeOptions;
    }

    ngOnInit() {
        this.searchField = new FormControl();
    }

    ngOnDestroy() {
        if(this.subscriptionSearch) {
            this.subscriptionSearch.unsubscribe();
        }
        if(this.subscriptionSort) {
            this.subscriptionSort.unsubscribe();
        }
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {

        this.paginator.pageSize = this.pageSize;

        this.subscriptionSort = this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.subscriptionSearch = this.searchField.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe(filterValue => {
            if(!this.loading && !this.enterFired && this.searchString !== filterValue) {
                this.searchString = filterValue;
                this.firstSearch = false;
                this.searchObs.next(true);
            }
        });

        this.searchUser();
    }

    searchUser() {

        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.searchObs)
            .pipe(
                startWith({}),
                switchMap(() => {
                    if(this.firstTime) {
                        this.firstTime = false;
                        return of({
                            statusCode: 1,
                            data: null,
                            total: 0,
                            totalPage: 0,
                            message: ''
                        });
                    } else {
                        this.loading = true;

                        const postData: SearchFilters = {
                            searchBy: 'all',
                            string: this.searchString,
                            sortField: this.sort.active,
                            sortDirection: this.sort.direction,
                            pageIndex: this.paginator.pageIndex,
                            pageSize: this.paginator.pageSize
                        };
                        return this.userService.list(postData);
                    }
                }),
                map((response: ResponseApiList<UserList | ErrorApi[]>) => {
                    if(response && response.statusCode === 200) {
                        this.error = false;
                        this.total = response.total;

                        this.pageSizeOptions = this.helper.generatePageSizeOptions(
                            this.total,
                            this.defaultPageSizeOptions,
                            this.paginator.pageSize
                        );

                        this.loading = false;
                        return <UserList[]>response.data;
                    } else if(response && response.statusCode === 1) {
                        return [];
                    } else {
                        this.error = true;
                        this.loading = false;
                        this.total = 0;
                        return [];
                    }
                }),
                catchError(() => {
                    this.error = true;
                    this.loading = false;
                    this.total = 0;
                    return of([]);
                })
        ).subscribe((data: UserList[]) => this.users = data);
    }

    public searchEnter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        if(!this.loading) {
            this.enterFired = true;
            this.searchString = filterValue;
            this.firstSearch = false;
            this.searchObs.next(true);
        }
    }

    select(user: UserList, index: number) {
        if(this.currentIndex === index) {
            this.currentIndex = -1;
            this.selectedUser = null;
        } else {
            this.currentIndex = index;
            this.selectedUser = user;
        }
    }

    done() {
        if(this.selectedUser) {
            this.dialogRef.close(this.selectedUser);
        }
    }

    doneDbl(user: UserList) {
        this.selectedUser = user;
        this.done();
    }

}
