import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { ErrorApi } from '../models/general/error-api';
import { Translation } from '../models/translation/translation';

@Injectable()
export class TranslationService {
    constructor(private http: HttpClient) {
    }

    getList(data: SortFilters) {
        return this.http.post<ResponseApiList<Translation | ErrorApi[]>>(`${environment.apiUrl}/v1/translation/list`, data);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Translation | ErrorApi[]>>(`${environment.apiUrl}/v1/translation/` + id);
    }

    getLangs() {
        return this.http.get<ResponseApi<string[]>>(`${environment.apiUrl}/v1/translation/languages`);
    }

    getVersion() {
        return this.http.get<ResponseApi<string>>(`${environment.apiUrl}/v1/translation/version`);
    }

    getCategories() {
        return this.http.get<ResponseApi<string[]>>(`${environment.apiUrl}/v1/translation/categories`);
    }

    getIndex(lang: string) {
        return this.http.get<ResponseApi<Object | ErrorApi[]>>(`${environment.apiUrl}/v1/translation/` + lang);
    }

    create(translation: Translation) {
        return this.http.post<ResponseApi<Translation | ErrorApi[]>>(`${environment.apiUrl}/v1/translation`, translation);
    }

    update(translation: Translation) {
        return this.http.put<ResponseApi<Translation | ErrorApi[]>>(`${environment.apiUrl}/v1/translation/` + translation.id, translation);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/translation/` + id);
    }

}
