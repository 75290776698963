import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Retailer } from '../models/retailer/retailer';
import { RetailerFilters } from '../models/filters/retailer-filters';
import { ErrorApi } from '../models/general/error-api';
import { RetailerQuickSearch } from '../models/retailer/retailer-quick-search';
import { RetailerRecommended } from '../models/retailer/retailer-recommended';
import { RetailerFeatured } from '../models/retailer/retailer-featured';
import { RetailerView } from '../models/retailer/retailer-view';
import { RetailerQuickInfo } from '../models/retailer/retailer-quick-info';
import { RetailerWithCoupon } from '../models/retailer/retailer-with-coupon';

@Injectable()
export class RetailerService {
    constructor(private http: HttpClient) {
    }

    getList(data: RetailerFilters) {
        return this.http.post<ResponseApiList<Retailer | ErrorApi>>(`${environment.apiUrl}/v1/retailer/list`, data);
    }

    quickSearch(search: string) {
        const data = {
            search: search
        };
        return this.http.post<ResponseApiList<RetailerQuickSearch | ErrorApi>>(`${environment.apiUrl}/v1/retailer/quick-search`, data);
    }

    getFeatured() {
        return this.http.get<ResponseApiList<RetailerFeatured>>(`${environment.apiUrl}/v1/retailer/featured`);
    }

    getRecommended(retailerId: number) {
        return this.http.get<ResponseApiList<RetailerRecommended>>(`${environment.apiUrl}/v1/retailer/recommended/`+ retailerId);
    }

    getQuickInfo(id: number) {
        return this.http.get<ResponseApi<RetailerQuickInfo | ErrorApi[]>>(`${environment.apiUrl}/v1/retailer/quick-info/` + id);
    }

    getActiveById(id: number) {
        return this.http.get<ResponseApi<RetailerView | ErrorApi[]>>(`${environment.apiUrl}/v1/retailer/active/` + id);
    }

    getAllWithCoupon() {
        return this.http.get<ResponseApiList<RetailerWithCoupon | ErrorApi>>(`${environment.apiUrl}/v1/retailer/all/with-coupon`);
    }
}
