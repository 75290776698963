import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { ErrorApi } from '../models/general/error-api';
import { ClickUser } from '../models/click/click-user';
import { ClickLink } from '../models/click/click-link';
import { GoToStoreRequest } from '../models/go-shopping/go-to-store-request';
import { ClickGenerateLink } from '../models/click/click-generate-link';
import { ClickFilters } from '../models/click/click-filters';
import { ClickCheckLink } from '../models/click/click-check-link';
import { CheckLink } from '../models/click/check-link';

@Injectable({
  providedIn: 'root'
})
export class ClicksService {

    constructor(private http: HttpClient) {
    }

    getUserClicks(data: SortFilters) {
        return this.http.post<ResponseApiList<ClickUser | ErrorApi>>(`${environment.apiUrl}/v1/click/user`, data);
    }

    list(data: ClickFilters) {
        return this.http.post<ResponseApiList<ClickUser | ErrorApi>>(`${environment.apiUrl}/v1/click/list`, data);
    }

    goToStore(data: GoToStoreRequest) {
        return this.http.post<ResponseApi<ClickLink | ErrorApi[]>>(`${environment.apiUrl}/v2/click/go-to-store`, data);
    }

    generateLink(data: ClickGenerateLink) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/click/generate-link`, data);
    }

    checkLink(data: ClickCheckLink) {
        return this.http.post<ResponseApi<CheckLink | ErrorApi[]>>(`${environment.apiUrl}/v1/click/check-link`, data);
    }
}
