import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { FirstLoadService } from '../services/first-load.service';
import { SiteSettings } from '../models/settings/site-settings';
import { ResponseApi } from '../models/general/response-api';
import { SiteModes } from '../models/general/site-modes';

@Injectable({
    providedIn: 'root',
})
export class SettingsProviderService {

    public isLoaded = false;

    private settings: SiteSettings | null = null;

    constructor(private settingsService: SettingsService) {
    }

    public getSettings() {
        return this.settings;
    }

    public getSettingNumber(key: string, defaultValue: number = 0): number {
        if(this.settings && this.settings[key]) {
            return +this.settings[key];
        }
        return defaultValue;
    }

    public getSettingString(key: string): string {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return '';
    }

    public getSettingBool(key: string): boolean {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return false;
    }

    public getSettingArray(key: string, defaultArray: boolean = false, defaultValue?: Array<any>): Array<any> | null {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return !!defaultValue ? defaultValue : (defaultArray ? [] : null);
    }

    public getSettingObj<T>(key: string, defaultObj: boolean = false, defaultValue: T): T | null {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return defaultObj ? defaultValue : null;
    }

    public load(): Promise<void> {
        // console.log('init load settings');

        return new Promise((resolve, reject) => {
            this.settingsService.getActive().subscribe(
                (response: ResponseApi<SiteSettings>) => {
                    if(response && response.statusCode === 200 && response.data) {
                        this.settings = response.data;
                    }
                    this.isLoaded = true;
                    resolve();
                },
                (error: string) => {
                    console.log(error);
                    this.isLoaded = true;
                    reject();
                }
            );
        });
    }
}
