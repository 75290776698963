<div class="form-group file-upload" #fileUploadHtml>
    <div class="files">
        <ng-container *ngIf="!!uploadFiles?.length; then uploadsTemplate; else noUploadsTemplate"></ng-container>
        <ng-template #uploadsTemplate>
            <div class="upload-file" *ngFor="let uploadFile of uploadFiles; let i = index">
                <div class="file-wrapper">
                    <div class="file-name">
                        <mat-icon *ngIf="uploadFile.mimeIcon"> {{ uploadFile.mimeIcon }} </mat-icon>
                        <span [title]="uploadFile.name"> {{ uploadFile.name }} </span>
                    </div>
                    <div class="right">
                        <div class="file-size"> {{ uploadFile.humanFileSize() }} </div>
                        <mat-icon class="file-delete" (click)="uploadFile.deleteFile()">delete</mat-icon>
                    </div>
                </div>
                <mat-progress-bar *ngIf="uploadFile.uploadStatus === 'progress'" class="progress-bar" mode="determinate" [value]="uploadFile.progress"></mat-progress-bar>
                <mat-error *ngIf="uploadFile.uploadStatus === 'validation'">
                    <div *ngFor="let error of uploadFile.errors">{{ error }}</div>
                </mat-error>
                <mat-error *ngIf="uploadFile.uploadStatus === 'error'"> {{ 'general.error_occured' | translate }} </mat-error>
            </div>
        </ng-template>
        <ng-template #noUploadsTemplate>
            <div class=""> {{ 'messages.no_file_uploaded' | translate }} </div>
        </ng-template>
    </div>
    <input type="file" class="hidden" [accept]="allowedFileFormats" (change)="onFileChange($event)" #fileInput>
    <button mat-mini-fab color="primary" class="upload-button" (click)="openFileInput($event)" [title]="allowedFileFormats">
        <mat-icon>attach_file</mat-icon>
    </button>
</div>