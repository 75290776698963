import { Component, OnInit, HostListener, ViewChild, AfterViewInit, OnDestroy, ElementRef, Injector } from '@angular/core';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';
import { AlertService } from './shared/services/alert.service';
import { NavigationEnd, Event as RouterEvent } from '@angular/router';
import { MatSidenav, MatSidenavContent, MatDrawerMode } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './shared/services/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WidthHeight } from './shared/models/general/width-height';
import { Announcement } from './shared/models/announcement/announcement';
import { BaseComponent } from './shared/components/base.component';
import { NewMessageEvent } from './shared/models/message/new-message-event';
import { WithdrawService } from './shared/services/withdraw.service';
import { NavUserObj } from './shared/models/navbar/nav-user-obj';
import { SidenavMode } from './shared/models/navbar/sidenav-mode';
import { UserGroupName } from './shared/models/user/private/user-group-name';
import { RouteMenu } from './shared/models/routes/route-menu';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { GlobalEventsService } from './shared/services/global-events.service';
import { Logged } from './shared/models/general/logged';
import { CustomClick } from './shared/models/analytics/custom-click';
import { AnalyticsService } from './shared/services/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    @ViewChild('matSidenavContent', { static: true }) matSidenavContent: MatSidenavContent;
    public activeSidenavMode: MatDrawerMode;

    public fullPage: boolean = true;
    public facebookChatEnabled: boolean = false;

    private windowWidth = 0;
    private windowHeigth = 0;

    title = `${environment.title}`;
    public nrUnredUser = 0;
    public nrUnredAdmin = 0;
    public nrWithdrawRequests = 0;

    public navUsers: NavUserObj;

    private sizeChangeEvent = new Subject<WidthHeight>();
    private sizeChangeEventSubscription: Subscription;
    private langSubscription: Subscription;
    private loggedSubscription: Subscription;
    private urlSubscription: Subscription;
    private urlPageSubscription: Subscription;
    private routeSubscription: Subscription;
    private firstLoadSubscription: Subscription;
    private messageCheckSubscription: Subscription;
    private gestureSubscription: Subscription;
    private langChangeSubscription: Subscription;
    private customSubscription: Subscription;
    private sidenavChangeSubscription: Subscription;

    private announcementFlag: string = null;

    constructor(injector: Injector,
                private authenticationService: AuthenticationService,
                private globalEventsService: GlobalEventsService,
                private alertService: AlertService,
                private announcementService: AnnouncementService,
                private withdrawService: WithdrawService,
                private localStorageService: LocalStorageService,
                private analyticsService: AnalyticsService) {

        super(injector);

        this.activeSidenavMode = SidenavMode.default;

        this.facebookChatEnabled = this.settingsProviderService.getSettingBool('facebook_chat');

        this.urlPageSubscription = this.router.events.subscribe((event: RouterEvent) => {
            if(event instanceof NavigationEnd && event.url) {
                environment.debug && console.log('fullpage');
                if(this.fullPage != this.appPath.checkIfFullPage(event.url)) {
                    this.fullPage = !this.fullPage;
                }
            }
        });

        this.firstLoadSubscription = this.globalEventsService.firstLoaded.subscribe(() => {
            this.loading = false;

            this.langChangeSubscription = this.globalEventsService.langChange.subscribe(() => {
                this.buildMenu('lang');
                this.showAnnouncement();
            });

            this.customSubscription = this.globalEventsService.customSubject.subscribe((type: string) => {
                switch(type) {
                    case 'announcement':
                        this.showAnnouncement();
                        break;
                    case 'withdraw-requests':
                        if(this.nrWithdrawRequests > 0) {
                            this.nrWithdrawRequests--;
                        }
                        break;
                    case 'alert-global-closed':
                        if(this.announcementFlag != null) {
                            this.localStorageService.setAnnouncement(this.announcementFlag);
                        }
                        break;
                }
            });

            this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
                if(result.isInitialized) {
                    this.buildMenu('logged');
                    this.showAnnouncement();
                    this.getWithdrawRequests(result.isAdmin);
                }
            });

            this.firstLoadSubscription.unsubscribe();
        });
    }

    ngOnInit() {

        this.messageCheckSubscription = this.globalEventsService.newMessage.subscribe((newMessageEvent: NewMessageEvent) => {
            this.nrUnredUser = newMessageEvent.nrUnredUser;
            this.nrUnredAdmin = newMessageEvent.nrUnredAdmin;
        });

        this.sizeChangeEventSubscription = this.sizeChangeEvent
            .pipe(debounceTime(250))
            .subscribe((size: WidthHeight) => {

                if(this.windowWidth !== size.width) {
                    this.windowWidth = size.width;
                    this.setSideNav(size.width);
                    this.globalEventsService.customSubject.next('window-size');
                } else if (!this.loading && this.windowHeigth !== size.height) {
                    this.windowHeigth = size.height;
                    this.globalEventsService.customSubject.next('window-size');
                }
            });
    }

    ngOnDestroy() {
        this.langSubscription?.unsubscribe();
        this.loggedSubscription?.unsubscribe();
        this.urlSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
        this.firstLoadSubscription?.unsubscribe();
        this.messageCheckSubscription?.unsubscribe();
        this.sizeChangeEventSubscription?.unsubscribe();
        this.gestureSubscription?.unsubscribe();
        this.customSubscription?.unsubscribe();
        this.langChangeSubscription?.unsubscribe();
        this.sidenavChangeSubscription?.unsubscribe();
        this.urlPageSubscription?.unsubscribe();
    }

    ngAfterViewInit() {

        this.windowWidth = window.innerWidth;
        this.windowHeigth = window.innerHeight;

        this.sidenavChangeSubscription = this.sidenav.openedChange.subscribe(() => {
            this.handleScrollBlock();
        });

        this.urlSubscription = this.router.events.subscribe((event: RouterEvent) => {

            if(event instanceof NavigationEnd) {

                this.buildMenu('first');

                if(this.authenticationService.loggedIn && event.urlAfterRedirects) {
                    const url = event.urlAfterRedirects;
                    const path = this.helper.getPath(url);
                    this.navUsers.activePath = this.appPath.getGroupByActivePath(path);
                } else {
                    this.navUsers.activePath = UserGroupName.GUEST;
                }

                this.setSideNav(this.windowWidth);

                // console.log('route event');
            }
        });
    }

    private handleScrollBlock(): void {
        environment.debug && console.log('sidenav opened', this.sidenav.opened);

        if(this.sidenav.opened && this.activeSidenavMode === SidenavMode.default) {
            this.helper.handleScrollBlock('set');
            return;
        }

        this.helper.handleScrollBlock('remove');
    }

    public checkExactRouteMatch(routeMenu: RouteMenu) {
        return routeMenu.extra && (
            routeMenu.extra.defaultHome ||
            routeMenu.extra.defaultUser ||
            routeMenu.extra.defaultAdmin
        );
    }

    private buildMenu(type: string) {
        switch(type) {
            case 'first':
                if(!this.navUsers) {
                    environment.debug && console.log('buildMenu', type);
                    this.navUsers = new NavUserObj(this.appPath);
                }
                break;
            case 'lang':
                environment.debug && console.log('buildMenu', type);
                this.navUsers.updateMenuItems(this.appPath);
                break;
            case 'logged':
                environment.debug && console.log('buildMenu', type);
                this.navUsers = new NavUserObj(this.appPath);
                break;
        }
    }

    private showAnnouncement() {
        const lang = this.appPath.lang;

        this.alertService.reset(true);
        this.announcementFlag = null;

        this.announcementService.get(lang).subscribe(
            (response: ResponseApi<Announcement>) => {
                if(response && response.statusCode === 200) {
                    const ann = response.data;

                    const version = this.localStorageService.getAnnouncement();

                    if(version && ann.version === version) {
                        return;
                    }

                    if(ann && ann.text && ann.text.length && (!ann.logged || ann.logged === this.authenticationService.loggedIn)) {
                        const message = ann.text.split('\n');
                        this.alertService.custom(message, ann.type, true, false, true);
                        this.announcementFlag = ann.version;
                        this.localStorageService.removeAnnouncement();
                    }
                }
            },
            (error: string) => {
                console.log(error);
            }
        );
    }

    private getWithdrawRequests(isAdmin: boolean) {

        if(!isAdmin) {
            this.nrWithdrawRequests = 0;
            return;
        }

        this.withdrawService.checkNr().subscribe(
            (response: ResponseApi<number>) => {
                if(response.statusCode === 200) {
                    this.nrWithdrawRequests = response.data
                }
            },
            (error: string) => {
                console.log(error);
            }
        );
    }

    setSideNav(width: number) {
        if(!this.sidenav) {
            return;
        }

        environment.debug && console.log('setSideNav');

        this.navUsers && this.navUsers.openActiveGroupPanel();

        if(width > 960) {
            if(this.navUsers && this.navUsers.isLoggedActivePath()) {
                this.activeSidenavMode = SidenavMode.user;
                if(!this.sidenav.opened) {
                    this.sidenav.open();
                }
            } else {
                this.activeSidenavMode = SidenavMode.default;
                if(this.sidenav.opened) {
                    this.sidenav.close();
                }
            }
        } else {
            this.activeSidenavMode = SidenavMode.default;
            if(this.sidenav.opened) {
                this.sidenav.close();
            }
        }

        this.handleScrollBlock();

        setTimeout(() => { this.checkSidenavMargin() }, 500);
    }

    private checkSidenavMargin() {

        if(!this.sidenav) {
            return;
        }

        const nav = this.matSidenavContent.getElementRef().nativeElement;
        // console.log(this.sidenav.opened, nav.style.cssText);

        if(!nav) {
            setTimeout(this.checkSidenavMargin, 50);
            return;
        }

        if(nav && nav.style) {
            if(this.sidenav.opened) {
                if(nav.style.cssText === '' || nav.style.cssText.indexOf('margin-left:') === -1) {
                    console.log('fixed left nav margin 1!');
                    nav.style.marginLeft = '300';
                }
            } else {
                if(nav.style.cssText.indexOf('margin-left:') > -1) {
                    console.log('fixed left nav margin 0!');
                    nav.style.marginLeft = '';
                }
            }
        }
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {

        const size: WidthHeight = {
            width: event.target.innerWidth,
            height: event.target.innerHeight
        };

        this.sizeChangeEvent.next(size);
    }

    @HostListener('document:click', ['$event'])
    @HostListener('document:auxclick', ['$event'])
    clickout(event: CustomClick) {

        if(!event.target || (event.button != 0 && event.button != 1)) {
            return;
        }

        this.helperService.handleCashbackLink(event);

        this.helperService.handleStopLink(event);

        this.analyticsService.clickEvent(event);
    }
}
