import { Component, OnInit, Input, OnDestroy, HostListener, Injector } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AsynchronouslyInitialisedComponent } from 'src/app/shared/components/asynchronously-initialised.component';
import { ResponseApiList } from 'src/app/shared/models/general/response-api-list';
import { RetailerRecommended } from 'src/app/shared/models/retailer/retailer-recommended';
import { RetailerService } from 'src/app/shared/services/retailer.service';

@Component({
    selector: 'app-retailer-recommended',
    templateUrl: './retailer-recommended.component.html',
    styleUrls: [ './retailer-recommended.component.scss' ]
})
export class RetailerRecommendedComponent extends AsynchronouslyInitialisedComponent implements OnInit, OnDestroy {

    // tslint:disable-next-line: variable-name
    private _retailerId: number;
    @Input()
    set retailerId(retailerId: number) {
        this._retailerId = retailerId;
    }
    get retailerId(): number {
        return this._retailerId;
    }

    retailers: RetailerRecommended[] = [];
    retailersBackup: RetailerRecommended[] = [];

    private widthChangeEvent = new Subject<number>();
    private widthChangeEventSubscription: Subscription;

    constructor(injector: Injector, private retailerService: RetailerService) {

        super(injector);
    }

    ngOnInit() {
        if(this.retailerId > 0) {
            this.retailerService.getRecommended(this.retailerId)
                .subscribe((response: ResponseApiList<RetailerRecommended>) => {

                    if(response && response.statusCode === 200 && response.data) {
                        this.retailers = response.data;
                        this.error = false;
                        this.retailersBackup = [...this.retailers];
                        this.widthChangeEvent.next(window.innerWidth);
                    } else {
                        this.error = true;
                    }
                    this.loading = false;
                    this.componentLoaded();

                }, (errors: string) => {
                    console.log(errors);
                    this.error = true;
                    this.loading = false;
                    this.componentLoaded();
                });
        } else {
            this.error = true;
            this.loading = false;
            this.componentLoaded();
        }

        this.widthChangeEventSubscription = this.widthChangeEvent
            .pipe(debounceTime(250))
            .subscribe((width: number) => {
                const total = this.retailersBackup.length;

                let finalNr = total;

                if(width >= 1400) {
                    finalNr = this.helper.closestInteger(total, 5);
                } else if(width >= 992) {
                    finalNr = this.helper.closestInteger(total, 4);
                } else if(width >= 768) {
                    finalNr = this.helper.closestInteger(total, 3);
                } else if(width > 576) {
                    finalNr = this.helper.closestInteger(total, 2);
                }

                if(finalNr !== this.retailers.length) {
                    this.retailers = this.retailersBackup.slice(0, finalNr);
                }
            });
    }

    ngOnDestroy() {
        if(this.widthChangeEventSubscription) {
            this.widthChangeEventSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.widthChangeEvent.next(event.target.innerWidth);
    }
}
