import { Subscription } from 'rxjs';
import { UploadFiles } from './upload-files';
import { UploadStatus, StatusUploadStatus } from '../../../models/message/upload-status';
import { AttachmentUploadResponse } from '../../../models/message/attachment-upload-response';
import { environment } from '../../../../../environments/environment';

export class UploadFile {
    public fileId: number;
    public errors: string[] = [];

    public name: string = '';
    public mimeIcon: string = '';

    private uploadSub: Subscription;
    public uploadStatus: StatusUploadStatus;
    public progress: number;

    public constructor(private file: File, private parent: UploadFiles, private index: number) {
        this.name = this.file.name;
        this.mimeIcon = this.parent.helper.getIconByMimeType(this.file.type);

        const { status, message } = this.parent.checkMaxAllowedFileSize(this.file.size);

        if(!status) {
            this.uploadStatus = 'validation';
            this.errors.push(message);
            this.parent.fileChange?.(this.uploadStatus, this.index);
            return;
        }

        this.uploadFile();

        this.parent.scrollChange?.emit();
    }

    public humanFileSize(): string {
        if(!this.file) {
            return '';
        }
        return this.parent.helper.humanFileSize(this.file.size);
    }

    private uploadFile() {

        this.uploadSub = this.parent.messageService.attachmentUpload(this.file).subscribe(
            (response: UploadStatus<AttachmentUploadResponse>) => {
                environment.debug && console.log('uploadStatus', response);

                switch(response.status) {
                    case 'progress':
                        this.uploadStatus = 'progress';
                        this.progress = response.message;
                        break;

                    case 'response':
                        this.progress = 100;
                        const data = response.message.data;
                        this.fileId = data.fileId;
                        this.uploadStatus = 'response';
                        this.parent.fileChange?.(this.uploadStatus, this.index);
                        break;

                    case 'validation':
                        const errors = response.message.data;
                        const errorMessage = this.parent.helper.formatError(errors, false);
                        this.errors = errorMessage;
                        this.uploadStatus = 'validation';
                        this.parent.fileChange?.(this.uploadStatus, this.index);
                        break;

                    case 'other':
                    default:
                        break;
                }
            },
            (error: string) => {
                console.log('uploadStatus error', error);
                this.errors = [ this.parent.helper.trans('general.error') ];
                this.uploadStatus = 'error';
                this.parent.fileChange?.(this.uploadStatus, this.index);
            }
        );
    }

    public deleteFile() {
        this.uploadSub?.unsubscribe();
        this.parent.delete(this.index, true);
    }

    public isValid(): boolean {
        return this.uploadStatus === 'response' && this.fileId > 0;
    }
}
