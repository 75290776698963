import { MessageCheckService } from 'src/app/shared/services/message-check.service';
import { BaseComponent } from './shared/components/base.component';
import { SocialService } from './shared/services/social.service';
import { NgModule, Optional, SkipSelf, Injector, Type, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RetailersComponent } from './retailers/retailers.component';
import { CouponsComponent } from './coupons/coupons.component';
import { RetailerComponent } from './retailers/retailer/retailer.component';
import { TopnavComponent } from './shared/components/topnav/topnav.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { UserComponent } from './user/user.component';
import { RecoverComponent } from './user/recover/recover.component';
import { RecoverEmailComponent } from './user/recover/recover-email/recover-email.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { ContentService } from './shared/services/content.service';
import { PageComponent } from './page/page.component';
import { UserService } from './shared/services/user.service';
import { ContactComponent } from './contact/contact.component';
import { RetailerService } from './shared/services/retailer.service';
import { CouponService } from './shared/services/coupon.service';
import { AddThisService } from './shared/services/add-this.service';
import { VersionCheckService } from './shared/services/version-check.service';
import { RetailerCouponsComponent } from './retailers/retailer/retailer-coupons/retailer-coupons.component';
import { RetailerReviewsComponent } from './retailers/retailer/retailer-reviews/retailer-reviews.component';
import { ReviewService } from './shared/services/review.service';
import { FavoriteService } from './shared/services/favorite.service';
import { MessageFormComponent } from './shared/components/messages/message-form/message-form.component';
import { TransactionService } from './shared/services/transaction.service';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuickSearchComponent } from './shared/components/quick-search/quick-search.component';
import { InviteService } from './shared/services/invite.service';
import { WithdrawService } from './shared/services/withdraw.service';
import { CountryService } from './shared/services/country.service';
import { ActivateComponent } from './user/register/activate/activate.component';
import { ReviewFormComponent } from './retailers/retailer/retailer-reviews/review-form/review-form.component';
import { BannerService } from './shared/services/banner.service';
import { CategoryService } from './shared/services/category.service';
import { NewsComponent } from './news/news.component';
import { ArticleComponent } from './news/article/article.component';
import { NewsService } from './shared/services/news.service';
import { RetailerRecommendedComponent } from './retailers/retailer/retailer-recommended/retailer-recommended.component';
import { MiscService } from './shared/services/misc.service';
import { NgcCookieConsentModule, NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { cookieConfig } from './shared/config/cookie-config';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { FacebookPixelService } from './shared/services/facebook-pixel.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { FirstLoadService } from './shared/services/first-load.service';
import { Subscription } from 'rxjs';
import { NavigationStart, NavigationEnd, Event, Routes } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { LoginExternalComponent } from './login-external/login-external.component';
import { ActivationEmailComponent } from './user/register/activation-email/activation-email.component';
import { AnnouncementService } from './shared/services/announcement.service';
import { GoShoppingDialogComponent } from './shared/components/go-shopping-dialog/go-shopping-dialog.component';
import { ExtensionHelperService } from './shared/services/extension-helper.service';
import { RoutingProviderService } from './shared/services/routing-provider.service';
import { PermissionGuard } from './shared/guards/permission.guard';
import { SubscribeDialogComponent } from './shared/components/mailchimp/subscribe-dialog/subscribe-dialog.component';
import { ReferralComponent } from './user/referral/referral.component';
import { GlobalEventsService } from './shared/services/global-events.service';
import { EventServiceService } from './shared/services/event-service.service';
import { OffersComponent } from './offers/offers.component';
import { OffersService } from './shared/services/offers.service';
import { PmethodService } from './shared/services/pmethod.service';
import { DeeplinkComponent } from './deeplink/deeplink.component';

const AppModuleComponents: Record<string, Type<any>> = {
    'HomeComponent': HomeComponent,
    'MaintenanceComponent': MaintenanceComponent,
    'RetailersComponent': RetailersComponent,
    'RetailerComponent': RetailerComponent,
    'CouponsComponent': CouponsComponent,
    'NewsComponent': NewsComponent,
    'OffersComponent': OffersComponent,
    'ArticleComponent': ArticleComponent,
    'PageComponent': PageComponent,
    'ContactComponent': ContactComponent,
    'LoginComponent': LoginComponent,
    'RegisterComponent': RegisterComponent,
    'RecoverComponent': RecoverComponent,
    'ActivationEmailComponent': ActivationEmailComponent,
    'RecoverEmailComponent': RecoverEmailComponent,
    'ActivateComponent': ActivateComponent,
    'LoginExternalComponent': LoginExternalComponent,
    'ReferralComponent': ReferralComponent,
    'DeeplinkComponent': DeeplinkComponent,
};

const LazyLoadModules: Record<string, () => any> = {
    'AdminModule': () => import('./admin/admin.module').then(m => m.AdminModule),
    'MyAccountModule': () => import('./my-account/my-account.module').then(m => m.MyAccountModule),
};

@NgModule({
    declarations: [
        AppComponent,
        TopnavComponent,
        HomeComponent,
        FooterComponent,
        RetailersComponent,
        CouponsComponent,
        RetailerComponent,
        NewsComponent,
        ArticleComponent,
        LoginComponent,
        RegisterComponent,
        UserComponent,
        RecoverComponent,
        RecoverEmailComponent,
        PageComponent,
        ContactComponent,
        RetailerCouponsComponent,
        RetailerReviewsComponent,
        QuickSearchComponent,
        ActivateComponent,
        ReviewFormComponent,
        RetailerRecommendedComponent,
        MaintenanceComponent,
        LoginExternalComponent,
        ActivationEmailComponent,
        ReferralComponent,
        OffersComponent,
        DeeplinkComponent,
    ],
    imports: [
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        AppRoutingModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        UserService,
        ContentService,
        RetailerService,
        CouponService,
        AddThisService,
        VersionCheckService,
        ReviewService,
        FavoriteService,
        TransactionService,
        InviteService,
        WithdrawService,
        CountryService,
        BannerService,
        CategoryService,
        NewsService,
        OffersService,
        SocialService,
        MiscService,
        AnnouncementService,
        GoogleAnalyticsService,
        FacebookPixelService,
        EventServiceService,
        AnalyticsService,
        PmethodService
    ],
    entryComponents: [
        MessageFormComponent,
        ReviewFormComponent,
        GoShoppingDialogComponent,
        SubscribeDialogComponent
    ],
    bootstrap: [ AppComponent ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule extends BaseComponent {

    private urlSubscription: Subscription;
    private translationsSubscription: Subscription;
    private firstLoadSubscription: Subscription;
    private appRoutesSubscription: Subscription;

    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;

    constructor(@Optional() @SkipSelf() parentModule: AppModule,
                injector: Injector,
                private versionCheckService: VersionCheckService,
                private globalEventsService: GlobalEventsService,
                private analyticsService: AnalyticsService,
                private firstLoadService: FirstLoadService,
                private meta: Meta,
                private ngcCookieConsentService: NgcCookieConsentService,
                private extensionHelperService: ExtensionHelperService,
                private routingProviderService: RoutingProviderService,
                private messageCheckService: MessageCheckService) {

        super(injector);

        environment.debug && console.log('init AppModule');

        // this.firstLoadService.addLoader('pula'); // to see loading logo

        this.appRoutesSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if(type === 'app-init') {

                this.buildRoutes();

                this.appRoutesSubscription.unsubscribe();
            }
        });

        this.setMetaTags();

        this.firstLoadSubscription = this.globalEventsService.firstLoaded.subscribe(() => {

            environment.debug && console.log('All Loader components loaded!');

            this.firstLoadSubscription.unsubscribe();

            if(!window) {
                return;
            }

            if(!this.checkOnline()) {
                return;
            }

            if(environment.enableTracking) {
                this.analyticsService.init();
                this.subscribeToCookiesConsent();
            }

            this.versionCheckService.init();
            this.extensionHelperService.init();
            this.messageCheckService.init();
        });

        this.urlSubscription = this.router.events.subscribe((event: Event) => {

            if(event instanceof NavigationStart) {
                // console.log(event);
                if(event.url) {
                    if(event.url.indexOf('/activate') > -1) {
                        this.firstLoadService.addLoader('activate');
                    } else if(event.url.match(/(^\/recover\/\d+\/)/gm)) {
                        this.firstLoadService.addLoader('recover-email');
                    }
                }
            }

            if(event instanceof NavigationEnd) {
                // console.log('NavigationEnd', event);
                if(event.url) {
                    const path = event.url;

                    if(this.helper.checkStaticMetaPages(path)) {

                        const title = this.appPath.getTitle(path);
                        const description = this.appPath.getMetaDescription(path);

                        this.helperService.setTitle(title);
                        this.helperService.handleMeta(description);

                        // this.helper.handleMeta(
                        //     this.helper.trans('general.meta_description'),
                        //     this.helper.trans('general.meta_keywords')
                        // );
                    }
                }
                this.meta.updateTag({ property: 'og:url', content: window.location.href });
            }
        });

        this.translationsSubscription = this.globalEventsService.translationsLoaded.subscribe((status: boolean) => {
            if(status) {
                this.loadCookieTranslations();
            }
        });
    }

    private setMetaTags() {
        // const metaTags = environment.metaTags;

        // metaTags.forEach((metaTag: MetaDefinition) => {
        //     let key = '';
        //     if(metaTag.name) {
        //         key += 'name=';
        //         key += '"' + metaTag.name + '"';
        //     } else if(metaTag.property) {
        //         key += 'property=';
        //         key += '"' + metaTag.property + '"';
        //     }

        //     this.meta.removeTag(key);
        //     this.meta.addTag(metaTag);
        // });
    }

    private subscribeToCookiesConsent() {

        this.initializeSubscription = this.ngcCookieConsentService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
                environment.debug && console.log(event);
            }
        );

        this.statusChangeSubscription = this.ngcCookieConsentService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                environment.debug && console.log(event);
            }
        );

        this.revokeChoiceSubscription = this.ngcCookieConsentService.revokeChoice$.subscribe(
            () => {
                environment.debug && console.log('revoke');
            }
        );
    }

    private unsubscribeToCookiesConsent() {
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
    }

    private loadCookieTranslations() {

        this.ngcCookieConsentService.getConfig().content = this.ngcCookieConsentService.getConfig().content || {};

        this.ngcCookieConsentService.getConfig().content.message = this.trans('cookies.message');
        this.ngcCookieConsentService.getConfig().content.message2 = this.trans('cookies.message2');
        this.ngcCookieConsentService.getConfig().content.deny = this.trans('cookies.deny');
        this.ngcCookieConsentService.getConfig().content.allow = this.trans('cookies.allow');
        this.ngcCookieConsentService.getConfig().content.dismiss = this.trans('cookies.dismiss');

        // this.ngcCookieConsentService.getConfig().content.cookiePolicyHref = this.appPath.getPrivacy().path;
        // this.ngcCookieConsentService.getConfig().content.cookiePolicyLink = this.appPath.getPrivacy().title;

        const privacyPath = this.appPath.getRoutePath(this.appPath.getPrivacy());
        const privacyTitle = this.appPath.getRouteTitle(this.appPath.getPrivacy());

        this.ngcCookieConsentService.getConfig().content.privacyPolicyHref = privacyPath;
        this.ngcCookieConsentService.getConfig().content.privacyPolicyLink = privacyTitle;

        const tosPath =  this.appPath.getRoutePath(this.appPath.getTos());
        const tosTitle =  this.appPath.getRouteTitle(this.appPath.getTos());

        this.ngcCookieConsentService.getConfig().content.tosHref = tosPath;
        this.ngcCookieConsentService.getConfig().content.tosLink = tosTitle;

        this.ngcCookieConsentService.getConfig().content.and = this.trans('cookies.and');

        this.ngcCookieConsentService.getConfig().autoOpen = true;

        this.ngcCookieConsentService.destroy();
        this.ngcCookieConsentService.init(this.ngcCookieConsentService.getConfig());
    }

    private buildRoutes() {
        const routes: Routes = this.routingProviderService.buildRoutes('AppModule', AppModuleComponents, LazyLoadModules, [PermissionGuard]);

        environment.debug && console.log('AppModule routes', routes);

        if(routes && routes.length) {
            this.router.resetConfig(routes);
        }
    }

    private checkOnline(): boolean {
        if(this.helperService.checkOnline()) {
            return true;
        }

        if(this.mobileHelperService.isMobile()) {
            this.mobileHelperService.error('maintenance', '');
        }
        this.router.navigate(this.helperService.getDefaultMaintenance());
        return false;
    }
}
