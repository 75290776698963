<div class="loading-shade" *ngIf="loading || error">
    <app-loading *ngIf="loading"></app-loading>
    <div *ngIf="error" class="error-div">
        <h3>{{ 'news.error' | translate }}</h3>
    </div>
</div>

<div class="content-body news-list" fxLayout="column wrap" [hidden]="loading || error">
    <h1 class="title-head">
        {{ 'news.head_title' | translate }}
        <div class="line-full"></div>
    </h1>
    <div class="band" *ngIf="total > 0">
        <div *ngFor="let blog of news; let i = index" class="card">
            <mat-card class="mat-card-w">
                <mat-card-header>
                    <mat-card-title class="title">
                        <div class="date-added"> {{ blog.added | dateFormat: 'DD.MM.YYYY' }} </div>
                        <div>
                            <a [routerLink]="articleLink(blog)"> {{ blog.news_title }} </a>
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <img mat-card-image class="img" [src]="blog.img_url" [alt]="blog.news_title" [routerLink]="articleLink(blog)" />
                <mat-card-content class="description">
                    <p [innerHtml]="blog.news_description | userId"></p>...
                </mat-card-content>
                <!-- <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                    </mat-card-actions> -->
            </mat-card>
        </div>
    </div>
    <mat-card class="filter-wrapper" [hidden]="total === 0">
        <mat-card-content>
            <mat-paginator #matPaginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>