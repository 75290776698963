<div class="reviews-section">
    <div class="title-head">
        <div class="wrap">
            <h2 class="w-title">
                <mat-icon class="mat-18">format_quote</mat-icon>
                {{ retailerTitle }} {{ 'retailers.reviews.head_title' | translate }} ({{ reviews.length }})
            </h2>
            <div class="h4 w-create">
                <a (click)="writeReview()">
                    <mat-icon class="mat-18">create</mat-icon> {{ 'retailers.write_review' | translate }}
                </a>
            </div>
        </div>
        <div class="line-full"></div>
    </div>
    <div class="loading-shade-relative" *ngIf="loading || error || reviews.length == 0">
        <app-loading *ngIf="loading"></app-loading>
        <div class="error-div" *ngIf="error">
            <h4>{{ 'retailers.reviews.failed_load' | translate }}</h4>
        </div>
        <div class="error-div" *ngIf="!loading && !error && reviews.length == 0">
            <h4>{{ 'retailers.reviews.empty' | translate }}</h4>
        </div>
    </div>
    <div class="review-list" [hidden]="error || loading || reviews.length == 0">
        <div class="wrap-box">
            <mat-card class="filter-wrapper" [hidden]="total <= pageSize">
                <mat-card-content>
                    <mat-paginator #matPaginator1 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                </mat-card-content>
            </mat-card>
            <!-- reviews grid-->
            <div class="band" *ngIf="reviews.length > 0">
                <div *ngFor="let review of reviews; let i = index" class="card">
                    <mat-card class="mat-card-w">
                        <mat-card-header>
                            <mat-card-title class="title">
                                <div class="date-added"> {{ review.added | dateFormat: 'DD.MM.YYYY' }} </div>
                                <div> {{ review.review_title }}</div>
                            </mat-card-title>
                            <!-- <mat-card-subtitle></mat-card-subtitle> -->
                        </mat-card-header>
                        <mat-card-content class="content-wrap">
                            <div class="user">
                                <star-rating [starType]="'svg'" [rating]="review.rating" staticColor="ok" size="medium" space="no"
                                    [readOnly]="true" [hoverEnabled]="true"></star-rating> {{ review.user.fname }} {{ review.user.lname }}
                            </div>
                            <div class="content"> {{ review.review }} </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <mat-card class="filter-wrapper" [hidden]="total <= pageSize">
                <mat-card-content>
                    <mat-paginator #matPaginator2 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
                    </mat-paginator>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>