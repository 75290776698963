import { BaseComponent } from '../base.component';
import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RetailerQuickSearch } from '../../models/retailer/retailer-quick-search';
import { RetailerService } from '../../services/retailer.service';
import { debounceTime, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import { ResponseApiList } from '../../models/general/response-api-list';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { of } from 'rxjs';
import { ErrorApi } from '../../models/general/error-api';

@Component({
    selector: 'app-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: [ './quick-search.component.scss' ]
})
export class QuickSearchComponent extends BaseComponent implements OnInit, AfterViewInit {

    retailerSearchCtrl: FormControl;
    filteredRetailers: RetailerQuickSearch[];
    searchString = '';

    constructor(injector: Injector, private retailerService: RetailerService) {

        super(injector);
    }

    ngOnInit() {
        this.retailerSearchCtrl = new FormControl();
        this.retailerSearchCtrl.setValue('');
    }

    ngAfterViewInit() {
        this.retailerSearchCtrl.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe((filterValue) => {

            if(typeof filterValue === 'string') {
                if(filterValue && this.searchString !== filterValue && filterValue.length > 0) {

                    this.searchString = filterValue;

                    this.retailerService.quickSearch(this.searchString).pipe(
                        map((response) => {
                            if(response.statusCode === 200 && response.data) {
                                const data = <RetailerQuickSearch[]>response.data;
                                return data;
                            }
                            return [];
                        }),
                        catchError(() => {
                            return of([]);
                        })
                    ).subscribe((result: RetailerQuickSearch[]) => {
                        this.filteredRetailers = result;
                    });
                } else if(filterValue.length === 0) {
                    this.searchString = '';
                    this.filteredRetailers = [];
                }
            }
        });
    }

    enter(event: MatAutocompleteSelectedEvent) {
        const value: RetailerQuickSearch = event.option.value;
        this.retailerSearchCtrl.setValue('');
        this.router.navigate(this.helperService.genRouteRetailer(value.id, value.slug));
    }
}
