import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, EventEmitter, Injector, Input } from '@angular/core';
import { UserName } from 'src/app/shared/models/user/private/user-name';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MessageService } from 'src/app/shared/services/message.service';
import { ResponseApiList } from 'src/app/shared/models/general/response-api-list';
import { MessageFormComponent } from 'src/app/shared/components/messages/message-form/message-form.component';
import { UserList } from 'src/app/shared/models/user/private/user-list';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { MessageCreate } from 'src/app/shared/models/message/message-create';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SortModel } from 'src/app/shared/models/sort/sort-model';
import { SearchUserFormComponent } from '../../user/search-user-form/search-user-form.component';
import { MessageList } from 'src/app/shared/models/message/message-list';
import { MessageType } from 'src/app/shared/models/message/message-type';
import { FormControl } from '@angular/forms';
import { MessageFilters } from 'src/app/shared/models/filters/message-filters';
import { MatSelectChange } from '@angular/material/select';
import { FiltersOptions } from 'src/app/shared/models/message/filters-options';
import { MessagesFilters } from 'src/app/shared/models/message/messages-filters';
import { Params } from '@angular/router';
import { GlobalEventsService } from './../../../services/global-events.service';
import { environment } from 'src/environments/environment';
import { MessageStatus } from '../../../models/message/message-status';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: [ './messages.component.scss' ]
})
export class MessagesComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    displayedColumns: string[] = [];
    statuses: string[];
    extendedStatuses: string[];

    types: string[];
    searchBy: string[];

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];

    defaultSort: SortModel = {
        field: 'last_reply',
        direction: 'desc'
    };

    @ViewChild('matPaginator', { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    messages: MatTableDataSource<MessageList>;
    total: number = 0;

    public closed: boolean = false;

    @Input() admin: boolean = false;

    searchField: FormControl;
    public filters: MessagesFilters = {
        pageSize: 0,
        pageIndex: 0,
        enterFired: false,
        searchString: '',
        status: '',
        type: '',
        unreadFirst: true,
        searchBy: ''
    };

    private dataSubscription: Subscription;
    private sortSubscription: Subscription;
    private messageCheckSubscription: Subscription;
    private paginatorSubscription: Subscription;
    private searchEvent = new EventEmitter<void>();

    constructor(injector: Injector,
                public snackBar: MatSnackBar,
                public dialog: MatDialog,
                private messageService: MessageService,
                private globalEventsService: GlobalEventsService) {

        super(injector);

        this.filters.pageSize = this.settingsProviderService.getSettingNumber('messages_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_table');
        this.pageSizeOptions = this.defaultPageSizeOptions;
        this.statuses = this.settingsProviderService.getSettingArray('message_statuses', true);
        this.extendedStatuses = this.settingsProviderService.getSettingArray('message_extended_statuses', true);
        this.types = Object.values(MessageType);

        this.filters.unreadFirst = this.settingsProviderService.getSettingBool('messages_unread_first');
        this.filters.type = this.types.length ? this.types[0] : '';
        this.filters.status = this.extendedStatuses.length ? this.extendedStatuses[0] : '';
    }

    ngOnInit() {

        this.searchBy = this.settingsProviderService.getSettingArray(this.admin ? 'message_admin_search_by' : 'message_search_by', true);
        this.filters.searchBy = this.searchBy.length ? this.searchBy[0] : '';

        if(this.admin) {
            this.displayedColumns = [ 'dir', 'from', 'subject', 'last_reply', 'status', 'answers', 'actions' ];
        } else {
            this.displayedColumns = [ 'dir', 'subject', 'last_reply', 'status', 'answers', 'actions' ];
        }

        this.paginator.pageIndex = this.filters.pageIndex;
        this.paginator.pageSize = this.filters.pageSize;
        this.sort.active = this.defaultSort.field;
        this.sort.direction = this.defaultSort.direction;
        this.messages = new MatTableDataSource([]);
        this.messages.sort = this.sort;
        this.messages.paginator = this.paginator;

        this.searchField = new FormControl();
        this.searchField.setValue(this.filters.searchString);

        this.route.queryParams.subscribe((params: Params) => {

            // console.log('queryparams:', params);

            if(params.pageSize && Number.isInteger(+params.pageSize) && +params.pageSize > 0) {
                this.filters.pageSize = +params.pageSize;
            }

            if(params.pageIndex && Number.isInteger(+params.pageIndex) && +params.pageIndex > 0) {
                this.filters.pageIndex = +params.pageIndex;
            }

            if(params.sortField) {
                const index = this.displayedColumns.indexOf(params.sortField);
                if(index > -1) {
                    this.defaultSort.field = params.sortField;

                    if(params.direction) {
                        switch(params.direction) {
                            case 'asc':
                            case 'desc':
                                this.defaultSort.direction = params.direction;
                                break;
                        }
                    }
                }
            }

            if(params.searchString && params.searchString.length) {
                this.filters.searchString = params.searchString;
            }

            if(params.searchBy) {
                const index = this.searchBy.indexOf(params.searchBy);
                if(index > -1) {
                    this.filters.searchBy = params.searchBy;
                }
            }

            if(params.status) {
                const index = this.extendedStatuses.indexOf(params.status);
                if(index > -1) {
                    this.filters.status = params.status;
                }
            }

            if(params.type) {
                const index = this.types.indexOf(params.type);
                if(index > -1) {
                    this.filters.type = params.type;
                }
            }

            if(params.unreadFirst) {
                const unreadFirst = params.unreadFirst === 'true' ? true : params.unreadFirst === 'false' ? false : null;
                if(unreadFirst !== null) {
                    this.filters.unreadFirst = unreadFirst;
                }
            }
        });
    }

    ngAfterViewInit() {

        this.sortSubscription = this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.helperService.updateUrl({
                pageIndex: 0,
                sortField: this.sort.active,
                sortDirection: this.sort.direction
            });
            this.searchEvent.next();
        });

        this.paginatorSubscription = this.paginator.page.subscribe(() => {
            this.helperService.updateUrl({
                pageIndex:  this.paginator.pageIndex,
                pageSize:  this.paginator.pageSize
            });
            this.searchEvent.next();
        });

        this.searchField.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe((filterValue: string) => {
            if(!this.loading && !this.filters.enterFired && this.filters.searchString !== filterValue) {

                this.filters.searchString = filterValue;

                this.paginator.pageIndex = 0;
                this.helperService.updateUrl({ searchString: this.filters.searchString, pageIndex: 0 });

                this.searchEvent.next();
            }
        });

        this.messageCheckSubscription = this.globalEventsService.newMessage.subscribe(() => {
            this.searchEvent.next();
        });

        this.dataSubscription = merge(this.searchEvent)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.loading = true;

                    const postData: MessageFilters = {
                        sortField: this.sort.active,
                        sortDirection: this.sort.direction,
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        string: this.filters.searchString,
                        searchBy: this.filters.searchBy,
                        type: this.filters.type,
                        unreadFirst: this.filters.unreadFirst,
                        status: this.filters.status
                    };

                    return this.admin ? this.messageService.adminList(postData) : this.messageService.list(postData);
                }),
                map((response: ResponseApiList<MessageList | ErrorApi[]>) => {

                    if(response && response.statusCode === 200) {
                        this.total = response.total;

                        this.pageSizeOptions = this.helper.generatePageSizeOptions(
                            this.total,
                            this.defaultPageSizeOptions,
                            this.paginator.pageSize
                        );

                        this.loading = false;
                        this.error = false;
                        return <MessageList[]>response.data;
                    } else {
                        this.error = true;
                        this.loading = false;
                        return [];
                    }
                }),
                catchError(() => {
                    this.loading = false;
                    this.error = true;
                    return of([]);
                })
            ).subscribe((data: MessageList[]) => {
                this.messages = new MatTableDataSource(data);

                if(this.route.snapshot.queryParams.newMessage) {
                    setTimeout(() => {
                        this.newMessage();
                    }, 250);
                    this.router.navigate([], { replaceUrl: true });
                }
            });

    }

    ngOnDestroy(): void {

        if(this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        if(this.sortSubscription) {
            this.sortSubscription.unsubscribe();
        }
        if(this.messageCheckSubscription) {
            this.messageCheckSubscription.unsubscribe();
        }
        if(this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
        }
    }

    public newMessage() {

        if(!this.admin) {
            this.openNewMessage(undefined);
            return;
        }

        const dialogRef = this.dialog.open(SearchUserFormComponent, {
            panelClass: 'custom-dialog-search-user',
            data: { }
        });

        dialogRef.afterClosed().subscribe((response: string | UserList) => {
            if(response && typeof (response) !== 'string' && typeof (response.user_id) !== 'undefined') {
                const user: UserName = {
                    user_id: response.user_id,
                    fname: response.fname,
                    lname: response.lname
                };
                this.openNewMessage(user);
            } else {
                environment.debug && console.log('abort!');
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    openNewMessage(user?: UserName) {

        const dialogRef = this.dialog.open(MessageFormComponent, {
            data: { admin: this.admin, user: user },
            panelClass: 'custom-dialog-wide'
        });

        dialogRef.afterClosed().subscribe((result: string | MessageCreate) => {
            if(result && typeof (result) !== 'string' && typeof (result.message_id) !== 'undefined') {

                const message: MessageList = {
                    message_id: result.message_id,
                    subject: result.subject,
                    status: result.status,
                    created: result.created,
                    answer_nr: result.answer_nr,
                    last_reply: null,
                    user_id: result.user_id,
                    user: result.user,
                    type: MessageType.send,
                    read: true,
                    hasAttachments: !!result.attachments?.length
                };

                const data = this.messages.data;
                data.unshift(message);
                this.messages.data = data;
                this.snackBar.open(this.helper.trans('messages.message_sent'), this.helper.trans('general.close'));
            } else {
                environment.debug && console.log('abort!');
            }
        }, (error: string) => {
            console.log(error);
        });
    }

    public closeMessage(message: MessageList) {

        if(!message || message?.status === MessageStatus.CLOSED) {
            return;
        }

        message.loadingClose = true;

        this.messageService.changeStatus(message.message_id, MessageStatus.CLOSED).subscribe((response) => {
            if(response && response.statusCode === 200) {

                message.status = MessageStatus.CLOSED;
                message.loadingClose = false;

                this.snackBar.open(this.helper.trans('messages.message_closed'), this.helper.trans('general.close'));
                return;
            }

            message.loadingClose = false;
            this.snackBar.open(this.trans('messages.message_close_failed'), this.trans('general.close'));

        }, (errors: string) => {
            console.log(errors);

            message.loadingClose = false;
            this.snackBar.open(this.trans('messages.message_close_failed'), this.trans('general.close'));
        });
    }

    goView(id: number, scroll: number = 0) {

        const url: string[] = this.admin ? this.helperService.getDefaultAdminMessage(id) : this.helperService.getDefaultMessage(id);

        switch(scroll) {
            case 1:
                this.router.navigate(url, { fragment: 'unread' });
                break;

            case 2:
                this.router.navigate(url, { fragment: 'reply' });
                break;

            default:
                this.router.navigate(url);
                break;
        }
    }

    format(key: string, type: FiltersOptions): string {
        let text = '';
        switch(type) {
            case 'extended_status':
                if(this.extendedStatuses && this.extendedStatuses.indexOf(key) > -1) {
                    text = this.trans('messages.status_' + key);
                }
                break;

            case 'status':
                if(this.statuses && this.statuses.indexOf(key) > -1) {
                    text = this.trans('messages.status_' + key);
                }
                break;

            case 'type':
                if(this.types && this.types.indexOf(key) > -1) {
                    text = this.trans('messages.type_' + key);
                }
                break;

            case 'searchBy':
                if(this.searchBy && this.searchBy.indexOf(key) > -1) {
                    text = this.trans('messages.search_by_' + key);
                }
                break;

            default:
                text = key;
                break;
        }

        return text;
    }

    checkNew(message: MessageList): boolean {
        return !message.read;
    }

    tooltipIcon(message: MessageList): string {
        switch(message.type) {
            case MessageType.receive:
                return this.trans(this.admin ? 'messages.user_send_to_admin' : 'messages.admin_send_to_you');
            case MessageType.send:
                return this.trans(this.admin ? 'messages.admin_send_to_user' : 'messages.you_send_to_admin');
            default:
                return '';
        }
    }

    arrowType(message: MessageList): string {
        switch(message.type) {
            case MessageType.send:
                return 'arrow_right_alt';
            case MessageType.receive:
                return 'keyboard_backspace'
            default:
                return '';
        }
    }

    goToUser(event: Event, user?: UserName): void {
        event.preventDefault();
        user && user.user_id > 0 && this.helperService.goToUser(user.user_id);
    }

    goToUserUrl(user?: UserName): string {
        return user && user.user_id > 0 ? this.helperService.goToUserUrl(user.user_id): '#';
    }

    public searchEnter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        if(!this.loading) {
            this.filters.enterFired = true;
            this.filters.searchString = filterValue;

            this.paginator.pageIndex = 0;
            this.helperService.updateUrl({ pageIndex: 0 });

            this.searchEvent.next();
        }
    }

    changeOption(event: MatSelectChange, type: FiltersOptions) {
        this.paginator.pageIndex = 0;

        switch(type) {
            case 'status':
                this.filters.status = event.value;
                this.helperService.updateUrl({ status: this.filters.status, pageIndex: 0 });
                this.searchEvent.next();
                break;

            case 'type':
                this.filters.type = event.value;
                this.helperService.updateUrl({ type: this.filters.type, pageIndex: 0 });
                this.searchEvent.next();
                break;

            case 'searchBy':
                this.filters.searchBy = event.value;
                this.helperService.updateUrl({ searchBy: this.filters.searchBy, pageIndex: 0 });
                this.filters.searchString.length > 0 && this.searchEvent.next();
                break;
        }
    }

    onCheckboxChange() {
        this.helperService.updateUrl({ unreadFirst: this.filters.unreadFirst });
        this.searchEvent.next();
    }

    public checkClosedStatus(message: MessageList): boolean {
        return message.status === MessageStatus.CLOSED || message.loadingClose;
    }
}
