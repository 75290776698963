import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'newLine' })
export class NewLinePipe implements PipeTransform {
    constructor() {
    }
    transform(value: string): string {
        let newValue = value.replace(new RegExp('\r?\n','g'), '<br/>');
        return newValue;
    }
}
