import { MessageView } from 'src/app/shared/models/message/message-view';
import { UserList } from 'src/app/shared/models/user/private/user-list';
import { Component, OnInit, OnDestroy, AfterViewInit, Input, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';
import { MessageAnswer } from 'src/app/shared/models/message/answer';
import { MessageCheckService } from 'src/app/shared/services/message-check.service';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { MessageReply } from 'src/app/shared/models/message/message-reply';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageStatus } from '../../../models/message/message-status';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    id = 0;
    errorText1 = false;
    errorText2 = false;
    errorText3 = false;
    private routeSubscription: Subscription;
    private routerSubscription: Subscription;
    private replySubscription: Subscription;
    public message: MessageView;
    fragment: string;
    scroll = false;

    @Input() admin: boolean;

    constructor(injector: Injector,
                private messageService: MessageService,
                private messageCheckService: MessageCheckService,
                private snackBar: MatSnackBar) {

        super(injector);

        this.routerSubscription = this.router.events.subscribe(s => {
            if(s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if(!!tree?.fragment) {
                    this.fragment = tree.fragment;
                    this.scroll = true;
                }
            }
        });
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
        this.routerSubscription?.unsubscribe();
        this.replySubscription?.unsubscribe();
    }

    ngAfterViewInit() {
        this.id = +this.route.snapshot.paramMap.get('id');

        this.routeSubscription = this.route.url.subscribe(url => {
            this.id = +this.route.snapshot.paramMap.get('id');
            this.getMessages();
        });
    }

    getMessages() {
        this.loading = true;
        if(this.id > 0) {

            let request: Observable<ResponseApi<MessageView | ErrorApi[]>>;

            if(this.admin) {
                request = this.messageService.adminView(this.id);
            } else {
                request = this.messageService.view(this.id);
            }

            request.subscribe((response: ResponseApi<MessageView | ErrorApi[]>) => {
                    if(response && response.statusCode === 200) {
                        this.message = <MessageView>response.data;
                        if(this.message.viewed == 0) {
                            this.messageCheckService.decrement(this.admin ? 'admin': 'user');
                        }

                        this.error = false;
                    } else {
                        this.error = true;
                        this.errorText1 = true;
                    }
                    this.loading = false;
                    this.scrollEl();
                }, (errors: string) => {
                    console.log(errors);
                    this.error = true;
                    this.errorText2 = true;
                    this.loading = false;
                    this.scrollEl();
                });
        } else {
            this.error = true;
            this.errorText3 = true;
            this.loading = false;
        }
    }

    public back() {
        const url = this.admin ? this.helperService.getDefaultAdminMessages() : this.helperService.getDefaultMessages();
        this.router.navigate(url);
    }

    scrollEl() {
        if(!scroll) {
            return;
        }

        setTimeout(() => {
            switch(this.fragment) {
                case 'reply':
                    document.querySelector('#reply')
                        ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });

                    break;

                case 'unread':
                    document.querySelector('.app-message .mat-card-w.new-message')
                        ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

                    break;

                default:
                    document.querySelector('#' + this.fragment)
                        ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
                    break;
            }

        }, 250);
    }

    public onAnswerEvent(answer: MessageReply) {
        const data: MessageAnswer[] = this.message.answers;
        data.push(answer);
        this.message.answers = data;
    }

    readTooltip(): string {
        if(this.checkMessageViewed()) {
            return this.helper.trans('messages.seen');
        } else {
            return this.helper.trans('messages.not_seen');
        }
    }

    readTooltipAnswer(viewed: number): string {
        if(viewed == 1) {
            return this.helper.trans('messages.seen');
        } else {
            return this.helper.trans('messages.not_seen');
        }
    }

    checkMessage(): boolean {
        return this.message.is_admin === +this.admin;
    }

    checkMessageViewed(): boolean {
        if(this.message.status === 'replied') {
            return true;
        }
        return this.message.viewed === 1;
    }

    checkNewMsg(answer: MessageAnswer): boolean {
        return (answer.is_admin !== +this.admin && answer.viewed === 0);
    }

    checkViewed(answer: MessageAnswer): boolean {
        return answer.is_admin === +this.admin;
    }

    checkReply(answer: MessageAnswer): boolean {
        return this.message.is_admin !== answer.is_admin;
    }

    checkSenderOrReceiver(message: MessageView): boolean {
        // to = true, from = false
        return message.is_admin === +this.admin;
    }

    goToUser(event: Event, user?: UserList): void {
        event.preventDefault();
        user && user.user_id > 0 && this.helperService.goToUser(user.user_id);
    }

    goToUserUrl(user?: UserList): string {
        return user && user.user_id > 0 ? this.helperService.goToUserUrl(user.user_id): '#';
    }

    public closeMessage() {
        this.messageService.changeStatus(this.id, MessageStatus.CLOSED).subscribe((response) => {
            if(response && response.statusCode === 200) {
                this.message.status = MessageStatus.CLOSED;
                this.snackBar.open(this.helper.trans('messages.message_closed'), this.helper.trans('general.close'));

                return;
            }

            this.snackBar.open(this.trans('messages.message_close_failed'), this.trans('general.close'));
        }, (errors: string) => {
            console.log(errors);
            this.snackBar.open(this.trans('messages.message_close_failed'), this.trans('general.close'));
        });
    }

    public checkClosedStatus(): boolean {
        return this.message?.status === MessageStatus.CLOSED;
    }
}
