import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../../environments/environment';

declare let fbq: any;
declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class FacebookPixelService {

    private pixelId: string;
    private loaded = false;

    constructor() {
    }

    public pageView(): boolean {
        try {
            environment.debug && console.log('fb pageview');
            fbq('track', 'PageView');
            return true;
        } catch (ex) {
            return this.error(ex);
        }
    }

    public clickEvent(type: string, text: string): boolean {

        environment.debug && console.log('fb click', type, text);

        try {
            fbq('track', 'ViewContent', {
                'content_category': 'click_events',
                'content_name': text,
                'content_type': type,
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public setUser(userId: number): void {
        try {
            fbq('init', this.pixelId, { 'external_id': userId });

        } catch(ex) {
            this.error(ex);
        }
    }

    public auth(): boolean {
        try {
            fbq('trackCustom', 'Authentication', {});
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public login(): boolean {
        try {
            fbq('trackCustom', 'Login', {});
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public register(): boolean {
        try {
            fbq('trackCustom', 'CompleteRegistration', {});
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public goShopping(clickId: number, title: string): boolean {
        environment.debug && console.log('pixel goShopping', title);
        try {
            fbq('track', 'AddToCart', {
                'content_type': 'checkout',
                'content_name': title,
                'value': 0,
                'currency': 'RON',
                'content_ids': [clickId],
                'contents': []
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public getClientId() {
        const _fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
        const _fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);

        const fbp = (_fbp.length && _fbp[0]) || null;
        let fbc = (_fbc.length && _fbc[0]) || null;

        if(!fbc && window.location.search.includes('fbclid=')){
            fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
        }

        return {
            fbp: fbp,
            fbc: fbc,
        }
    }

    public isBlocked(): boolean {
        return !window.fbq || !this.loaded;
    }

    private loadProject(): Observable<boolean> {
        return new Observable((observer: Subscriber<boolean>) => {
            switch(true) {
                case (!document || !window):
                    /**
                     * For Angular Universal
                     */
                    observer.error('Cannot render it on Server');
                    observer.complete();
                    break;

                case ((document.getElementById('facebook-pixel-js') != null)):
                    observer.next(true);
                    observer.complete();
                    break;

                case (!this.pixelId):
                    observer.error('no-project-pixel');
                    observer.complete();
                    break;

                case (!!window.fbq):
                    observer.next(true);
                    observer.complete();
                    break;

                default:

                    if(document.getElementById('fb-root') == null) {
                        const fbRoot = document.createElement('div');
                        fbRoot.id = 'fb-root';

                        document.body.append(fbRoot);
                    }

                    const n: any =  window.fbq = function() {
                        n.callMethod ?
                        n.callMethod.apply( n, arguments ) : n.queue.push( arguments )
                    };

                    if(!window._fbq) {
                        window._fbq = n;
                    }

                    n.push = n;
                    n.loaded = !0;
                    n.version = '2.0';
                    n.queue = [];

                    const noscript = document.createElement('noscript');

                    const img = document.createElement('img');
                    img.setAttribute('height', '1');
                    img.setAttribute('width', '1');
                    img.setAttribute('style', 'display:none');
                    img.src = 'https://www.facebook.com/tr?id=' + this.pixelId + '&ev=PageView&noscript=1';

                    noscript.appendChild(img);

                    const first1 = document.getElementsByTagName('script')[ 0 ];
                    first1.parentNode.insertBefore(noscript, first1);

                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.id = 'facebook-pixel-js';
                    script.async = true;
                    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
                    script.onload = (ev: Event) => {

                        fbq.disablePushState = true;
                        fbq('set', 'autoConfig', false, this.pixelId)
                        fbq('init', this.pixelId);

                        this.loaded = true;

                        observer.next(true);
                        observer.complete();
                    };

                    const first = document.getElementsByTagName('script')[ 0 ];
                    first.parentNode.insertBefore(script, first);

                    break;
            }
        });
    }

    private error(ex: any): boolean {
        environment.debug && console.log('fbq not found or error', ex);
        return false;
    }

    /**
     * @param accountKey
     */
    public init(accountKey: string): Observable<boolean> {
        this.pixelId = accountKey;
        return this.loadProject();
    }
}
