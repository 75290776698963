<div class="container" >
    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-xs-12 form-content">
            <div class="custom-card card mat-elevation-z2">
                <div class="card-header">
                    <h2>{{ 'user.register.head_title' | translate }}</h2>
                </div>
                <div class="card-body">
                    <app-alert [global]="false"></app-alert>

                    <ng-template [ngIf]="showForm" [ngIfElse]="elseTemplate">
                        <div class="social-platform-login">
                            <app-social-sign-in (signInEvent)="onSocialSignIn($event)"></app-social-sign-in>
                        </div>
                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="register-form" #registerFormDiv>
                            <div class="form-group">
                                <label for="username">{{ 'user.register.username' | translate }}*</label>
                                <input type="text" id="username" formControlName="username"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.username) }" />
                                <div *ngIf="check(f.username)" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">{{ 'user.register.username_required' | translate }}</div>
                                    <div *ngIf="f.username.errors.minlength">{{ 'user.register.username_min_length' | translate }}</div>
                                    <div *ngIf="f.username.errors.maxlength">{{ 'user.register.username_max_length' | translate }}</div>
                                    <div *ngIf="f.username.errors.username">{{ 'user.register.invalid_username' | translate }}</div>
                                    <div *ngIf="f.username.errors.custom">{{f.username['msg']}}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">{{ 'user.register.password' | translate }}*</label>
                                <input id="password" type="password" formControlName="password"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.password) }" />
                                <div *ngIf="check(f.password)" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">{{ 'user.register.password_required' | translate }}</div>
                                    <div *ngIf="f.password.errors.password">{{ 'user.register.password_error' | translate }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email">{{ 'user.register.email' | translate }}*</label>
                                <input type="text" id="email" formControlName="email"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.email) }" />
                                <div *ngIf="check(f.email)" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">{{ 'user.register.email_required' | translate }}</div>
                                    <div *ngIf="f.email.errors.email">{{ 'user.register.invalid_email' | translate }}</div>
                                    <div *ngIf="f.email.errors.minlength">{{ 'user.register.email_min_length' | translate }}</div>
                                    <div *ngIf="f.email.errors.maxlength">{{ 'user.register.email_max_length' | translate }}</div>
                                    <div *ngIf="f.email.errors.custom">{{f.email['msg']}}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="fname">{{ 'user.register.fname' | translate }}*</label>
                                <input type="text" id="fname" formControlName="fname"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.fname) }" />
                                <div *ngIf="check(f.fname)" class="invalid-feedback">
                                    <div *ngIf="f.fname.errors.required">{{ 'user.register.fname_required' | translate }}</div>
                                    <div *ngIf="f.fname.errors.minlength">{{ 'user.register.fname_min_length' | translate }}</div>
                                    <div *ngIf="f.fname.errors.maxlength">{{ 'user.register.fname_max_length' | translate }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="lname">{{ 'user.register.lname' | translate }}*</label>
                                <input type="text" id="lname" formControlName="lname"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.lname) }" />
                                <div *ngIf="check(f.lname)" class="invalid-feedback">
                                    <div *ngIf="f.lname.errors.required">{{ 'user.register.lname_required' | translate }}</div>
                                    <div *ngIf="f.lname.errors.minlength">{{ 'user.register.lname_min_length' | translate }}</div>
                                    <div *ngIf="f.lname.errors.maxlength">{{ 'user.register.lname_max_length' | translate }}</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="phone">{{ 'user.register.phone' | translate }}</label>
                                <input type="text" id="phone" formControlName="phone"
                                    class="form-control" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': check(f.phone) }" />
                                <div *ngIf="check(f.phone)" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors.maxlength">{{ 'user.register.phone_max_length' | translate }}</div>
                                    <div *ngIf="f.phone.errors.custom">{{f.phone['msg']}}</div>
                                </div>
                            </div>

                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" id="newsletter" formControlName="newsletter" class="custom-control-input"
                                    [ngClass]="{ 'is-invalid': check(f.newsletter) }" />
                                <label class="custom-control-label" for="newsletter">{{ 'user.register.newsletter' | translate }}</label>
                            </div>
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" id="tos" formControlName="tos" class="custom-control-input"
                                    [ngClass]="{ 'is-invalid': check(f.tos) }" />
                                <label class="custom-control-label" for="tos">
                                    {{ 'user.register.tos' | translate }}
                                    <a [routerLink]="getTosLink()">{{ getTosTitle() }} </a>*
                                </label>
                                <div *ngIf="check(f.tos)" class="invalid-feedback">
                                    <div *ngIf="f.tos.errors.required || f.tos.errors.invalidTrue">
                                        {{ 'user.register.tos_error' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group ">
                                <button mat-raised-button color="primary" [disabled]="loading">{{ 'user.register.register' | translate }}</button>
                                <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
                                <a mat-button [routerLink]="helperService.getDefaultHome()">{{ 'general.cancel' | translate }}</a>
                            </div>
                        </form>
                    </ng-template>
                    <ng-template #elseTemplate>
                        <div class="success-txt1" *ngIf="registerWithoutActivation">
                            <h4>{{ 'user.register.success' | translate }}</h4>
                            <h4>{{ 'user.register.success_autologin' | translate }}</h4>
                        </div>
                        <div class="success-txt2" *ngIf="registerWithActivation">
                            <h4>{{ 'user.register.need_activation_1' | translate }}</h4>
                            <h4>{{ 'user.register.need_activation_2' | translate }}</h4>
                        </div>
                        <div class="success-btns" *ngIf="registerWithoutActivation">
                            <button mat-raised-button color="primary" [routerLink]="helperService.getDefaultUser()" >
                                {{ appPath.getRouteTitle(appPath.getDefaultUser()) }}
                            </button>
                            <button mat-raised-button color="accent" [routerLink]="helperService.getDefaultHome()" >
                                {{ appPath.getRouteTitle(appPath.getDefaultHome()) }}
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>