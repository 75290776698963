import { Component, Injector } from '@angular/core';
import { SocialSigninBaseComponent } from './shared/social-signin-base.component';

@Component({
    selector: 'app-social-sign-in',
    templateUrl: './social-sign-in.component.html',
    styleUrls: ['./social-sign-in.component.scss']
})
export class SocialSignInComponent extends SocialSigninBaseComponent {

    public enableGoogleLogin = false;
    public enableFacebookLogin = false;

    constructor(injector: Injector) {

        super(injector);

        this.enableGoogleLogin = this.settingsProviderService.getSettingBool(this.isMobile() ? 'google_connect_mobile' : 'google_connect');
        this.enableFacebookLogin = this.settingsProviderService.getSettingBool(this.isMobile() ? 'facebook_connect_mobile' : 'facebook_connect');
    }
}
