import { Component, OnInit, Input, Output, Injector, EventEmitter } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { first } from 'rxjs/operators';
import { ReplyMessage } from 'src/app/shared/models/message/reply-message';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AdminReplyMessage } from 'src/app/shared/models/message/admin-reply-message';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { MessageReply } from 'src/app/shared/models/message/message-reply';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reply-form',
  templateUrl: './reply-form.component.html',
  styleUrls: ['./reply-form.component.scss']
})
export class ReplyFormComponent extends BaseComponent implements OnInit {

    submitted = false;
    msgForm: FormGroup;

    @Input() id: number;
    @Input() admin: boolean;
    @Input() userId: number;

    @Output() answerAction: EventEmitter<MessageReply> = new EventEmitter<MessageReply>();

    public eventAction: EventEmitter<string> = new EventEmitter<string>();

    private fileIds: Array<number>;

    constructor(injector: Injector,
                private messageService: MessageService,
                public snackBar: MatSnackBar) {

        super(injector);

        this.loading = false;
    }

    ngOnInit() {
        this.msgForm = new FormGroup({
            message: new FormControl(null, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10000),
            ])
        });
    }

    onSubmit() {
        this.submitted = true;

        if(!this.msgForm.valid) {
            return;
        }

        this.loading = true;

        let request: Observable<ResponseApi<MessageReply | ErrorApi[]>>;

        if(this.admin) {
            const message: AdminReplyMessage = {
                message_id: this.id,
                message: this.msgForm.value.message,
                userId: this.userId,
                fileIds: this.fileIds
            };
            request = this.messageService.adminReply(message);
        } else {
            const message: ReplyMessage = {
                message_id: this.id,
                message: this.msgForm.value.message,
                fileIds: this.fileIds
            };
            request = this.messageService.reply(message);
        }

        request.pipe(first()).subscribe(
            (response: ResponseApi<MessageReply | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.loading = false;
                    const data = <MessageReply>response.data;
                    this.answerAction.emit(data);

                    this.msgForm.reset();
                    this.eventAction?.emit('reset');

                    this.submitted = false;
                    this.snackBar.open(this.trans('messages.message_sent'), this.trans('general.close'));
                } else {
                    this.loading = false;

                    const errors = <ErrorApi[]>response.data;
                    this.helperService.setApiErrorsToForm(errors, this.f);
                    this.helperService.showOtherErrors(errors, this.f, true, false);

                    this.snackBar.open(this.trans('messages.message_sent_failed'), this.trans('general.close'));
                }
            },
            (error: string) => {
                console.log(error);
                this.loading = false;
                this.snackBar.open(this.trans('messages.message_sent_failed'), this.trans('general.close'));
            });
    }

    get f() {
        return this.msgForm.controls;
    }

    back() {
        const url = this.admin ? this.helperService.getDefaultAdminMessages() : this.helperService.getDefaultMessages();
        this.router.navigate(url);
    }

    public onEvent(fileIds?: Array<number>) {
        environment.debug && console.log('fileIds onEvent', fileIds);
        this.fileIds = fileIds;
    }
}
