import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AnalyticsEvent } from '../models/analytics/analytics-event';
import { Logged } from '../models/general/logged';
import { NewMessageEvent } from '../models/message/new-message-event';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventsService {

    // FirstLoadService finished loading all elements
    public firstLoaded = new ReplaySubject<void>(1);

    // send custom events
    public customSubject = new Subject<string>();

    // AuthenticationService user is logged event
    public logged = new BehaviorSubject<Logged>({ loggedIn: false, isInitialized: false, isAdmin: false });

    // MessageCheckService new message event
    public newMessage = new BehaviorSubject<NewMessageEvent>({ nrUnredUser: 0, nrUnredAdmin: null });

    // language
    public langChange = new Subject<string>();
    public translationsLoaded = new BehaviorSubject<boolean>(false);

    // analytics
    public analytics = new Subject<AnalyticsEvent>();

    constructor() {
    }
}
