<div class="container">
    <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-8 col-sm-10 col-xs-12 form-content">
            <div class="custom-card card mat-elevation-z2">
                <div class="card-header">
                    <h2> {{ 'user.login.login' | translate }}</h2>
                </div>
                <div class="card-body">
                    <div>
                        <app-alert [global]="false"></app-alert>
                    </div>
                    <div *ngIf="redirectShopping">
                        <h4>{{ 'user.login.login_for_cashback' | translate }}</h4>
                    </div>
                    <div class="social-platform-login">
                        <app-social-sign-in (signInEvent)="onSocialSignIn($event)"></app-social-sign-in>
                    </div>
                    <div>
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="username">{{ 'user.login.username' | translate }}</label>
                                <input type="text" formControlName="username" id="username" name="username"
                                    class="form-control" autocomplete="username"
                                       [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">{{ 'user.login.username_required' | translate }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">{{ 'user.login.password' | translate }}</label>
                                <input type="password" id="password" name="password" formControlName="password"
                                    class="form-control" autocomplete="current-password"
                                       [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">{{ 'user.login.passwd_required' | translate }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button mat-raised-button [disabled]="loading" color="primary" type="submit">{{ 'user.login.login' | translate }}</button>
                                <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
                                <a mat-button [routerLink]="helperService.getDefaultRegister()" color="primary"> {{ 'user.register.register' | translate }}</a>
                                <a mat-button [routerLink]="helperService.getDefaultHome()">{{ 'general.cancel' | translate }}</a>
                            </div>
                        </form>
                        <div class="extra-links">
                            <a [routerLink]="helperService.getDefaultRecover()"> {{ 'user.login.recover' | translate }}</a>
                            <a [routerLink]="helperService.getDefaultActivationEmail()">{{ 'user.login.activation_email' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
