import { CustomClick } from './../../../models/analytics/custom-click';
import { Component, OnInit, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/shared/services/message.service';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { CreateMessage } from 'src/app/shared/models/message/create-message';
import { MessageFormData } from 'src/app/shared/models/message/message-form-data';
import { Observable } from 'rxjs';
import { UserName } from 'src/app/shared/models/user/private/user-name';
import { AdminCreateMessage } from 'src/app/shared/models/message/admin-create-message';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { MessageCreate } from 'src/app/shared/models/message/message-create';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-message-form',
    templateUrl: './message-form.component.html',
    styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent extends BaseComponent implements OnInit {

    public closed: boolean = false;
    public submitted = false;
    public msgForm: FormGroup;
    public admin: boolean;
    public user: UserName;

    private fileIds: Array<number> | undefined;

    constructor(injector: Injector,
                private messageService: MessageService,
                private dialogRef: MatDialogRef<MessageFormComponent>,
                public snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: MessageFormData) {

        super(injector);

        this.loading = false;

        this.admin = data.admin;
        if(data.user) {
            this.user = data.user;
        }
    }

    ngOnInit() {
        this.msgForm = new FormGroup({
            subject: new FormControl(null, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]),
            message: new FormControl(null, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10000),
            ]),
        });
    }

    public onSubmit() {
        this.submitted = true;

        if(!this.msgForm.valid) {
            return;
        }

        this.loading = true;

        let request: Observable<ResponseApi<MessageCreate | ErrorApi[]>>;

        if(this.admin) {
            const message: AdminCreateMessage = {
                ...this.msgForm.value,
                userId: this.user.user_id,
                fileIds: this.fileIds
            };
            request = this.messageService.adminCreate(message);
        } else {
            const message: CreateMessage = {
                ...this.msgForm.value,
                fileIds: this.fileIds
            };
            request = this.messageService.create(message);
        }

        request.subscribe(
            (response: ResponseApi<MessageCreate | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.loading = false;
                    const data = <MessageCreate>response.data;
                    this.dialogRef.close(data);
                } else {
                    this.loading = false;

                    const errors = <ErrorApi[]>response.data;
                    this.helperService.setApiErrorsToForm(errors, this.f);
                    this.helperService.showOtherErrors(errors, this.f, true, false);

                    this.snackBar.open(this.trans('messages.message_sent_failed'), this.trans('general.close'));
                }
            },
            (error: string) => {
                console.log(error);
                this.loading = false;
                this.snackBar.open(this.trans('messages.message_sent_failed'), this.trans('general.close'));
            });
    }

    get f() {
        return this.msgForm.controls;
    }

    public check(control: AbstractControl, submitted: boolean = true) {
        return (this.submitted || control.touched) && control.errors;
    }

    public onClick(event: MouseEvent) {
        if(this.helperService.handleStopLink(event as CustomClick)) {
            this.dialogRef.close();
        }
    }

    public onEvent(fileIds?: Array<number>) {
        environment.debug && console.log('fileIds onEvent', fileIds);
        this.fileIds = fileIds;
    }
}
