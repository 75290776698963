import { Component, OnDestroy, OnInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { RecoverChangePassword } from 'src/app/shared/models/user/public/recover-change-password';
import { FirstLoadService } from 'src/app/shared/services/first-load.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { CheckRecoverForm } from 'src/app/shared/models/user/public/check-recover-form';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-recover-email',
    templateUrl: './recover-email.component.html',
    styleUrls: [ './recover-email.component.scss' ]
})
export class RecoverEmailComponent extends BaseComponent implements OnInit, OnDestroy {

    id: number;
    token: string;
    recoverForm: FormGroup;
    submitted = false;
    success = false;
    showForm = false;
    errors: string[] = [];

    constructor(injector: Injector,
                private userService: UserService,
                private formBuilder: FormBuilder,
                private alertService: AlertService,
                private firstLoadService: FirstLoadService) {

        super(injector);

        this.loading = false;
    }

    ngOnInit() {

        const params = this.route.snapshot.params;
        // const queryParams = this.route.snapshot.queryParams;

        if (typeof params[ 'id' ] === 'undefined' ||
            typeof params[ 'id' ] === null ||
            typeof params[ 'token' ] === 'undefined' ||
            typeof params[ 'token' ] === null) {
            this.router.navigate(this.helperService.getDefaultHome());
            environment.debug && console.log('fail');
            return;
        }

        this.id = +params[ 'id' ];
        this.token = params[ 'token' ];

        const data: CheckRecoverForm = {
            id: this.id,
            token: this.token
        };

        this.userService.checkRecover(data).subscribe(
            (response: ResponseApi<string | ErrorApi[]>) => {

                if (response && response.statusCode === 200 && response.data === 'ok') {
                    this.showForm = true;
                } else {
                    const errors = <ErrorApi[]>response.data;
                    this.errors = this.helper.formatError(errors);
                    this.showForm = false;
                }
                this.firstLoadService.event.next('recover-email');
            },
            (error: string) => {
                console.log(error);
                this.firstLoadService.event.next('recover-email');
            });

        this.recoverForm = this.formBuilder.group({
            password:['', [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(100),
                ValidationService.passwordValidator
            ]],
            passwordAgain: [ '', [
                Validators.required,
                this.checkPasswords
            ]],
        });
    }

    ngOnDestroy() {
    }

    get f() {
        return this.recoverForm.controls;
    }

    check(control: AbstractControl) {
        return (this.submitted || control.touched) && control.errors;
    }

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if (this.recoverForm.invalid) {
            return;
        }

        const recoverChangePassword: RecoverChangePassword = {
            id: this.id,
            token: this.token,
            password: this.recoverForm.value.password,
            passwordAgain: this.recoverForm.value.passwordAgain,
            source: this.helperService.getLoginSource()
        };

        this.loading = true;
        this.userService.recoverChangePassword(recoverChangePassword)
            .subscribe(
                (response: ResponseApi<string | ErrorApi[]>) => {
                    this.loading = false;

                    if(response && response.statusCode === 200 && response.data === 'ok') {
                        this.success = true;
                    } else {
                        const errors = <ErrorApi[]>response.data;
                        const errorMessage = this.helper.formatError(errors);
                        this.alertService.error(errorMessage);
                        this.success = false;
                    }
                    this.showForm = false;
                },
                (error: string) => {
                    console.log(error);
                });
    }

    checkPasswords(group: any) {
        const root: FormGroup = group.root;
        if (typeof root !== 'undefined' && typeof root.controls !== 'undefined') {
            const pass = root.controls.password.value;
            const confirmPass = root.controls.passwordAgain.value;
            return pass === confirmPass ? null : { notSame: true };
        } else {
            return true;
        }
    }
}
