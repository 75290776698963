import { Component, OnInit, Injector } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: [ './referral.component.scss' ]
})
export class ReferralComponent extends BaseComponent implements OnInit {

    constructor(injector: Injector,
                private localStorageService: LocalStorageService) {

        super(injector);

        const params = this.route.snapshot.params;
        environment.debug && console.log('Referral', params['id']);

        if(params && params['id'] && params['id'] > 0) {
            this.localStorageService.setReferralIdToStorage(+params['id']);
        }
    }

    ngOnInit() {
        this.router.navigate(this.helperService.getDefaultRegister());
    }
}
