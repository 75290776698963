import { MobileMessageActions } from './../models/mobile/mobile-message-actions';
import { SiteModes } from './../models/general/site-modes';
import { Injectable, EventEmitter } from '@angular/core';
import { FirstLoadService } from './first-load.service';
import { CashbackMobileHelper } from '../models/mobile/cashback-mobile-helper';
import { environment } from 'src/environments/environment';
import { MobilePostMessage } from '../models/mobile/mobile-post-message';
import { MobileSocialSignInResponse } from '../models/mobile/mobile-social-sign-in-response';
import { MobileSocialSignInRequest } from '../models/mobile/mobile-social-sign-in-request';
import { UserResponse } from '../models/user/private/user-response';
import { MobileCheckLoginResponse } from '../models/mobile/mobile-check-login-response';

declare let window: Window & { CashbackMobileHelper: CashbackMobileHelper };

@Injectable({
    providedIn: 'root'
})
export class MobileHelperService {

    private siteMode: SiteModes;
    private wrapper: CashbackMobileHelper;
    private port: MessagePort;

    private dataEvent = new EventEmitter<MobilePostMessage<any>>();

    constructor(private firstLoadService: FirstLoadService) {
        this.firstLoadService.addLoader('sitemode');
        this.wrapper = window.CashbackMobileHelper;

        if(this.wrapper) {
            const mode = this.wrapper.getSiteMode();
            environment.debug && console.log('siteMode helper', mode);
            this.siteMode = SiteModes.mobile;

            this.initWebMessagePort();

        } else {
            this.siteMode = SiteModes.site;
        }
        this.firstLoadService.event.next('sitemode');

        environment.debug && console.log('siteMode:');
        environment.debug && console.log(this.siteMode);
    }

    getSiteMode(): SiteModes {
        return this.siteMode;
    }

    isMobile(): boolean {
        return this.siteMode === SiteModes.mobile && !!this.wrapper;
    }

    reload(): void {
        this.isMobile() && this.wrapper.reload();
    }

    showMessage(text: string): void {
        this.isMobile() && this.wrapper.showMessage(text);
    }

    pushNotification(title: string, body: string): void {
        this.isMobile() && this.wrapper.pushNotification(title, body);
    }

    href(url: string): void {
        this.isMobile() && this.wrapper.href(url);
    }

    error(type: string, message: string): void {
        this.isMobile() && this.wrapper.error(type, message);
    }

    logout(): void {
        this.isMobile() && this.wrapper.logout();
    }

    scrollStatus(isScroll: boolean): void {
        this.isMobile() && this.wrapper.scrollStatus(isScroll);
    }

    socialSignIn(socialPlatform: string): Promise<MobileSocialSignInResponse | null> {
        return new Promise((resolve, reject) => {
            const data: MobileSocialSignInRequest = {
                socialPlatform: socialPlatform
            };
            this.sendWebMessagePort(MobileMessageActions.socialSignIn, data);

            let timeout = true;

            this.dataEvent.subscribe((event: MobilePostMessage<MobileSocialSignInResponse>) => {

                if(event) {

                    switch(event.action) {

                        case MobileMessageActions.socialSignInResponse:
                            if(event.data) {
                                const data: MobileSocialSignInResponse = event.data;
                                resolve(data);
                            } else {
                                reject(null);
                            }
                            break;

                        case MobileMessageActions.socialSignInReceived:
                            environment.debug && console.log(MobileMessageActions.socialSignInReceived);
                            timeout = false;
                            break;
                    }

                }
            });

            setTimeout(() => {
                if(!timeout) {
                    return;
                }
                environment.debug && console.log('Timeout reached '+ MobileMessageActions.socialSignInReceived +' !');
                reject(null);
            }, 15000);
        });

    }

    checkLogin(): Promise<UserResponse | null> {
        return new Promise((resolve, reject) => {
            environment.debug && console.log('Sending checkLogin to mobile!');

            this.sendWebMessagePort(MobileMessageActions.checkLogin, '');

            let timeout = true;

            this.dataEvent.subscribe((event: MobilePostMessage<MobileCheckLoginResponse>) => {

                 if(event) {

                    switch(event.action) {

                        case MobileMessageActions.checkLoginResponse:

                            if(event.data && !this.isEmpty(event.data) && event.data.status) {
                                const data = <UserResponse>event.data.user;
                                resolve(data);
                            } else {
                                reject(null);
                            }
                            break;

                        case MobileMessageActions.checkLoginReceived:
                            environment.debug && console.log(MobileMessageActions.checkLoginReceived);
                            timeout = false;
                            break;
                    }

                }
            });

            setTimeout(() => {
                if(!timeout) {
                    return;
                }

                environment.debug && console.log('Timeout reached ' + MobileMessageActions.checkLoginReceived + ' !');
                reject(null);
            }, 15000);
        });

    }

    private initWebMessagePort() {
        environment.debug && console.log("WebMessagePort INIT start!");
        this.wrapper && this.wrapper.initWebMessagePort();

        window.addEventListener('message', (event: MessageEvent) => {
            if(event && event.ports && event.ports.length) {
                environment.debug && console.log("WebMessagePort response!");

                this.port = event.ports[0];

                this.port.onmessage = (event: MessageEvent) => {
                    environment.debug && console.log('Mobile message');
                    environment.debug && console.log(event.data);

                    if(event && event.data) {
                        try {
                            const data: MobilePostMessage<any> = JSON.parse(event.data);

                            if(data && data.source && data.source == 'cashback') {

                                switch(data.action) {
                                    case MobileMessageActions.socialSignInResponse:
                                    case MobileMessageActions.socialSignInReceived:
                                    case MobileMessageActions.checkLoginResponse:
                                    case MobileMessageActions.checkLoginReceived:
                                        this.dataEvent.emit(data);
                                        break;
                                }
                            }
                        } catch(e) {
                            environment.debug && console.log('json parse error!');
                            environment.debug && console.log(e);
                        }
                    }
                }
            }
        });
    }

    private sendWebMessagePort(action: string, data: any) {
        if(this.port) {
            const message: MobilePostMessage<any> = {
                source: 'cashback',
                action: action,
                data: data
            }
            this.port.postMessage(JSON.stringify(message));
        }
    }

    private isEmpty(obj: Object) {
        for(const x in obj) {
            return false;
        }
        return true;
    }
}
