import { MobileHelperService } from '../services/mobile-helper.service';
import { SettingsProviderService } from '../services/settings-provider.service';
import { SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, GoogleInitOptions } from '@abacritt/angularx-social-login';

export function SocialProviderFactory(settingsProviderService: SettingsProviderService, mobileHelperService: MobileHelperService) {

    const isMobile = mobileHelperService.isMobile();

    const isGoogle = settingsProviderService.getSettingBool( isMobile ? 'google_connect_mobile' : 'google_connect' );
    const isFacebook = settingsProviderService.getSettingBool( isMobile ? 'facebook_connect_mobile' : 'facebook_connect' );

    const config: SocialAuthServiceConfig = {
        autoLogin: false,
        providers: []
    };

    if (isGoogle) {

        const googleAppId = settingsProviderService.getSettingString('google_appid');

        const googleLoginOptions: GoogleInitOptions = {
            scopes: ['email', 'profile'],
            oneTapEnabled: false,
            prompt: 'select_account',
        };

        config.providers.push({
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(googleAppId, googleLoginOptions),
        });
    }

    if (isFacebook) {

        const facebookAppId = settingsProviderService.getSettingString('facebook_appid');
        const facebookVersion = settingsProviderService.getSettingString('facebook_graph_version');


        const fbLoginOptions = {
            // scope: 'email,public_profile',
            scope: 'email,public_profile',
            // scope: null,
            return_scopes: true,
            enable_profile_selector: false,
            locale: 'ro_RO',
            fields: 'name,email,first_name,last_name',
            version: facebookVersion ? facebookVersion : 'v4.0',
        }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

        config.providers.push({
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(facebookAppId, fbLoginOptions)
        });
    }

    return config;
}