import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';


@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent extends BaseComponent implements OnInit {
    recoverForm: FormGroup;
    submitted = false;
    showForm = true;

    constructor(injector: Injector,
                private formBuilder: FormBuilder,
                private alertService: AlertService,
                private userService: UserService) {

            super(injector);

            this.loading = false;
        }

    ngOnInit() {
        this.recoverForm = this.formBuilder.group({
            email: ['', [ Validators.required, ValidationService.emailValidator ]],
        });
    }

    get f() {
        return this.recoverForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if (this.recoverForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.recover(this.f.email.value)
            .pipe(first())
            .subscribe(
                (result: ResponseApi<string | ErrorApi[]>) => {
                    this.submitted = false;
                    if (result && result.statusCode === 200 && result.data === 'ok') {
                        this.recoverForm.reset();
                        this.showForm = false;
                    } else {
                        const errors = <ErrorApi[]>result.data;
                        const errorMessage = this.helper.formatError(errors);
                        this.alertService.error(errorMessage);
                    }
                    this.loading = false;
                },
                (error: string) => {
                    console.log(error);
                    this.loading = false;
                });
    }

}
