import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Review } from '../models/review/review';
import { ReviewOwn } from '../models/review/review-own';
import { SortFilters } from '../models/filters/sort-filters';
import { ErrorApi } from '../models/general/error-api';
import { ReviewList } from '../models/review/review-list';
import { PaginationFilters } from '../models/filters/pagination-filters';

@Injectable()
export class ReviewService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Review>>(`${environment.apiUrl}/v1/review/all`);
    }

    getAdminList(data: SortFilters) {
        return this.http.post<ResponseApiList<ReviewList | ErrorApi[]>>(`${environment.apiUrl}/v1/review/admin/list`, data);
    }

    getList(data: SortFilters) {
        return this.http.post<ResponseApiList<ReviewList | ErrorApi[]>>(`${environment.apiUrl}/v1/review/list`, data);
    }

    getByRetailer(retailerId: number, filters: PaginationFilters) {
        return this.http.post<ResponseApiList<ReviewList | ErrorApi[]>>(`${environment.apiUrl}/v1/review/retailer/` + retailerId, filters);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Review | ErrorApi[]>>(`${environment.apiUrl}/v1/review/` + id);
    }

    create(review: ReviewOwn) {
        return this.http.post<ResponseApi<Review | ErrorApi[]>>(`${environment.apiUrl}/v1/review`, review);
    }

    update(review: ReviewOwn) {
        return this.http.put<ResponseApi<Review | ErrorApi[]>>(`${environment.apiUrl}/v1/review/own/` + review.review_id, review);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/review/` + id);
    }

    rating(retailerId: number) {
        return this.http.get<ResponseApi<number>>(`${environment.apiUrl}/v1/retailer/rating/` + retailerId);
    }
}
