<ng-template [ngIf]="loading || error" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
    </div>
</ng-template>
<ng-template #loaded>
    <div class="container">
        <h1 class="title-head">
            {{ content.title }}
            <div class="line-full"></div>
        </h1>
        <mat-card class="example-card">
            <mat-card-content>
                <div [innerHtml]="content.description | safeHtml"></div>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>