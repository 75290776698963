import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Category } from '../models/category/category';
import { CategoryShort } from '../models/category/category-short';
import { ErrorApi } from '../models/general/error-api';

@Injectable()
export class CategoryService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Category>>(`${environment.apiUrl}/v1/category/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<CategoryShort>>(`${environment.apiUrl}/v1/category/list`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Category | ErrorApi[]>>(`${environment.apiUrl}/v1/category/` + id);
    }

    create(category: Category) {
        return this.http.post<ResponseApi<Category | ErrorApi[]>>(`${environment.apiUrl}/v1/category`, category);
    }

    update(category: Category) {
        return this.http.put<ResponseApi<Category | ErrorApi[]>>(`${environment.apiUrl}/v1/category/` + category.category_id, category);
    }

    delete(id: number) {
        return this.http.delete<boolean | ErrorApi[]>(`${environment.apiUrl}/v1/category/` + id);
    }

}
