<h2 mat-dialog-title>{{ 'messages.new_message_title' | translate }}</h2>

<div *ngIf="admin" class="admin">
    {{ 'messages.admin_send_msg' | translate }}: <b>{{ user.fname }} {{ user.lname }}</b>
</div>

<form [formGroup]="msgForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content class="mat-typography">

        <ngb-alert [dismissible]="true" *ngIf="!closed" (close)="closed=true">
            <span [innerHtml]="trans('messages.missing_cashback_alert')" (click)="onClick($event)"></span>
        </ngb-alert>

        <app-alert></app-alert>

        <div class="form-group">
            <label for="subject">{{ 'messages.subject' | translate }}</label>
            <input type="text" id="subject" formControlName="subject" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
            <div *ngIf="check(f.subject)" class="invalid-feedback">
                <div *ngIf="f.subject.errors.required">{{ 'messages.subject_required' | translate }}</div>
                <div *ngIf="f.subject.errors.minlength">{{ 'messages.subject_min_length' | translate }}</div>
                <div *ngIf="f.subject.errors.maxlength">{{ 'messages.subject_max_length' | translate }}</div>
                <div *ngIf="f.subject.errors.custom">{{ f.subject['msg'] }}</div>
            </div>
        </div>
        <div class="form-group">
            <label for="message">{{ 'messages.message' | translate }}</label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="8" cdkAutosizeMaxRows="10"
                formControlName="message" id="message" class="form-control"
                [ngClass]="{ 'is-invalid': check(f.message) }">
            </textarea>

            <div *ngIf="check(f.message)" class="invalid-feedback">
                <div *ngIf="f.message.errors.required"> {{ 'messages.message_required' | translate }}</div>
                <div *ngIf="f.message.errors.minlength">{{ 'messages.message_min_length' | translate }}</div>
                <div *ngIf="f.message.errors.maxlength">{{ 'messages.message_max_length' | translate }}</div>
                <div *ngIf="f.message.errors.custom">{{ f.message['msg'] }}</div>
            </div>
        </div>

        <app-file-upload (filesChange)="onEvent($event)"></app-file-upload>

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close="close">{{ 'general.cancel' | translate }}</button>
        <button mat-raised-button cdkFocusInitial color="primary" [disabled]="loading" type="submit">
            {{ 'messages.send' | translate }}
        </button>
        <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
    </mat-dialog-actions>
</form>