<div class="coupon-list">

    <h2 class="title-head" [hidden]="coupons.length === 0">
        <mat-icon class="mat-18">card_giftcard</mat-icon>
        {{ 'retailers.coupons.head_title' | translate }}
        <div class="line-full"></div>
    </h2>

    <div class="loading-shade-relative" *ngIf="loading || error">
        <app-loading *ngIf="loading"></app-loading>
        <div class="error-div" *ngIf="error">
            <h3>{{ 'retailers.coupons.failed_load' | translate }}</h3>
        </div>
    </div>

    <div class="wrap-box" [hidden]="error || loading || coupons.length === 0">

        <mat-card class="filter-wrapper" [hidden]="total <= pageSize">
            <mat-card-content>
                <mat-paginator #matPaginator1 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
            </mat-card-content>
        </mat-card>
        <!-- coupons grid-->
        <div class="band" *ngIf="coupons.length > 0">
            <div *ngFor="let coupon of coupons; let i = index" class="card">
                <mat-card class="mat-card-w">
                    <mat-card-header>
                        <mat-card-title>
                            <div> {{ coupon.title }} </div>
                            <div class="go-shop-btn d-none d-lg-block">
                                <button mat-raised-button color="primary" (click)="goShopping(coupon)">
                                    {{ 'coupons.get_offer' | translate }}
                                </button>
                                <a mat-button *ngIf="isAdmin()" [title]="'coupons.go_to_coupon_admin' | translate"
                                    [href]="goToAdminCouponUrl(coupon.coupon_id)" (click)="goToAdminCoupon($event, coupon.coupon_id)">
                                    <mat-icon class="mat-18">pageview</mat-icon>
                                </a>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                        <div class="expiring">
                            <div class="wrap-badge">
                                <mat-icon class="mat-18">timer</mat-icon>
                                <div class="badge badge-success"> {{ 'coupons.expire' | translate }}
                                    {{ coupon.end_date | dateFormat: 'DD.MM.YYYY'}} </div>
                            </div>
                            <div class="time-remain"> {{ 'coupons.time_remaining' | translate }}:
                                <b>{{ coupon.end_date | remainingTime }}</b>
                            </div>
                        </div>

                        <div class="content-code" *ngIf="coupon.code.length > 0">
                            <div class="c-label"><b>{{ 'coupons.promo_code' | translate }}:</b></div>
                            <div class="coupon_code">{{coupon.code}}</div>
                            <div class="copy">
                                <a (click)="helper.copyCode(coupon.code)" matTooltip="copy">
                                    <mat-icon class="mat-18">file_copy</mat-icon>
                                </a>
                            </div>
                        </div>

                        <div class="go-shop-btn d-lg-none">
                            <button mat-raised-button color="primary" (click)="goShopping(coupon)">
                                {{ 'coupons.get_offer' | translate }}
                            </button>
                            <a mat-button *ngIf="isAdmin()" [title]="'coupons.go_to_coupon_admin' | translate"
                                [href]="goToAdminCouponUrl(coupon.coupon_id)" (click)="goToAdminCoupon($event, coupon.coupon_id)">
                                <mat-icon class="mat-18">pageview</mat-icon>
                            </a>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <mat-card class="filter-wrapper" [hidden]="total <= pageSize">
            <mat-card-content>
                <mat-paginator #matPaginator2 [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>