import { Component, OnInit, OnChanges, DoCheck, Input, EventEmitter, ViewChild, Output, SimpleChanges, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OptionItem } from '../../models/select-autocomplete/option-item';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { Helper } from 'src/app/shared/helpers/helper';

@Component({
    selector: 'app-mat-select-autocomplete',
    templateUrl: './mat-select-autocomplete.component.html',
    styleUrls: [ './mat-select-autocomplete.component.scss' ]
})
export class MatSelectAutocompleteComponent implements OnChanges, OnInit {

    @Input() selectPlaceholder: string = "search...";
    @Input() placeholder: string;
    @Input() customLabel: string;
    @Input() showSelectTrigger: boolean = true;
    @Input() options: Array<OptionItem> = [];
    @Input() disabled: boolean = false;
    @Input() errorMsg: string = "Field is required";
    @Input() showErrorMsg: boolean = false;
    @Input() selectedOption: string | number;
    @Input() appearance: "standard" | "fill" | "outline" = "standard";
    @Output() selectionChange: EventEmitter<string | number> = new EventEmitter();

    @ViewChild('selectElem') selectElem: MatSelect;
    @ViewChild('searchInput') searchInput: ElementRef;

    public filteredOptions: Array<OptionItem> = [];
    public selectedValue: string | number;

    constructor(private helper: Helper) {
    }

    ngOnInit() {
        this.loadValue();
        if(this.selectedOption != null) {
            this.selectedValue = this.selectedOption;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.options) {
            if(!changes.options.firstChange && !this.helper.arraysAreEqual(changes.options.currentValue, this.filteredOptions)) {
                this.loadValue();
            }
        }

        if(changes.selectedOption) {
            if(!changes.selectedOption.firstChange && this.selectedValue !== this.selectedOption) {
                this.selectedValue = this.selectedOption;
            }
        }
    }
    private loadValue() {
        this.filteredOptions = this.options && this.options.length ? this.options.slice() : [];
    }

    filterItem(value: string) {
        this.filteredOptions = this.options.filter(
            item => item.display.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
    }

    hideOption(option: OptionItem) {
        return !(this.filteredOptions.indexOf(option) > -1);
    }

    // Returns plain strings array of filtered values
    onDisplayString(): string {
        if(this.selectedValue) {
            const foundOption = this.options.find(x => x.value === this.selectedValue);

            if(foundOption) {
                return !!foundOption.display ? foundOption.display : foundOption.value.toString();
            }
        }

        return '';
    }

    onSelectionChange(val: MatSelectChange) {
        const value: number | string = val.value;
        this.selectedValue = value;

        this.resetSearch();

        this.selectionChange.emit(this.selectedValue);
    }

    public compare(a: number | string, b: number | string): boolean {
        return a?.toString() == b?.toString();
    }

    public resetSearch(): void {
        this.filterItem('');
        this.searchInput.nativeElement.value = '';
    }
}