import { COMMA, ENTER, P } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Helper } from 'src/app/shared/helpers/helper';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: [ './multiselect.component.scss' ]
})
export class MultiselectComponent implements OnInit, OnChanges {

    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() appearance: MatFormFieldAppearance = 'standard';

    @Input() allOptions: Array<string> = [];
    @Input() value: Array<string>;

    @Input() strict: boolean = false;
    @Input() disabled: boolean = false;

    @Output() change: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    selectable = false;
    removable = true;
    separatorKeysCodes: number[] = [ ENTER, COMMA ];
    formControl = new FormControl();
    filteredOptions: Observable<string[]>;

    selectedOptions: Array<string> = [];
    @ViewChild('fInput', { static: true }) fInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

    constructor(private helper: Helper) {

        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(null),
            map((option: string | null) => option ? this._filter(option) : this.allOptions.slice()));
    }

    ngOnInit() {
        this.loadValue();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.value) {
            if(!changes.value.firstChange && !this.helper.arraysAreEqual(changes.value.currentValue, this.selectedOptions)) {
                this.loadValue();
            }
        }
    }

    private loadValue() {
        this.selectedOptions = this.value && this.value.length ? this.value.slice() : [];
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if((value || '').trim()) {

            const val = value.trim();

            if((!this.strict || (this.strict && this.allOptions.indexOf(val) > -1)) && (this.selectedOptions.indexOf(val) === -1)) {
                this.selectedOptions.push(val);
                this.updateValues();
            }
        }

        if(input) {
            input.value = '';
        }

        this.formControl.setValue(null);
    }

    remove(option: string): void {
        const index = this.selectedOptions.indexOf(option);

        if(index >= 0) {
            this.selectedOptions.splice(index, 1);
            this.updateValues();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if(this.selectedOptions.indexOf(event.option.viewValue) === -1) {
            this.selectedOptions.push(event.option.viewValue);
            this.updateValues();
        }

        this.fInput.nativeElement.value = '';
        this.formControl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allOptions.slice().filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }

    private updateValues(): void {
        this.change.emit(this.selectedOptions);
    }

    public onClick() {
        this.fInput?.nativeElement.blur();
        setTimeout(() => {
            this.fInput?.nativeElement.focus();
        }, 10);
    }
}
