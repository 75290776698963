
import { UploadFile } from './upload-file';
import { EventEmitter } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { Helper } from '../../../helpers/helper';
import { StatusUploadStatus } from '../../../models/message/upload-status';

export class UploadFiles implements Iterable<UploadFile> {

    [key: number]: UploadFile;

    public keys: number[];

    public length: number;
    public lastIndex: number;

    public [Symbol.iterator]() {
        let index = 0;
        return {
            next: () => {
                const key = this.keys[index];
                const value = this[key];
                const done = index >= this.keys.length;
                index++;
                return {
                    value, done
                };
            }
        };
    }

    constructor(public messageService: MessageService,
                public maxAllowedFileSize: number,
                public helper: Helper,
                public filesChange: EventEmitter<Array<number>>,
                public scrollChange: EventEmitter<void>) {

        this.length = 0;
        this.keys = [];
        this.lastIndex = -1;
    }

    public add(file: File): void {

        this.lastIndex++;
        this.keys.push(this.lastIndex);
        this.length = this.keys.length;

        this[this.lastIndex] = new UploadFile(file, this, this.lastIndex);
    }

    public delete(index: number, emmit: boolean = true) {

        const i = this.keys.indexOf(index);
        this.keys.splice(i, 1);

        this.length = this.keys.length;
        this[index] = undefined;

        if(emmit) {
            this.filesChange?.emit(this.getFileIds());
        }
    }

    public checkMaxAllowedFileSize(size: number) {
        if(size > this.maxAllowedFileSize) {

            const message = this.helper
                .trans('messages.attachment_too_big')
                .replace('{size}', this.helper.humanFileSize(this.maxAllowedFileSize));

            return {
                status: false,
                message
            };
        }

        return { status: true };
    }

    public getFileIds() {

        const fileIds: Array<number> = [];

        this.keys.forEach((key) => {
            const uploadFile = this[key];
            if(uploadFile?.isValid()) {
                fileIds.push(uploadFile.fileId);
            }
        });

        return fileIds;
    }

    public fileChange(status: StatusUploadStatus, index: number) {
        switch(status) {
            case 'response':
                this.filesChange?.emit(this.getFileIds());
                break;

            case 'validation':
            case 'error':
                this.helper.delay(20000, () => {
                    this.delete(index, false);
                });
                break;

            default:
                break;
        }

        this.scrollChange?.emit();
    }

    public reset() {

        this.keys.forEach((key) => {
            this[key] = undefined;
        });

        this.length = 0;
        this.keys = [];
        this.lastIndex = -1;
    }
}
