import { Component, OnInit, Injector } from '@angular/core';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { UserService } from 'src/app/shared/services/user.service';
import { first } from 'rxjs/operators';
import { FirstLoadService } from 'src/app/shared/services/first-load.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserResponse } from 'src/app/shared/models/user/private/user-response';
import { UserActivateForm } from 'src/app/shared/models/user/public/user-activate-form';
import { UserActivate } from 'src/app/shared/models/user/public/user-activate';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent extends BaseComponent implements OnInit {

    id: number;
    token: string;
    success = false;
    errors: string[] = [];

    constructor(injector: Injector,
                private userService: UserService,
                private firstLoadService: FirstLoadService,
                private authenticationService: AuthenticationService) {

        super(injector);

    }

    ngOnInit() {

        const params = this.route.snapshot.params;
        // const queryParams = this.route.snapshot.queryParams;

        if (typeof params[ 'id' ] === 'undefined' ||
            typeof params[ 'id' ] === null ||
            typeof params[ 'token' ] === 'undefined' ||
            typeof params[ 'token' ] === null) {

            this.router.navigate(this.helperService.getDefaultHome());
            environment.debug && console.log('fail');
            return;
        }

        this.id = +params[ 'id' ];
        this.token = params[ 'token' ];

        const data: UserActivateForm = {
            id: this.id,
            token: this.token,
            source: this.helperService.getLoginSource()
        };

        this.userService.activate(data).subscribe(
            (response: ResponseApi<UserActivate | ErrorApi[]>) => {

                if (response && response.statusCode === 200 && response.data) {

                    this.success = true;

                    const result = <UserActivate>response.data;
                    const user: UserResponse = result.login;
                    this.authenticationService.loginUser(user, 'activate');


                    setTimeout(() => {
                        this.router.navigate(this.helperService.getDefaultUser());
                    }, 5000);

                } else {
                    this.error = true;
                    const errors = <ErrorApi[]>response.data;
                    this.errors = this.helper.formatError(errors);
                }

                this.firstLoadService.event.next('activate');
            },
            (error: string) => {
                console.log(error);
                this.firstLoadService.event.next('activate');
            });

    }
}
