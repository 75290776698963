<div class="container">
    <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11 col-xs-12 form-content">
            <div class="custom-card card mat-elevation-z2">
                <div class="card-header">
                    <h2>{{ 'user.activation_email.title' | translate }}</h2>
                </div>
                <div class="card-body">
                    <div class="success-txt" *ngIf="!showForm">
                        <h4>{{ 'user.activation_email.email_sent1' | translate }}</h4>
                        <h4>{{ 'user.activation_email.email_sent2' | translate }}</h4>
                    </div>
                    <div>
                        <app-alert [global]="false"></app-alert>
                    </div>
                    <div *ngIf="showForm">
                        <form [formGroup]="activationEmailForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="email">{{ 'user.activation_email.email' | translate }}</label>
                                <input type="text" formControlName="email" class="form-control" id="email"
                                       [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">{{ 'user.activation_email.email_required' | translate }}</div>
                                    <div *ngIf="f.email.errors.email">{{ 'user.activation_email.invalid_email' | translate }}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button mat-raised-button color="primary" [disabled]="loading" type="submit"> {{ 'user.activation_email.send' | translate }}</button>
                                <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
                                <button mat-button [routerLink]="helperService.getDefaultHome()">  {{ 'general.cancel' | translate }} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
