<mat-form-field [appearance]="appearance" class="app-mat-select-autocomplete">
    <mat-label *ngIf="customLabel">{{ customLabel }}</mat-label>
    <mat-select #selectElem [placeholder]="placeholder" [multiple]="false" [disabled]="disabled"
        [value]="selectedValue" (selectionChange)="onSelectionChange($event)" [compareWith]="compare">

        <div class="box-search">
            <input #searchInput type="text" [ngClass]="" (input)="filterItem(searchInput.value)"
                [placeholder]="selectPlaceholder" />

            <div class="box-search-icon" (click)="resetSearch()">
                <button mat-icon-button class="search-button">
                    <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
                </button>
            </div>
        </div>
        <mat-select-trigger *ngIf="showSelectTrigger"> {{ onDisplayString() }} </mat-select-trigger>

        <mat-option *ngFor="let option of options" [disabled]="option.disabled" [value]="option.value"
            [style.display]="hideOption(option) ? 'none' : 'flex'">{{ option.display }} </mat-option>
    </mat-select>
    <mat-hint style="color:red" *ngIf="showErrorMsg">{{ errorMsg }}</mat-hint>
</mat-form-field>