import { Component, OnInit, OnDestroy, AfterContentInit, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { SocialLinks } from '../../models/footer/social-links';
import { GlobalEventsService } from '../../services/global-events.service';
import { AsynchronouslyInitialisedComponent } from '../asynchronously-initialised.component';
import { RouteMenu } from './../../models/routes/route-menu';

@Component( {
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
} )
export class FooterComponent extends AsynchronouslyInitialisedComponent implements OnInit, OnDestroy, AfterContentInit {
    public footerItems: RouteMenu[] = [];
    public chromeExtension = '';
    public socialLinks: SocialLinks[];
    private navSubscription: Subscription;

    constructor(injector: Injector, private globalEventsService: GlobalEventsService) {

        super(injector);

        this.socialLinks = [
            {
                title: 'facebook',
                href: this.settingsProviderService.getSettingString('facebook'),
                classA: 'btn-fb',
                classI: 'fab fa-facebook-f'
            },
            {
                title: 'instagram',
                href: this.settingsProviderService.getSettingString('instagram'),
                classA: 'btn-ins',
                classI: 'faimg faimg-instagram'
            },
            {
                title: 'pinterest',
                href: this.settingsProviderService.getSettingString('pinterest'),
                classA: 'btn-pin',
                classI: 'fab fa-pinterest'
            }
        ];

        this.chromeExtension = this.settingsProviderService.getSettingString('chrome_extension');
    }

    ngOnInit() {
        this.navSubscription = this.globalEventsService.translationsLoaded.subscribe(() => {
            this.footerItems = this.appPath.getFooterLinks();
        });
    }

    ngOnDestroy(): void {

        if(this.navSubscription) {
            this.navSubscription.unsubscribe();
        }
    }

    ngAfterContentInit(): void {
        this.componentLoaded();
    }
}
