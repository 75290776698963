<ng-template [ngIf]="loading" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading></app-loading>
    </div>
</ng-template>
<ng-template #loaded>
    <div class="container-fluid">
        <h1 class="title-head">
            {{'contact.head_title' | translate }}
            <div class="line-full"></div>
        </h1>
        <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-xs-12 form-content">
            <mat-card class="contact-card">
                <mat-card-header>
                    <mat-card-title>
                        <div class="head" *ngIf="!error">
                            <h1 class="text-center">{{ content.title }}</h1>
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <div *ngIf="!error" class="description" [innerHtml]="content.description"></div>

                    <ngb-alert [dismissible]="true" *ngIf="!closed" (close)="closed=true">
                        <span [innerHtml]="trans('messages.missing_cashback_alert')"></span>
                    </ngb-alert>

                    <app-alert></app-alert>

                    <form [formGroup]="msgForm" class="msg-form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="fname">{{ 'contact.fname' | translate }}*</label>
                            <input type="text" id="fname" formControlName="fname" class="form-control"
                                [ngClass]="{ 'is-invalid': check(f.fname) }" />
                            <div *ngIf="check(f.fname)" class="invalid-feedback">
                                <div *ngIf="f.fname.errors.required">{{ 'contact.fname_required' | translate }}</div>
                                <div *ngIf="f.fname.errors.minlength">{{ 'contact.fname_min_length' | translate }}</div>
                                <div *ngIf="f.fname.errors.maxlength">{{ 'contact.fname_max_length' | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="email">{{ 'contact.email' | translate }}*</label>
                            <input type="text" id="email" formControlName="email" class="form-control"
                                [ngClass]="{ 'is-invalid': check(f.email) }" />
                            <div *ngIf="check(f.email)" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">{{ 'contact.email_required' | translate }}</div>
                                <div *ngIf="f.email.errors.email">{{ 'contact.invalid_email' | translate }}</div>
                                <div *ngIf="f.email.errors.minlength">{{ 'contact.email_min_length' | translate }}</div>
                                <div *ngIf="f.email.errors.maxlength">{{ 'contact.email_max_length' | translate }}</div>
                                <div *ngIf="f.email.errors.custom">{{f.email['msg']}}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="subject">{{ 'contact.subject' | translate }}*</label>
                            <input type="text" id="subject" formControlName="subject" class="form-control"
                                [ngClass]="{ 'is-invalid': check(f.subject) }" />
                            <div *ngIf="check(f.subject)" class="invalid-feedback">
                                <div *ngIf="f.subject.errors.required">{{ 'contact.subject_required' | translate }}</div>
                                <div *ngIf="f.subject.errors.minlength">{{ 'contact.subject_min_length' | translate }}</div>
                                <div *ngIf="f.subject.errors.maxlength">{{ 'contact.subject_max_length' | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="message">{{ 'contact.message' | translate }}*</label>
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="8" cdkAutosizeMaxRows="20"
                                formControlName="message" id="message" class="form-control"
                                [ngClass]="{ 'is-invalid': check(f.message) }">
                            </textarea>
                            <div *ngIf="check(f.message)" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required"> {{ 'contact.message_required' | translate }}</div>
                                <div *ngIf="f.message.errors.minlength">{{ 'contact.message_min_length' | translate }}</div>
                                <div *ngIf="f.message.errors.maxlength">{{ 'contact.message_max_length' | translate }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button mat-raised-button cdkFocusInitial color="primary" [disabled]="loading" type="submit">
                                {{ 'contact.send' | translate }} </button>
                            <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>