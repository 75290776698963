import { Component, OnInit, OnDestroy, ViewChild, Input, AfterContentInit, Injector } from '@angular/core';
import { AsynchronouslyInitialisedComponent } from 'src/app/shared/components/asynchronously-initialised.component';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { Coupon } from 'src/app/shared/models/coupon/coupon';
import { CouponService } from 'src/app/shared/services/coupon.service';
import { ResponseApiList } from 'src/app/shared/models/general/response-api-list';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { environment } from 'src/environments/environment';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { CouponList } from 'src/app/shared/models/coupon/coupon-list';

@Component({
    selector: 'app-retailer-coupons',
    templateUrl: './retailer-coupons.component.html',
    styleUrls: [ './retailer-coupons.component.scss' ]
})
export class RetailerCouponsComponent extends AsynchronouslyInitialisedComponent implements OnInit, OnDestroy, AfterContentInit {

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;

    @ViewChild('matPaginator1', { static: true }) paginator1: MatPaginator;
    @ViewChild('matPaginator2', { static: true }) paginator2: MatPaginator;

    // tslint:disable-next-line: variable-name
    private _retailerId: number;
    @Input()
    set retailerId(retailerId: number) {
        this._retailerId = retailerId;
    }
    get retailerId(): number {
        return this._retailerId;
    }

    coupons: CouponList[] = [];
    total = 0;

    private subscriptionPag1: Subscription;
    private subscriptionPag2: Subscription;

    private loggedIn: boolean;

    constructor(injector: Injector,
                private couponService: CouponService,
                private authenticationService: AuthenticationService) {

        super(injector);

        this.loggedIn = this.authenticationService.loggedIn;
        this.pageSize = this.settingsProviderService.getSettingNumber('coupons_retailer_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_2');
        this.pageSizeOptions = this.defaultPageSizeOptions;
    }

    ngOnInit() {}

    ngAfterContentInit(): void {

        // console.log(this.retailerId);
        this.paginator1.pageSize = this.pageSize;
        this.paginator2.pageSize = this.pageSize;

        if(this.loggedIn) {
            this.getCoupons();
        } else {
            this.loading = false;
        }

        this.subscriptionPag1 = this.paginator1.page.subscribe(() => {
            environment.debug && ('p1 c');
            this.paginator2.pageIndex = this.paginator1.pageIndex;
            this.paginator2.pageSize = this.paginator1.pageSize;
            this.getCoupons();
        });

        this.subscriptionPag2 = this.paginator2.page.subscribe(() => {
            environment.debug && console.log('p2 c');
            this.paginator1.pageIndex = this.paginator2.pageIndex;
            this.paginator1.pageSize = this.paginator2.pageSize;
            this.getCoupons();
        });
    }

    ngOnDestroy() {

        if(this.subscriptionPag1) {
            this.subscriptionPag1.unsubscribe();
        }
        if(this.subscriptionPag2) {
            this.subscriptionPag2.unsubscribe();
        }
    }

    getCoupons() {
        this.loading = true;

        const filters = {
            pageIndex: this.paginator1.pageIndex,
            pageSize: this.paginator1.pageSize
        };

        this.couponService.getByRetailer(this.retailerId, filters)
            .subscribe((response) => {
                if(response && response.statusCode === 200 && response.data) {

                    this.coupons = <CouponList[]>response.data;
                    this.total = response.total;

                    this.pageSizeOptions = this.helper.generatePageSizeOptions(
                        this.total,
                        this.defaultPageSizeOptions,
                        this.paginator1.pageSize
                    );

                    this.helperService.handleGoShoppingReturn(this.route.snapshot.queryParams);

                    this.error = false;

                } else {
                    this.error = true;
                }
                this.loading = false;
                this.componentLoaded();
            }, (error: string) => {
                // this.error = true;
                this.loading = false;
                this.componentLoaded();
            });
    }

    goShopping(coupon: CouponList) {
        if(coupon && coupon.coupon_id && coupon.retailer_id) {
            this.helperService.goShopping(coupon.retailer_id, coupon.coupon_id);
        }
    }
    public goToAdminCoupon(event: Event, couponId: number): void {
        event.preventDefault();
        this.helperService.goToAdminCoupon(couponId);
    }

    public goToAdminCouponUrl(couponId: number): string {
        return couponId > 0 ? this.helperService.goToAdminCouponUrl(couponId): '#';
    }

    public isAdmin(): boolean {
        return this.authenticationService.checkPermission('admin');
    }
}
