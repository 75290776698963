import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'dateFormat' })
export class MomentDateFormatPipe implements PipeTransform {
    transform(value: string, dateFormat: string): string {
        if(!value || value == '0000-00-00 00:00:00') {
            return '';
        }
        return moment(value).format(dateFormat);
    }
}
