import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { ValidationService } from '../../services/validation.service';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SubscribeDialogComponent } from './subscribe-dialog/subscribe-dialog.component';
import { environment } from '../../../../environments/environment';
import { SubscriberData } from '../../models/subscribe/subscriber-data';
import { SubsPostEvent } from './../../models/subscribe/subs-post-event';

type SubscribeMode = 'user' | 'guest';

@Component({
    selector: 'app-mailchimp',
    templateUrl: './mailchimp.component.html',
    styleUrls: [ './mailchimp.component.scss' ]
})
export class MailchimpComponent implements OnInit, OnDestroy, OnChanges {

    @Input() subscriberData: SubscriberData;
    @Input() mode: SubscribeMode = 'guest';
    @Output() event: EventEmitter<SubsPostEvent> = new EventEmitter<SubsPostEvent>();
    public email: FormControl;
    public subscribe: boolean = true;
    constructor(private dialog: MatDialog) {
    }

    ngOnInit() {
        this.email = new FormControl({ value: '', disabled: this.mode == 'user' }, [
            Validators.required,
            Validators.minLength(1),
            ValidationService.emailValidator
        ]);

        switch(this.mode) {
            case 'guest':
                this.subscribe = true;
                break;

            case 'user':
                this.email.setValue(this.subscriberData.email);
                this.subscribe = this.subscriberData.subscribe;
                break;
        }
    }

    ngOnDestroy() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.subscriberData && !changes.subscriberData.firstChange) {
            this.subscribe = this.subscriberData.subscribe;
        }
    }
    public onClick() {
        if(!this.email.value || this.email.invalid)
            return;

        const data: SubscriberData = {
            email: this.email.value,
            fname: this.subscriberData ? this.subscriberData.fname : '',
            lname: this.subscriberData ? this.subscriberData.lname : '',
            userId: this.subscriberData ? this.subscriberData.userId : null,
            member: this.subscriberData ? this.subscriberData.member : 'guest',
            subscribe: this.subscribe
        };

        const dialogRef = this.dialog.open(SubscribeDialogComponent, {
            panelClass: 'custom-dialog-subscribe',
            disableClose: false,
            data: data
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            environment.debug && console.log(result);
            this.afterClose();
        }, (error: any) => {
            console.log(error);
            this.afterClose();
        });
    }

    public check(): boolean {
        return this.email && this.email.touched && (!!this.email.value && this.email.invalid);
    }

    private afterClose() {
        switch(this.mode) {
            case 'guest':
                this.email.reset();
                break;

            case 'user':
                this.event.emit({ type: 'reload' });
                break;

        }
        this.email.markAsUntouched();
    }
}
