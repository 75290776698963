<div class="footer-container" id="footer-container">
    <div class="line-full"></div>

    <div class="footer-wrapper">

        <div class="footer-col footer-links">
            <ul class="list">
                <li *ngFor="let item of footerItems" class="list-item">
                    <a [routerLink]="[item.path]"> {{item.title}} </a>
                </li>
            </ul>
        </div>

        <app-mailchimp class="footer-col" mode="guest"></app-mailchimp>

        <div class="footer-col">
            <div class="partners"> {{ 'footer.partners' | translate }}: <img src="./assets/images/card.jpg" alt="{{ 'footer.partners' | translate }}"></div>

            <div class="social social-incons-footer">
                <a *ngFor="let item of socialLinks"
                    type="button" target="_blank" [floating]="true" size="lg" class="waves-light" [ngClass]="item.classA"
                    mdbBtn mdbWavesEffect [href]="item.href" [title]="item.title">
                        <i [ngClass]="item.classI"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="ext-wrapper">
        <div class="ext-cashback"> {{ 'footer.extension' | translate }}
            <a mat-raised-button color="primary" target="_blank"
                [href]="chromeExtension"> {{ 'footer.dw' | translate }}
            </a>
        </div>
    </div>

    <footer class="mat-typography">
        <div class="title"> ©{{ 'footer.rights' | translate }} </div>
    </footer>
</div>