import { BaseComponent } from '../shared/components/base.component';
import { AuthenticationService } from './../shared/services/authentication.service';
import { OnInit, Component, Injector } from '@angular/core';
import { ContentService } from '../shared/services/content.service';
import { ResponseApi } from '../shared/models/general/response-api';
import { Content } from '../shared/models/content/content';
import { MessageService } from '../shared/services/message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ValidationService } from '../shared/services/validation.service';
import { CreateContactMessage } from '../shared/models/contact/create-contact-message';
import { AlertService } from '../shared/services/alert.service';
import { ErrorApi } from '../shared/models/general/error-api';
import { AnalyticsService } from '../shared/services/analytics.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: [ './contact.component.scss' ]
})
export class ContactComponent extends BaseComponent implements OnInit {

    public closed: boolean = false;
    content: Content = null;
    msgForm: FormGroup;
    submited = false;

    constructor(injector: Injector,
                private messageService: MessageService,
                public snackBar: MatSnackBar,
                private contentService: ContentService,
                private alertService: AlertService,
                private authenticationService: AuthenticationService,
                private analyticsService: AnalyticsService) {

        super(injector);

        if(this.authenticationService.loggedIn) {
            this.router.navigate(this.helperService.getDefaultMessages(), {
                queryParams: { newMessage: 1 }
            });
        }
    }

    ngOnInit() {

        this.contentService.getByName('contact').subscribe(
            (response: ResponseApi<Content | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.content = <Content>response.data;

                    this.helperService.setTitle(this.content.title);
                    this.helperService.handleMeta(this.content.meta_description, this.content.meta_keywords);

                    this.analyticsService.pageView(this.router.url, this.content.title);

                    this.error = false;
                } else {
                    console.log('error on content load!', response.data);
                    this.error = true;
                }
                this.loading = false;
            },
            (error: string) => {
                console.log('error on content load!', error);
                this.error = true;
                this.loading = false;
            }
        );

        this.msgForm = new FormGroup({
            fname: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(50)
            ]),
            email: new FormControl(null, [
                Validators.required,
                ValidationService.emailValidator,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]),
            subject: new FormControl(null, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]),
            message: new FormControl(null, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10000),
            ])
        });
    }

    check(control: AbstractControl) {
        return (this.submited || control.touched) && control.errors;
    }

    onSubmit() {
        this.submited = true;

        this.alertService.reset();

        if(!this.msgForm.valid) {
            return;
        }

        this.loading = true;
        const message: CreateContactMessage = this.msgForm.value;

        this.messageService.contact(message).subscribe(
            (response: ResponseApi<string | ErrorApi[]>) => {
                this.submited = false;
                this.helper.scrollTop();
                if(response && response.statusCode === 200 && response.data === 'ok') {
                    this.msgForm.reset();
                    this.snackBar.open(this.trans('contact.message_sent_success'), this.trans('general.close'));
                } else {
                    const errors = <ErrorApi[]>response.data;
                    const errorMessage = this.helper.formatError(errors);
                    this.alertService.error(errorMessage, false, false);
                    this.snackBar.open(this.trans('contact.message_sent_failed'), this.trans('general.close'));
                }
                this.loading = false;
            },
            (error: string) => {
                this.submited = false;
                console.log(error);
                this.loading = false;
                this.snackBar.open(this.trans('contact.message_sent_failed'), this.trans('general.close'));
            });
    }

    get f() {
        return this.msgForm.controls;
    }

}
