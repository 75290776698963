import { UserGroupName } from '../user/private/user-group-name';
import { RouteMenu } from '../routes/route-menu';

export class NavUser {
    type: UserGroupName;
    title: string;

    menuItems: RouteMenu[];

    panelOpenState: boolean;

    constructor(type: UserGroupName, menuItems: RouteMenu[], activePath: UserGroupName) {
        this.panelOpenState = type === activePath;
        this.type = type;
        this.menuItems = menuItems;
        this.title = 'menu.title_' + type;  // 'menu.admin_title'
    }

    public replaceMenuItems(menuItems: RouteMenu[]) {
        this.menuItems = menuItems;
    }
}