<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12 col-xs-12 form-content">
            <div class="custom-card card mat-elevation-z2">
                <div class="card-header">
                    <h2>{{ 'user.recover_email.recover_email_title' | translate }}</h2>
                </div>
                <div class="card-body">
                    <app-alert [global]="false"></app-alert>

                    <ng-template [ngIf]="showForm" [ngIfElse]="elseTemplate">
                        <form [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="password"> {{ 'user.recover_email.password' | translate }}*</label>
                                <input id="password" type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': check(f.password) }" />
                                <div *ngIf="check(f.password)" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">{{ 'user.recover_email.passwd_required' | translate }}</div>
                                    <div *ngIf="f.password.errors.minlength">{{ 'user.recover_email.passwd_min_6' | translate }} </div>
                                    <div *ngIf="f.password.errors.maxlength"> {{ 'user.recover_email.passwd_max' | translate }}</div>
                                    <div *ngIf="f.password.errors.password">{{ 'user.recover_email.password_error' | translate }} </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password-again">{{ 'user.recover_email.password_again' | translate }}*</label>
                                <input matInput id="password-again" type="password" formControlName="passwordAgain" class="form-control"
                                    [ngClass]="{ 'is-invalid': check(f.passwordAgain) }" />
                                <div *ngIf="check(f.passwordAgain)" class="invalid-feedback">
                                    <div *ngIf="f.passwordAgain.errors.required">{{ 'user.recover_email.passwd_required' | translate }}</div>
                                    <div *ngIf="f.passwordAgain.errors.notSame"> {{ 'user.recover_email.passwd_no_match' | translate }} </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button mat-raised-button color="primary" [disabled]="loading">{{ 'general.change' | translate }}</button>
                                <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
                                <a mat-button [routerLink]="helperService.getDefaultHome()">{{ 'general.cancel' | translate }}</a>
                            </div>
                        </form>
                    </ng-template>
                    <ng-template #elseTemplate>
                        <ng-template [ngIf]="success" [ngIfElse]="errorsTemplate">
                            <div class="success-txt"><h3>{{ 'user.recover_email.change_success' | translate }}</h3></div>
                            <div class="success-btns">
                                <button mat-raised-button color="primary" [routerLink]="helperService.getDefaultLogin()" >
                                    {{ appPath.getRouteTitle(appPath.getDefaultLogin()) }}
                                </button>
                                <button mat-raised-button color="accent" [routerLink]="helperService.getDefaultHome()" >
                                    {{ appPath.getRouteTitle(appPath.getDefaultHome()) }}
                                </button>
                            </div>
                        </ng-template>
                        <ng-template #errorsTemplate>
                            <div class="error-alert-div error">
                                <div class="h4" *ngFor="let error of errors">{{ error }}</div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
