import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { first } from 'rxjs/operators';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
    selector: 'app-activation-email',
    templateUrl: './activation-email.component.html',
    styleUrls: [ './activation-email.component.scss' ]
})
export class ActivationEmailComponent extends BaseComponent implements OnInit {

    activationEmailForm: FormGroup;
    submitted = false;
    showForm = true;

    constructor(injector: Injector,
                private formBuilder: FormBuilder,
                private alertService: AlertService,
                private userService: UserService) {

        super(injector);

        this.loading = false;
    }

    ngOnInit() {
        this.activationEmailForm = this.formBuilder.group({
            email: [ '', [ Validators.required, ValidationService.emailValidator ] ],
        });
    }

    get f() {
        return this.activationEmailForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if(this.activationEmailForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.activationEmail(this.f.email.value)
            .pipe(first())
            .subscribe(
                (result: ResponseApi<string | ErrorApi[]>) => {
                    this.submitted = false;
                    if(result && result.statusCode === 200 && result.data === 'ok') {
                        this.activationEmailForm.reset();
                        this.showForm = false;
                    } else {
                        const errors = <ErrorApi[]>result.data;
                        const errorMessage = this.helper.formatError(errors);
                        this.alertService.error(errorMessage);
                    }
                    this.loading = false;
                },
                (error: string) => {
                    console.log(error);
                    this.loading = false;
                });
    }

}
