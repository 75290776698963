import { Helper } from 'src/app/shared/helpers/helper';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'userId' })
export class UserIdPipe implements PipeTransform {

    constructor(private authenticationService: AuthenticationService, private helper: Helper) { }

    public transform(content: string | null | undefined): string {
        if(!content || !this.authenticationService.loggedIn) {
            return content ?? '';
        }

        return this.helper.userId(content, this.authenticationService.getUserId().toString());
    }
}
