import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment-duration-format';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'remainingTime' })
export class MomentRemainingTimePipe implements PipeTransform {

    moment: any;

    constructor(private translate: TranslateService) {
        this.moment = moment;
        const currentLang = this.translate.currentLang;
        // console.log(currentLang);
        this.moment.locale(currentLang);
    }

    transform(value: string | Date | moment.Moment): any {
        return this.moment(value).fromNow(true);
        //.humanize();

        // const days = date.diff(moment(), 'days');
        // const hours = date.diff(moment(), 'hours');

        // if(days > 0) {
        //     return days + ' ' + hours + ' ';
        // }
    }
}
