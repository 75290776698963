<div class="container-fluid app-coupons">

    <h1 class="title-head">
        {{ 'coupons.head_title' | translate }}
        <div class="line-full"></div>
    </h1>
    <app-alert [global]="false"></app-alert>

    <!-- filter zone -->
    <mat-card class="filter-wrapper">
        <mat-card-content class="filter-content">
            <div class="left-filter">
                <div class="field">
                    <div class="retailer-icon">
                        <mat-icon>store</mat-icon>
                    </div>
                    <app-mat-select-autocomplete
                        class="select-retailer"
                        [customLabel]="trans('coupons.select_retailer')"
                        [placeholder]="trans('coupons.select_retailer')"
                        [showSelectTrigger]="false"
                        [options]="retailers"
                        [selectedOption]="filters.retailerId"
                        [disabled]="retailerLoading || loading"
                        (selectionChange)="changeRetailerOption($event)">
                    </app-mat-select-autocomplete>
                </div>

                <div class="field">
                    <div class="option-icon">
                        <mat-icon>filter_alt</mat-icon>
                    </div>
                    <mat-form-field class="select-option">
                        <mat-label> {{ 'coupons.select_option' | translate }} </mat-label>
                        <mat-select [placeholder]="trans('coupons.select_option')" [value]="filters.option"
                        (selectionChange)="changeOption($event)" [disabled]="loading">
                            <mat-option *ngFor="let option of filterOptions; let i = index" [value]="option.id" >
                                {{ option.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <span class="nav-spacer"></span>
            <mat-paginator #matPaginator1 [length]="total" [pageSizeOptions]="pageSizeOptions" [disabled]="loading"></mat-paginator>
        </mat-card-content>
    </mat-card>


    <div class="loading-shade-relative" *ngIf="loading || error || coupons.length === 0">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
        <div *ngIf="!error && !loading && coupons.length === 0">
            <h3>{{ 'coupons.empty' | translate }} </h3>
        </div>
    </div>

    <!-- coupons grid-->
    <div class="band" *ngIf="!error && !loading && coupons && coupons.length > 0">
        <div *ngFor="let coupon of coupons; let i = index" class="card">
            <mat-card class="mat-card-w">
                <div class="content-top">
                    <h5 class="f-center coupon-title">{{ coupon.title }}</h5>

                    <h4 class="f-center">
                        <a [routerLink]="helperService.genRouteRetailer(coupon.retailer_id, coupon.retailer.slug)">
                            {{ coupon.retailer.title }}
                        </a>
                    </h4>

                    <div class="f-center logo" [routerLink]="helperService.genRouteRetailer(coupon.retailer_id, coupon.retailer.slug)">
                        <img class="img" [src]="coupon.retailer.image" [alt]="coupon.retailer.title" />
                    </div>

                    <div class="f-center content-code" *ngIf="coupon.code.length > 0">
                        <div class="c-label"><b>{{ 'coupons.promo_code' | translate }}:</b></div>
                        <div class="coupon_code">{{coupon.code}}</div>
                        <div class="copy">
                            <a (click)="helper.copyCode(coupon.code)" matTooltip="copy">
                                <mat-icon class="mat-18">file_copy</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="content-bottom">

                    <div class="f-center expiring-badge">
                        <mat-icon class="mat-18">timer</mat-icon>
                        <div class="badge badge-success">
                            {{ 'coupons.expire' | translate }}
                            {{ coupon.end_date | dateFormat: 'DD.MM.YYYY'}}
                        </div>
                    </div>

                    <div class="f-center expiring-time-remain">
                        {{ 'coupons.time_remaining' | translate }}:&nbsp;
                        <b>{{ coupon.end_date | remainingTime }}</b>
                    </div>

                    <div class="go-shop-btn">
                        <button mat-raised-button color="accent" class="see-all-btn"
                            [routerLink]="helperService.genRouteRetailer(coupon.retailer_id, coupon.retailer.slug)">
                            {{ 'coupons.see_all_r' | translate }}
                        </button>

                        <a mat-button class="admin-view" *ngIf="isAdmin()" [title]="'coupons.go_to_coupon_admin' | translate"
                            [href]="goToAdminCouponUrl(coupon.coupon_id)" (click)="goToAdminCoupon($event, coupon.coupon_id)">
                            <mat-icon class="mat-18">pageview</mat-icon>
                        </a>

                        <div class="go-shop-wrap">
                            <button mat-button class="copy-btn"
                                (click)="generateRefLink(coupon.coupon_id)"
                                [title]="'coupons.send_link_friend' | translate">
                                    <mat-icon> {{ referralLink[coupon.coupon_id] ? 'done' : 'content_copy' }}</mat-icon>
                            </button>

                            <button mat-raised-button color="primary" class="get-offer-btn"
                                (click)="goShoppingAndCopyCode(coupon)">
                                {{ (!!coupon.code ? 'coupons.copy_and_get_offer' : 'coupons.get_offer' ) | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <mat-card class="filter-wrapper">
        <mat-card-content>
            <mat-paginator #matPaginator2 [length]="total" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>