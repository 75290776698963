<h2 mat-dialog-title>{{ 'reviews.new_review_title' | translate }}</h2>

<div class="not-logged" *ngIf="!loggedIn">

    <h3 class="content">{{ 'reviews.not_logged' | translate }}</h3>

    <div class="buttons-nl">
        <button mat-raised-button color="primary" (click)="login()">
            {{ loginTitle }}
        </button>

        <button mat-button mat-dialog-close="close">{{ 'general.cancel' | translate }}</button>
    </div>
</div>

<div class="review-form" *ngIf="loggedIn">
    <app-alert [global]="false"></app-alert>

    <div class="text-center" *ngIf="loggedIn">
        <h4>{{ 'reviews.for' | translate }}: <b>{{ retailer.title }} </b> </h4>
    </div>

    <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()" >
        <mat-dialog-content class="mat-typography">
            <div class="form-group">
                <label for="review_title">{{ 'reviews.review_title' | translate }}</label>
                <input type="text" id="review_title" formControlName="review_title" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.review_title.errors }" />
                <div *ngIf="submitted && f.review_title.errors" class="invalid-feedback">
                    <div *ngIf="f.review_title.errors.required">{{ 'reviews.review_title_required' | translate }}</div>
                    <div *ngIf="f.review_title.errors.minlength">{{ 'reviews.review_title_min_length' | translate }}</div>
                    <div *ngIf="f.review_title.errors.maxlength">{{ 'reviews.review_title_max_length' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <label for="rating">{{ 'reviews.rating' | translate }}</label>
                <star-rating-control [starType]="'svg'" formControlName="rating" staticColor="ok" size="large" space="no"
                    [readOnly]="false" [hoverEnabled]="true" id="rating"></star-rating-control>
                <div *ngIf="submitted && f.rating.errors" class="invalid-feedback">
                    <div *ngIf="f.rating.errors.required"> {{ 'reviews.rating_required' | translate }}</div>
                    <div *ngIf="f.rating.errors.number"> {{ 'reviews.rating_number' | translate }}</div>
                </div>
            </div>

            <div class="form-group fg-rating">
                <label for="review">{{ 'reviews.review' | translate }}</label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="8" cdkAutosizeMaxRows="20"
                    formControlName="review" id="review" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.review.errors }">
                </textarea>

                <div *ngIf="submitted && f.review.errors" class="invalid-feedback">
                    <div *ngIf="f.review.errors.required"> {{ 'reviews.review_required' | translate }}</div>
                    <div *ngIf="f.review.errors.minlength">{{ 'reviews.review_min_length' | translate }}</div>
                    <div *ngIf="f.review.errors.maxlength">{{ 'reviews.review_max_length' | translate }}</div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close="close">{{ 'general.cancel' | translate }}</button>
            <button mat-raised-button cdkFocusInitial color="primary" [disabled]="loading" type="submit">
                {{ 'reviews.send' | translate }}
            </button>
            <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}"/>
        </mat-dialog-actions>
    </form>
</div>
