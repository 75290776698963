import { FirstLoadService } from 'src/app/shared/services/first-load.service';
import { Injectable } from '@angular/core';
import { RouteInfo } from '../models/routes/route-info';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from './local-storage.service';
import { RoutingProviderService } from './routing-provider.service';
import { RouteMenu } from '../models/routes/route-menu';
import { UserGroupName } from '../models/user/private/user-group-name';
import { Helper } from './../helpers/helper';
import { SettingsProviderService } from './settings-provider.service';
import { GlobalEventsService } from './global-events.service';

@Injectable({
    providedIn: 'root',
})
export class AppPath {

    public lang: string;
    public isLoaded = false;

    private routes: RouteInfo[] = [];

    public allowedLangs: string[];
    private defaultLang: string;

    private eventSubscription: Subscription;

    constructor(private settingsProviderService: SettingsProviderService,
                private firstLoadService: FirstLoadService,
                private authenticationService: AuthenticationService,
                private localStorageService: LocalStorageService,
                private helper: Helper,

                private routingProviderService: RoutingProviderService,
                private globalEventsService: GlobalEventsService) {

        this.firstLoadService.addLoader('app-path');

        this.allowedLangs = environment.allowedLangs;
        this.defaultLang = environment.defaultLang;
        this.localStorageService.setDefaultLang(this.defaultLang);

        this.eventSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if(type === 'app-init') {

                let defaultLang = this.settingsProviderService.getSettingString('site_language');
                defaultLang = defaultLang ? defaultLang : environment.defaultLang;
                this.defaultLang = defaultLang;
                this.localStorageService.setDefaultLang(defaultLang);

                const languages = this.settingsProviderService.getSettingArray('languages');
                this.allowedLangs = languages ? languages : environment.allowedLangs;

                const lang = this.localStorageService.getLangStorage();
                if(this.allowedLangs.indexOf(lang) > -1) {
                    this.lang = lang;
                } else {
                    this.lang = defaultLang;
                    this.localStorageService.setLangStorage(this.lang);
                }

                const routes = this.routingProviderService.getRoutes();

                this.routes = routes ? this.excludeNotEnabledRoutes(routes) : [];

                this.isLoaded = true;

                this.firstLoadService.event.next('app-path');

                this.eventSubscription.unsubscribe();
            }
        });
    }

    private excludeNotEnabledRoutes(routes: RouteInfo[]): RouteInfo[] {
        const newsEnabled = this.settingsProviderService.getSettingBool('news_enabled');

        let filteredRoutes = routes;

        if(!newsEnabled) {
            filteredRoutes = filteredRoutes.filter(route => !(route.extra && route.extra.article === true ))
        }

        const offersEnabled = this.settingsProviderService.getSettingBool('offers_enabled');

        if(!offersEnabled) {
            filteredRoutes = filteredRoutes.filter(route => !(route.extra && route.extra.offers === true ))
        }

        return filteredRoutes;
    }

    public changeLang(lang: string) {
        environment.debug && console.log('lang change');
        this.lang = lang;
        this.localStorageService.setLangStorage(this.lang);
        this.globalEventsService.langChange.next(this.lang);
    }

    public checkRoutePermission(path: string): boolean {
        let routeInfo = this.findRouteByPath(path);

        if(routeInfo) {
            return this.authenticationService.checkPermission(routeInfo.permission);
        }

        return true;
    }

    public checkForbidRoutesLogged(route: string): boolean {
        const routeInfo = this.findRouteByPath(route);
        if(routeInfo && routeInfo.extra) {
            return !!routeInfo.extra.fordbidLogged;
        }

        return false;
    }

    private findRouteByExtra(key: string): RouteInfo | undefined {
        return this.helper.deepClone(
            this.routes.find((item: RouteInfo) => item.extra && item.extra[key])
        );
    }

    private findRouteByLocation(key: string): RouteInfo[] {
        return this.helper.deepClone(
            this.routes.filter((item: RouteInfo) => item.location && item.location.indexOf(key) > -1)
        );
    }

    private _findRouteByPath(path: string, exact: boolean = false): RouteInfo[] {
        return this.routes.filter((item: RouteInfo) => {
            if(item.type === 'component') {
                for (let k in item.path) {
                    if(path === item.path[k]) {
                        return true;
                    } else if(!exact && path.indexOf(item.path[k]) > -1) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    public findRouteByPath(path: string, exactOnly: boolean = false): RouteInfo | undefined {

        // match exact
        let routes = this._findRouteByPath(path, true);

        // match loose
        if(!exactOnly && ( !routes || (routes && routes.length === 0) )) {
            routes = this._findRouteByPath(path, false);
        }

        if(routes && routes.length > 0) {
            return this.helper.deepClone(this.helper.last(routes));
        }
        return undefined;
    }

    public getRoutePathArr(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string[] {
        return [ routeInfo ? this.getPath(routeInfo): defaultPath ];
    }

    public getRoutePath(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string {
        return routeInfo ? this.getPath(routeInfo): defaultPath;
    }

    public getRouteTitle(routeInfo: RouteInfo, _lang?: string): string {
        const lang = this.allowedLangs.indexOf(_lang) > -1 ? _lang : this.lang;
        return routeInfo && routeInfo.title && routeInfo.title[lang] ? routeInfo.title[lang] : '';
    }

    public getRouteDescription(routeInfo: RouteInfo, _lang?: string): string {
        const lang = this.allowedLangs.indexOf(_lang) > -1 ? _lang : this.lang;
        return routeInfo && routeInfo.metaDescription && routeInfo.metaDescription[lang] ? routeInfo.metaDescription[lang] : '';
    }

    public getPath(routeInfo: RouteInfo | undefined): string {
        return routeInfo && routeInfo.path && routeInfo.path[this.lang] ? routeInfo.path[this.lang] : '';
    }

    private findRouteByLocations(keys: string[]): RouteInfo[] {

        return this.routes.filter((item: RouteInfo) => {
            let isMatch = true;

            keys.forEach((key: string) => {
                isMatch = isMatch && item.location && item.location.indexOf(key) > -1;
            });

            return isMatch;
        });
    }

    public getDefaultUser(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUser');
    }

    public getDefaultArticle(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultArticle');
    }

    public getDefaultHome(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultHome');
    }

    public getDefaultRegister(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRegister');
    }

    getDefaultRetailer(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRetailer');
    }

    getDefaultRetailers(): RouteInfo {
        return this.findRouteByExtra('defaultRetailers');
    }

    public getDefaultAdminMessages(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminMessages');
    }

    public getDefaultPage(slug: string | number): RouteInfo | undefined {
        const route = this.helper.deepClone(this.findRouteByExtra('defaultPage'));

        if(route && route.path) {
            for(let i in route.path) {
                route.path[i] = route.path[i].replace('{slug}', slug.toString());
            }
        }

        return route;
    }

    public getDefaultMessages(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMessages');
    }

    public getDefaultAdminMessage(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminMessage');
    }

    public getDefaultMessage(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMessage');
    }

    public getDefaultLogin(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultLogin');
    }

    public getDefaultMaintenance(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMaintenance');
    }
    public getDefaultRecover(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRecover');
    }
    public getDefaultActivationEmail(): RouteInfo | undefined {
        return this.findRouteByExtra('activationEmail');
    }

    public getTos(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultTos');
    }

    public getPrivacy(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultPrivacy');
    }

    public getDefaultPayment(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultPayment');
    }

    public getDefaultBalance(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultBalance');
    }

    public getDefaultInvite(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultInvite');
    }

    public getDefaultOldAdmin(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultOldAdmin');
    }

    public getDefaultSocialPost(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultSocialPost');
    }

    public getRoutes(): RouteInfo[] {
        return this.routes;
    }

    public getTopMenuLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('topmenu');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    public getFooterLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('footer');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    public getUserLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('user');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    private mapMenu(route: RouteInfo): RouteMenu {
        const menu: RouteMenu = {
            path: route.path[this.lang],
            title: route.title[this.lang],
            icon: route.icon,
            permission: route.permission,
            extra: route.extra
        }
        return menu;
    }

    public getLeftMenuLinks(group: UserGroupName): RouteMenu[] {

        let routes: RouteInfo[] = null;

        switch(group) {
            case UserGroupName.GUEST:
                routes = this.findRouteByLocations(['leftmenu', 'topmenu']);
                break;
            case UserGroupName.USER:
            case UserGroupName.MARKETING:
            case UserGroupName.ADMIN:
                routes = this.findRouteByLocations(['leftmenu', group]);
                break;
        }

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }

        return [];

    }

    public isGroupPath(group: UserGroupName, path: string): boolean {
        const route = this.findRouteByPath(path);

        return route && route.location.indexOf('leftmenu') > -1 && route.location.indexOf(group) > -1;
    }
    public getGroupByActivePath(path: string): UserGroupName {

        let groupName: UserGroupName = UserGroupName.GUEST;
        let route = this.findRouteByPath(path);

        if(route && route.location && route.location.length) {
            const allGroups = this.authenticationService.getUserGroupNames();

            allGroups.forEach((group) => {
                if(route.location.indexOf(group) > -1) {
                    groupName = group;
                    return;
                }
            });
        }

        return groupName;
    }

    public getUserTopLinks(): RouteMenu[] {
        let routes = this.findRouteByLocation('topuser');

        if(routes && routes.length) {

            routes = routes.filter((route) => this.checkTopUserMenu(route))

            if(routes && routes.length) {
                return routes.map((route) => this.mapMenu(route));
            }
        }
        return [];
    }

    private checkTopUserMenu(menuItem: RouteInfo): boolean {

        const loggedIn = this.authenticationService.loggedIn;

        if(this.authenticationService.checkPermission(menuItem.permission)) {

            if(menuItem.extra && typeof menuItem.extra.logged !== 'undefined') {
                return menuItem.extra.logged === loggedIn;
            }

            return true;
        }

        return false;
    }

    public getUserGroupNames(): UserGroupName[] {
        return this.authenticationService.getUserGroupNames();
    }

    public getTitle(url: string): string {
        if(url && url.length > 0) {
            const route = this.findRouteByPath(this.getPathFromUrl(url), true);

            if(route && route.title && !(route.extra && route.extra.defaultHome)) {
                let title = this.getRouteTitle(route);
                if(!title) {
                    const lang = this.allowedLangs.find(l => l !== this.lang);
                    title = this.getRouteTitle(route, lang);
                }
                return title;
            }
        }
        return '';
    }

    public getMetaDescription(url: string): string {
        if(url && url.length > 0) {
            const route = this.findRouteByPath(this.getPathFromUrl(url), true);

            if(route && route.title && !(route.extra && route.extra.defaultHome)) {
                let description = this.getRouteDescription(route);
                if(!description) {
                    const lang = this.allowedLangs.find(l => l !== this.lang);
                    description = this.getRouteDescription(route, lang);
                }
                return description;
            }
        }
        return '';
    }

    private getPathFromUrl(url: string): string {
        const tempPath = url.split(/\?|#/g);
        return tempPath && tempPath[0] || '';
    }

    public getReferralLink(): string {
        const route = this.findRouteByExtra('defaultReferral');

        if(route) {
            let url = this.helper.getBaseUrl();

            url = url + this.getRoutePath(route) + this.localStorageService.getUserId();

            return url;
        }
        return '';
    }

    public checkIfFullPage(path: string | undefined): boolean {
        if(path) {
            let routeInfo = this.findRouteByPath(path);
            if(routeInfo && routeInfo.extra) {
                return routeInfo.extra?.fullPage === true || routeInfo.extra?.fullPage === undefined;
            }
        }

        return true;
    }
}
