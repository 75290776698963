import { Pipe, PipeTransform } from '@angular/core';
import { LoginSource } from '../models/user/public/login-source';

@Pipe({ name: 'loginSource' })
export class LoginSourcePipe implements PipeTransform {

    constructor() { }

    public transform(loginSource: number | null | undefined): string {
        switch(+loginSource) {
            case LoginSource.SITE:
                return 'Site';

            case LoginSource.EXTENSION:
                return 'Extension';

            case LoginSource.MOBILE:
                return 'Mobile';

            case LoginSource.UNKNOWN:
            default:
                return 'Unknown';
        }
    }
}
