<div class="loading-shade" *ngIf="loading">
    <app-loading></app-loading>
</div>

<app-topnav *ngIf="fullPage" [sidenav]="sidenav" [hidden]="loading"></app-topnav>

<mat-sidenav-container class="main" [ngClass]="{'top-nav': fullPage}" id="mat-sidenav-container" [hidden]="loading">
    <mat-sidenav [mode]="activeSidenavMode" [opened]="false" class="mat-elevation-z3" color="accent" #sidenav>
        <ng-template [ngIf]="fullPage">
            <app-quick-search fxHide.gt-sm="true"></app-quick-search>
            <!-- Links -->
            <mat-nav-list role="navigation" class="nav-custom">
                <mat-accordion displayMode="flat">
                    <!-- user type navbar -->
                    <ng-container *ngFor="let navUser of navUsers;">

                        <mat-expansion-panel *ngIf="navUser.menuItems && navUser.menuItems.length" [fxHide.gt-sm]="navUser.type === 'guest'"
                            [expanded]="navUser.panelOpenState" hideToggle="false"
                            (opened)="navUser.panelOpenState = true" (closed)="navUser.panelOpenState = false">

                            <mat-expansion-panel-header>
                                <mat-panel-title class="exp-title">
                                    <mat-icon class="mat-icon-rtl-mirror"> {{navUser.panelOpenState ? 'expand_more' : 'chevron_right'}} </mat-icon>
                                    {{ navUser.title | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-nav-list *ngIf="navUser.menuItems">
                                <a matLine mat-list-item *ngFor="let menuItem of navUser.menuItems" class="side-line" [routerLink]="[menuItem.path]" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: checkExactRouteMatch(menuItem) }" #rla="routerLinkActive">
                                    <mat-icon matListIcon>{{ menuItem.icon }}</mat-icon>
                                    <div>{{ menuItem.title }}</div>
                                    <!-- badges -->
                                    <div *ngIf="menuItem.extra && menuItem.extra.defaultMessages === true && nrUnredUser > 0" [matBadge]="nrUnredUser.toString()"
                                        matBadgeOverlap="false" color="warm" class="msg-badge"> &nbsp; </div>
                                    <div *ngIf="menuItem.extra && menuItem.extra.defaultAdminMessages === true && nrUnredAdmin > 0" [matBadge]="nrUnredAdmin.toString()"
                                        matBadgeOverlap="false" color="warm" class="msg-badge"> &nbsp; </div>
                                    <div *ngIf="menuItem.extra && menuItem.extra.defaultAdminWithdrawRequests === true && nrWithdrawRequests > 0" [matBadge]="nrWithdrawRequests.toString()"
                                        matBadgeOverlap="false" color="warm" class="msg-badge"> &nbsp; </div>

                                    <button mat-button *ngIf="menuItem.extra && !!menuItem.extra.iframeReload && rla.isActive"
                                        class="menu-reload-btn" (click)="helperService.sendIframeReload(menuItem.name)">
                                            <mat-icon class="mat-18">replay</mat-icon>
                                    </button>
                                </a>
                            </mat-nav-list>

                        </mat-expansion-panel>

                    </ng-container>

                </mat-accordion>
            </mat-nav-list>
        </ng-template>
    </mat-sidenav>
    <mat-sidenav-content id="mat-sidenav-content" #matSidenavContent>

        <app-alert *ngIf="fullPage" [global]="true" class="stick-top"></app-alert>

        <div class="main-content" id="main-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer *ngIf="fullPage"></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>

<app-facebook-chat *ngIf="fullPage && facebookChatEnabled && !loading"></app-facebook-chat>
