/**
 * https://stackoverflow.com/questions/35538552/in-angular2-i-only-want-to-add-the-addthis-plugin-to-a-specific-route-e-g-my-b
 * Reinitiate add this.
 */

import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { SettingsProviderService } from './settings-provider.service';

declare let window: Window & { addthis: any };

@Injectable({
    providedIn: 'root'
})
export class AddThisService {

    constructor(private settingsProviderService: SettingsProviderService) { }

    private removeScript() {
        let element = null;
        let divElements = null;
        let divElement = null;

        element = document.getElementById('addthis-js');
        if(element) {
            element.parentNode.removeChild(element);
        }

        divElement = document.getElementById('_atssh');
        if(divElement) {
            divElement.style.display = 'none';
            // divElement.parentNode.removeChild(divElement);
        }

        divElements = document.getElementsByClassName('addthis-smartlayers');
        while(divElements.length > 0) {
            divElements[ 0 ].parentNode.removeChild(divElements[ 0 ]);
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-desktop');
        while(divElements.length > 0) {
            divElements[ 0 ].parentNode.removeChild(divElements[ 0 ]);
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-mobile');
        while(divElements.length > 0) {
            divElements[ 0 ].parentNode.removeChild(divElements[ 0 ]);
        }

        return true;
    }

    private showLayers() {
        let divElements = null;
        let divElement = null;

        divElement = document.getElementById('_atssh');
        if(divElement) {
            divElement.style.display = 'block';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'block';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-desktop');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'block';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-mobile');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'block';
        }

        return true;
    }

    private hideLayers() {
        let divElements = null;
        let divElement = null;

        divElement = document.getElementById('_atssh');
        if(divElement) {
            divElement.style.display = 'none';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'none';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-desktop');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'none';
        }

        divElements = document.getElementsByClassName('addthis-smartlayers-mobile');
        for(let i = 0; i < divElements.length; i++) {
            divElements[ 0 ].style.display = 'none';
        }

        return true;
    }

    private loadProject(key: string, hideLayers: boolean) {
        return Observable.create((observer: Subscriber<boolean | string>) => {
            switch(true) {
                case (!document || !window):
                    /**
                     * For Angular Universal
                     */
                    observer.error('Cannot render it on Server');
                    observer.complete();
                    break;
                case ((document.getElementById('addthis-js') != null)):

                    this.refresh(hideLayers);

                    observer.next(true);
                    observer.complete();
                    break;

                case (!key):
                    observer.error('no-project-addthis');
                    observer.complete();
                    break;

                default:
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.id = 'addthis-js';
                    script.async = true;
                    script.src = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=' + key;
                    script.onload = (ev: Event) => {
                        if(hideLayers) {
                            this.hideLayers();
                        } else {
                            this.showLayers();
                        }
                        window.addthis.init();
                        observer.next(true);
                        observer.complete();
                    };

                    const first = document.getElementsByTagName('script')[ 0 ];
                    first.parentNode.insertBefore(script, first);
                    break;
            }
        });
    }
    /**
     *
     * @param accountKey eg. ra-13245612346
     * @param hideLayers eg. true for hiding in a particular state and true for showing in another.
     */
    public register(): Observable<boolean | string> {
        const accountKey = this.settingsProviderService.getSettingString('add_this_token');
        return this.loadProject(accountKey, false);
    }

    public unregister() {
        this.hideLayers();
    }

    public update(url: string, title: string = '', description: string = '') {
        if(url) {
            window.addthis.update('share', 'url', url);
        }

        if(title) {
            window.addthis.update('share', 'title', title);
        }

        if(description) {
            window.addthis.update('share', 'description', description);
        }
    }

    private refresh(hideLayers: boolean = false) {
        if(window.addthis.layers && window.addthis.layers.refresh) {
            window.addthis.layers.refresh();
        }

        if(hideLayers) {
            this.hideLayers();
        } else {
            this.showLayers();
        }
    }
}
