import { AuthenticationService } from './authentication.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { MessageService } from './message.service';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { isNumber } from 'util';
import { Subject, Observable, forkJoin } from 'rxjs';
import { HelperService } from './helper.service';
import { NewMessageEvent } from '../models/message/new-message-event';
import { UserType } from '../models/user/private/user-type';
import { CustomSnackbarData } from '../models/snackbar/custom-snackbar-data';
import { CustomMultiSnackbarComponent } from '../components/directives/custom-multi-snackbar/custom-multi-snackbar.component';
import { GlobalEventsService } from './global-events.service';
import { Logged } from '../models/general/logged';

@Injectable({
    providedIn: 'root'
})
export class MessageCheckService {

    private defaultFrequency = 1000 * 60 * 2;
    private snackbarDuration = 1000 * 60 * 1;
    private nrUnredUser = 0;
    private nrUnredAdmin = 0;
    private frequency: number;
    private interval: number = null;

    private snackBarRef: MatSnackBarRef<CustomMultiSnackbarComponent> = null;

    constructor(private messageService: MessageService,
                private snackBar: MatSnackBar,
                private helperService: HelperService,
                private authenticationService: AuthenticationService,
                private globalEventsService: GlobalEventsService) {


    }

    private createInterval() {
        if(this.interval != null) {
            clearInterval(this.interval);
        }

        this.interval = setInterval(this.handler, this.frequency);
        this.checkMessage();
    }

    private removeInterval() {
        clearInterval(this.interval);
        this.interval = null;
        this.nrUnredUser = 0;
        this.nrUnredAdmin = 0;
        if(this.snackBarRef) {
            this.snackBarRef.dismiss();
        }
        this.sendEvent();
    }


    private goToMessage = (type: UserType) => {
        switch(type) {
            case 'admin':
                this.authenticationService.isAdmin && this.helperService.goToMessages(true);
                break;
            case 'user':
                this.helperService.goToMessages(false);
                break;
        }
    }

    private handler: TimerHandler = () => { this.checkMessage(); };

    public checkMessage() {
        environment.debug && console.log('Checking new message');

        if(this.authenticationService.loggedIn) {

            let requests: Array<Observable<ResponseApi<number>>> = [];
            requests.push(this.messageService.checkMessage());

            if(this.authenticationService.isAdmin) {
                requests.push(this.messageService.checkAdminMessage());
            }

            forkJoin(requests).subscribe((responses: Array<ResponseApi<number>>) => {
                if(responses) {
                    const userMessageChange = responses[0] && this.handleResponse(responses[0], 'user');
                    const adminMessageChange = responses[1] && this.handleResponse(responses[1], 'admin');

                    if(userMessageChange || adminMessageChange) {
                        this.sendEvent();
                    }
                }
            },
            (error: string) => {
                console.log(error);
            });

        } else if(this.nrUnredUser !== 0) {
           this.removeInterval();
           this.sendEvent();
        }
    }

    public decrement(type: UserType): void {
        switch(type) {
            case 'admin':
                this.nrUnredAdmin--;
                this.sendEvent();
                break;

            case 'user':
                this.nrUnredUser--;
                this.sendEvent();
                break;
        }
    }

    private handleResponse(response: ResponseApi<number>, type: UserType): boolean {
        if(response && response.statusCode === 200) {

            if(this.compareNr(response.data, type)) {

                if(response.data > 0) {

                    const data: CustomSnackbarData = {
                        type: type,
                        message: (type === 'admin' ? 'messages.new_admin_msg_alert' : 'messages.new_msg_alert'),
                        close: 'general.close',
                        go: 'messages.view',
                        callback: () => { this.goToMessage(type) }
                    }

                    if(!this.snackBarRef) {
                        this.snackBarRef = this.snackBar.openFromComponent(CustomMultiSnackbarComponent, {
                            data: [data],
                            duration: this.snackbarDuration,
                            panelClass: 'custom-snackbar-container'
                        });
                        this.snackBarRef.afterDismissed().subscribe(() => {
                            this.snackBarRef = null;
                        });
                    } else {
                        this.snackBarRef.instance.addMessage(data);
                        this.snackBarRef.containerInstance.snackBarConfig.duration = this.snackbarDuration;
                    }

                }

                this.setNrUnread(response.data, type);
                return true;
            }
        }
        return false;
    }

    private compareNr(newData: number, type: UserType): boolean {
        switch(type) {
            case 'admin':
                return this.nrUnredAdmin !== newData;
            case 'user':
                return this.nrUnredUser !== newData;
            default:
                return false;
        }
    }

    private setNrUnread(nrUnred: number, type: UserType) {
        switch(type) {
            case 'admin':
                this.nrUnredAdmin = nrUnred;
                break;
            case 'user':
                this.nrUnredUser = nrUnred;
                break;
        }
    }

    private sendEvent() {
        const payload: NewMessageEvent = {
            nrUnredUser: this.nrUnredUser,
            nrUnredAdmin: this.nrUnredAdmin,
        };
        this.globalEventsService.newMessage.next(payload);
    }

    public init(): void {
        this.frequency = environment.messageCheckInterval;
        if(!this.frequency) {
            this.frequency = this.defaultFrequency;
        }

        this.globalEventsService.logged.subscribe((result: Logged) => {
            environment.debug && console.log('initMessageCheck');
            if(result.loggedIn) {
                this.createInterval();
                return;
            }
            this.removeInterval();
        });
    }
}
