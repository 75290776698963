import { Component, OnInit, OnDestroy, ViewEncapsulation, Injector } from '@angular/core';
import { Content } from '../shared/models/content/content';
import { ResponseApi } from '../shared/models/general/response-api';
import { ContentService } from '../shared/services/content.service';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorApi } from '../shared/models/general/error-api';
import { BaseComponent } from '../shared/components/base.component';
import { AnalyticsService } from '../shared/services/analytics.service';
import { GlobalEventsService } from './../shared/services/global-events.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: [ './page.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class PageComponent extends BaseComponent implements OnInit, OnDestroy {

    public content: Content = null;

    private routeSubscription: Subscription;
    private langChangeSubscription: Subscription;

    constructor(injector: Injector,
                private contentService: ContentService,
                private analyticsService: AnalyticsService,
                private globalEventsService: GlobalEventsService,
                private location: Location) {

        super(injector);
    }

    ngOnInit() {
        this.routeSubscription = this.route.url.subscribe((url) => {
            this.loadPage(this.route.snapshot.paramMap.get('slug'));
        });

        this.langChangeSubscription = this.globalEventsService.langChange.subscribe((lang: string) => {
            if(!this.content) {
                return;
            }

            if(lang == this.content.language) {
                return;
            }

            this.loadPage(this.content.name, true);
        });
    }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
        this.langChangeSubscription?.unsubscribe();
    }

    private loadPage(slug: string, byName: boolean = false) {
        let isNr = false;
        this.loading = true;
        this.error = false;

        let request: Observable<ResponseApi<Content | ErrorApi | ErrorApi[]>> = null;

        if(typeof slug === 'undefined' || typeof slug === null) {
            this.router.navigate(this.helperService.getDefaultHome());
            environment.debug && console.log('fail');
        } else if (Number.isInteger(+slug) && +slug > 0) {
            isNr = true;
            request = this.contentService.getById(+slug);
        } else if(byName) {
            isNr = true;
            request = this.contentService.getByName(slug);
        } else {
            request = this.contentService.getBySlug(slug);
        }

        request.subscribe(
            (response: ResponseApi<Content | ErrorApi | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.content = <Content>response.data;
                    if(isNr && this.content.page_url) {
                        this.location.replaceState(this.helperService.getDefaultPage(this.content.page_url));
                    }

                    this.helperService.setTitle(this.content.title);
                    this.helperService.handleMeta(this.content.meta_description, this.content.meta_keywords);

                    this.analyticsService.pageView(this.router.url, this.content.title);


                    this.error = false;
                } else {
                    this.error = true;
                    console.log('error on content load!', response.data);
                }
                this.loading = false;
            },
            (error: string) => {
                console.log('error on content load!', error);
                this.error = true;
                this.loading = false;
            }
        );
    }
}
