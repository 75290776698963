import { Component, OnInit, ElementRef, ViewChild, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { SocialRes } from './../../shared/models/social/social-res';
import { RegisterForm } from 'src/app/shared/models/user/public/register-form';
import { UserRegister } from 'src/app/shared/models/user/public/register-response';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserResponse } from 'src/app/shared/models/user/private/user-response';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: [ './register.component.scss' ]
})
export class RegisterComponent extends BaseComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;
    showForm = true;
    referralId = 0;
    returnUrl: string;
    registerWithoutActivation = false;
    registerWithActivation = false;

    @ViewChild('registerFormDiv') registerFormDiv: ElementRef;

    constructor(injector: Injector,
                private formBuilder: FormBuilder,
                private userService: UserService,
                public localStorageService: LocalStorageService,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) {

        super(injector);

        this.loading = false;

        this.referralId = this.localStorageService.getReferralIdFromStorage();

        this.returnUrl =
            this.route.snapshot.queryParams.returnUrl || this.appPath.getRoutePath(this.appPath.getDefaultUser(), '/');

    }

    ngOnInit() {

        this.registerForm = this.formBuilder.group({
            username: [ '', [
                Validators.required, Validators.minLength(2),
                Validators.maxLength(70), ValidationService.usernameValidator
            ]],
            password: [ '', [ Validators.required, ValidationService.passwordValidator ] ],
            email: [ '', [
                Validators.required, ValidationService.emailValidator,
                Validators.minLength(3), Validators.maxLength(100)
            ]],
            fname: [ '', [ Validators.required, Validators.minLength(1), Validators.maxLength(32) ] ],
            lname: [ '', [ Validators.required, Validators.minLength(1), Validators.maxLength(25) ] ],

            phone: [ '', [ Validators.maxLength(20) ] ],
            tos: [ false, [ Validators.required, ValidationService.isTrue ] ],
            newsletter: [ false ],
        });

    }

    get f() {
        return this.registerForm.controls;
    }

    check(control: AbstractControl) {
        return (this.submitted || control.touched) && control.errors;
    }

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if (this.registerForm.invalid) {
            return;
        }

        const data: RegisterForm = {
            ...this.registerForm.value,
            ref_id: this.referralId,
            source: this.helperService.getLoginSource()
        };

        this.loading = true;

        this.userService.register(data).subscribe(
            (response: ResponseApi<UserRegister | ErrorApi[]>) => {
                this.submitted = false;

                if (response && response.statusCode === 200 && response.data) {
                    const registerResponse = <UserRegister>response.data;
                    this.registerForm.reset();

                    if(registerResponse.type) {
                        this.registerWithActivation = true;

                        if(this.route.snapshot.queryParams.deeplink) {
                            this.helper.delay(5000, () => {
                                const url = this.helper.getPath(this.returnUrl);
                                const queryParams = this.helper.getQueryParams(this.returnUrl);

                                queryParams['userId'] = registerResponse.userId;
                                this.router.navigate([ url ], { queryParams: queryParams });
                            });
                        }

                    } else {
                        this.registerWithoutActivation = true;
                        const user = <UserResponse>registerResponse.login;
                        this.authenticationService.loginUser(user, 'register');

                        this.helper.delay(5000, () => this.successLogin());
                    }
                    this.showForm = false;
                } else {
                    this.showForm = true;
                    const errors = <ErrorApi[]>response.data;

                    this.f.password.reset();
                    this.f.password.markAsUntouched();

                    errors.forEach(
                        (val: ErrorApi) => {
                            if (val.field === 'exception') {
                                console.log(val.message);
                            } else if ( typeof this.f[val.field] !== 'undefined' ) {

                                this.f[val.field].setErrors({custom: true});
                                Object.defineProperty(this.f[val.field], 'msg', {
                                    value: val.message,
                                    writable: true
                                });
                                // this.f[val.field].msg = val.message;
                            }
                        }
                    );
                }
                this.loading = false;
                this.helper.scrollTop();
            },
            (error: string) => {
                console.log(error);
                this.loading = false;
            });
    }

    public onSocialSignIn(result: SocialRes | undefined) {

        if (!result) {
            return;
        }

        this.alertService.reset();

        switch(result.status) {
            case 'ok':
                this.alertService.success([this.trans('user.login.success')], false, true);
                this.successLogin();
                break;

            case 'message':
                this.alertService.error(result.data, false, false);
                break;
        }
    }

    public getTosLink(): string[] {
        return this.helperService.getTos();
    }

    public getTosTitle(): string {
        return this.appPath.getRouteTitle(this.appPath.getTos());
    }

    private successLogin() {
        const url = this.helper.getPath(this.returnUrl);
        const queryParams = this.helper.getQueryParams(this.returnUrl);

        this.router.navigate([ url ], { queryParams: queryParams });
    }
}
