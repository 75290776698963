import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Injector } from '@angular/core';
import { NewsService } from '../shared/services/news.service';
import { first } from 'rxjs/operators';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { Article } from '../shared/models/news/article';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { PaginationFilters } from '../shared/models/filters/pagination-filters';
import { ErrorApi } from '../shared/models/general/error-api';
import { BaseComponent } from '../shared/components/base.component';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: [ './news.component.scss' ]
})
export class NewsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    news: Article[] = [];
    total = 0;

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;
    pageIndex = 0;

    @ViewChild('matPaginator', { static: true }) paginator: MatPaginator;

    private subscription: Subscription;

    constructor(injector: Injector,
                private newsService: NewsService) {

        super(injector);

        this.pageSize = this.settingsProviderService.getSettingNumber('news_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_4');
        this.pageSizeOptions = this.defaultPageSizeOptions;

        this.route.queryParams.subscribe((params) => {

            if(params.pageSize && Number.isInteger(+params.pageSize) && +params.pageSize > 0) {
                this.pageSize = +params.pageSize;
            }

            if(params.page && Number.isInteger(+params.page) && +params.page > 0) {
                this.pageIndex = +params.page;
            }
        });
    }

    ngOnInit() {
        if(!this.settingsProviderService.getSettingBool('news_enabled')) {
            this.router.navigate(this.helperService.getDefaultHome());
        }
    }

    ngAfterViewInit() {

        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;

        this.subscription = this.paginator.page.subscribe(() => {

            this.updateUrl({
                page:  this.paginator.pageIndex,
                pageSize:  this.paginator.pageSize
            });

            this.getNews();
        });

        this.getNews();
    }

    ngOnDestroy(): void {

        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private getNews() {

        this.loading = true;
        const filters: PaginationFilters = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        };

        this.newsService.getList(filters)
                .pipe(first())
                .subscribe((response: ResponseApiList<Article | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {

                    this.news = <Article[]>response.data;
                    this.total = response.total;

                    this.pageSizeOptions = this.helper.generatePageSizeOptions(
                        this.total,
                        this.defaultPageSizeOptions,
                        this.pageSize
                    );

                    this.error = false;

                } else {
                    this.error = true;
                }
                this.loading = false;
            }, (error: string) => {
                this.error = true;
                this.loading = false;
            });
    }

    articleLink(article: Article) {
        return this.helperService.getDefaultArticle(article.news_id, article.news_url);
    }

    private updateUrl(newParams: { page: number, pageSize: number}) {

        this.pageIndex = newParams.page;
        this.pageSize = newParams.pageSize;

        this.helperService.updateUrl(newParams);
    }
}
