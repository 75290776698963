import { Injectable } from '@angular/core';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MiscService {
    constructor(private http: HttpClient) {}

    about() {
        return this.http.get<ResponseApi<Object>>(`${environment.apiUrl}/v1/about`);
    }

}
