<ng-template [ngIf]="loading || error" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading *ngIf="loading"></app-loading>
        <div class="error-div" *ngIf="error">
            <h3 *ngIf="errorText1">{{ 'retailers.no_retailer_found' | translate }}</h3>
            <h3 *ngIf="errorText2">{{ 'general.failed_load' | translate }}</h3>
            <h3 *ngIf="errorText3">{{ 'retailers.invalid_page' | translate }}</h3>
        </div>
    </div>
</ng-template>
<ng-template #loaded>
    <div class="container-fluid">
        <h1 class="title-head">
            {{ retailer.title }}
            <div class="new-btn-w" *ngIf="isMarketing()">
                <button mat-fab color="primary" [matTooltip]="trans('retailers.post_social')" matTooltipPosition="left"
                    [hidden]="loading" (click)="postSocial()">
                    {{ 'retailers.post_social_short' | translate }}
                </button>
            </div>
            <div class="view-btn-w" *ngIf="isAdmin()">
                <a mat-fab color="primary" [hidden]="loading"
                    [matTooltip]="trans('retailers.go_to_retailer_admin')" matTooltipPosition="left"
                    [href]="goToAdminRetailerUrl()" (click)="goToAdminRetailer($event)">
                    {{ 'retailers.go_to_retailer_admin_short' | translate }}
                </a>
            </div>
            <div class="line-full"></div>
        </h1>
        <app-alert [global]="false"></app-alert>
        <ngb-alert [dismissible]="true" *ngIf="!loggedIn && !closed" (close)="closed=true">
            <strong>{{ 'general.warning' | translate }} </strong> <span [innerHtml]="trans('retailers.login_warn')"></span>
        </ngb-alert>

        <mat-card class="retailer">
            <mat-card-content>
                <div class="row">
                    <div class="col-lg-8 col-md-7 col-sm-12 col-xs-12 left-side">
                        <h2 class="title">
                            <div class="title-2">
                                <div>{{ retailer.title }}</div>
                                <ng-container *ngIf="favorite; else fav">
                                    <a (click)="toggleFavorite()" class="fav" [matTooltip]="trans('retailers.remove_favorite')">
                                        <mat-icon class="mat-18 icon">favorite</mat-icon>
                                    </a>
                                </ng-container>
                                <ng-template #fav>
                                    <a (click)="toggleFavorite()" class="fav" [matTooltip]="trans('retailers.add_favorite')">
                                        <mat-icon class="mat-18 icon">favorite_border</mat-icon>
                                    </a>
                                </ng-template>
                            </div>
                            <star-rating [starType]="'svg'" [rating]="retailer.rating" staticColor="ok" size="large" space="no" [readOnly]="true"
                                [hoverEnabled]="true"></star-rating>
                        </h2>
                        <div class="cashback"><h4>{{ helperService.displayCashback(retailer.cashback) }}</h4></div>
                        <div class="description">
                            <div [innerHtml]="retailer.description"></div>
                            <div *ngIf="retailer.approval_time > 0" class="bold">
                                {{ 'retailers.approval_time' | translate }} {{ retailer.approval_time }} {{ 'retailers.days' | translate }}
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12 right-side">
                        <div class="img-wrap">
                            <div class="img">
                                <!-- <span class="featured" [title]="trans('retailers.recommanded_retailer')" -->
                                    <!-- [matTooltip]="trans('retailers.recommanded_retailer')"></span> -->
                                <img class="img" [src]="retailer.image" [alt]="retailer.title" />
                            </div>
                        </div>
                        <div class="button">
                            <button mat-raised-button color="primary" class="btn-1 btn-md" (click)="helperService.goShopping(id, null)">
                                {{ 'retailers.go_shooping_and' | translate }}
                            </button>
                            <button *ngIf="loggedIn" mat-raised-button class="btn-1 btn-md" (click)="generateRefLink()">
                                <mat-icon> {{ referralLink[id] ? 'done' : 'content_copy' }}</mat-icon>
                                {{ 'retailers.send_link_friend' | translate }}
                            </button>
                            <div [hidden]="!loggedIn || !referralLink[id]" class="input-group mb-2" (click)="copyLink()">
                                <input class="form-control" [value]="referralLink[id]" #referralLinkRef matTooltip="copy">
                            </div>
                        </div>
                        <div class="share">
                            <div class="addthis_inline_share_toolbox"></div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>


        <div class="conditions-wrapper" *ngIf="retailer.conditions">

            <h2 class="title-head">
                <mat-icon class="mat-18">info</mat-icon>
                {{ 'retailers.terms_and_contitions' | translate }}
                <div class="line-full"></div>
            </h2>

            <mat-card>
                <mat-card-content>
                    <div class="conditions" [innerHtml]="retailer.conditions"></div>
                </mat-card-content>
            </mat-card>
        </div>

        <app-retailer-coupons #coupons [retailerId]="id" *ngIf="!loading && !error"></app-retailer-coupons>

        <div class="stats-wrapper" *ngIf="showRetailerStatistics || showCashbackCalculator">

            <h2 class="title-head">
                <mat-icon class="mat-18">info</mat-icon>
                {{ 'retailers.stats_title' | translate }}
                <div class="line-full"></div>
            </h2>

            <mat-card>
                <mat-card-content>
                    <div class="row">

                        <div class="col-md-6 col-sm-12 stats-col" *ngIf="showRetailerStatistics">
                            <div class="stats mat-elevation-z5">
                                <div class="r-head"> {{ 'retailers.statistics' | translate }} </div>
                                <div class="r-table">
                                    <div class="r-row">
                                        <div class="r-col"> {{ 'retailers.stats_coupons' | translate }}: </div>
                                        <div class="r-col"> {{ retailer.nrCoupons }}</div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col"> {{ 'retailers.stats_reviews' | translate }}: </div>
                                        <div class="r-col">{{ retailer.nrReviews }}</div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col"> {{ 'retailers.stats_favorites' | translate }}: </div>
                                        <div class="r-col"> {{ retailer.nrFavorites }}</div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col"> {{ 'retailers.date_added' | translate }}: </div>
                                        <div class="r-col"> {{ retailer.added | dateFormat: 'DD.MM.YYYY' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12 stats-col" *ngIf="showCashbackCalculator">
                            <div class="cashback-calc mat-elevation-z5">
                                <div class="r-head"> {{ 'retailers.cashback_calculator' | translate }} </div>
                                <div class="r-table">
                                    <div class="r-row">
                                        <div class="r-col"> {{ 'retailers.spend' | translate }}</div>
                                        <div class="r-col"> {{ 'retailers.cashback' | translate }} </div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col">100 {{ currency }} </div>
                                        <div class="r-col">{{ calc(100) }}</div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col">500 {{ currency }}</div>
                                        <div class="r-col">{{ calc(500) }}</div>
                                    </div>
                                    <div class="r-row">
                                        <div class="r-col">1000 {{ currency }}</div>
                                        <div class="r-col">{{ calc(1000) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <app-retailer-reviews #reviews [retailerId]="id" [retailerTitle]="retailer.title" *ngIf="!loading && !error"></app-retailer-reviews>

        <app-retailer-recommended #recommended [retailerId]="id" *ngIf="!loading && !error"></app-retailer-recommended>
    </div>
</ng-template>
