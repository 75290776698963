<h3 mat-dialog-title>{{ 'goshopping.head_title' | translate }}</h3>
<mat-dialog-content class="mat-typography">

    <div class="loading-shade-relative" *ngIf="loading || error">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
    </div>

    <div *ngIf="!loading && !error" class="content-wrapper">
        <ngb-alert [dismissible]="false" *ngIf="isAdBlock" [type]="'danger'">
            <div class="h5" [innerHtml]="'goshopping.adblock_detected' | translate | newLine | safeHtml"></div>
        </ngb-alert>

        <ngb-alert [dismissible]="false" *ngIf="!isAdBlock" [type]="'info'">
            <div class="h5" [innerHtml]="setCongrats() | newLine | safeHtml"></div>
        </ngb-alert>

        <ngb-alert [dismissible]="false" *ngIf="errorMessages && errorMessages.length" [type]="'danger'">
            <div *ngFor="let errorMessage of errorMessages"> {{ errorMessage }}</div>
        </ngb-alert>

        <div *ngIf="content" class="content" [innerHtml]="setDesc() | safeHtml"></div>
    </div>

    <div class="btn-actions">
        <div class="btn-actions-wrap">

            <button mat-raised-button color="primary" [disabled]="loading || error || loadingShopping" (click)="goShopping()" class="btn-lg">
                {{ 'goshopping.continue' | translate }}
            </button>

            <button mat-button (click)="close()" class="btn-sm">{{ 'general.cancel' | translate }}</button>
        </div>

    </div>

</mat-dialog-content>
