<div class="reply-wrap reply card" id="reply">
    <form [formGroup]="msgForm" (ngSubmit)="onSubmit()">
        <mat-card class="mat-card-w mat-elevation-z8">
            <mat-card-header>
                <mat-card-title>
                    <h2>{{ 'messages.reply' | translate }}</h2>
                    <h5 *ngIf="!admin">{{ 'messages.reply_msg_title' | translate }}</h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-alert></app-alert>
                <div class="form-group">
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="20"
                        formControlName="message" id="message" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                    </textarea>

                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                        <div *ngIf="f.message.errors.required"> {{ 'messages.message_required' | translate }}</div>
                        <div *ngIf="f.message.errors.minlength">{{ 'messages.message_min_length' | translate }}</div>
                        <div *ngIf="f.message.errors.maxlength">{{ 'messages.message_max_length' | translate }}</div>
                        <div *ngIf="f.message.errors.custom">{{ f.message['msg'] }}</div>
                    </div>
                </div>
                <app-file-upload (filesChange)="onEvent($event)" [eventAction]="eventAction"></app-file-upload>
            </mat-card-content>
            <mat-card-actions>
                <div class="action-btn">
                    <div>
                        <button mat-raised-button cdkFocusInitial color="primary" [disabled]="loading" type="submit">
                            {{ 'messages.send' | translate }}
                        </button>
                        <img *ngIf="loading" [src]="helper.loadingSrc" alt="{{ 'general.loading' | translate }}" />
                    </div>
                    <button mat-button [hidden]="loading" (click)="back()"> {{ 'messages.go_back' | translate }} </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </form>
</div>