import { EventEmitter, Injectable } from '@angular/core';
import { GoToStore } from '../models/go-shopping/go-to-store';
import { RetailerQuickInfo } from '../models/retailer/retailer-quick-info';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { ExtensionGoShoppingRequest } from '../models/extension/extension-go-shopping-request';
import { ExtensionMessageActions } from '../models/extension/extension-message-actions';
import { ExtensionPostMessage } from '../models/extension/extension-post-message';
import { ExtensionGoShoppingResponse } from '../models/extension/extension-go-shopping-response';
import { AnalyticsClientIds } from '../models/analytics/analytics-client-ids';
import { ExtensiongoCashbackLinkResponse } from '../models/extension/extension-go-cashback-link-response';
import { ExtensionGoCashbackLinkRequest } from '../models/extension/extension-go-cashback-link-request';

declare let window: Window & { chrome: any };

@Injectable({
    providedIn: 'root'
})
export class ExtensionHelperService {

    private extensionId: string = "*";
    private isScript = false;
    private dataEvent = new EventEmitter<ExtensionPostMessage<any>>();

    constructor(private localStorageService: LocalStorageService) {
    }

    private isChrome(): boolean {
        return !!window.chrome && window.navigator?.userAgent?.toLowerCase()?.indexOf('chrome') > -1;
    }

    public isExtension(): boolean {
        return this.isChrome() && this.isScript;
    }

    private sendMessage(action: string, data: any = null): void {
        if(action) {
            const message: ExtensionPostMessage<any> = {
                source: 'cashback-site',
                action: action,
                data: data
            }
            window.postMessage(message, this.extensionId);
        }
    }

    private registerWebMessagePort(): void {
        environment.debug && console.log("Extension MessagePort INIT start!");

        window.addEventListener('message', this.handleReceiveMessage, false);
    }

    private unregisterWebMessagePort(): void {
        window.removeEventListener('message', this.handleReceiveMessage, false);
    }

    private handleReceiveMessage = (event: MessageEvent) => {
        if(event && event.data) {
            const data = event.data;
            if(data && data.source && data.source == 'cashback-extension') {

                environment.debug && console.log('Received message', data);

                switch(data.action) {
                    case ExtensionMessageActions.goShoppingResponse:
                    case ExtensionMessageActions.goShoppingReceived:
                    case ExtensionMessageActions.goCashbackLinkReceived:
                    case ExtensionMessageActions.goCashbackLinkResponse:
                    case ExtensionMessageActions.checkScriptResponse:
                        this.dataEvent.emit(data);
                        break;
                }
            }
        }
    }

    public goShopping(click: GoToStore, retailer: RetailerQuickInfo, clientIds: AnalyticsClientIds): Promise<void> {

        environment.debug && console.log('Sending goShopping to extension!');

        return new Promise((resolve, reject) => {

            const data: ExtensionGoShoppingRequest = {
                retailerId: click.retailerId,
                couponId: click.couponId,
                retailer: {
                    retailer_id: retailer.retailer_id,
                    title: retailer.title,
                    cashback: retailer.cashback,
                    domain: retailer.retailer_url,
                },
                user: this.localStorageService.getUser(),
                clientIds: clientIds
            }

            this.sendMessage(ExtensionMessageActions.goShoppingRequest, data);

            let timeout = true;

            this.dataEvent.subscribe((event: ExtensionPostMessage<ExtensionGoShoppingResponse | undefined | null>) => {

                if(!event) {
                    return;
                }

                switch(event.action) {

                    case ExtensionMessageActions.goShoppingResponse:
                        console.log('goShopping to extension response: ' + event.data);

                        switch(event.data) {
                            case 'success':
                                resolve();
                                break;

                            case 'error':
                            default:
                                reject();
                                break;
                        }
                        break;

                    case ExtensionMessageActions.goShoppingReceived:
                        timeout = false;
                        break;
                }
            });

            setTimeout(() => {
                if(!timeout) {
                    return;
                }
                environment.debug && console.log('Timeout reached ' + ExtensionMessageActions.goShoppingReceived + ' !');
                reject();
            }, environment.extensionTimeout);
        });
    }

    public goCashbackLink(url: string): Promise<void> {

        environment.debug && console.log('Sending goCashbackLink to extension!');

        return new Promise((resolve, reject) => {

            const data: ExtensionGoCashbackLinkRequest = {
                url: url,
                user: this.localStorageService.getUser(),
            }

            this.sendMessage(ExtensionMessageActions.goCashbackLinkRequest, data);

            let timeout = true;

            this.dataEvent.subscribe((event: ExtensionPostMessage<ExtensiongoCashbackLinkResponse | undefined | null>) => {

                if(!event) {
                    return;
                }

                switch(event.action) {

                    case ExtensionMessageActions.goCashbackLinkResponse:
                        console.log('goCashbackLink to extension response: ' + event.data);

                        switch(event.data) {
                            case 'success':
                                resolve();
                                break;

                            case 'error':
                            default:
                                reject();
                                break;
                        }
                        break;

                    case ExtensionMessageActions.goCashbackLinkReceived:
                        timeout = false;
                        break;
                }
            });

            setTimeout(() => {
                if(!timeout) {
                    return;
                }
                environment.debug && console.log('Timeout reached ' + ExtensionMessageActions.goCashbackLinkReceived + ' !');
                reject();
            }, environment.extensionTimeout);
        });
    }

    public init():void {

        environment.debug && console.log('Send check extension!');

        if(this.isChrome()) {
            this.unregisterWebMessagePort();
            this.registerWebMessagePort();

            this.sendMessage(ExtensionMessageActions.checkScriptRequest, null);

            let timeout = true;

            this.dataEvent.subscribe((event: ExtensionPostMessage<null>) => {

                if(event) {
                    switch(event.action) {

                        case ExtensionMessageActions.checkScriptResponse:
                            this.handleScriptResponse(true);
                            timeout = false;
                            break;
                    }
                }
            });

            setTimeout(() => {
                if(!timeout) {
                    return;
                }

                environment.debug && console.log('Timeout reached ' + ExtensionMessageActions.checkScriptResponse + ' !');
                this.handleScriptResponse(false);
            }, 3000);

        } else {
            this.handleScriptResponse(false);
        }
    }

    private handleScriptResponse(isScript: boolean) {
        this.isScript = isScript;

        if(!isScript) {
            this.unregisterWebMessagePort();
        }
    }
}
