import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { Transation } from '../models/transation/transation';
import { ErrorApi } from '../models/general/error-api';
import { Balance } from '../models/transation/balance';

@Injectable()
export class TransactionService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Transation>>(`${environment.apiUrl}/v1/transaction/all`);
    }

    getList(data: SortFilters) {
        return this.http.post<ResponseApiList<Transation | ErrorApi[]>>(`${environment.apiUrl}/v1/transaction/list`, data);
    }

    getUserBalances() {
        return this.http.get<ResponseApi<Balance>>(`${environment.apiUrl}/v1/transaction/user-balances`);
    }
}
