import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-yes-no-dialog',
    templateUrl: './yes-no-dialog.component.html',
    styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {

    message: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
        this.message = data;
    }

    ngOnInit() {
    }

}
