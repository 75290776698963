import { Component, Output, EventEmitter, Input, Injector } from '@angular/core';
import { SocialRes } from 'src/app/shared/models/social/social-res';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MobileHelperService } from 'src/app/shared/services/mobile-helper.service';
import { SettingsProviderService } from 'src/app/shared/services/settings-provider.service';

@Component({
    selector: 'app-google-signin-base',
    template: '',
})
export class SocialSigninBaseComponent {
    @Input() isLogin: boolean = true;

    @Output() signInEvent: EventEmitter<SocialRes> = new EventEmitter<SocialRes>();

    protected authenticationService: AuthenticationService;
    protected mobileHelperService: MobileHelperService;
    protected settingsProviderService: SettingsProviderService;

    constructor(private injector: Injector) {
        this.settingsProviderService = this.injector.get(SettingsProviderService);
        this.authenticationService = this.injector.get(AuthenticationService);
        this.mobileHelperService = this.injector.get(MobileHelperService);
    }

    public isMobile = () => this.mobileHelperService.isMobile();

    public socialSignIn(type: string) {

        if (this.isMobile()) {
            this.authenticationService.mobileSocialSignin(type, this.isLogin).then(this.onSocialSignIn.bind(this));
            return;
        }

        this.authenticationService.socialSignIn(type, this.isLogin).then(this.onSocialSignIn.bind(this));
    }

    public onSocialSignIn = (result: SocialRes) => this.signInEvent.emit(result);
}
