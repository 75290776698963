import { ElementRef, Injectable } from '@angular/core';
import { ErrorApi } from '../models/general/error-api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import moment, { Moment, unitOfTime } from 'moment';
import { ResponseApiList } from '../models/general/response-api-list';

@Injectable({
    providedIn: 'root',
})
export class Helper {

    public loadingSrc =
    // tslint:disable-next-line: max-line-length
    `data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==`;

    private utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    private asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    private fileIcons: Map<string, string> = new Map([
        ['image/png', 'image'],
        ['image/gif', 'gif'],
        ['image/jpeg', 'image'],
        ['image/bmp', 'image'],
        ['image/webp', 'image'],
        ['application/pdf', 'picture_as_pdf'],
        ['application/msword', 'insert_drive_file'],
        ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'insert_drive_file'],
        ['text/csv', 'rss_feed'],
        ['application/xml', 'rss_feed'],
        ['text/xml', 'rss_feed'],
        ['application/vnd.ms-excel', 'insert_drive_file'],
        ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'insert_drive_file'],
        ['application/json', 'rss_feed'],
        ['application/rtf', 'insert_drive_file'],
        ['text/plain', 'note'],
        ['application/vnd.ms-powerpoint', 'insert_drive_file'],
        ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'insert_drive_file'],
    ]);
    // list_alt, present_to_all, note, insert_drive_file

    private defaultErrorResponse = Object.freeze({
        statusCode: 1,
        data:  Object.freeze([]),
        total: 0,
        totalPage: 0,
        message: ''
    });

    constructor(private translateService: TranslateService) {}

    public trans(key: string): string {
        return this.translateService.instant(key);
    }

    public formatError(errors: ErrorApi[], field: boolean = false): string[] {
        let errorMessage: string[];

        if(errors) {
            errorMessage = [];

            errors.forEach((err: ErrorApi) => {
                if (err.field === 'exception') {
                    console.log(err.message);
                } else {
                    if(field) {
                        const msg = err.message && err.message.map(x => err.field + ': ' + x);
                        errorMessage.push(...msg);
                    } else {
                        errorMessage.push(...err.message);
                    }
                }
            });

        }
        return errorMessage;
    }

    public scrollTop() {
        setTimeout(() => {
            const element = document.querySelector('html');
            if(element && element.scrollTop) {
                element.scrollTop = 0;
            }
        }, 300);
    }

    public last(array: Array<any>): any {
        return array[ array.length - 1 ];
    }
    public cutText(text: string, max: number = 200, displayHellip: boolean = true): string {
        return (
            text.substr(0, max - 1) + (text.length > max ? (displayHellip ? '&hellip;' : '...') : '')
        );
    }

    public getBaseUrl(): string {

        const path = window.location.origin ?
            window.location.origin :
            window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

        if(path && path.length > 0) {
            return path;
        }

        return '';
    }

    public checkAdsBlocked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const testURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

            const myInit: RequestInit = {
                method: 'HEAD',
                mode: 'no-cors'
            };

            const myRequest = new Request(testURL, myInit);

            fetch(myRequest).then((response) => {
                return response;
            }).then((response) => {
                // console.log(response);
                resolve(false);
            }).catch((e) => {
                // console.log(e);
                resolve(true);
            });
        });
    }

    public getPath(url: string): string {
        if(url) {
            let path: string = decodeURIComponent(url);
            if(url.indexOf('?') > -1) {
                path = url.substring(0, url.indexOf('?'));
            }
            return path;
        }

        return '';
    }

    public getQueryParams(url: string): Record<string, any> {
        let params: Record<string, any> = {};
        if(url.indexOf('?') > -1) {
            const querystring = url.substring(url.indexOf('?') + 1).split('&');
            // march and parse
            for(let i = querystring.length - 1; i >= 0; i--) {
                const pair = querystring[ i ].split('=');
                params[ decodeURIComponent(pair[ 0 ]) ] = decodeURIComponent(pair[ 1 ] || '');
            }
        }
        return params;
    }

    public copyCode(text?: string) {
        if(!text?.length) {
            return;
        }

        window.navigator.clipboard.writeText(text);
    }

    public focusAndCopy(referralLinkRef: ElementRef<HTMLInputElement> | undefined) {
        if(!referralLinkRef) {
            return;
        }

        referralLinkRef.nativeElement?.focus();
        referralLinkRef.nativeElement?.select();

        const value = referralLinkRef.nativeElement?.value;
        this.copyCode(value);
    }

    public closestInteger(a: number, b: number): number {
        const c1 = a - (a % b);
        const c2 = (a + b) - (a % b);

        if(a - c1 > c2 - a) {
            return c2;
        }
        return c1;
    }

    public generatePageSizeOptions(total: number, pageSizeOptions: number[], pageSize: number): number[] {

        if(total < this.last(pageSizeOptions)) {
            pageSizeOptions = pageSizeOptions.filter(x => x <= total);
        }

        return pageSizeOptions;
    }

    public arraysAreEqual = (array1: Array<any>, array2: Array<any>) => (array1.length === array2.length) && (array1.every(val => array2.includes(val)));

    public handleHeightMainCotent(type: 'set' | 'remove'): HTMLElement | undefined {
        const main = document.getElementById('main-content');

        if(!main) {
            return undefined;
        }

        const footer = document.getElementById('footer-container');

        switch(type) {
            case 'remove':
                main.style.removeProperty('min-height');
                footer?.style.removeProperty('min-height');
                break;

            case 'set':
                main.style.setProperty('min-height', '0', 'important');
                footer?.style.setProperty('min-height', '0', 'important');
                break;
        }

        return main;
    }

    public checkIframeSize(iframe: ElementRef) {
        const main = this.handleHeightMainCotent('set');
        const offset = window.innerHeight - (window.innerWidth >= 600 ? 64 : 56) - (main ? main.offsetTop : 0);
        iframe.nativeElement.style.height = offset.toString() + 'px';
    }

    public handleScrollBlock(type: 'set' | 'remove'): void {
        const element = document.querySelector('html');
        if(element) {
            switch(type) {
                case 'remove':
                    element.classList.contains('cdk-global-scrollblock') &&
                        element.classList.remove('cdk-global-scrollblock');
                    break;

                case 'set':
                    !element.classList.contains('cdk-global-scrollblock') &&
                        element.classList.add('cdk-global-scrollblock');
                    break;
            }
        }
    }

    public appendCurrentLocalUrl(data: any) {
        if(!environment.production) {
            const url = this.getBaseUrl();
            if(url) {
                data.url = url;
            }
        }
    }

    public checkStaticMetaPages(path?: string): boolean {

        if(!path) {
            return false;
        }

        if(
            path.indexOf('/page/') === -1 &&
            path.indexOf('/pagina/') === -1 &&
            path.indexOf('/contact') === -1 &&
            path.indexOf('/retailer/') === -1 &&
            path.indexOf('/magazin/') === -1 &&
            path.indexOf('/articol/') === -1 &&
            path.indexOf('/article/') === -1
        ) {
            return true;
        }

        return false;
    }

    public deepClone(obj: any) {

        // return value is input is not an Object or Array.
        if(typeof (obj) !== 'object' || obj === null) {
            return obj;
        }

        let clone: any;

        if(Array.isArray(obj)) {
            clone = obj.slice();  // unlink Array reference.
        } else {
            clone = Object.assign({}, obj); // Unlink Object reference.
        }

        let keys = Object.keys(clone);

        for(let i = 0; i < keys.length; i++) {
            clone[ keys[ i ] ] = this.deepClone(clone[ keys[ i ] ]); // recursively unlink reference to nested objects.
        }

        return clone; // return unlinked clone.
    }

    public today() {
        return moment();
    }

    public prevDate(input: number, unit: unitOfTime.Base, margin: 'min' | 'max') {
        const date = moment().subtract(input, unit);
        return this.setMargins(date, unit, margin);
    }

    public futureDate(input: number, unit: unitOfTime.Base, margin: 'min' | 'max') {
        const date = moment().add(input, unit);
        return this.setMargins(date, unit, margin);
    }

    private setMargins(date: Moment, unit: unitOfTime.Base, margin: 'min' | 'max'): Moment {
        switch (unit) {
            case 'hour':
            case 'hours':
                switch (margin) {
                    case 'min':
                        return date.minutes(0).seconds(0);
                    default:
                    case 'max':
                        return date.minutes(59).seconds(59);
                }

            case 'minute':
            case 'minutes':
                switch (margin) {
                    case 'min':
                        return date.seconds(0);
                    default:
                    case 'max':
                        return date.seconds(59);
                }

            case 'day':
            case 'days':
            default:
                switch (margin) {
                    case 'min':
                        return date.hours(0).minutes(0).seconds(0);
                    default:
                    case 'max':
                        return date.hours(23).minutes(59).seconds(59);
                }
        }
    }

    public delay(delay: number, callback: () => void) {
        setTimeout(() => {
            callback();
        }, delay ?? 1000);
    }

    public sleep = (ms: number) => new Promise((resolve, reject) => setTimeout(resolve, ms));

    public userId(str: string | undefined, userId: string | undefined): string {
        if (!str || !userId) {
            return str ?? '';
        }

        return str.replace(/\%7bUSERID\%7d/g, userId)
            .replace(/\%7BUSERID\%7D/g, userId)
            .replace(/{USERID}/g, userId) ?? '';
    }

    public humanFileSize(size: number): string {
        if(!size) {
            return '';
        }

        const i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    public checkIconByMimeType(mimeType: string) {
        return !!this.fileIcons.get(mimeType);
    }

    public getIconByMimeType(mimeType: string): string {
        return this.fileIcons.get(mimeType) ?? '';
    }

    public getFileName(disposition: string): string | null {
        if(!disposition) {
            return null;
        }

        if(this.utf8FilenameRegex.test(disposition)) {
            return decodeURIComponent(this.utf8FilenameRegex.exec(disposition)[1]);
        }

        // prevent ReDos attacks by anchoring the ascii regex to string start and
        // slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=');
        if(filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = this.asciiFilenameRegex.exec(partialDisposition);

            if(matches != null && matches[2]) {
                return matches[2];
            }
        }

        return null;
    }

    public isInViewport(element: any) {
        if(!element) {
            return true;
        }

        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    public getDefaultErrorResponse = <T>() => {
        return this.deepClone(this.defaultErrorResponse) as ResponseApiList<T>;
    }
}
