import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {

    transform(value: string, search: string, replace: string): string {
        return value.replace(new RegExp(this.escapeRegExp(search), 'g'), replace);
    }

    private escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

}
