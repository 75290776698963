import { environment } from 'src/environments/environment';
import { ElementRef, Component, AfterViewInit, ViewChild, Input, Injector } from '@angular/core';
import { SocialSigninBaseComponent } from '../shared/social-signin-base.component';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';

declare let window: Window & { google: any }

@Component({
    selector: 'app-google-signin',
    templateUrl: 'google-signin.component.html',
    styleUrls: ['google-signin.component.scss']
})
export class GoogleSigninComponent extends SocialSigninBaseComponent implements AfterViewInit {

    @Input() type: 'icon' | 'standard' = 'standard';

    @ViewChild('wrapperBtn', { static: false }) wrapperBtn: ElementRef;

    constructor(injector: Injector) {
        super(injector)
    }

    protected isIcon = () => this.type === 'icon';

    ngAfterViewInit() {
        if (this.isMobile()) {
            return;
        }

        this.authenticationService.initializeSocialLogin().then(() => {
            this.authenticationService.authState().subscribe(userData => {

                if (userData?.provider !== GoogleLoginProvider.PROVIDER_ID) {
                    return;
                }

                environment.debug && console.log('google_auth', userData);

                this.authenticationService.socialSignIn('google', this.isLogin, userData).then(this.onSocialSignIn.bind(this))
            }, (error: any) => {
                console.log(error);
            });

            this.renderButton();
        });
    }

    private renderButton() {

        if (!this.wrapperBtn?.nativeElement) {
            return;
        }

        window.google.accounts.id.renderButton(this.wrapperBtn.nativeElement, {
            type: this.type,
            size: this.isIcon() ? 'medium' : 'large',
            text: '',
            width: this.isIcon() ? '' : '220',
            shape: this.isIcon() ? 'circle' : 'rectangular',
            theme: 'filled_blue',
            logo_alignment: 'left',
            locale: 'ro',
        });
    }
}
