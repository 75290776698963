const titleExtented = 'Cashback Shopping | Bani inapoi la cumparaturile online';
const description = 'CashBack Shopping aduce un concept nou in Romania, acela de a economisi bani la cumparaturi. Castigi bani la cumparaturile online din magazinele tale preferate!';
const image = '/assets/images/logo/fb-cashback-share-2.png';
const keywords = 'economisesti bani, shopping online, cashback, cumparaturi online, cumperi online, primesti bani, bani inapoi, aduni bani, aduni cashback, romania, cashback online, comert online, magazine partenere, castigi bani, castiga cashback, cashback shopping';

export const EnviromentBase = {
    metaTags: [
        { property: 'og:title', content: titleExtented },
        { property: 'og:image', content: image },
        { property: 'og:url', content: '' },
        { name: 'keywords', content: keywords },
        { name: 'description', content: description }
    ],
    defaultLang: 'ro',
    allowedLangs: [ 'ro', 'en' ],
    title: 'Cashback Shopping',
    versionCheckURL: '/version.json',
    localStorage: {
        currentUser: 'cashback_current_user',
        lang: 'cashback_lang',
        referral: 'cashback_referral',
        translationsVersion: 'cashback_translations_version_',
        translations: 'cashback_translations_',
        announcement: 'cashback_announcement',
    },
    header: {
        lang: 'cashback-lang'
    },
    mediaHosting: undefined,
    imageAlbums: {
        news: 3,
        content: 10,
        banners: 9,
        retailer: 11,
        flags: 8,
        logo: 7,
        other: 12,
        offers: 13
    },
    enableTracking: true,
    extensionTimeout: 1000
};
