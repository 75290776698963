import { Component, OnDestroy, OnInit, Input, Injector } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Event } from '@angular/router';
import { BaseComponent } from '../base.component';
import { UserType } from '../../models/user/private/user-type';
import { NewMessageEvent } from './../../models/message/new-message-event';
import { RouteMenu } from '../../models/routes/route-menu';
import { GlobalEventsService } from '../../services/global-events.service';
import { Logged } from '../../models/general/logged';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: [ './topnav.component.scss' ]
})
export class TopnavComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() sidenav: MatSidenav;

    public title = `${environment.title}`;

    public menuItems: RouteMenu[] = [];
    public menuUserItems: RouteMenu[] = [];

    public loggedIn: boolean;
    public isAdmin: boolean;

    public nrUnredUser = 0;
    public nrUnredAdmin = 0;


    public languages: string[];

    private loggedSubscription: Subscription;
    private firstLoadServiceSubscription: Subscription;
    private langChangeSubscription: Subscription;
    private messageCheckSubscription: Subscription;
    private urlSubscription: Subscription;
    private navSubscription: Subscription;

    constructor(injector: Injector,
                private authenticationService: AuthenticationService,
                private globalEventsService: GlobalEventsService) {

        super(injector);

    }

    ngOnInit() {

        this.languages = this.appPath.allowedLangs;

        this.firstLoadServiceSubscription = this.globalEventsService.firstLoaded.subscribe(() => {

            this.languages = this.appPath.allowedLangs;

            this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
                this.loggedIn = result.loggedIn;
                this.isAdmin = result.isAdmin;
                this.loadMenus();
            });

            this.langChangeSubscription = this.globalEventsService.langChange.subscribe(() => {
                this.loadMenus();
            });

            this.messageCheckSubscription = this.globalEventsService.newMessage.subscribe((newMessageEvent: NewMessageEvent) => {
                this.nrUnredUser = newMessageEvent.nrUnredUser;
                this.nrUnredAdmin = newMessageEvent.nrUnredAdmin;
            });
        });
    }

    private loadMenus(): void {
        environment.debug && console.log('load menus');
        this.menuItems = this.appPath.getTopMenuLinks();
        this.menuUserItems = this.appPath.getUserTopLinks();
    }

    ngOnDestroy(): void {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
        if(this.navSubscription) {
            this.navSubscription.unsubscribe();
        }
        if(this.messageCheckSubscription) {
            this.messageCheckSubscription.unsubscribe();
        }
        if(this.urlSubscription) {
            this.urlSubscription.unsubscribe();
        }
        if(this.firstLoadServiceSubscription) {
            this.firstLoadServiceSubscription.unsubscribe();
        }
    }

    toggle() {
        this.sidenav?.toggle();
    }

    changeLang(lang: string) {
        this.appPath.changeLang(lang);
    }

    public handle(menuItem: RouteMenu): void {
        if(menuItem.extra) {
            if(menuItem.extra.defaultLogout) {
                this.authenticationService.logout(true);
                this.router.navigate(this.helperService.getDefaultHome());
            } else if(menuItem.extra.defaultLogin) {
                this.authenticationService.logout(true);
                this.router.navigate(this.helperService.getDefaultLogin());
            }
        }
    }

    public goToMessages(type: UserType): void {
        switch(type) {
            case 'admin':
                this.isAdmin && this.helperService.goToMessages(true);
                break;
            case 'user':
                this.helperService.goToMessages(false);
                break;
        }
    }

    public getLangIcon(lang: string): string {
        return './assets/images/icons/' + lang.toLowerCase() + '.png';
    }
}
