<div class="quick-search">
    <div class="form-group">
        <div class="input-group mb-2 mr-sm-2">
            <input [placeholder]="trans('retailers.search_retailer')" class="form-control"
                [matAutocomplete]="auto" [formControl]="retailerSearchCtrl" tabindex="-1">
            <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                    <mat-icon matListIcon>search</mat-icon>
                </div>
            </div> -->
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="enter($event)">
            <mat-option *ngFor="let option of filteredRetailers" [value]="option">
                <a>{{ option.title }}</a>
            </mat-option>
        </mat-autocomplete>
    </div>
</div>
