import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ClicksService } from 'src/app/shared/services/clicks.service';
import { Injectable } from '@angular/core';
import { GoShoppingResponse } from './../models/go-shopping/go-shopping-response';
import { GoShoppingDialogComponent } from './../components/go-shopping-dialog/go-shopping-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ClickLink } from '../models/click/click-link';
import { GoToStore } from '../models/go-shopping/go-to-store';
import { TranslateService } from '@ngx-translate/core';
import { FormControls } from '../models/form/form-controls';
import { ErrorApi } from '../models/general/error-api';
import { Helper } from './../helpers/helper';
import { AlertService } from './alert.service';
import { SettingsProviderService } from './settings-provider.service';
import { AppPath } from './app-path.service';
import { GlobalEventsService } from './global-events.service';
import { CustomClick } from '../models/analytics/custom-click';
import { ClickGenType } from '../models/click/click-type';
import { ResponseApi } from '../models/general/response-api';
import { ExtensionHelperService } from './extension-helper.service';
import { RoutingProviderService } from './routing-provider.service';
import { LoginSource } from '../models/user/public/login-source';
import { MobileHelperService } from './mobile-helper.service';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(private appPath: AppPath,
                private domSanitizer: DomSanitizer,
                private router: Router,
                private route: ActivatedRoute,
                private meta: Meta,
                private titleService: Title,
                private dialog: MatDialog,
                private settingsProviderService: SettingsProviderService,
                private translateService: TranslateService,
                private helper: Helper,
                private alertService: AlertService,
                private globalEventsService: GlobalEventsService,
                private clicksService: ClicksService,
                private authenticationService: AuthenticationService,
                private extensionHelperService: ExtensionHelperService,
                private routingProviderService: RoutingProviderService,
                private mobileHelperService: MobileHelperService) {}

    public getDefaultHome(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultHome(), '/');
    }

    public getDefaultLogin(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultLogin());
    }

    public getDefaultUser() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultUser());
    }

    public getDefaultRegister() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRegister());
    }

    public getDefaultMessages() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMessages());
    }

    public getDefaultAdminMessages() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminMessages());
    }

    public getDefaultPage(id: string | number) {
        return this.appPath.getRoutePath(this.appPath.getDefaultPage(id));
    }

    // public getDefaultAdminPages() {
    //     return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminPages());
    // }

    public getDefaultMaintenance() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMaintenance(), '/maintenance');
    }

    public getDefaultRecover() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRecover());
    }
    public getDefaultActivationEmail() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultActivationEmail());
    }
    public genRouteRetailer(retailerId: number, slug: string = ''): string[] {
        return [ this.appPath.getRoutePath(this.appPath.getDefaultRetailer()) + retailerId + (slug ? '/' + slug : '') ];
    }

    public getDefaultArticle(articleId: number, slug: string = ''): string[] {
        return [ this.appPath.getRoutePath(this.appPath.getDefaultArticle()) + articleId + '/' + slug ];
    }

    public genRouteRetailers() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRetailers());
    }

    public getDefaultBalance() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultBalance());
    }

    public getDefaultInvite() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultInvite());
    }

    public getDefaultPayment() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultPayment());
    }

    public getDefaultOldAdmin() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultOldAdmin());
    }

    public getDefaultAdminMessage(id: number) {
        return [ this.appPath.getRoutePath(this.appPath.getDefaultAdminMessage()) + id.toString() ];
    }

    public getDefaultMessage(id: number) {
        return [ this.appPath.getRoutePath(this.appPath.getDefaultMessage()) + id.toString() ];
    }

    public getTos() {
        return this.appPath.getRoutePathArr(this.appPath.getTos());
    }


    public getDefaultSocialPost() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultSocialPost());
    }

    public parseHtml(text: string) {
        return this.domSanitizer.bypassSecurityTrustHtml(text);
    }

    public formatNumber(val: number | string): string {
        let txt: string = '';

        if(typeof val === 'number') {
            txt = val.toFixed(2).toString();

            if(this.appPath.lang === 'ro') {
                txt = txt.replace('.', ',');
            }
        } else if(typeof val === 'string') {
            const valalNr = parseFloat(val);

            txt = valalNr.toFixed(2).toString();

            if(this.appPath.lang === 'ro') {
                txt = txt.replace('.', ',');
            }
        } else {
            txt = val;
        }
        return txt;
    }

    public replaceDecimalPoint(value: string): string {
        if(!value) {
            return '';
        }

        switch(this.appPath.lang) {
            case 'ro':
                return value.replace(/\./g, ',');
            case 'en':
                return value.replace(/,/g, '.');
            default:
                return value;
        }
    }

    public goShopping(retailerId?: number, couponId?: number): void {

        const click: GoToStore = {
            retailerId: retailerId,
            couponId: couponId
        };

        const dialogRef = this.dialog.open(GoShoppingDialogComponent, {
            disableClose: false,
            data: {
                click: click,
                helperService: this
            }
        });

        dialogRef.afterClosed().subscribe((response: GoShoppingResponse<ClickLink | string>) => {
            environment.debug && console.log(response);

            if(typeof response !== 'string') {
                switch(response.type) {
                    case 'click':
                        const link = <ClickLink>response.data;
                        window.open(link.link, '_blank');
                        break;

                    case 'error':
                        break;

                    case 'close':

                        break;
                    case 'extension':

                        break;

                    case 'login_error':
                        this.router.navigate(this.getDefaultLogin(), {
                            queryParams: {
                                returnUrl: this.router.url,
                                goShopping: retailerId,
                                goShoppingCoupon: couponId
                            }
                        });
                        break;
                }
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    public handleGoShoppingReturn(queryParams: Record<string, any>) {
        if(queryParams && (queryParams.goShopping || queryParams.goShoppingCoupon)) {
            const retailerId = queryParams.goShopping;
            const couponId = queryParams.goShoppingCoupon;

            if(retailerId || couponId) {
                setTimeout(() => {
                    this.goShopping(retailerId, couponId);
                }, 200);

                const newQueryParams = { ...queryParams };
                delete newQueryParams.goShopping;
                delete newQueryParams.goShoppingCoupon;

                this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams });
            }
        }
    }

    public handleMeta(description?: string, keywords?: string, image?: string) {
        // this.meta.removeTag('name="description"');
        // this.meta.removeTag('property="og:description"');
        // this.meta.removeTag('property="og:keywords"');

        const metaTags = environment.metaTags;
        if(!description) {
            const metaDescription = metaTags.find(item => item.name === 'description');
            description = metaDescription?.content ?? '';
        }

        if(!keywords) {
            const metaKeywords = metaTags.find(item => item.name === 'keywords');
            keywords = metaKeywords.content ?? '';
        }

        if(!image) {
            const metaImage = metaTags.find(item => item.name === 'og:image');
            image = metaImage?.content ?? '';
        }

        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ name: 'twitter:description', content: description });
        this.meta.updateTag({ name: 'keywords', content: keywords });
        this.meta.updateTag({ name: 'og:image', content: image });
        this.meta.updateTag({ name: 'twitter:image', content: image });
        this.meta.updateTag({ name: 'twitter:image:src', content: image });
    }

    public setTitle(name: string) {

        let title: string = '';

        if(name && name.length) {
            title =  name + ' | ' + environment.title;
        } else {
            const metaTitle = environment.metaTags.find(item => item.property === 'og:title');
            title = metaTitle ? metaTitle.content : environment.title;
        }

        this.titleService.setTitle(title);
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({ name: 'twitter:title', content: title });
    }

    public goToMessages(isAdmin: boolean) {
        let path: string[] = isAdmin ? this.getDefaultAdminMessages() : this.getDefaultMessages();

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(path, {
                queryParams: { unreadFirst: true }
            });
        });
    }

    public goToUser(userId: number) {
        this.router.navigate(this.getDefaultOldAdmin(), this.goToUserParams(userId));
    }

    public goToUserUrl(userId: number): string {
        return this.router.createUrlTree(this.getDefaultOldAdmin(), this.goToUserParams(userId)).toString();
    }

    public goToAdminCoupon(couponId: number) {
        this.router.navigate(this.getDefaultOldAdmin(), this.goToAdminCouponParams(couponId));
    }

    public goToAdminCouponUrl(couponId: number): string {
        return this.router.createUrlTree(this.getDefaultOldAdmin(), this.goToAdminCouponParams(couponId)).toString();
    }

    public goToAdminOffer(id: number) {
        this.router.navigate(this.getDefaultOldAdmin(), this.goToAdminOfferParams(id));
    }

    public goToAdminOfferUrl(id: number): string {
        return this.router.createUrlTree(this.getDefaultOldAdmin(), this.goToAdminOfferParams(id)).toString();
    }

    public goToAdminRetailer(retailerId: number) {
        this.router.navigate(this.getDefaultOldAdmin(), this.goToAdminRetailerParams(retailerId));
    }

    public goToAdminRetailerUrl(retailerId: number): string {
        return this.router.createUrlTree(this.getDefaultOldAdmin(), this.goToAdminRetailerParams(retailerId)).toString();
    }

    private goToUserParams(userId: number): Record<string, any> {
        return {
            queryParams: {
                adminUrl: '/admin/user_details.php?id=' + userId
            }
        }
    }

    private goToAdminCouponParams(couponId: number): Record<string, any> {
        return {
            queryParams: {
                adminUrl: '/admin/coupon_details.php?id=' + couponId
            }
        }
    }

    private goToAdminOfferParams(id: number): Record<string, any> {
        return {
            queryParams: {
                adminUrl: '/admin/offer_edit.php?id=' + id
            }
        }
    }

    private goToAdminRetailerParams(retailerId: number): Record<string, any> {
        return {
            queryParams: {
                adminUrl: '/admin/retailer_details.php?id=' + retailerId
            }
        }
    }

    public updateUrl(newParams: Record<string, any>, replaceUrl: boolean = false) {
        this.router.navigate([], {
            replaceUrl: replaceUrl,
            queryParams: {
                ...this.route.snapshot.queryParams,
                ...newParams
            }
        });
    }
    public displayCashback(value: string): string {

        const defaultCurency = this.settingsProviderService.getSettingString('website_currency');

        let cashback = '';

        const cashbackText = this.trans('retailers.cashback');
        const cashbackVariableText = this.trans('retailers.cashback_variable_text');
        const cashbackUpTo = this.trans('retailers.cashback_up_to');
        const cashbackVariable = this.trans('retailers.cashback_variable');

        if(!value || value === '') {
            return '';
        } else if(value === '0variabil' || value === 'variabil') {
            cashback = cashbackVariableText;
        } else if(value.indexOf('variabil') > -1) {
            cashback = cashbackUpTo + ' ' + value.replace('variabil', '') + ' ' + cashbackVariable;
        } else if(value.indexOf('Lei') > -1 || value.indexOf('RON') > -1 || value.indexOf('USD') > -1 || value.indexOf('EUR') > -1) {
            cashback = value + ' ' + cashbackText;
        } else if(value.indexOf('%') > -1) {
            cashback = value + ' ' + cashbackText;
        } else {
            cashback = value + ' ' + defaultCurency + ' ' + cashbackText;
        }

        return this.replaceDecimalPoint(cashback);
    }

    public trans(key: string): string {
        return this.translateService.instant(key);
    }

    public setApiErrorsToForm(errors: ErrorApi[], f: FormControls,  consoleExceptions: boolean = true): void {
        if(errors && errors.length && f) {
            errors.forEach((err: ErrorApi) => {
                if(err.field === 'exception') {
                    consoleExceptions && console.log(err.message);
                } else if(typeof f[ err.field ] !== 'undefined') {
                    f[ err.field ].setErrors({ custom: true });

                    const msg = err.message.reduce((prev, current) => prev = prev + ' <br> ' + current);

                    Object.defineProperty(f[ err.field ], 'msg', {
                        value: msg,
                        writable: true
                    });
                }
            });
        }
    }

    public showOtherErrors(errors: ErrorApi[], f: FormControls, consoleExceptions: boolean = true, showField: boolean = false): void {

        if(errors && errors.length && f) {

            const otherErrors: ErrorApi[] = [];

            errors.forEach((err: ErrorApi) => {
                if(err.field === 'exception') {
                    consoleExceptions && console.log(err.message);
                } else if(typeof f[ err.field ] === 'undefined') {
                    otherErrors.push(err);
                }
            });

            if(otherErrors.length > 0) {
                const errorMessage = this.helper.formatError(otherErrors, showField);
                this.alertService.error(errorMessage, false, false);
            }
        }
    }

    public sendIframeReload(name?: string) {
        this.globalEventsService.customSubject.next('iframe-reload');
    }

    public handleStopLink(event: CustomClick): boolean {

        if(!event.target) {
            return false;
        }

        if(event.target.className && (
            event.target.className === 'stop-link' ||
            (event.target.className.indexOf && event.target.className.indexOf('stop-link') > -1)
        )) {

            if(!event.target.href) {
                return false;
            }

            event.preventDefault();

            if(event.target?.href?.indexOf(window.location.origin) > -1) {
                const index = event.target.baseURI.length - 1;
                const url = event.target.href.slice(index);
                this.handleHref(url);
                return true;
            }

            if(event.target?.indexOf && (event.target.indexOf('%7BUSERID%7D') > -1 || event.target.indexOf('{USERID}'))) {
                const url: string = decodeURI(event.target);

                if(!this.authenticationService.loggedIn) {
                    this.router.navigate(this.getDefaultLogin(), {
                        queryParams: {
                            cashbackLink: url,
                            returnUrl: this.router.url,
                        }
                    });
                    return true;
                }

                this.handleCashbackHref(url);
                return true;
            }

            window.open(event.target.href, '_blank');
            return true;

        } else if(event.target.nodeName === 'IMG') {

            if(!event.path) {
                return false;
            }

            const aRef = event.path[1];

            if(aRef.className === 'stop-link' || aRef.className.indexOf('stop-link') > -1) {

                if(!aRef.href) {
                    return false;
                }

                event.preventDefault();

                if(aRef.href.indexOf(window.location.origin) > -1) {
                    const index = aRef.baseURI.length - 1;
                    const url = aRef.href.slice(index);
                    this.handleHref(url);
                    return true;
                }

                if(aRef.href.indexOf('%7BUSERID%7D') > -1 || aRef.href.indexOf('{USERID}')) {
                    let url: string = decodeURI(aRef.href);

                    if(!this.authenticationService.loggedIn) {
                        this.router.navigate(this.getDefaultLogin(), {
                            queryParams: {
                                cashbackLink: url,
                                returnUrl: this.router.url,
                            }
                        });
                        return true;
                    }

                    this.handleCashbackHref(url);
                    return true;
                }

                window.open(aRef.href, '_blank');
                return true;
            }
        }
        return false;
    }

    public handleCashbackLink(event: CustomClick): boolean {

        if(!event.target || !event.target.href) {
            return false;
        }

        if(event.target.className && (
            event.target.className === 'cashback-link' ||
            (event.target.className.indexOf && event.target.className.indexOf('cashback-link') > -1)
        )) {
            let url: string = decodeURI(event.target.href);
            environment.debug && console.log(url);

            event.preventDefault();
            event.stopPropagation();

            if(!this.authenticationService.loggedIn) {
                this.router.navigate(this.getDefaultLogin(), {
                    queryParams: {
                        cashbackLink: url,
                        returnUrl: this.router.url,
                    }
                });
                return true;
            }

            this.handleCashbackHref(url);

            return true;
        }

        return false;
    }

    private handleHref(url: string) {
        const path = this.helper.getPath(url);
        const queryParams = this.helper.getQueryParams(url);

        environment.debug && console.log(url, path, queryParams);

        this.router.navigate([ path ], {
            queryParams: {
                ...queryParams,
                returnUrl:  this.router.url
            }
        });
    }

    public handleCashbackHref(url: string): void {

        url = this.helper.userId(url, this.authenticationService.getUserId().toString());
        environment.debug && console.log(url);

        if(!this.extensionHelperService.isExtension()) {
            window.open(url, '_blank');
            return;
        }

        this.extensionHelperService.goCashbackLink(url)
            .then(() => {})
            .catch(() => {
                window.open(url, '_blank');
            });
    }

    public async generateLink(type: ClickGenType, id: number): Promise<string> {

        return new Promise((resolve, reject) => {

            const postData = {
                type: type,
                id: id
            };

            this.clicksService.generateLink(postData).subscribe((response: ResponseApi<string | ErrorApi[]>) => {
                if(response && response.statusCode === 200) {
                    const data = <string>response.data;
                    resolve(data);
                    return;
                }
                reject();
            },
            (error: string) => {
                console.log(error);
                reject();
            });
        });
    }

    public generateRefLink(type: 'retailer' | 'coupon', id: number, referralLink: Record<number, string>): Promise<string | void> {
        return new Promise((resolve, reject) => {

            if(referralLink[id]) {
                this.helper.copyCode(referralLink[id]);
                resolve();
                return;
            }

            this.generateLink(type, id)
                .then((url: string) => {
                    referralLink[id] = url;
                    this.helper.copyCode(url);
                    resolve(url);
                })
                .catch(() => { resolve() });
        })
    }

    public checkOnline(): boolean {

        const isOnline = window?.navigator?.onLine ?? false;
        const settings = this.settingsProviderService.getSettings();
        const isLive = this.settingsProviderService.getSettingBool('is_live');
        const routes = this.routingProviderService.getRoutes();

        if(isOnline && (!settings || !isLive || !routes)) {
            return false;
        }

        return true;
    }

    public getLoginSource() {
        return this.mobileHelperService.isMobile() ? LoginSource.MOBILE : LoginSource.SITE;
    }
}
