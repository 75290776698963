import { UserGroupId } from './user-group-id';
import { UserGroupName } from './user-group-name';

export const UserGroup = {

    getName: (id: number): UserGroupName | undefined => {
        switch (id)
        {
            case UserGroupId.ADMIN:
                return UserGroupName.ADMIN;

            case UserGroupId.MARKETING:
                return UserGroupName.MARKETING;

            case UserGroupId.USER:
                return UserGroupName.USER;

            case UserGroupId.GUEST:
                return UserGroupName.GUEST;

            default:
                return undefined;
        }
    },
    getId: (name: string): UserGroupId => {
        switch (name)
        {
            case UserGroupName.ADMIN:
                return UserGroupId.ADMIN;

            case UserGroupName.MARKETING:
                return UserGroupId.MARKETING;

            case UserGroupName.USER:
                return UserGroupId.USER;

            case UserGroupName.GUEST:
                return UserGroupId.GUEST;

            default:
                return 0;
        }
    },

    getGroups: (): UserGroupName[] => {
        return [
            UserGroupName.GUEST,
            UserGroupName.USER,
            UserGroupName.MARKETING,
            UserGroupName.ADMIN
        ];
    },
    getGroupIds: (): UserGroupId[] => {
        return [
            UserGroupId.GUEST,
            UserGroupId.USER,
            UserGroupId.MARKETING,
            UserGroupId.ADMIN
        ];
    },

    getUserGroupNames: (userGroup: UserGroupName): UserGroupName[] => {
        const groupId = UserGroup.getId(userGroup);

        const groupsIds = UserGroup.getGroupIds().filter(x => x <= groupId);

        if(groupsIds && groupsIds.length) {
            return groupsIds.map(gId => UserGroup.getName(gId))
        }

        return [UserGroupName.GUEST];
    }

}