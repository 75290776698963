<ng-template [ngIf]="loading || error" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
    </div>
</ng-template>
<ng-template #loaded>
    <div class="container">
        <div>
            <h2 class="title-head">
                {{ article.news_title }}
                <div class="new-btn-w" *ngIf="isMarketing()">
                    <button mat-fab color="primary" [matTooltip]="trans('article.post_social')" matTooltipPosition="left"
                        [hidden]="loading" (click)="postSocial(article.news_id)">
                        {{ 'article.post_social_short' | translate }}
                    </button>
                </div>
            </h2>
            <div class="line-full"></div>
        </div>

        <mat-card class="article">
            <mat-card-content>
                <div class="date-wrap">
                    <mat-icon>calendar_today</mat-icon>
                    <div class="date">{{ article.added | dateFormat: 'DD.MM.YYYY' }}</div>
                </div>
                <div class="content" [innerHtml]="article.news_description | userId | safeHtml"></div>

                <div class="share">
                    <div class="addthis_inline_share_toolbox"></div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>