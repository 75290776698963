import { Injectable } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AlertModel } from '../models/general/alert-model';

@Injectable()
export class AlertService {
    private subject = new Subject<AlertModel>();
    private keepAfterNavigationChange = false;
    private global = false;

    constructor(private router: Router) {
        // clear alert message on route change
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if (!this.keepAfterNavigationChange) {
                    this.reset(this.global);
                }
            }
        });
    }

    success(message: string[], global = false, timeout = false, keepAfterNavigationChange = false) {
        this.custom(message, 'success', global, timeout, keepAfterNavigationChange);
    }

    error(message: string[], global = false, timeout = false, keepAfterNavigationChange = false) {
        this.custom(message, 'danger', global, timeout, keepAfterNavigationChange);
    }

    warning(message: string[], global = false, timeout = false, keepAfterNavigationChange = false) {
        this.custom(message, 'warning', global, timeout, keepAfterNavigationChange);
    }

    custom(message: string[], type = 'warning', global = false, timeout = false, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.global = global;
        this.subject.next({ type, text: message, global, timeout });
    }

    reset(global = false) {
        this.subject.next({ type: 'reset', text: null, global, timeout: false });
    }

    getMessage(): Observable<AlertModel> {
        return this.subject.asObservable();
    }
}
