<div class="container-fluid">

    <h1 class="title-head">
        <div>
            {{ (admin ? 'messages.admin.head_title': 'messages.head_title') | translate }}
            <div class="new-btn-w">
                <button mat-fab color="primary" [hidden]="loading" (click)="newMessage()" [matTooltip]="trans('messages.new_msg')"
                    matTooltipPosition="left"> {{'messages.new' | translate }} </button>
            </div>
        </div>
        <div class="line-full"></div>
    </h1>

    <ngb-alert [dismissible]="true" *ngIf="!closed" (close)="closed=true">
        <span [innerHtml]="trans('messages.missing_cashback_alert')"></span>
    </ngb-alert>

    <!-- filter zone -->
    <mat-card class="filter-wrapper">
        <mat-card-content class="filter-content">
            <div class="left-filter">

                <div class="field-select">
                    <div class="sort-icon">
                        <mat-icon>sync_alt</mat-icon>
                    </div>
                    <mat-form-field>
                        <mat-select [placeholder]="trans('messages.types')" [value]="filters.type"
                        (selectionChange)="changeOption($event, 'type')" [disabled]="loading">
                            <mat-option *ngFor="let option of types; let i = index" [value]="option" >
                                <span> {{ format(option, 'type') }} </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="field-select">
                    <div class="sort-icon">
                        <mat-icon>mark_email_unread</mat-icon>
                    </div>
                    <mat-form-field>
                        <mat-select [placeholder]="trans('messages.extended_statuses')" [value]="filters.status"
                        (selectionChange)="changeOption($event, 'status')" [disabled]="loading">
                            <mat-option *ngFor="let option of extendedStatuses" [value]="option" >
                                <span> {{ format(option, 'extended_status') }} </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="left-filter">

                <div class="field-select">
                    <div class="sort-icon">
                        <mat-icon>search</mat-icon>
                    </div>
                    <mat-form-field>
                        <mat-select [placeholder]="trans('messages.search_by')"
                        [value]="filters.searchBy" (selectionChange)="changeOption($event, 'searchBy')" [disabled]="loading">
                            <mat-option *ngFor="let option of searchBy" [value]="option">
                                {{ format(option, 'searchBy') }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="field-search">
                    <div class="sort-icon">
                        <mat-icon>search</mat-icon>
                    </div>
                    <mat-form-field class="search-string">
                        <input matInput [formControl]="searchField" (keyup.enter)="searchEnter($event)"
                        [placeholder]="trans('messages.filter')" [readonly]="loading">
                    </mat-form-field>
                </div>

            </div>

            <span class="nav-spacer"></span>

            <div class="field-checkbox">
                <mat-checkbox
                    [color]="'primary'"
                    [(ngModel)]="filters.unreadFirst"
                    (change)="onCheckboxChange()"
                    [labelPosition]="'after'"
                    [disabled]="loading">
                        {{ 'messages.unread_first_checkbox' | translate }}
                </mat-checkbox>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="loading-shade-relative" *ngIf="loading || error || messages.data.length === 0">
        <app-loading *ngIf="loading"></app-loading>
        <div class="text-center" *ngIf="error">
            <h3> {{ 'messages.error' | translate }} </h3>
        </div>
        <div class="text-center" *ngIf="!error && !loading && messages.data.length === 0">
            <h3>{{ 'messages.empty' | translate }} </h3>
        </div>
    </div>

    <div class="my-messages mat-elevation-z8" [hidden]="loading || error || messages.data.length === 0">
        <div class="table-container">
            <table mat-table [dataSource]="messages" class="example-table" matSort matSortDisableClear>

                <ng-container matColumnDef="dir">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="header-container">{{ 'messages.dir' | translate }}</div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon [matTooltip]="tooltipIcon(row)" matTooltipPosition="right">
                            {{ arrowType(row) }}
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="header-container">{{ 'messages.from' | translate }}</div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-template [ngIf]="row.user" [ngIfElse]="user">
                            <a [href]="goToUserUrl(row.user)" (click)="goToUser($event, row.user)">
                                {{ row.user.fname ? row.user.fname + ' ' + row.user.lname : row.user.username }}
                            </a>
                        </ng-template>
                        <ng-template #user>-</ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'messages.subject' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                        <a (click)="goView(row.message_id, (checkNew(row) ? 1 : 0) )">{{ row.subject }} </a>
                        <mat-icon *ngIf="row.hasAttachments" class="has-attachments" [title]="'messages.has_attachments' | translate">attachment</mat-icon>
                    </td>
                </ng-container>

                <!-- <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'messages.created' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{row.created | dateFormat: 'DD.MM.YYYY HH:mm'}}</td>
                </ng-container> -->

                <ng-container matColumnDef="last_reply">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'messages.last_reply' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{ (row.last_reply ? row.last_reply : row.created ) | dateFormat: 'DD.MM.YYYY HH:mm' }}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'messages.status' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{ format(row.status, 'status') }}</td>
                </ng-container>

                <ng-container matColumnDef="answers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <div class="header-container">R</div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-template [ngIf]="row.answer_nr > 0" [ngIfElse]="ans1">
                            <a (click)="goView(row.message_id, 0)">{{ row.answer_nr }} </a>
                        </ng-template>
                        <ng-template #ans1>
                            {{ row.answer_nr }}
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef disableClear>
                        <div class="header-container">#</div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="btn-actions">
                            <a (click)="goView(row.message_id, 1)" class="go">
                                <mat-icon>pageview</mat-icon>
                            </a>
                            <a [ngClass]="{'disabled': checkClosedStatus(row)}" (click)="goView(row.message_id, 2)" class="reply">
                                <mat-icon>reply</mat-icon>
                            </a>
                            <a *ngIf="admin" [ngClass]="{'disabled': checkClosedStatus(row)}" (click)="closeMessage(row)" class="close-msg"
                               [matTooltip]="trans('messages.close_msg_title')" matTooltipPosition="above">
                                <mat-icon>cancel</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'active': checkNew(row)}"></tr>
            </table>
        </div>
        <mat-paginator #matPaginator [length]="total" [pageSize]="filters.pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>

</div>