import { Component, OnInit, Inject, Injector, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from './../../base.component';
import { SubscriberData } from '../../../models/subscribe/subscriber-data';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { SubscriberMember } from '../../../models/subscribe/subscriber-member';
import { MailchimpConfig } from '../../../models/subscribe/mailchimp-config';

@Component({
    selector: 'app-subscribe-dialog',
    templateUrl: './subscribe-dialog.component.html',
    styleUrls: [ './subscribe-dialog.component.scss' ]
})
export class SubscribeDialogComponent extends BaseComponent implements OnInit {

    @ViewChild('mailchimpForm') mailchimpForm: ElementRef;
    @ViewChild('mailchimpIframe', { static: true }) mailchimpIframe: ElementRef;

    public mailchimpFormUrl: SafeResourceUrl;
    public mailchimpConfig: MailchimpConfig = {
        id: '',
        u: '',
        groupId: 15669,
        groupOptions: [
            { type: 'user1', value: 1 },
            { type: 'user2', value: 2 },
            { type: 'user3', value: 4 },
            { type: 'guest', value: 8 },
        ],
        gdpr: [169],
        subscribeUrl: '',
        unsubscribeUrl: ''
    };

    public subscriberData: SubscriberData;

    constructor(injector: Injector,
                @Inject(MAT_DIALOG_DATA) private data: SubscriberData,
                private dialogRef: MatDialogRef<SubscribeDialogComponent>,
                private sanitizer: DomSanitizer) {

        super(injector);
        this.subscriberData = data;

        this.mailchimpConfig = this.settingsProviderService.getSettingObj<MailchimpConfig>('mailchimp_config', true, this.mailchimpConfig);
        const url = this.mailchimpConfig[this.subscriberData.subscribe ? 'subscribeUrl' : 'unsubscribeUrl'];
        this.mailchimpFormUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(url);

    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.mailchimpForm.nativeElement.submit();
        }, 50);
    }

    public checkMemeber(type: SubscriberMember): boolean {
        return this.subscriberData.member == type;
    }
}
