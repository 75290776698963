import { Helper } from '../helpers/helper';
import { SocialService } from './social.service';
import { Injectable, Injector, NgZone } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { LoginForm } from '../models/user/public/login-form';
import { UserResponse } from '../models/user/private/user-response';
import { ResponseApi } from '../models/general/response-api';
import { AlertService } from './alert.service';
import { UserService } from './user.service';
import { FirstLoadService } from './first-load.service';
import { SocialAuthService, FacebookLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { SocialLoginForm } from '../models/social/social-login-form';
import { SocialRes } from '../models/social/social-res';
import { LoginResponse } from '../models/user/public/login-response';
import { SocialProviderFactory } from '../providers/social-provider-factory';
import { environment } from 'src/environments/environment';
import { MobileHelperService } from './mobile-helper.service';
import { ErrorApi } from '../models/general/error-api';
import { LocalStorageService } from './local-storage.service';
import { UserGroupName } from '../models/user/private/user-group-name';
import { UserGroup } from '../models/user/private/user-group';
import { UserGroupId } from '../models/user/private/user-group-id';
import { SettingsProviderService } from './settings-provider.service';
import { GlobalEventsService } from './global-events.service';
import { Source } from '../models/user/private/source';
import { LoginSource } from '../models/user/public/login-source';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public loggedIn = false;
    public isAdmin = false; // TODO remove
    private isInitialized = false;
    private oldVal = false;
    private socialAuthService: SocialAuthService;

    private role: UserGroupName;
    private roleId: number;

    private isSocialLoaded: boolean = false;
    private firstLoadSubscription: Subscription;

    constructor(private alertService: AlertService,
                private userService: UserService,
                private socialService: SocialService,
                private helper: Helper,
                private localStorageService: LocalStorageService,
                private globalEventsService: GlobalEventsService,
                private firstLoadService: FirstLoadService,
                private settingsProviderService: SettingsProviderService,
                private mobileHelperService: MobileHelperService,
                private injector: Injector,
                private ngZone: NgZone) {

        this.firstLoadService.addLoader('login');
    }

    public login(username: string, password: string): Observable<LoginResponse> {
        const login: LoginForm = {
            username,
            password,
            source: this.getLoginSource()
        };

        return this.userService.login(login).pipe(
            map((result: ResponseApi<UserResponse | ErrorApi[]>) => {
                return this.handleSuccessLoginResponse(result);
            }),
            catchError((error: string) => {
                return this.handleErrorLoginResponse(error);
            })
        );
    }

    public loginExternal(token: string): Observable<LoginResponse> {

        const userMoke: UserResponse = {
            id: 0,
            username: '',
            token,
            role: ''
        };
        this.localStorageService.setUser(userMoke);

        return this.userService.loginExternal(this.getLoginSource()).pipe(
            map((result: ResponseApi<UserResponse>) => {
                return this.handleSuccessLoginResponse(result);
            }),
            catchError((error: string) => {
                return this.handleErrorLoginResponse(error);
            })
        );
    }

    private handleSuccessLoginResponse(result: ResponseApi<UserResponse | ErrorApi[]>): LoginResponse {

        if(result && result.statusCode === 200) {
            const user = <UserResponse>result.data;
            this.setLoggedAfterLogin(user);

            this.globalEventsService.analytics.next({ type: 'login', userId: user.id });

            return <LoginResponse>{
                status: 'ok',
                message: ['ok']
            };
        }

        this.setNotLoggedAfterLogin();

        const errors = <ErrorApi[]>result.data;
        const errorMessage = this.helper.formatError(errors, false);

        return <LoginResponse>{
            status: 'message',
            message: errorMessage
        };
    }

    private handleErrorLoginResponse(error: string): Observable<LoginResponse> {
        console.log(error);
        const res: LoginResponse = {
            status: 'error',
            message: [ error ]
        };
        return of(res);
    }

    public logout(remove: boolean = false) {
        console.log('logout');
        if(remove) {
            if(this.getToken()) {
                this.userService.logout().subscribe(() => {}, () => {});
            }
            this.localStorageService.removeUser();
        }

        this.setNotLoggedAfterLogin();

        if(this.mobileHelperService.isMobile()) {
            this.mobileHelperService.logout();
        }
    }

    public isAuthenticated(): boolean | Promise<boolean> {

        if (this.isInitialized) {
            this.sendSubject();
            return this.loggedIn;
        }

        const userItem = this.localStorageService.getUser();

        if (
            userItem !== null &&
            typeof userItem.id !== 'undefined' && typeof userItem.token !== 'undefined'
        ) {
            return new Promise((resolve, reject) => {
                this.checkUser().subscribe(
                    (result: boolean) => {
                        environment.debug && console.log('success auth check!');
                        this.setLogged();
                        resolve(this.loggedIn);
                    },
                    (error: string) => {
                        console.log('error auth', error);
                        if(this.mobileHelperService.isMobile()) {
                            this.checkMobileLogin().then((result: boolean) => {
                                resolve(result);
                            });
                            return;
                        }

                        this.setNotLogged();
                        resolve(this.loggedIn);
                    }
                );
            });
        }

        if(this.mobileHelperService.isMobile()) {
            return this.checkMobileLogin();
        }

        this.setNotLogged();
        return this.loggedIn;
    }

    private setNotLogged() {
        this.loggedIn = false;
        this.setRole(UserGroupName.GUEST);
        // this.removeUser();
        this.sendSubject();
    }

    private setNotLoggedAfterLogin() {
        this.loggedIn = false;
        this.setRole(UserGroupName.GUEST);
        this.isInitialized = true;
        this.sendSubject();
    }

    private setLogged() {
        this.loggedIn = true;
        this.checkRole();
        this.sendSubject();
    }

    private setLoggedAfterLogin(user: UserResponse) {
        this.localStorageService.setUser(user);
        this.loggedIn = true;
        this.isInitialized = true;
        this.setRole(user.role);
        this.sendSubject();
    }

    private checkMobileLogin(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            this.mobileHelperService.checkLogin()
                .then((result: UserResponse) => {
                    console.log('success auth mobile!');
                    const user = <UserResponse>result;
                    this.localStorageService.setUser(user);
                    this.setLogged();
                    resolve(true);
                })
                .catch(() => {
                    this.setNotLogged();
                    resolve(false);
                });
        });
    }

    public checkUser(): Observable<boolean> {
        return this.userService.check(this.getLoginSource()).pipe(map(
            (result: ResponseApi<UserResponse>) => {
                if (result && result.statusCode === 200) {
                    this.localStorageService.setUser(result.data);
                    return true;
                }

                return false;
            }));
    }

    private sendSubject(): void {
        if(this.oldVal !== this.loggedIn || !this.isInitialized) {
            this.oldVal = this.loggedIn;

            if(!this.isInitialized) {
                this.isInitialized = true;
            }

            this.globalEventsService.logged.next({
                loggedIn: this.loggedIn,
                isInitialized: this.isInitialized,
                isAdmin: this.isAdmin,
                userId: this.getUserId()
            });

            this.firstLoadService.event.next('login');
        }
    }

    private setRole(role: string): void {

        if(this.loggedIn && role) {
            this.role = <UserGroupName>role;
            this.roleId = UserGroup.getId(role);
            this.isAdmin = this.roleId === UserGroupId.ADMIN;
            return;
        }

        this.role = UserGroupName.GUEST;
        this.roleId = UserGroupId.GUEST;
        this.isAdmin = false;
    }

    private checkRole(): void {
        const userItem = this.localStorageService.getUser();
        if(userItem && userItem.role) {
            this.setRole(userItem.role);
            return;
        }

        this.role = UserGroupName.GUEST;
        this.roleId = UserGroupId.GUEST;
    }

    public loginUser(user: UserResponse, source: Source): void {
        this.setLoggedAfterLogin(user);

        switch(source) {
            case 'change_password':
                break;
            case 'register':
            case 'activate':
                this.globalEventsService.analytics.next({ type: 'register', userId: user.id });
                break;
            case 'social':
                this.globalEventsService.analytics.next({ type: user.isNew ? 'register' : 'login', userId: user.id });
                break;
        }
    }

    public authState = () => this.socialAuthService.authState;

    public async mobileSocialSignin(socialPlatform: string, login: boolean = false) {

        const socialResponse = await this.mobileHelperService.socialSignIn(socialPlatform)

        environment.debug && console.log('mobile login data!');
        environment.debug && console.log(socialResponse);

        if (!socialResponse) {
            return <SocialRes> {
                status: 'message',
                data: [this.helper.trans('user.login.error_token_front')],
                socialPlatform
            };
        }

        if (socialResponse.status) {
            const authToken = socialResponse.authToken;

            return this.processSocialToken(socialPlatform, authToken, login);
        }

        let message: string = '';
        let type: string = '';

        switch (socialResponse.message) {
            case '':
            case 'SOCIAL_USER_CANCEL':
                type = 'abort';
                break;

            default:
                type = 'message'
                message = socialResponse.message;
                break;
        }

        return <SocialRes> {
            status: type,
            data: [message],
            socialPlatform
        };
    }

    public async socialSignIn(socialPlatform: string, isLogin: boolean = true, socialUser?: SocialUser): Promise<SocialRes> {

        let authToken: string | null = null;

        if (!this.isSocialLoaded) {
            await this.initializeSocialLogin();
        }

        try {
            switch (socialPlatform) {
                case 'facebook':
                    const userData = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
                    authToken = userData?.authToken;
                    break;

                case 'google':
                    authToken = socialUser?.idToken;
                    break;
            }

        } catch (error) {

            console.log('social login error:', error?.error || error);

            let message: string = '';
            let type: string = '';

            switch (error) {
                case 'popup_blocked_by_browser':
                    type = 'message';
                    message = this.helper.trans('user.login.popup_blocked_by_browser');
                    break;

                case 'popup_closed_by_user':
                    type = 'abort';
                    break;

                case 'timeout':
                    type = 'message';
                    message = this.helper.trans('user.login.timeout');
                    break;

                case 'empty_response':
                    type = 'message';
                    message = this.helper.trans('user.login.empty');
                    break;

                default:
                    type = 'abort';
                    message = this.helper.trans('user.login.generic_social_error');
                    break;
            }

            return <SocialRes>{
                status: type,
                data: [message],
                socialPlatform
            };
        }

        return this.processSocialToken(socialPlatform, authToken, isLogin);
    }

    private async processSocialToken(socialPlatform: string, authToken: string, isLogin: boolean): Promise<SocialRes> {

        if (!authToken) {
            return <SocialRes>{
                status: 'message',
                data: [this.helper.trans('user.login.error_token_front')],
                socialPlatform
            };
        }

        if (!isLogin) {
            // when link account
            return <SocialRes>{
                status: 'ok',
                data: [authToken],
                socialPlatform
            };
        }

        // when login
        const loginData: SocialLoginForm = {
            authToken,
            socialPlatform,
            ref_id: this.localStorageService.getReferralIdFromStorage(),
            source: this.getLoginSource()
        };

        const res: SocialRes = await this.socialService.login(loginData).pipe(
            map((result: ResponseApi<UserResponse | ErrorApi[]>) => {
                if (result && result.statusCode === 200) {
                    const user = <UserResponse>result.data;
                    this.loginUser(user, 'social')

                    return <SocialRes>{
                        status: 'ok',
                        data: ['ok'],
                        socialPlatform
                    };
                }

                this.setNotLoggedAfterLogin();

                const errors = <ErrorApi[]>result.data;
                const errorMessage = this.helper.formatError(errors, false);
                this.alertService.error(errorMessage);

                return <SocialRes>{
                    status: 'message',
                    data: errorMessage,
                    socialPlatform
                };

            }),
            catchError((error: string) => {
                console.log(error);
                return of(<SocialRes>{
                    status: 'error',
                    data: [error],
                    socialPlatform
                });
            })
        ).toPromise();

        return res;
    }

    public checkPermission(permission: string): boolean {
        const permissionId = UserGroup.getId(permission);
        return this.roleId >= permissionId;
    }

    public checkIfSamePermission(permission: string): boolean {
        const permissionId = UserGroup.getId(permission);
        return this.roleId === permissionId;
    }

    public getUsername(): string {
        return this.localStorageService.getUsername();
    }

    public getUserId(): number {
        return this.localStorageService.getUserId();
    }

    public getToken(): string {
        return this.localStorageService.getToken();
    }

    public getUserGroupNames(): UserGroupName[] {
        return UserGroup.getUserGroupNames(this.role)
    }

    public getRole(): string {
        return this.role;
    }

    public initializeSocialLogin() {
        return new Promise<void>((resolve, reject) => {

            this.globalEventsService.firstLoaded.subscribe(() => {

                this.firstLoadSubscription?.unsubscribe();

                const socialConfig = SocialProviderFactory(this.settingsProviderService, this.mobileHelperService);

                this.socialAuthService = new SocialAuthService(socialConfig, this.ngZone, this.injector);

                this.socialAuthService.initState.subscribe((success) => {
                    environment.debug && console.log('social login loaded', success);
                    this.isSocialLoaded = success;
                    resolve();

                }, (error: any) => {
                    console.log(error);
                    reject();
                });
            });
        });
    }

    private getLoginSource() {
        return this.mobileHelperService.isMobile() ? LoginSource.MOBILE : LoginSource.SITE;
    }
}
