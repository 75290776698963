import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { SocialForm } from '../models/social/social-form';
import { SocialLoginForm } from '../models/social/social-login-form';
import { UserResponse } from '../models/user/private/user-response';
import { ErrorApi } from '../models/general/error-api';
import { SocialLinkData } from '../models/social/social-link-data';
import { MobileSocialCheck } from '../models/mobile/mobile-social-check';
import { Helper } from '../helpers/helper';

@Injectable()
export class SocialService {
    constructor(private http: HttpClient, private helper: Helper) {
    }

    create(social: SocialForm) {
        return this.http.post<ResponseApi<SocialLinkData | ErrorApi[]>>(`${environment.apiUrl}/v1/social`, social);
    }

    delete(socialPlatform: string) {
        const data =  {
            socialPlatform: socialPlatform
        };
        return this.http.post<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/social/delete`, data);
    }

    get() {
        return this.http.get<ResponseApi<SocialLinkData | ErrorApi[]>>(`${environment.apiUrl}/v1/social`);
    }

    login(login: SocialLoginForm) {
        this.helper.appendCurrentLocalUrl(login);

        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/social/login`, login);
    }

    check(data: MobileSocialCheck) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/social/check`, data);
    }
}
