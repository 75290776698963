import { BaseComponent } from '../shared/components/base.component';
import { Component, OnInit, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: [ './maintenance.component.scss' ]
})
export class MaintenanceComponent extends BaseComponent implements OnInit {

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        const isLive = this.settingsProviderService.getSettingBool('is_live');
        const settings = this.settingsProviderService.getSettings();
        if(settings && isLive) {
            this.router.navigate(this.helperService.getDefaultHome());
        }
    }

    onClick() {
        if(this.mobileHelperService.isMobile()) {
            this.mobileHelperService.href('/');
            environment.debug && console.log('Mobile reload!');
        } else {
            location.href = '/';
        }
    }
}
