import { BaseComponent } from '../shared/components/base.component';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { LoginResponse } from '../shared/models/user/public/login-response';
import { Subscription } from 'rxjs';
import { GlobalEventsService } from '../shared/services/global-events.service';
import { Logged } from '../shared/models/general/logged';

@Component({
    selector: 'app-login-external',
    templateUrl: './login-external.component.html',
    styleUrls: [ './login-external.component.scss' ]
})
export class LoginExternalComponent extends BaseComponent implements OnInit, OnDestroy {

    private loggedSubscription: Subscription;

    constructor(injector: Injector,
                private authenticationService: AuthenticationService,
                private globalEventsService: GlobalEventsService) {

        super(injector);

        const queryParams = this.route.snapshot.queryParams;

        if(queryParams && queryParams.token) {
            const token = queryParams.token;
            const returnUrl = queryParams.returnUrl;

            this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
                if(result.isInitialized) {
                    this.handle(result.loggedIn, token, returnUrl);
                }
            });
            return;
        }

        this.navigate(this.helperService.getDefaultHome());
    }

    ngOnInit() {}

    ngOnDestroy() {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
    }

    private navigate(url: string[], returnUrl: string = '') {
        if(returnUrl) {
            this.router.navigate(url, {
                replaceUrl: true,
                queryParams: {
                    returnUrl: returnUrl
                }
            });
            return;
        }

        this.router.navigate(url, {
            replaceUrl: true
        });
    }

    private handle(loggedIn: boolean, token: string, returnUrl?: string) {

        const returnPath = returnUrl ? [returnUrl] : this.helperService.getDefaultHome();

        if(loggedIn) {
            this.navigate(returnPath);
            return;
        }

        this.authenticationService.loginExternal(token).subscribe(
            (response: LoginResponse) => {
                if(response && response.status === 'ok') {
                    this.navigate(returnPath);
                    return;
                }

                this.navigate(this.helperService.getDefaultLogin(), returnPath[0]);
            }
        );
    }
}
