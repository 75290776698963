<div class="mailchimp">
    <div class="mailchimp-title" >{{ 'footer.subscribe_title' | translate }}</div>
    <div class="mailchimp-descr">{{ 'footer.subscribe_desc' | translate }}</div>
    <div class="mailchimp-user-descr" *ngIf="mode === 'user'">
        <ngb-alert [dismissible]="false">{{ 'footer.communication_user_descr' | translate }}</ngb-alert>
    </div>

    <div class="mailchimp-form">
        <input type="text" id="email" [formControl]="email" class="form-control form-item"
            [ngClass]="{ 'is-invalid': check() }" [placeholder]="'footer.subscribe_email' | translate" />

        <button mat-raised-button [color]="subscribe ? 'primary' : 'warn'" class="form-item" (click)="onClick()"
            [disabled]="check()"> {{ (subscribe ? 'footer.subscribe' : 'footer.unsubscribe') | translate }} </button>
    </div>
</div>