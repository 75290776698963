import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseApi } from '../models/general/response-api';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { InvitePost } from '../models/invite/invite-post';
import { Helper } from '../helpers/helper';
import { InvitesStatus } from '../models/invite/invites-status';
import { ErrorApi } from '../models/general/error-api';
import { Referrals } from '../models/invite/referrals';

@Injectable()
export class InviteService {
    constructor(private http: HttpClient, private helper: Helper) {
    }

    getReferrals(data: SortFilters) {
        return this.http.post<ResponseApiList<Referrals | ErrorApi[]>>(`${environment.apiUrl}/v1/invite/referrals`, data);
    }

    invite(data: InvitePost) {

        this.helper.appendCurrentLocalUrl(data);

        return this.http.post<ResponseApi<InvitesStatus[] | ErrorApi[]>>(`${environment.apiUrl}/v1/invite`, data);
    }
}
