<div class="app-deeplink">
    <ng-container *ngIf="!loading && !error && valid; then contentTemplate; else errorTemplate"></ng-container>

    <ng-template #errorTemplate>
        <div class="loading-shade">
            <app-loading *ngIf="loading"></app-loading>
            <div class="error-div" *ngIf="error">
                <h3> {{ 'general.error_occured' | translate }} </h3>
            </div>
            <div class="error-div" *ngIf="!valid">
                <h3> {{ 'deeplink.invalid_expired_link' | translate }} </h3>
            </div>
        </div>
    </ng-template>

    <ng-template #contentTemplate>
        <div class="content-wrapper">
            <mat-card class="content-card">
                <mat-card-title>
                    <a class="brand-logo" [routerLink]="helperService.getDefaultHome()">
                        <img src="./assets/images/logo/logo.png" [alt]="" class="logo">
                    </a>
                </mat-card-title>
                <mat-card-content class="content">

                    <h2 class="line title">{{ 'deeplink.head_title' | translate }}</h2>

                    <ng-container *ngIf="loggedIn || userId > 0; then loggedTemplate; else notLoggedTemplate"></ng-container>

                    <ng-template #notLoggedTemplate>
                        <div class="line go-register">
                            <button mat-raised-button color="primary" class="btn-lg" (click)="onRegister()">
                                {{ 'deeplink.register' | translate }}
                            </button>
                        </div>

                        <div class="line have-account">
                            {{ 'deeplink.have_account' | translate }}
                            <a (click)="onLogin()">{{ 'deeplink.login' | translate }}</a>
                        </div>

                        <div class="line no-cashback">
                            {{ 'deeplink.no_cashback' | translate }}
                            <a (click)="goNoCashback()">{{ 'deeplink.go_shopping_no_cashback' | translate }}</a>
                        </div>
                    </ng-template>

                    <ng-template #loggedTemplate>
                        <h5 class="line redirecting">
                            {{ 'deeplink.redirecting_to_shopping' | translate }}
                        </h5>
                    </ng-template>
                </mat-card-content>
            </mat-card>
        </div>
    </ng-template>
</div>