import { Component, OnInit, AfterViewInit, OnDestroy, Injector, ViewChild, ElementRef } from '@angular/core';
import { RetailerService } from 'src/app/shared/services/retailer.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AddThisService } from 'src/app/shared/services/add-this.service';
import { Subscription } from 'rxjs';
import { FavoriteService } from 'src/app/shared/services/favorite.service';
import { Favorite } from 'src/app/shared/models/favorite/favorite';
import { RetailerView } from 'src/app/shared/models/retailer/retailer-view';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { GlobalEventsService } from '../../shared/services/global-events.service';
import { Logged } from '../../shared/models/general/logged';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
    selector: 'app-retailer',
    templateUrl: './retailer.component.html',
    styleUrls: [ './retailer.component.scss' ]
})
export class RetailerComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('referralLinkRef', { static: false }) referralLinkRef: ElementRef<HTMLInputElement>;
    id = 0;
    errorText1 = false;
    errorText2 = false;
    errorText3 = false;
    retailer: RetailerView;
    currency = '';
    public loggedIn: boolean;
    favorite = false;
    favoriteId: number;
    disableFavorite = false;
    showRetailerStatistics = false;
    showCashbackCalculator = false;

    closed: boolean = false;
    public referralLink: Record<number, string> = {};
    private loggedSubscription: Subscription;

    constructor(injector: Injector,
                private retailerService: RetailerService,
                private authenticationService: AuthenticationService,
                private addThisService: AddThisService,
                private favoriteService: FavoriteService,
                private alertService: AlertService,
                private globalEventsService: GlobalEventsService,
                private analyticsService: AnalyticsService) {

        super(injector);

        this.showRetailerStatistics = this.settingsProviderService.getSettingBool('show_statistics');
        this.showCashbackCalculator = this.settingsProviderService.getSettingBool('show_cashback_calculator');
        this.currency = this.settingsProviderService.getSettingString('website_currency');
    }

    ngOnInit() {
        this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
            this.loggedIn = result.loggedIn;
        });
    }

    ngOnDestroy() {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        this.addThisService.unregister();
    }

    ngAfterViewInit() {
        this.id = +this.route.snapshot.paramMap.get('id');

        this.route.url.subscribe(() => {
            this.id = +this.route.snapshot.paramMap.get('id');
            this.getRetailer();
        });
    }

    getRetailer() {
        this.loading = true;

        if(this.id <= 0) {
            this.errorText3 = true;
            this.finishLoading(false);
            return;
        }

        this.retailerService.getActiveById(this.id).subscribe(
            (response: ResponseApi<RetailerView | ErrorApi[]>) => {

                if(response && response.statusCode === 200 && response.data) {
                    this.retailer = <RetailerView>response.data;

                    const titleMeta = this.retailer.title + ' | ' + this.trans('retailers.head_title');

                    this.helperService.setTitle(titleMeta);
                    this.helperService.handleMeta(this.retailer.meta_description, this.retailer.meta_keywords, this.retailer.image);

                    this.analyticsService.pageView(this.router.url, titleMeta);

                    this.finishLoading(true);
                } else {
                    this.errorText1 = true;
                    this.finishLoading(false);
                }
            },
            (errors: string) => {
                console.log(errors);
                this.errorText2 = true;
                this.finishLoading(false);
            }
        );
    }

    toggleFavorite() {
        if(!this.disableFavorite) {
            this.disableFavorite = true;
            if(this.favorite && this.favoriteId) {
                this.favoriteService.delete(this.favoriteId).subscribe(
                    (response: ResponseApi<boolean | ErrorApi[]>) => {
                        if(response && response.statusCode === 200) {
                            this.favorite = false;
                            this.favoriteId = null;
                            this.alertService.success([this.trans('retailers.favorite_removed_success')], false, true, false);
                        } else {
                            this.alertService.error([this.trans('retailers.favorite_error')], false, true, false);
                        }
                        this.disableFavorite = false;
                    }, (error: string) => {
                        console.log(error);
                        this.alertService.error([this.trans('retailers.favorite_error')], false, true, false);
                        this.disableFavorite = false;
                    });
            } else {
                this.favoriteService.create(this.id).subscribe(
                    (response: ResponseApi<Favorite | ErrorApi[]>) => {
                        if(response && response.statusCode === 200) {
                            const favoriteData = <Favorite>response.data;
                            this.favoriteId = favoriteData.favorite_id;
                            this.favorite = true;
                            this.alertService.success([this.trans('retailers.favorite_added_success')], false, true, false);
                        } else {
                            this.alertService.error([this.trans('retailers.favorite_error')], false, true, false);
                        }
                        this.disableFavorite = false;
                    }, (error: string) => {
                        console.log(error);
                        this.disableFavorite = false;
                        this.alertService.error([this.trans('retailers.favorite_error')], false, true, false);
                    });
            }
        }
    }

    checkFavorite() {
        if(!this.loggedIn) {
            this.loading = false;
            return;
        }

        this.favoriteService.check(this.id).subscribe(
            (response: ResponseApi<Favorite | boolean>) => {
                if(response && response.statusCode === 200 && response.data) {
                    const favoriteData = <Favorite>response.data;
                    this.favoriteId = favoriteData.favorite_id;
                    this.favorite = true;
                } else {
                    this.favorite = false;
                }
                this.loading = false;
            }, (error: string) => {
                console.log(error);
                this.favorite = false;
                this.loading = false;
            });
    }

    calc(value: number) {

        const cashback = this.retailer.cashback;

        if(!cashback || cashback === '' || cashback == null) {
            return 0;
        } else if(cashback.indexOf('-') > -1) {
            // range
            return '-';
        } else {
            // single value
            return this.calcCash(value, cashback);
        }
    }

    calcCash(value: number, cashback: string) {

        if(cashback.indexOf('%') > -1) {
            cashback = cashback.replace('%', '');
            const cashbackTemp = parseFloat(cashback);
            if(!isNaN(cashbackTemp)) {
                return (+((value * (cashbackTemp / 100)).toFixed(2))).toString() + ' ' + this.currency;
            } else {
                return '-';
            }
        } else if(cashback.indexOf('variabil') > -1) {
            return this.trans('retailers.variable');
        } else if(cashback.indexOf('Lei') > -1) {
            return cashback;
        } else if(cashback.indexOf('USD') > -1) {
            return cashback;
        } else if(cashback.indexOf('EUR') > -1) {
            return cashback;
        } else {
            return '-';
        }
    }

    private finishLoading(success: boolean) {

        this.error = !success;
        this.loading = false;

        if(success) {

            this.helperService.handleGoShoppingReturn(this.route.snapshot.queryParams);

            setTimeout(() => {
                this.addThisService.register().subscribe(() => {});
            }, 50);

            this.checkFavorite();
        }
    }

    public postSocial(): void {
        this.router.navigate(this.helperService.getDefaultSocialPost(), {
            queryParams: {
                ...this.route.snapshot.queryParams,
                selected: 'retailer',
                articleId: this.id,
                articleTitle: this.retailer.title,
                returnUrl: this.router.url
            }
        })
    }

    public isMarketing(): boolean {
        return this.authenticationService.checkPermission('marketing');
    }
    public isAdmin(): boolean {
        return this.authenticationService.checkPermission('admin');
    }

    public goToAdminRetailer(event: Event): void {
        event.preventDefault();
        this.helperService.goToAdminRetailer(this.id);
    }

    public goToAdminRetailerUrl(): string {
        return this.helperService.goToAdminRetailerUrl(this.id);
    }

    public copyLink() {
        this.helper.focusAndCopy(this.referralLinkRef);
    }

    public generateRefLink() {
        if(!this.loggedIn) {
            return;
        }

        this.helperService.generateRefLink('retailer', this.id, this.referralLink).then((url) => {
            if(!url) {
                return;
            }
            this.addThisService.update(url);
        });
    }
}
