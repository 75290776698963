import { BannerOrder } from './../models/banner/banner-order';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Banner } from '../models/banner/banner';
import { SortFilters } from '../models/filters/sort-filters';
import { map } from 'rxjs/operators';
import { UploadStatus, UploadStatusResponse } from '../models/message/upload-status';
import { ErrorApi } from '../models/general/error-api';
import { BannerShort } from '../models/banner/banner-short';
import { BannerImageFile } from '../models/banner/banner-image-file';
import { MediaSuccessResponse } from '../models/media-hosting/media-success-response';
import { MediaErrorResponse } from '../models/media-hosting/media-error-response';
import { BannerCreate } from '../models/banner/banner-create';

@Injectable()
export class BannerService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Banner>>(`${environment.apiUrl}/v1/banner/all`);
    }

    getList(data: SortFilters) {
        return this.http.post<ResponseApiList<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/list`, data);
    }

    getActive() {
        return this.http.get<ResponseApiList<BannerShort>>(`${environment.apiUrl}/v1/banner/active`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + id);
    }

    create(banner: BannerCreate) {
        return this.http.post<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner`, banner);
    }

    update(banner: Banner) {
        return this.http.put<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + banner.id, banner);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + id);
    }

    upload(url: string, apiKey: string, img: File, albumId: number) {

        const formFile = new FormData();
        formFile.append('key', apiKey);
        formFile.append('format', 'json');
        formFile.append('album_id', albumId.toString());
        formFile.append('source', img);

        // console.log(formFile);
        return this.http.post<UploadStatus<BannerImageFile>>(`${url}/api/1/upload`, formFile, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        }).pipe(map((event: HttpEvent<any>) => {
            switch(event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return{ status: 'progress', message: progress };
                case HttpEventType.Response:
                    environment.debug && console.log(event.body);

                    return this.mapResponse(event.body);
                default:
                    // return `Unhandled event: ${event.type}`;
                    return { status: 'other', message: event }
            }
        }));
    }

    updateOrder(banners: BannerOrder[]) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/update-order`, banners);
    }

    private mapResponse(resApi: unknown): UploadStatusResponse<BannerImageFile> {

        const res = <MediaSuccessResponse | MediaErrorResponse>resApi;

        if(res.status_code == 200) {
            const image = (res as MediaSuccessResponse).image;
            const responseOk : ResponseApi<BannerImageFile> = {
                statusCode: res.status_code,
                data: {
                    img: image.url
                },
                message: res.status_txt
            };
            return { status: 'response', message: responseOk };
        }

        const error = (res as MediaErrorResponse).error;
        const responseErr: ResponseApi<ErrorApi[]> = {
            statusCode: res.status_code,
            data: [
                { field: error.context, message: [ error.message ] }
            ],
            message: res.status_txt
        };
        return { status: 'validation', message: responseErr };
    }
}
