import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Article } from '../models/news/article';
import { PaginationFilters } from '../models/filters/pagination-filters';
import { ErrorApi } from '../models/general/error-api';

@Injectable()
export class NewsService {
    constructor(private http: HttpClient) {
    }

    getList(filters: PaginationFilters) {
        return this.http.post<ResponseApiList<Article | ErrorApi[]>>(`${environment.apiUrl}/v1/news/list`, filters);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Article | ErrorApi[]>>(`${environment.apiUrl}/v1/news/` + id);
    }
}
