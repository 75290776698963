<ngb-alert
    *ngIf="message"
    [type]="message.type"
    [ngClass]="{ global: global }"
    (close)="close()"
    (closed)="close()"
    [dismissible]="dismissible"
>
    <div *ngFor="let text of message.text" class="message">
        {{ text }}
    </div>
</ngb-alert>
