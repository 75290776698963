<!--/.Navbar green color-->
<mat-toolbar color="primary" class="mat-elevation-z6 fix-nav app-topnav">
    <div class="menu-top-btn">
        <button fxShow="true" fxHide.gt-sm="true" mat-icon-button (click)="toggle()"> <!--fxHide.gt-sm="true"-->
            <mat-icon aria-label="Open side bar">menu</mat-icon>
        </button>
    </div>
    <div class="nav-brand">
        <a class="brand-logo" [routerLink]="helperService.getDefaultHome()">
            <img src="./assets/images/logo/cashback-shopping-logo.png" [alt]="title" class="logo">
        </a>
    </div>
    <span class="nav-spacer"></span>
    <!-- Links -->
    <mat-nav-list role="navigation" fxShow="true" fxHide.lt-md="true">
        <a matLine mat-list-item *ngFor="let menuItem of menuItems"
            [routerLink]="[menuItem.path]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: menuItem.extra && menuItem.extra.defaultHome }">
            {{ menuItem.title }}
        </a>
    </mat-nav-list>
    <!-- Links -->
    <span class="nav-spacer"></span>
    <!-- Search form -->
    <app-quick-search fxShow="true" fxHide.lt-md="true"></app-quick-search>

    <span class="nav-spacer"></span>
    <!-- Messages Admin -->
    <button mat-icon-button (click)="goToMessages('admin')" *ngIf="isAdmin">
        <ng-template [ngIf]="nrUnredAdmin > 0" [ngIfElse]="msgBadge0">
            <mat-icon [matBadge]="nrUnredAdmin.toString()" matBadgeColor="warn" [matTooltip]="trans('nav.messages')">message</mat-icon>
        </ng-template>
        <ng-template #msgBadge0>
            <mat-icon [matTooltip]="trans('nav.admin_messages')">mark_chat_read</mat-icon>
        </ng-template>
    </button>
    <!-- Messages User -->
    <button mat-icon-button (click)="goToMessages('user')" *ngIf="loggedIn">
        <ng-template [ngIf]="nrUnredUser > 0" [ngIfElse]="msgBadge1">
            <mat-icon [matBadge]="nrUnredUser.toString()" matBadgeColor="warn" [matTooltip]="trans('nav.messages')">mail</mat-icon>
        </ng-template>
        <ng-template #msgBadge1>
            <mat-icon [matTooltip]="trans('nav.messages')">drafts</mat-icon>
        </ng-template>
    </button>
    <!-- languages -->
    <span *ngIf="languages">
        <button [matTooltip]="trans('lang.language')" mat-icon-button [matMenuTriggerFor]="appLang">
            <mat-icon>language</mat-icon>
        </button>
        <mat-menu #appLang="matMenu">
            <button *ngFor="let lang of languages" mat-menu-item [matTooltip]="trans('lang.label_' + lang)"  (click)="changeLang(lang)">
                <img [src]="getLangIcon(lang)" class="lang-img" [alt]="trans('lang.label_' + lang)">
                <span> {{ lang.toUpperCase() }} </span>
            </button>
        </mat-menu>
    </span>
    <!-- Account -->
    <button [matTooltip]="trans('nav.user')" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
        <div *ngFor="let menuItem of menuUserItems">
            <a mat-menu-item [routerLink]="[menuItem.path]" (click)="handle(menuItem)">
                <mat-icon> {{ menuItem.icon }} </mat-icon>
                <span> {{ menuItem.title }} </span>
            </a>
        </div>
    </mat-menu>
</mat-toolbar>
<!--/.Navbar green color-->