import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Pipe({ name: 'username' })
export class UsernamePipe implements PipeTransform {

    constructor(private localStorageService: LocalStorageService) {}

    transform(value: string): string {
        const username = this.localStorageService.getUsername();
        return value.replace('{username}', username);
    }

}
