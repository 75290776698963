import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { FavoriteCreate } from '../models/favorite/favorite-create';
import { ErrorApi } from '../models/general/error-api';
import { Favorite } from '../models/favorite/favorite';
import { FavoriteUser } from '../models/favorite/favorite-user';

@Injectable()
export class FavoriteService {
    constructor(private http: HttpClient) {}

    getUserFavorites(data: SortFilters) {
        return this.http.post<ResponseApiList<FavoriteUser | ErrorApi[]>>(`${environment.apiUrl}/v1/favorite/user`, data);
    }

    create(retailerId: number) {
        const favorite: FavoriteCreate = {
            retailer_id: retailerId
        };
        return this.http.post<ResponseApi<Favorite | ErrorApi[]>>(`${environment.apiUrl}/v1/favorite`, favorite);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/favorite/` + id);
    }

    check(id: number) {
        return this.http.get<ResponseApi<Favorite | boolean>>(`${environment.apiUrl}/v1/favorite/check/` + id);
    }
}
