<div class="recommended-section">
    <h2 class="title-head" [hidden]="!loading && !error && retailers.length === 0">
        <mat-icon class="mat-18">local_offer</mat-icon>
        {{ 'retailers.recommended.list_title' | translate }}
        <div class="line-full"></div>
    </h2>
    <div class="loading-shade-relative" *ngIf="loading || error">
        <app-loading *ngIf="loading"></app-loading>
        <div class="error" *ngIf="error">
            <h3>{{ 'retailers.recommended.failed_load' | translate }}</h3>
        </div>
    </div>
    <div class="recommended-list" [hidden]="error || loading || retailers.length === 0">
        <div class="band">
            <div *ngFor="let retailer of retailers; let i = index" class="card">
                <mat-card class="mat-card-w">
                    <mat-card-header>
                        <mat-card-title>
                            <a [routerLink]="helperService.genRouteRetailer(retailer.id, retailer.slug)">{{ retailer.title }}</a>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="wrap-logo" [routerLink]="helperService.genRouteRetailer(retailer.id, retailer.slug)">
                        <img class="img" [src]="retailer.image" [alt]="retailer.title" />
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>