import { Injectable } from '@angular/core';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseApiList } from '../models/general/response-api-list';
import { SiteSettings } from '../models/settings/site-settings';
import { Setting } from '../models/settings/setting';
import { RouteInfo } from '../models/routes/route-info';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    // this will be replaced by actual hash post-build.js
    private currentVersion = '{{APP_VERSION}}';

    constructor(private http: HttpClient) {
        if(this.currentVersion === '{{APP_VERSION}}') {
            this.currentVersion = 'dev'
        }
    }

    getAll() {
        return this.http.get<ResponseApiList<Setting>>(`${environment.apiUrl}/v1/setting/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<Setting>>(`${environment.apiUrl}/v1/setting/list`);
    }

    getActive() {
        return this.http.get<ResponseApi<SiteSettings>>(`${environment.apiUrl}/v1/setting/active`);
    }

    getSiteSettings() {
        return this.http.get<ResponseApi<SiteSettings>>(`${environment.apiUrl}/v1/setting/site`);
    }

    getAppRoutes() {
        return this.http.get<RouteInfo[]>(`/app-routes.json?v=${this.currentVersion}`);
    }
}
