<mat-form-field class="form-group" [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>

    <mat-chip-list #chipList aria-label="" [disabled]="disabled">
        <mat-chip *ngFor="let option of selectedOptions" [selectable]="selectable" [removable]="removable" (removed)="remove(option)">
            {{ option }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

        <input [placeholder]="placeholder" #fInput [formControl]="formControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" (click)="onClick()">
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option"> {{ option }} </mat-option>
    </mat-autocomplete>

    <div class="error-zone">
        <ng-content></ng-content>
    </div>

</mat-form-field>