<h2 mat-dialog-title>{{ 'user.admin_user.head_title' | translate }}</h2>
<!-- <app-alert></app-alert> -->
<mat-dialog-content class="mat-typography content">
    <div class="field">
        <div class="sort-icon">
            <mat-icon>search</mat-icon>
        </div>
        <mat-form-field class="search-string">
            <input matInput [formControl]="searchField" (keyup.enter)="searchEnter($event)"
                [placeholder]="trans('user.admin_user.input_user')" class="" [readonly]="loading">
        </mat-form-field>
    </div>
    <div class="results">
        <div class="loading-shade-relative" *ngIf="loading || error || users.length === 0">
            <app-loading *ngIf="loading"></app-loading>
            <div class="text-center" *ngIf="error">
                <h3> {{ 'user.admin_user.error' | translate }} </h3>
            </div>
            <div class="text-center" *ngIf="!loading && !error && users.length === 0 && !this.firstSearch">
                <h3>{{ 'user.admin_user.empty' | translate }} </h3>
            </div>
        </div>

        <div class="mat-elevation-z8 table-container" [hidden]="loading || error || users.length === 0">
            <table mat-table [dataSource]="users" matSort matSortActive="name" matSortDisableClear
                matSortDirection="desc">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.admin_user.id' | translate }}</th>
                    <td mat-cell *matCellDef="let row"> {{ row.user_id }} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.admin_user.name' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{  row.fname  }} {{ row.lname }}</td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.admin_user.username' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{row.username}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user.admin_user.email' | translate }}</th>
                    <td mat-cell *matCellDef="let row">{{ row.email }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
                    (click)="select(row, i)" (dblclick)="doneDbl(row)"
                    [ngClass]="{ 'active': i === currentIndex }"></tr>
            </table>
            <mat-paginator #matPaginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close="close">{{ 'general.cancel' | translate }}</button>
    <button mat-raised-button cdkFocusInitial color="primary" [disabled]="loading || !selectedUser" (click)="done()">
        {{ 'user.admin.select' | translate }}
    </button>
</mat-dialog-actions>