import { Component, ElementRef, Injector, OnInit, ViewChild, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { BaseComponent } from '../base.component';
import { UploadFiles } from './helpers/upload-files';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: [ './file-upload.component.scss' ]
})
export class FileUploadComponent extends BaseComponent implements OnInit, OnDestroy {

    @Output() filesChange = new EventEmitter<Array<number>>();
    @Input() eventAction: EventEmitter<string>;

    @ViewChild('fileUploadHtml') fileUploadHtmlRef: ElementRef<HTMLDivElement>;
    @ViewChild('fileInput') fileInputRef: ElementRef<HTMLInputElement>;

    public uploadFiles: UploadFiles;

    private maxAllowedFileSize: number;
    public allowedFileFormats = '';

    private scrollChange = new EventEmitter<void>();

    private subscriptionEventAction: Subscription;
    private subscriptionScrollChange: Subscription;

    constructor(injector: Injector, private messageService: MessageService) {
        super(injector);

        const allowedFileFormatsArray = this.settingsProviderService.getSettingArray('allowed_file_formats', true, []);
        this.allowedFileFormats = allowedFileFormatsArray.map(x => '.' + x).join(', ');

        this.maxAllowedFileSize = this.settingsProviderService.getSettingNumber('max_allowed_file_size', 15 * 1024 * 1024);
    }

    ngOnInit(): void {

        this.uploadFiles = new UploadFiles(
            this.messageService,
            this.maxAllowedFileSize,
            this.helper,
            this.filesChange,
            this.scrollChange
        );

        if(this.eventAction) {
            this.subscriptionEventAction = this.eventAction?.subscribe((event: string) => {
                environment.debug && console.log(event);
                if(event === 'reset') {
                    this.reset();
                }
            });
        }

        this.subscriptionScrollChange = this.scrollChange.subscribe(() => {
            setTimeout(() => {
                const h = this.fileUploadHtmlRef?.nativeElement;
                if(!h) {
                    return;
                }

                const element = this.helper.last(Array.from(h.querySelectorAll('.upload-file')));

                if(this.helper.isInViewport(element)) {
                    return;
                }

                element?.scrollIntoView({
                    behavior: 'smooth', block: 'center', inline: 'nearest'
                });
            }, 200);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionEventAction?.unsubscribe();
        this.subscriptionScrollChange?.unsubscribe();
    }

    public onFileChange(event: Event) {

        const target = <HTMLInputElement>event.target;

        if(!target?.files?.length) {
            return;
        }

        const file: File = target.files[ 0 ];

        this.uploadFiles.add(file);
    }

    public openFileInput(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        // @ts-ignore
        this.fileInputRef?.nativeElement.value = null;

        this.fileInputRef?.nativeElement.click();
    }

    private reset() {
        this.uploadFiles.reset();
    }
}
