import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseApi } from '../models/general/response-api';
import { Announcement } from '../models/announcement/announcement';
import { ErrorApi } from '../models/general/error-api';
import { AnnouncementAdmin } from '../models/announcement/announcement-admin';

@Injectable()
export class AnnouncementService {

    constructor(private http: HttpClient) {
    }

    all() {
        return this.http.get<ResponseApi<AnnouncementAdmin>>(`${environment.apiUrl}/v1/announcement/all`);
    }
    get(lang: string) {
        return this.http.get<ResponseApi<Announcement>>(`${environment.apiUrl}/v1/announcement/` + lang);
    }

    update(ann: AnnouncementAdmin) {
        return this.http.post<ResponseApi<AnnouncementAdmin | ErrorApi[]>>(`${environment.apiUrl}/v1/announcement`, ann);
    }
}
