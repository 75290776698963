
import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit, Injector } from '@angular/core';
import { SlideInOutAnimation } from 'src/app/shared/modules/animations';
import { AsynchronouslyInitialisedComponent } from 'src/app/shared/components/asynchronously-initialised.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { ReviewService } from 'src/app/shared/services/review.service';
import { ResponseApiList } from 'src/app/shared/models/general/response-api-list';
import { ReviewFormComponent } from './review-form/review-form.component';
import { RetailerExtraShort } from 'src/app/shared/models/retailer/retailer-extra-short';
import { ReviewList } from 'src/app/shared/models/review/review-list';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-retailer-reviews',
    templateUrl: './retailer-reviews.component.html',
    styleUrls: [ './retailer-reviews.component.scss' ],
    animations: [ SlideInOutAnimation ]
})
export class RetailerReviewsComponent extends AsynchronouslyInitialisedComponent implements OnInit, OnDestroy, AfterViewInit {

    animationState = 'out';

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;

    // tslint:disable-next-line: variable-name
    private _retailerId: number;
    @Input()
    set retailerId(retailerId: number) {
        this._retailerId = retailerId;
    }
    get retailerId(): number {
        return this._retailerId;
    }

    // tslint:disable-next-line: variable-name
    private _retailerTitle;
    @Input()
    set retailerTitle(retailerTitle: string) {
        this._retailerTitle = retailerTitle;
    }
    get retailerTitle(): string {
        return this._retailerTitle;
    }

    total = 0;

    @ViewChild('matPaginator1', { static: true }) paginator1: MatPaginator;
    @ViewChild('matPaginator2', { static: true }) paginator2: MatPaginator;

    reviews: ReviewList[] = [];

    private subscriptionPag1: Subscription;
    private subscriptionPag2: Subscription;

    constructor(injector: Injector,
                private reviewService: ReviewService,
                private dialog: MatDialog) {

        super(injector);

        this.pageSize = this.settingsProviderService.getSettingNumber('reviews_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_2');
        this.pageSizeOptions = this.defaultPageSizeOptions;
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.paginator1.pageSize = this.pageSize;
        this.paginator2.pageSize = this.pageSize;

        this.subscriptionPag1 = this.paginator1.page.subscribe(() => {
            this.paginator2.pageIndex = this.paginator1.pageIndex;
            this.paginator2.pageSize = this.paginator1.pageSize;
            this.getReviews();
        });

        this.subscriptionPag2 = this.paginator2.page.subscribe(() => {
            this.paginator1.pageIndex = this.paginator2.pageIndex;
            this.paginator1.pageSize = this.paginator2.pageSize;
            this.getReviews();
        });

        this.getReviews();
    }

    getReviews() {

        const filters = {
            pageIndex: this.paginator1.pageIndex,
            pageSize: this.paginator1.pageSize
        };

        this.reviewService.getByRetailer(this.retailerId, filters).subscribe(
            (response: ResponseApiList<ReviewList | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {

                    this.reviews = <ReviewList[]>response.data;
                    this.total = response.total;

                    this.pageSizeOptions = this.helper.generatePageSizeOptions(
                        this.total,
                        this.defaultPageSizeOptions,
                        this.paginator1.pageSize
                    );

                    this.error = false;

                    if(this.route.snapshot.queryParams.review) {
                        setTimeout(() => {
                            this.writeReview();
                        }, 200);
                        this.router.navigate([], { replaceUrl: true});
                    }

                } else {
                    this.error = true;
                }
                this.loading = false;
                this.componentLoaded();
            }, (error: string) => {
                this.loading = false;
                this.error = true;
                this.componentLoaded();
            });
    }

    ngOnDestroy() {
        if(this.subscriptionPag1) {
            this.subscriptionPag1.unsubscribe();
        }
        if(this.subscriptionPag2) {
            this.subscriptionPag2.unsubscribe();
        }
    }

    writeReview() {

        const retailer: RetailerExtraShort = {
            id: this.retailerId,
            title: this.retailerTitle
        };

        const dialogRef = this.dialog.open(ReviewFormComponent, {
            data: retailer
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            // console.log(result);
            if(result && typeof (result) !== 'string') {
                environment.debug && console.log('closed');
                return;
            }

            environment.debug && console.log('abort!');
        }, (error: any) => {
            console.log(error);
        });
    }
}
