import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../../environments/environment';

declare let gtag: Function;
declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private gaTrackingId: string;
    private gaAwConversion: string;

    private clientId: string;
    private loaded = false;

    constructor() {
    }

    public pageView(url?: string, title?: string): boolean {
        try {

            const params = {
                page_path: url || window.location.pathname,
                page_title: title || document.title,
                page_location: window.location.href,
                send_page_view: true
            };

            environment.debug && console.log('gtag page view', params.page_title);

            gtag('config', this.gaTrackingId, params);

            if(this.gaAwConversion) {
                gtag('config', this.gaAwConversion, params);
            }
            return true;

        } catch(ex) {
            return this.error(ex);
        }
    }

    public clickEvent(type: string, text: string) {

        const label = type + ' ' + text;
        environment.debug && console.log('gtag click', label);

        try {
            gtag('event', 'click', {
                'event_category': 'click_events',
                'event_label': label
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public login(): boolean {
        try {
            gtag('send', 'event', 'login', {
                'event_category': 'engagement',
                'event_label': 'Login',
                'value': 1
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public setUser(userId: number): void {
        try {
            gtag('set', 'userId', userId);
        } catch(ex) {
            this.error(ex);
        }
    }

    public auth(): boolean {
        try {
            gtag('send', 'event', 'authentication', {
                'event_category': 'engagement',
                'event_label': 'Authentication',
                'value': 1
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public register(): boolean {
        try {
            gtag('send', 'event', 'sign_up', {
                'event_category': 'engagement',
                'event_label': 'Register',
                'value': 1
            });
            return true;
        } catch(ex) {
            return this.error(ex);
        }
    }

    public goShopping(clickId: number, title: string): boolean {

        environment.debug && console.log('gtag goShopping', title);
        try {
            gtag('event', 'add_to_cart', {
                'event_category': 'checkout',
                'event_label': title,
                'items': [{
                    'id': clickId,
                    'item_id': clickId,
                    'name': title,
                }],
                'value': 0,
                'currency': 'RON'
            });
            return true;
        } catch(ex) {
           return this.error(ex);
        }
    }

    public getClientId(): string {
        let clientId = this.clientId;

        if(!clientId) {
            const ga = document.cookie.split(';').filter(c => c.includes('_ga=')).map(c => c.split('_ga=')[1]);

            const ga1 = (ga.length && ga[0] && ga[0].includes('GA1')) ? ga[0] : null;

            if(ga1) {
                try {
                    clientId = ga1.split('.').reduce((p,c,i,arr) => arr[2] + '.' + arr[3], '')
                } catch(e) {}
            }
        }

        return clientId;
    }

    public isBlocked(): boolean {
        return !this.loaded || !window.gtag || !this.clientId;
    }

    private loadProject() {
        return new Observable((observer: Subscriber<boolean>) => {
            switch(true) {
                case (!document || !window):
                    /**
                     * For Angular Universal
                     */
                    observer.error('Cannot render it on Server');
                    observer.complete();
                    break;

                case ((document.getElementById('google-analytics-js') != null)):
                    observer.next(true);
                    observer.complete();
                    break;

                case (!this.gaTrackingId):
                    observer.error('no-project-ga');
                    observer.complete();
                    break;

                default:
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.id = 'google-analytics-js';
                    script.async = true;
                    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.gaTrackingId;
                    script.onload = (ev: Event) => {

                        window.dataLayer = window.dataLayer || [];
                        window.gtag = window.gtag || function() {
                            window.dataLayer.push(arguments);
                        }

                        gtag('js', new Date());
                        gtag('config', this.gaTrackingId, { 'send_page_view': false });

                        gtag('get', this.gaTrackingId, 'client_id', (clientId: string) => {
                            // environment.debug && console.log('GA clientID', clientId);
                            this.clientId = clientId;
                        });

                        if(this.gaAwConversion) {
                            gtag('config', this.gaAwConversion, { 'send_page_view': false });

                            gtag('get', this.gaAwConversion, 'client_id', (clientId: string) => {
                                environment.debug && console.log('AW clientID', clientId);
                            });
                        }

                        this.loaded = true;

                        observer.next(true);
                        observer.complete();
                    };

                    const first = document.getElementsByTagName('script')[ 0 ];
                    first.parentNode.insertBefore(script, first);
            }
        });
    }

    private error(ex: any): boolean {
        environment.debug && console.log('ga not found or error', ex);
        return false;
    }

    public init(gaTrackingId: string, gaAwConversion?: string): Observable<boolean> {
        this.gaTrackingId = gaTrackingId;
        this.gaAwConversion = gaAwConversion;
        return this.loadProject();
    }
}
