import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Coupon } from '../models/coupon/coupon';
import { CouponFilters } from '../models/filters/coupon-filters';
import { PaginationFilters } from '../models/filters/pagination-filters';
import { CouponList } from '../models/coupon/coupon-list';
import { ErrorApi } from '../models/general/error-api';

@Injectable()
export class CouponService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Coupon>>(`${environment.apiUrl}/v1/coupon/all`);
    }

    getList(data: CouponFilters) {
        return this.http.post<ResponseApiList<CouponList | ErrorApi>>(`${environment.apiUrl}/v1/coupon/list`, data);
    }

    getByRetailer(retailerId: number, filters: PaginationFilters) {
        return this.http.post<ResponseApiList<CouponList | ErrorApi>>(`${environment.apiUrl}/v1/coupon/retailer/` + retailerId, filters);
    }

    getActive() {
        return this.http.get<ResponseApiList<Coupon>>(`${environment.apiUrl}/v1/coupon/active`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Coupon | ErrorApi[]>>(`${environment.apiUrl}/v1/coupon/` + id);
    }

    create(coupon: Coupon) {
        return this.http.post<ResponseApi<Coupon | ErrorApi[]>>(`${environment.apiUrl}/v1/coupon`, coupon);
    }

    update(coupon: Coupon) {
        return this.http.put<ResponseApi<Coupon | ErrorApi[]>>(`${environment.apiUrl}/v1/coupon/` + coupon.coupon_id, coupon);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/coupon/` + id);
    }

}
