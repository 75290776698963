
<ng-template [ngIf]="loading || error" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
    </div>
</ng-template>
<ng-template #loaded>
    <div class="content-body app-home" fxLayout="column wrap">

        <div class="carousel-cashback">
            <mdb-carousel *ngIf="banners && banners.length > 0" class="carousel slide"
                [animation]="cSet.animation" [interval]="cSet.interval"
                [noPause]="cSet.noPause" [keyboard]="cSet.keyboard" [isControls]="cSet.isControls">
                <mdb-carousel-item *ngFor="let banner of banners; let i = index" class="c-item">
                    <a [href]="banner.link | userId" [title]="banner.title" class="carousel-cashback-link stop-link">
                        <img class="d-block w-100" [src]="banner.img" [alt]="banner.title" [title]="banner.title" />
                    </a>
                </mdb-carousel-item>
            </mdb-carousel>
        </div>

        <div class="hello-content" *ngIf="content || loadingContent">
            <mat-card class="example-card">
                <mat-card-content>
                    <div *ngIf="content && !loadingContent" [innerHtml]="content.description"></div>
                    <app-loading *ngIf="loadingContent"></app-loading>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="recommanded-retailers" *ngIf="total > 0">
            <div class="">
                <h2>{{ 'home.recommanded_retailers' | translate }}</h2>
            </div>
            <div class="band">
                <div *ngFor="let retailer of retailers; let i = index" class="card">
                    <mat-card class="mat-card-w" >
                        <mat-card-header>
                            <mat-card-title>
                                <a [routerLink]="helperService.genRouteRetailer(retailer.id, retailer.slug)">{{ retailer.title }}</a>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="wrap-logo" [routerLink]="helperService.genRouteRetailer(retailer.id, retailer.slug)">
                            <img class="img" [src]="retailer.image" [alt]="retailer.title" />
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="see-all-retailers">
            <button mat-raised-button color="primary" mdbWavesEffect [routerLink]="helperService.genRouteRetailers()">
                {{ 'home.see_all_retailers' | translate }}
            </button>
        </div>
    </div>
</ng-template>