import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ErrorApi } from '../models/general/error-api';
import { ResponseApiList } from '../models/general/response-api-list';
import { Pmethod } from '../models/pmethod/pmethod';

@Injectable()
export class PmethodService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Pmethod>>(`${environment.apiUrl}/v1/pmethod/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<Pmethod>>(`${environment.apiUrl}/v1/pmethod/list`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Pmethod | ErrorApi[]>>(`${environment.apiUrl}/v1/pmethod/` + id);
    }

    create(pmethod: Pmethod) {
        return this.http.post<ResponseApi<Pmethod | ErrorApi[]>>(`${environment.apiUrl}/v1/pmethod`, pmethod);
    }

    update(pmethod: Pmethod) {
        return this.http.put<ResponseApi<Pmethod | ErrorApi[]>>(`${environment.apiUrl}/v1/pmethod/` + pmethod.pmethod_id, pmethod);
    }

    delete(id: number) {
        return this.http.delete<boolean | ErrorApi[]>(`${environment.apiUrl}/v1/pmethod/` + id);
    }
}
