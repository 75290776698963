import { Component, OnInit, Input } from '@angular/core';
import { Helper } from '../../../../helpers/helper';
import { MessageAttachmentShort } from '../../../../models/message/message-attachment-short';
import saveAs from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../../../../services/message.service';
import { ErrorApi } from '../../../../models/general/error-api';
import { ResponseApi } from '../../../../models/general/response-api';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

    @Input() attachments?: Array<MessageAttachmentShort>;

    constructor(public helper: Helper,
                private snackBar: MatSnackBar,
                private messageService: MessageService) {

    }

    ngOnInit(): void {
    }

    public getFile(attachmentId: number) {
        this.messageService.attachmentDownload(attachmentId).subscribe(
            (response) => {

                switch(response.status) {
                    case 200:
                        const fileName = this.helper.getFileName(response.headers.get('content-disposition'));
                        const file = response.body as Blob;
                        saveAs(file, fileName);
                        break;

                    case 202:
                    case 422:
                        const body = response.body as ResponseApi<ErrorApi[]>;
                        const errorMessages = this.helper.formatError(body.data, false);
                        const msg = errorMessages?.[0] ?? this.helper.trans('general.error');

                        this.snackBar.open(msg, this.helper.trans('general.close'));

                        break;

                    default:
                        break;
                }
            },
            (error: string) => {
                console.log(error);
                this.snackBar.open(this.helper.trans('general.error'), this.helper.trans('general.close'));
            }
        );
    }

}
