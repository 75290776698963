import { SocialRes } from 'src/app/shared/models/social/social-res';
import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginResponse } from 'src/app/shared/models/user/public/login-response';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ]
})
export class LoginComponent extends BaseComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    returnUrl: string;
    allowRegister = false;
    redirectShopping = false;
    private cashbackLink = false;

    constructor(injector: Injector,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) {

        super(injector);

        this.loading = false;
    }

    ngOnInit() {

        this.loginForm = this.formBuilder.group({
            username: [ '', Validators.required ],
            password: [ '', Validators.required ]
        });

        this.authenticationService.logout(true);

        this.returnUrl =
            this.route.snapshot.queryParams.returnUrl || this.appPath.getRoutePath(this.appPath.getDefaultUser(), '/');

        if(
            this.route.snapshot.queryParams.goShopping ||
            this.route.snapshot.queryParams.goShoppingCoupon ||
            this.route.snapshot.queryParams.review
        ) {
            this.redirectShopping = true;
        }

        if(this.route.snapshot.queryParams.cashbackLink) {
            this.cashbackLink = true;
        }
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authenticationService
            .login(this.f.username.value, this.f.password.value).subscribe(
                (result: LoginResponse) => {
                    if (result.status === 'ok') {
                        this.successLogin();
                        return;
                    }

                    this.failedLogin();
                    if(result.status === 'message') {
                        this.alertService.error(result.message, false, false);
                    }
                }
            );
    }

    public onSocialSignIn(result: SocialRes | undefined) {

        if (!result) {
            return;
        }

        this.alertService.reset();

        if (result.status === 'ok') {
            this.successLogin();
            return;
        }

        this.failedLogin();
        if (result.status === 'message') {
            this.alertService.error(result.data, false, false);
        }
    }

    private successLogin() {

        this.alertService.success([this.trans('user.login.success')], false, true);

        if(this.redirectShopping) {

            let queryParams = new Object();

            if(this.route.snapshot.queryParams.goShopping) {
                queryParams['goShopping'] = this.route.snapshot.queryParams.goShopping;
            }

            if(this.route.snapshot.queryParams.goShoppingCoupon) {
                queryParams['goShoppingCoupon'] = this.route.snapshot.queryParams.goShoppingCoupon;
            }

            if(this.route.snapshot.queryParams.review) {
                queryParams['review'] = this.route.snapshot.queryParams.review;
            }

            const url = this.helper.getPath(this.returnUrl);
            const queryParamsOld = this.helper.getQueryParams(this.returnUrl);

            this.router.navigate([ url ], { queryParams: {
                ...queryParamsOld,
                ...queryParams
            }});

        } else if(this.cashbackLink) {
            const goUrl = this.route.snapshot.queryParams.cashbackLink;

            this.helper.delay(200, () => this.helperService.handleCashbackHref(goUrl));

            const url = this.helper.getPath(this.returnUrl);
            const queryParams = this.helper.getQueryParams(this.returnUrl);
            delete queryParams.cashbackLink;
            this.router.navigate([ url ], { queryParams });

        } else {
            const url = this.helper.getPath(this.returnUrl);
            const queryParams = this.helper.getQueryParams(this.returnUrl);
            this.router.navigate([ url ], { queryParams: queryParams });
        }

        this.helper.delay(100, () => this.loading = false);
    }

    private failedLogin() {
        this.submitted = false;
        this.loginForm.reset();
        this.loading = false;
    }
}
