import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { ReviewService } from 'src/app/shared/services/review.service';
import { ReviewOwn } from 'src/app/shared/models/review/review-own';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { RetailerExtraShort } from 'src/app/shared/models/retailer/retailer-extra-short';
import { Review } from 'src/app/shared/models/review/review';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent extends BaseComponent implements OnInit {

    submitted = false;
    reviewForm: FormGroup;
    loggedIn = false;
    retailer: RetailerExtraShort;

    loginTitle: string;

    constructor(injector: Injector,
                private alertService: AlertService,
                private dialogRef: MatDialogRef<ReviewFormComponent>,
                public snackBar: MatSnackBar,
                private reviewService: ReviewService,
                private authenticationService: AuthenticationService,
                @Inject(MAT_DIALOG_DATA) public data: RetailerExtraShort) {

        super(injector);

        this.loading = false;

        this.loggedIn = this.authenticationService.loggedIn;

        this.loginTitle =  this.appPath.getRouteTitle(this.appPath.getDefaultLogin());

        this.retailer = data;

        this.reviewForm = new FormGroup({
            review_title: new FormControl(null, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]),
            rating: new FormControl(0, [
                Validators.required,
                ValidationService.numbergt0
            ]),
            review: new FormControl(null, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(this.settingsProviderService.getSettingNumber('max_review_length')),
            ])
        });
    }

    ngOnInit() {}

    onSubmit() {
        this.submitted = true;

        this.alertService.reset();

        if(!this.reviewForm.valid) {
            return;
        }

        this.loading = true;

        const review: ReviewOwn = {
            retailer_id: this.retailer.id,
            ...this.reviewForm.value
        };

        this.reviewService.create(review)
            .pipe(first())
            .subscribe(
                (response: ResponseApi<Review | ErrorApi[]>) => {
                    if(response && response.statusCode === 200) {
                        this.loading = false;
                        this.snackBar.open(this.trans('review.save_success'), this.trans('general.close'));
                        this.dialogRef.close(null);
                    } else {
                        this.loading = false;
                        const errors = <ErrorApi[]>response.data;
                        const errorMessage = this.helper.formatError(errors, false);
                        this.alertService.error(errorMessage);
                    }
                },
                (error: string) => {
                    console.log(error);
                    this.loading = false;
                    this.snackBar.open(this.trans('review.save_failed'), this.trans('general.close'));
                });
    }

    get f() {
        return this.reviewForm.controls;
    }

    login() {
        this.dialogRef.close(null);
        this.router.navigate(this.helperService.getDefaultLogin(), {
            queryParams: {
                returnUrl:  this.router.url,
                review: 1
            }
        });
    }
}
