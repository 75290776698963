import { EnviromentBase } from './environment.base';

export const environment = {
    ...EnviromentBase,
    debug: true,
    production: true,
    domain: 'cashback.monsterbox.tk',
    apiUrl: 'https://cashback-api.monsterbox.tk',
    oldAdminLoginUrl: 'https://cashback-legacy.monsterbox.tk/admin/login_v2.php',
    oldAdminLogoutUrl: 'https://cashback-legacy.monsterbox.tk/admin/api/logout_v2.php',
    versionCheckInterval: 1000*60*1, // 1 minute
    messageCheckInterval: 1000*60*1, // 1 minute
};
