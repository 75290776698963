import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../shared/components/base.component';
import { PaginationFilters } from '../shared/models/filters/pagination-filters';
import { ErrorApi } from '../shared/models/general/error-api';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { Offer } from '../shared/models/offer/offer';
import { AuthenticationService } from '../shared/services/authentication.service';
import { OffersService } from './../shared/services/offers.service';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: [ './offers.component.scss' ]
})
export class OffersComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    offers: Offer[] = [];
    total = 0;

    pageSizeOptions: number[];
    defaultPageSizeOptions: number[];
    pageSize: number;
    pageIndex = 0;

    @ViewChild('matPaginator', { static: true }) paginator: MatPaginator;

    private subscription: Subscription;

    constructor(injector: Injector,
        private offersService: OffersService,
        private authenticationService: AuthenticationService) {

        super(injector);

        this.pageSize = this.settingsProviderService.getSettingNumber('offer_per_page');
        this.defaultPageSizeOptions = this.settingsProviderService.getSettingArray('page_size_options_4');
        this.pageSizeOptions = this.defaultPageSizeOptions;

        this.route.queryParams.subscribe((params) => {

            if(params.pageSize && Number.isInteger(+params.pageSize) && +params.pageSize > 0) {
                this.pageSize = +params.pageSize;
            }

            if(params.page && Number.isInteger(+params.page) && +params.page > 0) {
                this.pageIndex = +params.page;
            }
        });
    }

    ngOnInit() {
        if(!this.settingsProviderService.getSettingBool('offers_enabled')) {
            this.router.navigate(this.helperService.getDefaultHome());
        }
    }

    ngAfterViewInit() {

        this.paginator.pageIndex = this.pageIndex;
        this.paginator.pageSize = this.pageSize;

        this.subscription = this.paginator.page.subscribe(() => {

            this.updateUrl({
                page: this.paginator.pageIndex,
                pageSize: this.paginator.pageSize
            });

            this.getOffers();
        });

        this.getOffers();

        this.helperService.handleGoShoppingReturn(this.route.snapshot.queryParams);
    }

    ngOnDestroy(): void {

        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private getOffers() {

        this.loading = true;
        const filters: PaginationFilters = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        };

        this.offersService.getList(filters).subscribe(
            (response: ResponseApiList<Offer | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {

                    this.offers = <Offer[]>response.data;
                    this.total = response.total;

                    this.pageSizeOptions = this.helper.generatePageSizeOptions(
                        this.total,
                        this.defaultPageSizeOptions,
                        this.pageSize
                    );

                    this.error = false;

                } else {
                    this.error = true;
                }
                this.loading = false;
            }, (error: string) => {
                this.error = true;
                this.loading = false;
            });
    }

    private updateUrl(newParams: { page: number, pageSize: number }) {

        this.pageIndex = newParams.page;
        this.pageSize = newParams.pageSize;

        this.helperService.updateUrl(newParams);
    }

    public goToAdminOffer(event: Event, id: number): void {
        event.preventDefault();
        this.helperService.goToAdminOffer(id);
    }

    public goToAdminOfferUrl(id: number): string {
        return id > 0 ? this.helperService.goToAdminOfferUrl(id): '#';
    }

    public isMarketing(): boolean {
        return this.authenticationService.checkPermission('marketing');
    }

    public goShopping(retailerId?: number) {
        if(!retailerId) {
            return;
        }

        this.helperService.goShopping(retailerId, null);
    }
}
