import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { Content } from '../models/content/content';
import { ResponseApiList } from '../models/general/response-api-list';
import { ErrorApi } from '../models/general/error-api';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ContentService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Content>>(`${environment.apiUrl}/v1/content/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<Content>>(`${environment.apiUrl}/v1/content/list`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Content | ErrorApi[]>>(`${environment.apiUrl}/v1/content/` + id);
    }

    getBySlug(slug: string) {

        const data = {
            slug: slug
        };
        return this.http.post<ResponseApi<Content | ErrorApi[]>>(`${environment.apiUrl}/v1/content/slug`, data);
    }
    getByName(name: string) {

        const lang = this.localStorageService.getLangStorage();

        const data = {
            name: name,
            lang: lang
        };
        return this.http.post<ResponseApi<Content | ErrorApi[]>>(`${environment.apiUrl}/v1/content/name`, data);
    }

    create(content: Content) {
        return this.http.post<ResponseApi<Content | ErrorApi[]>>(`${environment.apiUrl}/v1/content`, content);
    }

    update(content: Content) {
        return this.http.put<ResponseApi<Content | ErrorApi[]>>(`${environment.apiUrl}/v1/content/` + content.content_id, content);
    }

    delete(id: number) {
        return this.http.delete<boolean | ErrorApi[]>(`${environment.apiUrl}/v1/content/` + id);
    }

}
