import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { PaginationFilters } from '../models/filters/pagination-filters';
import { ErrorApi } from '../models/general/error-api';
import { Offer } from '../models/offer/offer';

@Injectable()
export class OffersService {
    constructor(private http: HttpClient) {
    }

    getList(filters: PaginationFilters) {
        return this.http.post<ResponseApiList<Offer | ErrorApi[]>>(`${environment.apiUrl}/v1/offer/list`, filters);
    }
}
