import { environment } from './../../environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Logged } from './../shared/models/general/logged';
import { ErrorApi } from './../shared/models/general/error-api';
import { ClickCheckLink } from './../shared/models/click/click-check-link';
import { ClicksService } from 'src/app/shared/services/clicks.service';
import { Component, Injector, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../shared/components/base.component';
import { ResponseApi } from '../shared/models/general/response-api';
import { CheckLink } from '../shared/models/click/check-link';
import { GlobalEventsService } from '../shared/services/global-events.service';

@Component({
    selector: 'app-deeplink',
    templateUrl: './deeplink.component.html',
    styleUrls: [ './deeplink.component.scss' ]
})
export class DeeplinkComponent extends BaseComponent implements OnInit, OnDestroy {

    public valid: boolean = true;
    private data: CheckLink;

    public loggedIn: boolean = false;
    public userId: number | undefined;

    private routeSubscription: Subscription;
    private loggedSubscription: Subscription;

    constructor(injector: Injector,
                private globalEventsService: GlobalEventsService,
                private clicksService: ClicksService,
                private authenticationService: AuthenticationService) {
        super(injector);
    }

    ngOnInit(): void {
        this.loggedSubscription = this.globalEventsService.logged.subscribe((result: Logged) => {
            this.loggedIn = result.loggedIn;
        });

        this.routeSubscription = this.route.url.subscribe(() => {
            const id = this.route.snapshot.paramMap.get('id');
            this.userId = +this.route.snapshot.queryParamMap.get('userId');
            this.getDeepLink(id);
        });
    }

    ngOnDestroy(): void {
        this.routeSubscription?.unsubscribe();
        this.loggedSubscription?.unsubscribe();
    }

    private getDeepLink(idStr: string) {
        try {
            const data = atob(idStr);

            const [ type, id ] = data.split('|');

            const postData: ClickCheckLink = { type, id };

            this.clicksService.checkLink(postData).subscribe(
                (response: ResponseApi<CheckLink | ErrorApi[]>) => {
                    if(response && response.statusCode == 200) {
                        this.data = <CheckLink>response.data;
                        this.valid = this.data.valid;

                        if(this.valid) {
                            let url = '';

                            if(this.loggedIn) {
                                url = this.helper.userId(this.data.shortLink, this.authenticationService.getUserId().toString());
                            } else if(this.userId > 0) {
                                url = this.helper.userId(this.data.shortLink, this.userId.toString());
                            }
                            environment.debug && console.log('redirecting', url);

                            if(url?.length) {
                                this.helper.delay(1000, () => {
                                    window.location.assign(url);
                                });
                            }
                        }

                        this.error = false;
                    } else {
                        this.error = true;
                    }

                    this.loading = false;
                },
                (error: string) => {
                    this.loading = false;
                    this.error = true;
                    console.log(error);
                }
            );

        } catch(ex) {
            this.error = false;
            this.valid = true;
        }
    }

    public onRegister() {

        this.router.navigate(this.helperService.getDefaultRegister(), {
            queryParams: {
                returnUrl:  this.router.url,
                deeplink: 1
            }
        });
    }
    public onLogin() {

        this.router.navigate(this.helperService.getDefaultLogin(), {
            queryParams: {
                returnUrl:  this.router.url,
                deeplink: 1
            }
        });
    }

    public goNoCashback() {
        if(!this.data || !this.valid) {
            return;
        }

        location.href = this.helper.userId(this.data.link, '');
    }
}
