import { MissingCashbackForm } from './../models/click/missing-cashback-form';
import { MessageView } from './../models/message/message-view';
import { MessageReply } from './../models/message/message-reply';
import { MessageCreate } from './../models/message/message-create';
import { MessageList } from './../models/message/message-list';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { SortFilters } from '../models/filters/sort-filters';
import { CreateMessage } from '../models/message/create-message';
import { ReplyMessage } from '../models/message/reply-message';
import { CreateContactMessage } from '../models/contact/create-contact-message';
import { AdminReplyMessage } from '../models/message/admin-reply-message';
import { AdminCreateMessage } from '../models/message/admin-create-message';
import { ErrorApi } from '../models/general/error-api';
import { UploadStatus } from '../models/message/upload-status';
import { map, catchError } from 'rxjs/operators';
import { AttachmentUploadResponse } from '../models/message/attachment-upload-response';
import { throwError } from 'rxjs';

@Injectable()
export class MessageService {
    constructor(private http: HttpClient) { }

    list(data: SortFilters) {
        return this.http.post<ResponseApiList<MessageList | ErrorApi[]>>(`${environment.apiUrl}/v1/message/list`, data);
    }

    adminList(data: SortFilters) {
        return this.http.post<ResponseApiList<MessageList | ErrorApi[]>>(`${environment.apiUrl}/v1/message/admin/list`, data);
    }

    create(data: CreateMessage) {
        return this.http.post<ResponseApi<MessageCreate | ErrorApi[]>>(`${environment.apiUrl}/v1/message`, data);
    }

    adminCreate(data: AdminCreateMessage) {
        return this.http.post<ResponseApi<MessageCreate | ErrorApi[]>>(`${environment.apiUrl}/v1/message/admin`, data);
    }

    contact(data: CreateContactMessage) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/message/contact`, data);
    }

    reply(data: ReplyMessage) {
        return this.http.post<ResponseApi<MessageReply | ErrorApi[]>>(`${environment.apiUrl}/v1/message/reply`, data);
    }

    adminReply(data: AdminReplyMessage) {
        return this.http.post<ResponseApi<MessageReply | ErrorApi[]>>(`${environment.apiUrl}/v1/message/admin/reply`, data);
    }

    view(id: number) {
        return this.http.get<ResponseApi<MessageView | ErrorApi[]>>(`${environment.apiUrl}/v1/message/` + id);
    }

    adminView(id: number) {
        return this.http.get<ResponseApi<MessageView | ErrorApi[]>>(`${environment.apiUrl}/v1/message/admin/` + id);
    }

    checkMessage() {
        return this.http.get<ResponseApi<number>>(`${environment.apiUrl}/v1/message/check`);
    }

    checkAdminMessage() {
        return this.http.get<ResponseApi<number>>(`${environment.apiUrl}/v1/message/admin/check`);
    }

    missingCashback(data: MissingCashbackForm) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/message/missing-cashback`, data);
    }

    attachmentUpload(file: File) {

        const formFile = new FormData();
        formFile.append('file', file);

        return this.http.post<UploadStatus<AttachmentUploadResponse>>(`${environment.apiUrl}/v1/message/attachment/upload`, formFile, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        }).pipe(map((event: HttpEvent<any>) => {
            // environment.debug && console.log('HttpEvent', event.type, event);

            switch(event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };

                case HttpEventType.Response: {

                    const response = <ResponseApi<AttachmentUploadResponse | ErrorApi[]>>event.body

                    if(response.statusCode === 200) {
                        return { status: 'response', message: response };
                    }

                    return { status: 'validation', message: response };
                }

                default:
                    // return `Unhandled event: ${event.type}`;
                    return { status: 'other', message: event };
            }
        }));
    }

    attachmentDownload(id: number) {
        return this.http.get(`${environment.apiUrl}/v1/message/attachment/download/` + id, {
            observe: 'response',
            reportProgress: false,
            responseType: 'blob',
        }).pipe(catchError(async (err: HttpErrorResponse) => {
            console.log(err);
            if(err.status === 422) {

                const blob = err.error as Blob;
                const str = await blob.text();
                const body = JSON.parse(str);

                const response = new HttpResponse<ResponseApi<ErrorApi[]>>({
                    body,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url
                });
                return response;
            }

            console.log('error from api jwt!');
            console.log(err);

            const error: string = (err && err.error && err.error.message) || err.message || err.statusText;
            throwError(error);
            return null;
        }));
    }

    public changeStatus(messageId: number, status: string) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/message/admin/change-status`, {
            messageId,
            status
        });
    }
}
