import { BaseComponent } from '../../shared/components/base.component';
import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { Article } from 'src/app/shared/models/news/article';
import { NewsService } from 'src/app/shared/services/news.service';
import { ResponseApi } from 'src/app/shared/models/general/response-api';
import { ErrorApi } from 'src/app/shared/models/general/error-api';
import { AddThisService } from '../../shared/services/add-this.service';
import { AuthenticationService } from './../../shared/services/authentication.service';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: [ './article.component.scss' ]
})
export class ArticleComponent extends BaseComponent implements OnInit, OnDestroy {

    public article: Article = null;
    private routeSubscription: Subscription;

    constructor(injector: Injector,
                private newsService: NewsService,
                private addThisService: AddThisService,
                private authenticationService: AuthenticationService,
                private analyticsService: AnalyticsService) {

        super(injector);
    }

    ngOnInit() {

        if(!this.settingsProviderService.getSettingBool('news_enabled')) {
            this.router.navigate(this.helperService.getDefaultHome());
        }

        this.routeSubscription = this.route.url.subscribe((url) => {
            const id = +this.route.snapshot.paramMap.get('id');
            const slug = this.route.snapshot.paramMap.get('slug');
            // console.log(id, slug);
            this.loadPage(id, slug);
        });
    }

    ngOnDestroy() {
        if(this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }

        this.addThisService.unregister();
    }

    private loadPage(id: number, slug: string) {

        this.loading = true;
        this.error = false;

        this.newsService.getById(id).subscribe(
            (response: ResponseApi<Article | ErrorApi[]>) => {
                if(response && response.statusCode === 200 && response.data) {
                    this.article = <Article>response.data;

                    this.helperService.handleMeta(this.article.news_title, this.article.keywords, this.article.img_url);
                    this.helperService.setTitle(this.article.news_title);

                    this.analyticsService.pageView(this.router.url, this.article.news_title);

                    this.error = false;

                    this.addThisService.register().subscribe(() => {});

                } else {
                    this.error = true;
                }
                this.loading = false;
            },
            (error: string) => {
                console.log('error on content load!', error);
                this.error = true;
                this.loading = false;
            }
        );
    }

    public postSocial(id: number): void {
        this.router.navigate(this.helperService.getDefaultSocialPost(), {
            queryParams: {
                ...this.route.snapshot.queryParams,
                selected: 'news',
                articleId: id,
                articleTitle: this.article.news_title,
                returnUrl: this.router.url
            }
        })
    }

    public isMarketing(): boolean {
        return this.authenticationService.checkPermission('marketing');
    }
}
