import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Country } from '../models/country/country';
import { ErrorApi } from '../models/general/error-api';
import { CountryShort } from '../models/country/country-short';

@Injectable()
export class CountryService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Country>>(`${environment.apiUrl}/v1/country/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<CountryShort>>(`${environment.apiUrl}/v1/country/list`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Country | ErrorApi[]>>(`${environment.apiUrl}/v1/country/` + id);
    }

    create(country: Country) {
        return this.http.post<ResponseApi<Country | ErrorApi[]>>(`${environment.apiUrl}/v1/country`, country);
    }

    update(country: Country) {
        return this.http.put<ResponseApi<Country | ErrorApi[]>>(`${environment.apiUrl}/v1/country/` + country.country_id, country);
    }

    delete(id: number) {
        return this.http.delete<boolean | ErrorApi[]>(`${environment.apiUrl}/v1/country/` + id);
    }

}
